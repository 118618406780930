import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

import styles from './index.module.css';

const Text = ({ weight, color, size, children }) => (
  <span
    className={classNames(styles.root, styles[weight], styles[size])}
    style={{ color: colors[color] }}
  >
    {children}
  </span>
);

Text.propTypes = {
  weight: PropTypes.oneOf(['', 'regular', 'semiBold']),
  color: PropTypes.oneOf(Object.keys(colors)),
  size: PropTypes.oneOf(['', 'caption']),
  children: PropTypes.node.isRequired,
};

Text.defaultProps = {
  color: 'rebranding-md-color-brand-black',
  weight: 'regular',
  size: '',
};
export { Text };
export default memo(Text);
