import _ from 'lodash';
import { statuses, invoiceStatuses } from 'payments/translations';
import {
  allStatusRegex,
  cancelledStatus,
  expiredStatus,
  orderRefundCompletedStatus as refundCompleted,
  paidStatus,
  pendingStatus,
  refundingStatusRegex,
} from 'payments/propTypes';

const orderStatusToMap = [
  cancelledStatus,
  paidStatus,
  pendingStatus,
  refundCompleted,
];

const invoiceStatusToMap = [
  cancelledStatus,
  expiredStatus,
  paidStatus,
  pendingStatus,
];
const customStatus = [
  { label: 'All status', value: allStatusRegex },
  { label: 'Refunding', value: refundingStatusRegex },
];

export const invoiceFilterStatuses = _.chain(invoiceStatusToMap)
  .map((status) => ({ label: invoiceStatuses[status], value: status }))
  .concat(customStatus[0])
  .orderBy('label')
  .value();

export const orderFilterStatuses = _.chain(orderStatusToMap)
  .map((status) => ({ label: statuses[status], value: status }))
  .concat(customStatus)
  .orderBy('label')
  .value();
