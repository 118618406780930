import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import CodeDisplay from 'common/components/CodeDisplay';
import QRCode from 'common/components/QRCode';
import Label from 'common/components/Label';
import CodeInput from '../../CodeInput';

import styles from './index.module.css';

const Enable = ({ onCodeSubmit, keyUri, secret, errorMessage }) => (
    <>
      <Text color="rebranding-md-color-grey-70">
        <p className={styles.description}>
          Use the authentication code or scan the QR code on your authenticator
          app.
        </p>
        <p className={styles.descriptionMobile}>
          Use the authentication code on your authenticator app.
        </p>
      </Text>
      <div className={styles.grid}>
        <div className={styles.column}>
          <CodeDisplay
            label="Authentication secret code"
            code={secret}
            clipboard
          />
          <CodeInput
            labelText="Insert verification code provided by the Authenticator app"
            buttonLabel="Activate"
            onCodeSubmit={onCodeSubmit}
            errorMessage={errorMessage}
          />
        </div>
        <div className={styles['qr-wrapper']}>
          <Label>Scan this code</Label>
          <div className={styles.qrCode}>
            <QRCode content={keyUri} />
          </div>
        </div>
      </div>
    </>
  );

Enable.propTypes = {
  onCodeSubmit: PropTypes.func.isRequired,
  keyUri: PropTypes.string.isRequired,
  secret: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default Enable;
