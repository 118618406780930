import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { settlement as settlementPropType } from 'settlements/propTypes';
import classNames from 'classnames';

import Heading from 'common/components/Heading';
import Icon from 'common/components/Icon';
import Separator from 'common/components/Separator';
import Text from 'common/components/Text';
import ToolTip from 'common/components/ToolTip';
import PopUp from 'common/components/PopUp';
import { cryptoChains } from 'common/currencies';

import colors from 'common/styles/colors';
import { displayUtcDate, getNextDay } from 'common/services/date';
import stringUtils from 'common/stringUtils';
import ReportLinks from '../ReportLinks';
import { settlementsPeriodFormatter } from '../../utils/periodFormatter';

import styles from './index.module.css';

const Extended = () => (
  <span className={styles.extendedBadge} data-test="extended-period">
    <span className={styles.iconWrapper}>
      <Icon.Clock
        size={'small'}
        description="Period Extended"
        fill={colors['rebranding-md-color-orange']}
      />
    </span>
    <ToolTip
      message={
        <>
          End date was extended because you did not reach the minimum total to
          transfer to your bank. To stop extending go to the{' '}
          <Link to="/settings/settlements">
            Settlements tab in your Settings
          </Link>
          .
        </>
      }
    >
      <Text size="caption" color="rebranding-md-color-orange">
        Period extended
      </Text>
    </ToolTip>
  </span>
);

const Field = ({ extended, label, value, subtitle }) => (
  <div className={styles.ongoingField}>
    <div className={styles.ongoingFieldLabel}>
      <Text>{label}</Text>
      {extended && <Extended />}
    </div>
    <Heading level="3" size="3">
      {value}
    </Heading>
    <Text size="caption" color="rebranding-md-color-grey-60">
      {subtitle}
    </Text>
  </div>
);

const SmallField = ({ downloadUrl, label, long, settlementId, value }) => {
  const classes = classNames(styles.ongoingField, { [styles.longField]: long });
  return (
    <div className={classes}>
      <Text size="caption" color="rebranding-md-color-grey-60">
        {label}
      </Text>
      {downloadUrl ? (
        <div className={styles.downloadLink}>
          <PopUp content={<ReportLinks settlementId={settlementId} />}>
            <span className={styles.downloadText}>{value}</span>{' '}
            <Icon.Download size={'small'} />
          </PopUp>
        </div>
      ) : (
        <Text>{value}</Text>
      )}
    </div>
  );
};

const isExtended = (firstEndDate, periodEndsAt) => {
  const periodEndDate = new Date(firstEndDate);
  const settlementEndDate = new Date(periodEndsAt);

  return periodEndDate.getTime() < settlementEndDate.getTime();
};

const OngoingSettlement = ({
  bankInfo,
  settlement: {
    endsAt,
    id,
    regularPeriodEndsAt,
    shortId,
    startsAt,
    toBeSettledAmount,
    periodEndsAt,
    settlementBlockchain,
  },
}) => {
  const match = useRouteMatch();
  const extended = isExtended(regularPeriodEndsAt, periodEndsAt);

  const balanceSubtitle = id
    ? `Updated - ${displayUtcDate(endsAt, 'dd MMM, HH:mm')} UTC`
    : `Will update at the end of the day`;

  const isFiatSettlement = ['EUR', 'USD', 'GBP'].includes(
    toBeSettledAmount.currency
  );

  return (
    <>
      <div className={styles.headingWrapper} data-test="ongoing-settlement">
        <Heading level="3" size="3" weight="medium">
          Ongoing
        </Heading>
      </div>
      <div className={styles.ongoingBody}>
        <div className={styles.ongoingRow}>
          <Field
            label="Balance"
            value={`${toBeSettledAmount.currency} ${toBeSettledAmount.value}`}
            subtitle={balanceSubtitle}
          />
          <Field
            label="Period"
            value={settlementsPeriodFormatter(startsAt, periodEndsAt)}
            extended={extended}
          />
          <Field
            label="Expected payout date"
            value={displayUtcDate(getNextDay(periodEndsAt), 'dd MMM, yy')}
          />
        </div>
        <Separator />
        <div className={styles.ongoingRow}>
          {id && (
            <SmallField
              label="Settlement"
              value={shortId}
              downloadUrl={`${match.path}/${id}`}
              settlementId={id}
            />
          )}
          {isFiatSettlement ? (
            <>
              {bankInfo.iban && (
                <SmallField label="IBAN" value={bankInfo.iban} />
              )}
              {bankInfo.swiftCode && (
                <SmallField label="Swift" value={bankInfo.swiftCode} />
              )}
              {bankInfo.bankAccountNumber && (
                <SmallField
                  label="Bank account number"
                  value={bankInfo.bankAccountNumber}
                />
              )}
              {bankInfo.routingNumber && (
                <SmallField
                  label="Routing number"
                  value={bankInfo.routingNumber}
                />
              )}
              {bankInfo.sortCode && (
                <SmallField label="Sort code" value={bankInfo.sortCode} />
              )}
            </>
          ) : (
            <>
              <div className={styles.addressWrapper}>
                <SmallField
                  label="Wallet address"
                  value={stringUtils.shortenAddress(
                    bankInfo.cryptoWalletAddress,
                    13,
                    11
                  )}
                />
              </div>
              <div className={styles.addressWrapperMobile}>
                <SmallField
                  label="Wallet address"
                  value={stringUtils.shortenAddress(
                    bankInfo.cryptoWalletAddress,
                    13,
                    11
                  )}
                />
              </div>
              <div className={styles.blockchainWrapper}>
                <SmallField
                  label="Network"
                  value={cryptoChains[settlementBlockchain]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

OngoingSettlement.propTypes = {
  bankInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  settlement: settlementPropType.isRequired,
};

export default OngoingSettlement;
