import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import PendingTooltip from 'settlements/components/PendingTooltip';

import { getScenario } from '../utils';

import styles from './index.module.css';

const TransferredOnMobile = ({ settledOn }) => {
  const {
    color,
    content,
    mobileSpecific,
    icon: Icon,
    showTooltip: pendingTooltip,
  } = getScenario(settledOn);

  return (
    <div className={styles.root}>
      <Text size="caption" color={color}>
        {mobileSpecific || content}
      </Text>
      {pendingTooltip && <PendingTooltip />}
      {Icon && <Icon />}
    </div>
  );
};
TransferredOnMobile.propTypes = {
  settledOn: PropTypes.string,
};

export default TransferredOnMobile;
