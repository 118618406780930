import React from 'react';
import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import propTypes from 'prop-types';

import styles from './index.module.css';

const Wrapper = ({ fill, children }) => (
  <div className={styles.wrapper} style={{ backgroundColor: fill }}>
    {children}
  </div>
);

Wrapper.propTypes = {
  fill: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

const Pending = () => (
  <Wrapper fill={colors['rebranding-md-color-blue']}>
    <Icon.Info fill="white" size="medium" description="Order Placed" />
  </Wrapper>
);

const RefundProcessing = () => (
  <Wrapper fill={colors['rebranding-md-color-orange']}>
    <Icon.Dots fill="white" size="medium" description="Refund Processing" />
  </Wrapper>
);

const Canceled = () => (
  <Wrapper fill={colors['rebranding-md-color-grey-60']}>
    <Icon.Cross fill="white" size="medium" description="Order Canceled" />
  </Wrapper>
);
const Paid = () => (
  <Wrapper fill={colors['rebranding-md-color-green']}>
    <Icon.Checkmark size="small" description="Order Paid" fill="white" />
  </Wrapper>
);
const RefundCompleted = () => (
  <Wrapper fill={colors['rebranding-md-color-pink']}>
    <Icon.Arrow
      size="small"
      description="Refund Completed"
      direction="left"
      fill="white"
    />
  </Wrapper>
);
export default {
  paid: Paid,
  pending: Pending,
  canceled: Canceled,
  refundProcessing: RefundProcessing,
  refundCompleted: RefundCompleted,
};
