import React from 'react';
import PropTypes from 'prop-types';

import { cryptoChains } from 'common/currencies';
import { displayUtcDate } from 'common/services/date';
import { reportPeriodFormatter } from '../../utils/periodFormatter';

import styles from './index.module.css';

const ReportHeader = ({
  activeStoreName,
  companyOfficialName,
  start,
  end,
  settledOn,
  id,
  blockchain,
}) => {
  const period = reportPeriodFormatter(start, end);
  const settledOnDate = settledOn
    ? displayUtcDate(settledOn, 'yyyy/MM/dd')
    : 'N/A';

  return (
    <header className={styles.root}>
      <div className={styles.title}>
        <h1 className={styles.heading}>Settlement report</h1>
        <div className={styles.id}>
          <strong className={styles.bold}>ID: </strong>
          {id}
        </div>
      </div>
      <div className={styles.settlementDetails}>
        <div className={styles.item}>
          <strong className={styles.bold}>Merchant: </strong>
          {activeStoreName}
        </div>
        <div className={styles.item}>
          <strong className={styles.bold}>Company legal name: </strong>{' '}
          {companyOfficialName}
        </div>
        <div className={styles.item}>
          <strong className={styles.bold}>Report period: </strong>
          <time dateTime={period}>{period}</time>
        </div>
        <div className={styles.item}>
          <strong className={styles.bold}>Settled on: </strong> {settledOnDate}
        </div>
        {blockchain && (
          <div className={styles.blockchainText}>
            This settlement was processed using the {cryptoChains[blockchain]}{' '}
            Network
          </div>
        )}
      </div>
    </header>
  );
};

ReportHeader.propTypes = {
  activeStoreName: PropTypes.string.isRequired,
  companyOfficialName: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  settledOn: PropTypes.string,
  id: PropTypes.string.isRequired,
  blockchain: PropTypes.string,
};

ReportHeader.defaultProps = {
  settledOn: null,
};

export default ReportHeader;
