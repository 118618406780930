import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Caret } from '../Icons';

import styles from './index.module.css';

const Button = ({ open, children, buttonProps, name, error }) => {
  const rootClassNames = classNames(styles.button, {
    [styles.open]: open,
    [styles.error]: error,
  });

  return (
    <button
      type="button"
      className={rootClassNames}
      {...buttonProps}
      name={name}
    >
      {children}
      <Caret isOpen={open} />
    </button>
  );
};

Button.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  open: PropTypes.bool.isRequired,
  buttonProps: PropTypes.shape({}).isRequired,
  children: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Button;
