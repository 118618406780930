import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import styles from './index.module.css';

const pagesReducer = (maxPages) => (acc, page) => {
  const invalid = page < 1 || page > maxPages;
  const lastPage = acc.length && acc[acc.length - 1];
  const isAfterLast = page - 1 === lastPage;

  if (invalid) return acc;
  if (isAfterLast) return [...acc, page];
  if (page - lastPage === 2) return [...acc, page - 1, page];
  if (acc.length) return [...acc, '...', page];
  return [page];
};

class PageNumbers extends PureComponent {
  get pages() {
    const { currentPage, maxPages } = this.props;

    return [1, ..._.range(currentPage - 1, currentPage + 2), maxPages];
  }

  get buttons() {
    const { maxPages } = this.props;

    return _(this.pages)
      .uniq()
      .reduce(pagesReducer(maxPages), [])
      .map(this.renderButton);
  }

  handleClick = (page) => (evt) => {
    const { setPage } = this.props;
    evt.preventDefault();
    setPage(page);
  };

  renderButton = (page, index) => {
    const { currentPage } = this.props;
    const isNumber = _.isNumber(page);
    const isCurrentPage = currentPage === page;
    const ariaLabel = isNumber ? `Page ${currentPage}` : '...';
    const onClick = isNumber ? this.handleClick(page) : null;
    const handleKeyDown = () => ({ key = '' }) => {
      if (key === 'Enter') {
        this.handleClick(page);
      }
    };
    const className = classNames({
      [styles.pageNumber]: isNumber,
      [styles.currentPage]: isCurrentPage,
    });

    return (
      <motion.span
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        key={`${page}-${index}`}
        className={className}
        aria-current="page"
        aria-label={ariaLabel}
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={isNumber ? handleKeyDown() : null}
      >
        {page}
      </motion.span>
    );
  };

  render() {
    return <>{this.buttons}</>;
  }
}

PageNumbers.propTypes = {
  setPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  maxPages: PropTypes.number.isRequired,
};

export default PageNumbers;
