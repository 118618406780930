import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const newWidth = window.innerWidth;

    setWindowWidth(newWidth);
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  return windowWidth;
};

export default useWindowWidth;
