import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { settlement as settlementPropType } from 'settlements/propTypes';

import SettlementsTable from '../SettlementsTable';
import OngoingSettlement from '../OngoingSettlement';

import styles from './index.module.css';

const Settlements = ({ bankInfo, settlements, ongoing }) => {
  const tableSettlements = _.pickBy(
    settlements,
    (a) => a.status !== 'accumulating'
  );

  return (
    <div className={styles.root} role="main" aria-labelledby="page-title">
      <div className={styles.container}>
        <div>
          {ongoing && (
            <OngoingSettlement bankInfo={bankInfo} settlement={ongoing} />
          )}
          <SettlementsTable settlements={tableSettlements} />
        </div>
      </div>
    </div>
  );
};

Settlements.propTypes = {
  bankInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  ongoing: settlementPropType,
  settlements: PropTypes.oneOfType([
    PropTypes.arrayOf(settlementPropType),
    PropTypes.objectOf(settlementPropType),
  ]).isRequired,
};

export default Settlements;
