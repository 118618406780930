import { formatDate } from 'common/services/date';
import _ from 'lodash';
import { copyTransform, applyWarningMessage } from './copy_transformer';

const eventsByDate = (activities) => {
  const transformedEvents = _(activities)
    .orderBy(['createdAt', 'id'], ['desc', 'desc'])
    .map(copyTransform)
    .compact()
    .value();

  // if the last event is RefundProcessing we need to add a warning
  // message that it may take up to 24hrs
  const processedEvents = applyWarningMessage(transformedEvents);

  return _(processedEvents)
    .groupBy((activity) => formatDate(activity.createdAt, 'dd MMM'))
    .value();
};

export default eventsByDate;
