import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { rootRoute } from 'app/containers/routes';
import sessionActions from 'session/actions';
import { selectors as featuresSelectors } from '../../features';
import TwoFactorVerification from '../components/TwoFactorVerification';
import actions from '../actions';
import SignIn from '../components/SignIn';
import {
  isIdentified as isIdentifiedSelector,
  isTwoFactorWrong as isTwoFactorWrongSelector,
  isAuthenticated as isAuthenticatedSelector,
  signInError as signInErrorSelector,
  twoFactorError as twoFactorErrorSelector,
} from '../reducers';

class SignInPage extends Component {
  state = {
    accountLocked: false,
  };

  componentDidUpdate(prevProps) {
    const { twoFactorError, cancel } = this.props;

    if (
      prevProps.twoFactorError !== twoFactorError &&
      twoFactorError === 'account_locked'
    ) {
      cancel();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ accountLocked: true });
    }
  }

  handleSignIn = (values) => {
    const { signIn } = this.props;

    this.setState({ accountLocked: false });
    return signIn(values);
  };

  handleVerify = (values) => {
    const { verify } = this.props;

    return verify(values);
  };

  handleCancelVerify = () => {
    const { cancel } = this.props;

    return cancel();
  };

  render() {
    const {
      isAuthenticated,
      isIdentified,
      isTwoFactorWrong,
      isMerchantRegistrationActive,
      location,
      signInError,
    } = this.props;

    if (isAuthenticated)
      return (
        <Redirect to={{ pathname: rootRoute, state: { from: location } }} />
      );

    if (isIdentified)
      return (
        <TwoFactorVerification
          {...this.props}
          onAuthCodeSubmit={this.handleVerify}
          cancel={this.handleCancelVerify}
          invalid={isTwoFactorWrong}
        />
      );

    return (
      <SignIn
        {...this.props}
        error={(this.state.accountLocked && 'account_locked') || signInError}
        isMerchantRegistrationActive={isMerchantRegistrationActive}
        onSubmit={this.handleSignIn}
      />
    );
  }
}

SignInPage.propTypes = {
  signIn: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isIdentified: PropTypes.bool.isRequired,
  isTwoFactorWrong: PropTypes.bool.isRequired,
  isMerchantRegistrationActive: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  signInError: PropTypes.string,
  twoFactorError: PropTypes.string,
};

const ConnectedSignInPage = connect(
  (state) => ({
    isAuthenticated: isAuthenticatedSelector(state),
    isIdentified: isIdentifiedSelector(state),
    isTwoFactorWrong: isTwoFactorWrongSelector(state),
    isMerchantRegistrationActive:
      featuresSelectors.isMerchantRegistrationActive(state),
    signInError: signInErrorSelector(state),
    twoFactorError: twoFactorErrorSelector(state),
  }),
  {
    signIn: actions.signInRequest,
    verify: actions.verifyRequest,
    cancel: sessionActions.sessionClear,
  }
)(SignInPage);

export default ConnectedSignInPage;
