import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';

import Button from 'common/components/Button';
import TextBox from 'common/components/TextBox';
import formUtils from 'common/formUtils.js';
import Card from '../Card';

import styles from '../index.module.css';

const Profile = ({ onSubmit, isSubmitting, ...initialValues }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Card heading="Profile" id="personal-profile">
              <div className={styles.grid}>
                <Field name="firstName" validate={formUtils.validateRequired}>
                  {({ field, meta }) => (
                    <TextBox
                      id="firstName"
                      labelText="First name"
                      labelType="firstName"
                      placeholder="Your given name"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="lastName" validate={formUtils.validateRequired}>
                  {({ field, meta }) => (
                    <TextBox
                      id="lastName"
                      labelText="Last name"
                      labelType="lastName"
                      placeholder="Your family name"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="email" validate={formUtils.validateEmail}>
                  {({ field, meta }) => (
                    <TextBox
                      disabled
                      id="email"
                      labelText="Email"
                      labelType="email"
                      placeholder="Your email address"
                      autoComplete="new-email"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  full
                  kind="primary"
                  loading={isSubmitting}
                  disabled={formUtils.isSubmitDisabled(errors, touched)}
                >
                  <button
                    type="submit"
                    id="profile-submit"
                    data-test="update-profile-button"
                  >
                    Save
                  </button>
                </Button>
              </div>
            </Card>
          </form>
        )}
    </Formik>
  );

Profile.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Profile;
