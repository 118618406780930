import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FadeInTransition from 'common/components/FadeInTransition';
import Layout from 'common/containers/Layout';
import LoadingPage from 'common/components/LoadingPage';

import {
  webhooks as webhooksSelector,
  isFetchingWebhooks as isFetchingWebhooksSelector,
} from '../reducers';
import actions from '../actions';

import styles from './index.module.css';

import IntegrationsTabs from '../components/IntegrationsTabs';
import WebhooksTable from '../components/WebhooksTable';

const useWebhooksData = ({ page, size }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.webhooksRequest({
        page,
        size,
      })
    );
  }, [page, size]);

  const webhooks = useSelector(webhooksSelector);
  const isFetchingWebhooks = useSelector(isFetchingWebhooksSelector);

  return {
    webhooks: webhooks.byId,
    totalElements: webhooks.totalElements,
    isFetching: isFetchingWebhooks,
  };
};

const PAGE_SIZE_OPTIONS = [10, 25, 50];

const WebhooksContainer = () => {
  const [pageData, setPageData] = useState({
    page: 1,
    size: PAGE_SIZE_OPTIONS[0],
  });
  const { webhooks, totalElements, isFetching } = useWebhooksData(pageData);

  return (
    <Layout heading="Integrations">
      <IntegrationsTabs />
      {isFetching && <LoadingPage />}
      {!isFetching && (
        <FadeInTransition className={styles.transition}>
          {webhooks && (
            <WebhooksTable
              className={styles.table}
              webhooks={webhooks}
              paginationData={{
                page: pageData.page,
                pageSize: pageData.size,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                setPageSize: (size) => setPageData({ page: 1, size }),
                setPage: (page) => setPageData({ page, size: pageData.size }),
                totalElements,
              }}
            />
          )}
        </FadeInTransition>
      )}
    </Layout>
  );
};

export default WebhooksContainer;
