import React from 'react';
import PropTypes from 'prop-types';

import Label from 'common/components/Label';
import Dropdown from 'common/components/Dropdown';

import styles from './index.module.css';

const integrationTypes = [
  {
    label: 'Magento 1',
    value: 'magento1',
  },
  {
    label: 'Magento 2',
    value: 'magento2',
  },
  {
    label: 'OpenCart',
    value: 'opencart',
  },
  {
    label: 'Payrexx',
    value: 'payrexx',
  },
  {
    label: 'PrestaShop',
    value: 'prestashop',
  },
  {
    label: 'Shopify',
    value: 'shopify',
  },
  {
    label: 'Weasy',
    value: 'weasy',
  },
  {
    label: 'WHMCS',
    value: 'whmcs',
  },
  {
    label: 'WooCommerce',
    value: 'woocommerce',
  },
  {
    label: 'Custom (using xMoney crypto API)',
    value: 'custom',
  },
];

const IntegrationInfo = ({ setIntegrationType, storeIntegrationType }) => (
  <div className={styles.row}>
    <Label>Type of integration</Label>
    <Dropdown
      id="integrationType"
      name="integrationType"
      placeholder="Choose the way you'll integrate"
      items={integrationTypes}
      initialSelectedItem={storeIntegrationType}
      onChange={(value) => {
        setIntegrationType(value);
      }}
    />
  </div>
);

IntegrationInfo.propTypes = {
  setIntegrationType: PropTypes.func.isRequired,
  storeIntegrationType: PropTypes.string,
};

export default IntegrationInfo;
