import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
  statusTableFilter,
  tableFilterDateRangesProp,
} from 'payments/propTypes';
import Dropdown from 'common/components/Dropdown';
import SearchInput from 'common/components/SearchInput';
import Label from 'common/components/Label';

import styles from './index.module.css';

const PaymentsFilter = ({
  disabled,
  statuses,
  dateRanges,
  searchValue: storedSearch,
  statusValue,
  dateRangeValue,
  onStatusChange,
  onDateRangeChange,
  onSearchChange,
}) => {
  const [search, setSearch] = useState(storedSearch);
  const handleInputChange = useCallback(
    (event) => {
      setSearch(event.target.value);
      onSearchChange(event.target.value);
    },
    [setSearch, onSearchChange]
  );

  return (
    <>
      <div className={styles.searchInputContainer}>
        <SearchInput
          disabled={disabled}
          placeholder="Search"
          value={search}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.filterContainer} data-test="filter-status-button">
        <Label>Status</Label>
        <Dropdown
          disabled
          onChange={onStatusChange}
          initialSelectedItem={statusValue}
          selectedItem={statusValue}
          items={statuses}
        />
      </div>
      <div className={styles.filterContainer} data-test="filter-range-button">
        <Label>Timeframe</Label>
        <Dropdown
          disabled
          onChange={onDateRangeChange}
          initialSelectedItem={dateRangeValue}
          selectedItem={dateRangeValue}
          items={dateRanges}
        />
      </div>
    </>
  );
};

PaymentsFilter.propTypes = {
  disabled: PropTypes.bool,
  onStatusChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      value: statusTableFilter.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  dateRanges: tableFilterDateRangesProp.isRequired,
  searchValue: PropTypes.string,
  statusValue: statusTableFilter.isRequired,
  dateRangeValue: PropTypes.string.isRequired,
};

PaymentsFilter.defaultProps = {
  searchValue: undefined,
};

export default PaymentsFilter;
