import NoOrderResults from './components/PaymentsTable/NoOrderResults';
import NoInvoiceResults from './components/PaymentsTable/NoInvoiceResults';

import { getOrdersTable } from './reducers/orders';
import { getInvoicesTable } from './reducers/invoices';

import actions from './actions';

const generateOrderPageLink = (orderId) => `/payments/orders/${orderId}`;
const generateInvoicePageLink = (invoiceId) =>
  `/payments/invoices/${invoiceId}`;

export const pageSizeOptions = [10, 25, 50];
export const defaultPageSize = 10;

export const noResultsMap = {
  orders: NoOrderResults,
  invoices: NoInvoiceResults,
};

export const tableMap = {
  orders: getOrdersTable,
  invoices: getInvoicesTable,
};

export const actionsMap = {
  orders: {
    nextPage: actions.ordersTableNextPage,
    onOrderIdClick: generateOrderPageLink,
    previousPage: actions.ordersTablePreviousPage,
    resetPage: actions.resetOrdersTablePage,
    setFilter: actions.setOrdersTableFilter,
    setPage: actions.setOrdersTablePage,
    setSort: actions.setOrdersTableSort,
  },
  invoices: {
    nextPage: actions.invoicesTableNextPage,
    onOrderIdClick: generateInvoicePageLink,
    previousPage: actions.invoicesTablePreviousPage,
    resetPage: actions.resetInvoicesTablePage,
    setFilter: actions.setInvoicesTableFilter,
    setPage: actions.setInvoicesTablePage,
    setSort: actions.setInvoicesTableSort,
  },
};
