import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { selectors } from '../../auth';
import { selectors as featuresSelectors } from '../../features';

// TODO: Remove old auth logic when SSO is stable
class PublicRoute extends Component {
  renderRoute = (routeProps) => {
    const {
      authenticated,
      component: Comp,
      isSSOActive,
      ...props
    } = this.props;

    if (!authenticated && !isSSOActive) {
      return <Comp {...props} {...routeProps} />;
    }

    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
  };

  render() {
    const props = omit(this.props, ['authenticated', 'component']);

    return <Route {...props} render={this.renderRoute} />;
  }
}

PublicRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.shape({}),
  ]),
};

const ConnectedPublicRoute = connect((state) => ({
  authenticated: selectors.isAuthenticated(state),
  isSSOActive: featuresSelectors.isSSOActive(state),
}))(PublicRoute);

export default withRouter(ConnectedPublicRoute);
