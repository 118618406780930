import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import { route as routePropType } from 'common/propTypes';
import RouteSelection from '../containers/RouteSelection';

class Routes extends Component {
  render() {
    const { match } = this.props;

    return (
      <Fragment>
        <Route path={match.path} component={RouteSelection} />
      </Fragment>
    );
  }
}

Routes.propTypes = routePropType;

export default Routes;
