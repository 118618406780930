import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRouteMatch, Link } from 'react-router-dom';

import styles from './index.module.css';

const Tabs = ({ children, dataTest }) => (
  <div data-test={dataTest} className={styles.root}>
    {children}
  </div>
);

const Tab = ({ to, children }) => (
    <h3 className={styles.header}>
      <Link
        to={to}
        className={classNames(styles.tab, {
          [styles.active]: useRouteMatch(to),
        })}
      >
        {children}
      </Link>
    </h3>
  );

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
};

export default Tabs;
export { Tab };
