import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { head, values } from 'lodash';

import { changePasswordUO } from 'app/services/api';
import { api } from 'app/services';
import Card from '../components/Card';
import { Form, Success } from '../components/NewPassword';

const NewPasswordContainer = ({ apiError, isSSOActive }) => {
  const [changed, setChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleError = (error) => {
    if (isSSOActive) {
      return setErrorMessage(error);
    }

    if (error === 'password_mismatch') {
      setErrorMessage('Wrong password');
    }
  };

  const handlePasswordSubmit = async (formValues) => {
    if (isSSOActive) {
      const { error } = await changePasswordUO(formValues);

      if (error && error?.message) {
        handleError(error.message);
        return error.message;
      }

      setErrorMessage('');
      setChanged(true);
      return;
    }

    // TODO: Remove this when the SSO is table
    const data = await api.changePassword(formValues);
    const { error, response } = data;

    if (!error) {
      const { token } = head(values(response.password)).attributes;
      api.storeItem('signInToken', token);
      setErrorMessage('');
      setChanged(true);
      return null;
    }

    apiError(error);
    handleError(error);
    return error;
  };

  return (
    <Card heading="Password" id="password-profile">
      {changed ? (
        <Success />
      ) : (
        <Form
          onPasswordSubmit={handlePasswordSubmit}
          errorMessage={errorMessage}
        />
      )}
    </Card>
  );
};

NewPasswordContainer.propTypes = {
  apiError: PropTypes.func.isRequired,
  isSSOActive: PropTypes.bool.isRequired,
};

export default NewPasswordContainer;
