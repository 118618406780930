import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const BACKDROP_ID = 'modal-backdrop';

const Modal = ({
  isOpen,
  onClose,
  closeOnClickOutside,
  closeOnEscape,
  children,
}) => {
  const handleClick = (evt) => {
    if (closeOnClickOutside && evt.target.id === BACKDROP_ID) {
      onClose();
    }
  };

  // Adds/Removes key press listener when modal opens/closes
  useEffect(() => {
    if (isOpen && closeOnEscape) {
      const onKeyDown = (evt) => {
        evt.preventDefault();
        if (evt.key === 'Escape') {
          onClose();
        }
      };
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [isOpen, onClose, closeOnEscape]);

  if (!isOpen) return null;
  return (
    <div
      className={styles.backdrop}
      id={BACKDROP_ID}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex="0"
    >
      <div
        role="dialog"
        className={styles.modal}
        id={'modal-element'}
        aria-modal="true"
      >
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnClickOutside: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
};

Modal.defaultProps = {
  closeOnClickOutside: false,
  closeOnEscape: false,
};

export default Modal;
