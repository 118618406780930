import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

//fonts
import 'common/fonts/index.css';

//global colors & variables (used for Dev server to pick them up)
//for Production server purposes, files must be added to `importFrom` on postcss.config
import 'common/styles/colors.css';
import 'common/styles/globalVariables.css';

// reset & normalize
import 'common/styles/reset.css';
import 'common/styles/normalize.css';

import SentryErrorBondary from 'common/components/SentryErrorBondary';
import App from './containers/App';
import configureStore from './store';
import rootSaga from './sagas';

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <SentryErrorBondary>
      <Provider store={store}>
        <App />
      </Provider>
    </SentryErrorBondary>
  </React.StrictMode>,
  document.getElementById('root')
);
