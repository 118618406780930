import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pick, uniqueId } from 'lodash';

import TextAreaInput from './TextAreaInput';
import TextAreaLabel from './TextAreaLabel';
import TextAreaErrorMessage from './ErrorMessage';

import styles from './index.module.css';

class TextArea extends PureComponent {
  id = this.props.id || uniqueId('text-area-');

  errorId = this.props.errorId || uniqueId(`${this.id}-error-message`);

  get textAreaProps() {
    const props = pick(this.props, [
      'placeholder',
      'rows',
      'disabled',
      'required',
      'onBlur',
      'onFocus',
      'name',
      'value',
    ]);

    return {
      ...props,
      id: this.id,
      errorId: this.errorId,
      onChange: this.handleChange,
      onClick: this.handleClick,
    };
  }

  handleChange = (evt) => {
    const { disabled, onChange } = this.props;

    if (!disabled) onChange(evt);
  };

  handleClick = (evt) => {
    const { disabled, onClick } = this.props;

    if (!disabled) onClick(evt);
  };

  render() {
    const {
      disabled,
      error,
      errorText,
      optionalLabel,
      labelType,
      labelText,
      hideLabel,
      required,
    } = this.props;
    const { id } = this;

    return (
      <div className={styles.root}>
        <TextAreaLabel
          id={id}
          labelText={labelText}
          labelType={labelType}
          disabled={disabled}
          optionalLabel={optionalLabel}
          hideLabel={hideLabel}
          required={required}
        />
        <TextAreaInput {...this.textAreaProps} error={error} />
        {error && (
          <TextAreaErrorMessage id={id} error={error} errorText={errorText} />
        )}
      </div>
    );
  }
}

TextArea.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorId: PropTypes.string,
  errorText: PropTypes.string,
  filled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  labelType: PropTypes.string.isRequired,
  name: PropTypes.string,
  optionalLabel: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextArea.defaultProps = {
  active: false,
  disabled: false,
  error: false,
  errorId: '',
  errorText: '',
  filled: false,
  hideLabel: false,
  name: '',
  labelText: '',
  onChange: () => {},
  onClick: () => {},
  onBlur: () => {},
  onFocus: () => {},
  optionalLabel: false,
  placeholder: '',
  required: false,
  rows: 3,
  value: '',
};

export default TextArea;
