import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { useSelector } from 'react-redux';
import { selectors as featuresSelectors } from 'features';
import {
  referenceFiatCurrencyItems,
  settlementCurrencyItems,
  settlementFiatCurrencyItems,
} from 'common/currencies';
import _ from 'lodash';

import Dropdown from 'common/components/Dropdown';
import Label from 'common/components/Label';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Text from 'common/components/Text';
import TextBox from 'common/components/TextBox';
import { track } from 'common/analytics';
import formUtils from 'common/formUtils.js';
import {
  onboardingDisabledCountries,
  requiresVat,
} from 'common/countryHelpers';

import styles from './index.module.css';

const emptyValues = {
  storeName: '',
  storeUrl: '',
  companyOfficialName: '',
  registeredAddress: '',
  country: '',
  timezone: '',
  vatNumber: '',
  settlementCurrency: '',
  referenceCurrency: '',
  referenceBlockchain: '',
};

const sandboxValues = {
  storeName: 'Demo store',
  storeUrl: 'www.demostore.com',
  companyOfficialName: 'Demo Lda',
  registeredAddress: 'P sherman 42, wallaby street, Sydney',
  country: 'AUS',
  timezone: 'Asia/Vladivostok',
  vatNumber: '',
  settlementCurrency: 'EUR',
  referenceCurrency: 'USD',
};

const CantFind = ({ url }) => (
  <div className={styles.countryDescription} size="caption">
    <p>
      If you can’t find yours, click{' '}
      <a href={url} target="_blank" rel="noopener noreferrer">
        here
      </a>{' '}
      for more information.
    </p>
  </div>
);

CantFind.propTypes = {
  url: PropTypes.string.isRequired,
};

const CompanyInfoForm = ({
  countries,
  fillFakeData,
  isFetchingCountries,
  missingPurposeError,
  onSubmit,
  purposes,
  submissionFailed,
  submitting,
  timezoneItems,
}) => {
  const initialValues = fillFakeData ? sandboxValues : emptyValues;
  const [vatRequired, setVatRequired] = useState(false);
  const checkVatRequirement = (value) => {
    setVatRequired(requiresVat(value));
  };
  const isReferenceCurrencyActive = useSelector(
    featuresSelectors.isReferenceCurrencyActive
  );

  const isRonReferenceCurrencyActive = useSelector(
    featuresSelectors.isRonReferenceCurrencyActive
  );

  const isChfReferenceCurrencyActive = useSelector(
    featuresSelectors.isChfReferenceCurrencyActive
  );

  const isChfSettlementCurrencyActive = useSelector(
    featuresSelectors.isChfSettlementCurrencyActive
  );

  const isCryptoSettlementsActive = useSelector(
    featuresSelectors.isCryptoSettlementsActive
  );

  const isEcommerceWebsite = purposes.includes('orders');

  const selectableReferenceFiatCurrencies = referenceFiatCurrencyItems;

  if (!isRonReferenceCurrencyActive) {
    _.remove(selectableReferenceFiatCurrencies, ({ value }) => value === 'RON');
  }

  if (!isChfReferenceCurrencyActive) {
    _.remove(selectableReferenceFiatCurrencies, ({ value }) => value === 'CHF');
  }

  const settlementCurrencies = isCryptoSettlementsActive
    ? settlementCurrencyItems
    : settlementFiatCurrencyItems;

  if (!isChfSettlementCurrencyActive) {
    _.remove(settlementCurrencies, ({ value }) => value === 'CHF');
  }

  const DISABLED_COUNTRY_ERROR = 'DISABLED_COUNTRY';

  const formikRef = useRef();

  useEffect(() => {
    formikRef.current.validateForm();
  }, [purposes]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (onboardingDisabledCountries.includes(values.country)) {
          errors.country = DISABLED_COUNTRY_ERROR;
        }
        return errors;
      }}
    >
      {({ handleSubmit, setFieldValue, touched, errors, setFieldTouched }) => {
        const handleCurrencyChange = (value) => {
          const [currency, chain] = value.split('-');
          setFieldValue('settlementCurrency', currency, true);

          if (chain) {
            setFieldValue('settlementBlockchain', chain, true);
          }
        };
        const handleReferenceCurrencyChange = (value) => {
          setFieldValue('referenceCurrency', value, true);
        };
        const handleDropdownChange = (field, value) => {
          setFieldTouched(field, true);
          setFieldValue(field, value, true);
          checkVatRequirement(value);
        };
        return (
          <form onSubmit={handleSubmit}>
            <Heading level="3" size="3" id="online-store-information">
              Merchant
            </Heading>
            <br />
            <Field name="storeName" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <>
                  <div className={styles.row}>
                    <TextBox
                      id="storeName"
                      labelText="Merchant name"
                      labelType="storeName"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  </div>
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    This is what your customers will see.
                  </Text>
                </>
              )}
            </Field>
            <br />
            <Field
              name="storeUrl"
              validate={
                isEcommerceWebsite
                  ? formUtils.validateRequiredUrl
                  : formUtils.validateUrl
              }
            >
              {({ field, meta }) => (
                <>
                  <div className={styles.row}>
                    <TextBox
                      id="storeUrl"
                      labelText={
                        isEcommerceWebsite
                          ? 'E-commerce store website'
                          : 'Institutional website'
                      }
                      labelType="storeUrl"
                      optionalLabel={isEcommerceWebsite ? null : 'optional'}
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  </div>
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    {isEcommerceWebsite ? (
                      <>
                        Provide the URL of the website where you intend to use
                        our integration.
                        <br />
                        Please contact us if this ever changes.
                      </>
                    ) : (
                      "Provide the URL to your company's website."
                    )}
                  </Text>
                </>
              )}
            </Field>
            <br />
            <Heading level="3" size="3" id="company-information">
              Company
            </Heading>
            <br />
            <Field
              name="companyOfficialName"
              validate={formUtils.validateRequired}
            >
              {({ field, meta }) => (
                <>
                  <div className={styles.row}>
                    <TextBox
                      id="companyOfficialName"
                      labelText="Company legal name"
                      labelType="companyOfficialName"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  </div>
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    This will feature on settlement reports.
                  </Text>
                </>
              )}
            </Field>
            <br />
            <Field
              name="registeredAddress"
              validate={formUtils.validateRequired}
            >
              {({ field, meta }) => (
                <>
                  <div className={styles.row}>
                    <TextBox
                      id="registeredAddress"
                      labelText="Company legal address"
                      labelType="registeredAddress"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      {...field}
                    />
                  </div>
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    Please include ZIP code and city.
                  </Text>
                </>
              )}
            </Field>
            <br />
            <Field name="country" validate={formUtils.validateRequired}>
              {({ meta }) => (
                <div className={styles.row}>
                  <Label>Country of registration</Label>
                  {isFetchingCountries ? (
                    'Loading...'
                  ) : (
                    <Dropdown
                      isSearchable
                      id="country"
                      initialSelectedItem={meta.initialValue}
                      name="country"
                      placeholder="Choose your country"
                      items={countries}
                      error={!!(meta.touched && meta.error)}
                      errorText={
                        meta.error === DISABLED_COUNTRY_ERROR ? '' : meta.error
                      }
                      onChange={(value) => {
                        track('Country Selected', {
                          country: value,
                        });
                        handleDropdownChange('country', value);
                      }}
                    />
                  )}

                  <CantFind url="https://support.xmoney.com/en/articles/4388085-merchant-is-xmoney-available-in-my-country" />
                  {meta.error === DISABLED_COUNTRY_ERROR && (
                    <div className={styles.infoBox}>
                      Due to regulatory constraints, xMoney Crypto is currently
                      unable to conduct business with companies registrated in
                      this country. This is a temporary limitation our Legal
                      Team is actively working to solve as quickly as possible.{' '}
                      <br />
                      <br /> If you wish to be informed when these constraints
                      are overcome, please reach out to us at{' '}
                      <a href="mailto:compliance@xmoney.com">
                        compliance@xmoney.com
                      </a>
                    </div>
                  )}
                </div>
              )}
            </Field>
            <br />
            <Field name="timezone" validate={formUtils.validateRequired}>
              {({ meta }) => (
                <>
                  <div className={styles.row}>
                    <Label>Timezone</Label>
                    <Dropdown
                      isSearchable
                      id="timezone"
                      initialSelectedItem={meta.initialValue}
                      name="timezone"
                      placeholder="Choose company timezone"
                      items={timezoneItems}
                      error={!!(meta.touched && meta.error)}
                      errorText={meta.error}
                      onChange={(value) => {
                        setFieldTouched('timezone', true);
                        setFieldValue('timezone', value, true);
                      }}
                    />
                  </div>
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    This is so we can synchronize settlement reports.
                  </Text>
                </>
              )}
            </Field>
            {vatRequired && (
              <>
                <br />
                <Field name="vatNumber">
                  {({ field, meta }) => (
                    <div className={styles.row}>
                      <TextBox
                        id="vatNumber"
                        error={meta.touched && meta.error}
                        errorText={meta.error}
                        labelText="VAT number"
                        labelType="vatNumber"
                        optionalLabel="optional"
                        data-lpignore="true"
                        autoComplete="new-vatNumber"
                        {...field}
                      />
                    </div>
                  )}
                </Field>
              </>
            )}
            <br />
            {isReferenceCurrencyActive && (
              <>
                <Field
                  name="referenceCurrency"
                  validate={formUtils.validateRequired}
                >
                  {({ meta }) => (
                    <>
                      <div className={styles.row}>
                        <Label>Reference currency</Label>
                        <Dropdown
                          id="referenceCurrency"
                          initialSelectedItem={meta.initialValue}
                          name="referenceCurrency"
                          placeholder="Your reference currency"
                          items={selectableReferenceFiatCurrencies}
                          error={meta.touched && meta.error}
                          errorText={meta.error}
                          onChange={(value) => {
                            track('Reference Currency Selected', {
                              referenceCurrency: value,
                            });
                            handleReferenceCurrencyChange(value);
                          }}
                        />
                      </div>
                      <Text size="caption" color="rebranding-md-color-grey-60">
                        Make sure you choose the currency your company’s books
                        are kept in. We will calculate each payment in this
                        currency to invoice the xMoney fees.
                      </Text>
                    </>
                  )}
                </Field>
                <br />
              </>
            )}
            <Field
              name="settlementCurrency"
              validate={formUtils.validateRequired}
            >
              {({ meta }) => (
                <>
                  <div className={styles.row}>
                    <Label>Settlements currency</Label>
                    <Dropdown
                      id="settlementCurrency"
                      initialSelectedItem={meta.initialValue}
                      name="settlementCurrency"
                      placeholder="Your settlement currency"
                      items={settlementCurrencies}
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      onChange={(value) => {
                        track('Currency Selected', {
                          settlementCurrency: value,
                        });
                        handleCurrencyChange(value);
                      }}
                    />
                  </div>
                  <Text size="caption" color="rebranding-md-color-grey-60">
                    This is the currency in which you will receive your payouts.
                    We will ask for your bank details or crypto address later
                    on.
                  </Text>
                </>
              )}
            </Field>
            <br />
            <span className={styles.formFooter}>
              <Text color="rebranding-md-color-red">
                {submissionFailed && 'Something went wrong. Please try again.'}
                {missingPurposeError &&
                  'Please select your preferred solution.'}
              </Text>
              <div className={styles.buttonWrapper}>
                <Button
                  full
                  kind="primary"
                  loading={submitting}
                  disabled={formUtils.isSubmitDisabled(errors, touched)}
                >
                  <button
                    type="submit"
                    id="progress-status"
                    data-test="submit-company-info-button"
                  >
                    Submit
                  </button>
                </Button>
              </div>
            </span>
          </form>
        );
      }}
    </Formik>
  );
};

const dropdownOption = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

CompanyInfoForm.propTypes = {
  countries: PropTypes.arrayOf(dropdownOption),
  fillFakeData: PropTypes.bool.isRequired,
  isFetchingCountries: PropTypes.bool,
  missingPurposeError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  purposes: PropTypes.arrayOf(PropTypes.string).isRequired,
  submissionFailed: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  timezoneItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

CompanyInfoForm.defaultProps = {
  countries: [],
};

export default CompanyInfoForm;
