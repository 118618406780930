import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import {
  invoicesRoute,
  apiIntegrationsRoute,
  settlementsRoute,
} from 'app/containers/routes';
import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const GetStartedCompleted = ({
  isInvoicingEnabled,
  isCryptoSettlementsActive,
}) => (
  <div className={styles.root} data-test="get-started-completed">
    <div className={styles.logo}>
      <LogoxMoney />
    </div>
    <Heading level="1" size="1" color="blue">
      What’s next?
    </Heading>
    <ol>
      <li className={styles.section}>
        <Heading size="2" level="2">
          1st. Collect payments
        </Heading>
        <ol className={styles.instructions}>
          <li className={styles.item}>
            <Heading size="3" level="3">
              Via an online store
            </Heading>
            <Text>
              You just need to go to Integrations page and integrate with the
              option that suits you the best.
            </Text>
            <Button
              name="integrations-link"
              kind="subtle"
              icon={
                <Icon.Plug
                  fill={colors['rebranding-md-color-brand-purple']}
                  size="large"
                />
              }
            >
              <Link to={apiIntegrationsRoute}>Go to integrations</Link>
            </Button>
          </li>
          {isInvoicingEnabled && (
            <li className={styles.item}>
              <Heading size="3" level="3">
                Via email
              </Heading>
              <Text>
                Inside the payments section you can invoice your clients at any
                time. Just click create a new invoice and you’re on your way.
              </Text>
              <Button
                name="payments-link"
                kind="subtle"
                icon={
                  <Icon.Orders
                    fill={colors['rebranding-md-color-brand-purple']}
                    size="large"
                  />
                }
              >
                <Link to={invoicesRoute}>Go to payments</Link>
              </Button>
            </li>
          )}
        </ol>
      </li>
      <li className={styles.section}>
        <Heading size="2" level="2">
          2nd. Enable payouts
        </Heading>
        <ol className={styles.instructions}>
          <li className={styles.item}>
            <Heading size="3" level="3">
              {isCryptoSettlementsActive ? (
                <span data-test="enable-payouts-heading-active">
                  To a bank account or crypto address
                </span>
              ) : (
                <span data-test="enable-payouts-heading-inactive">
                  To a bank account
                </span>
              )}
            </Heading>
            <Text>
              {isCryptoSettlementsActive ? (
                <span data-test="enable-payouts-description-active">
                  Activate periodic payouts to your bank account or crypto
                  address by verifying your business and providing your
                  account&apos;s information.
                </span>
              ) : (
                <span data-test="enable-payouts-description-inactive">
                  Activate automatic settlements to your bank account by
                  verifying your business and providing your bank information.
                </span>
              )}
            </Text>
            <Button
              name="settlements-link"
              kind="subtle"
              icon={
                <Icon.Settlements
                  fill={colors['rebranding-md-color-brand-purple']}
                  size="large"
                />
              }
            >
              <Link to={settlementsRoute}>Go to settlements</Link>
            </Button>
          </li>
        </ol>
      </li>
    </ol>
  </div>
);

GetStartedCompleted.propTypes = {
  isInvoicingEnabled: PropTypes.bool.isRequired,
  isCryptoSettlementsActive: PropTypes.bool.isRequired,
};

export default GetStartedCompleted;
