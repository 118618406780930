function isMobile() {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

function isTablet() {
  return /iPad|Android(?!.*Mobile)|Tablet/i.test(navigator.userAgent);
}

export function isDesktop() {
  return !isMobile() && !isTablet();
}
