import { combineReducers } from 'redux';
import { map, reduce } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import build from 'redux-object';

import reduxUtils from 'common/reduxUtils';
import { invoiceFilterStatuses } from './filterStatusesUtils';
import { allStatusRegex } from '../propTypes';
import { defaultDateRangeValue, dateRanges } from './filterDateRangeUtils';
import actions from '../actions';

const {
  COUNTRIES_LIST_SUCCESS,
  INVOICES_FAILURE,
  INVOICES_REQUEST,
  INVOICES_SUCCESS,
  INVOICES_TABLE_NEXT_PAGE,
  INVOICES_TABLE_PREVIOUS_PAGE,
  RESET_INVOICES_TABLE,
  RESET_INVOICES_TABLE_PAGE,
  SET_INVOICES_TABLE_FILTER,
  SET_INVOICES_TABLE_PAGE,
  SET_INVOICES_TABLE_PAGE_SIZE,
  SET_INVOICES_TABLE_SORT,
  SINGLE_INVOICE_SET_CANCELLED,
  SINGLE_INVOICE_PAGE_REQUEST,
  SINGLE_INVOICE_PAGE_SUCCESS,
  SINGLE_INVOICE_PAGE_FAILURE,
} = actions;

const transformInvoices = (payload) => {
  const invoices = build(payload, 'invoices');

  return reduce(
    invoices,
    (result, invoice) => ({
      ...result,
      [invoice.id]: invoice,
    }),
    {}
  );
};

const transformSingleInvoice = (payload) => {
  const invoicesList = transformInvoices(payload);
  const paymentUrl = reduxUtils.getMetaInfo(payload, 'paymentUrl');
  const invoiceId = Object.keys(invoicesList)[0];

  invoicesList[invoiceId].paymentUrl = paymentUrl;
  return invoicesList;
};

export const getInvoice = (state, id) => state.invoices.byId[id];

const byId = handleActions(
  {
    [INVOICES_SUCCESS]: (state, action) => ({
      ...transformInvoices(action.payload),
    }),
    [SINGLE_INVOICE_PAGE_SUCCESS]: (state, action) => ({
      ...state,
      ...transformSingleInvoice(action.payload),
    }),
    [SINGLE_INVOICE_SET_CANCELLED]: (state, { payload }) => {
      const invoice = state[payload];
      return {
        ...state,
        [payload]: { ...invoice, status: 'cancelled' },
      };
    },
  },
  {}
);

const countriesListReducer = handleActions(
  {
    [COUNTRIES_LIST_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  {}
);

const isFetchingInvoicesReducer = handleActions(
  {
    [combineActions(INVOICES_REQUEST, SINGLE_INVOICE_PAGE_REQUEST)]: () => true,
    [combineActions(
      INVOICES_SUCCESS,
      INVOICES_FAILURE,
      SINGLE_INVOICE_PAGE_SUCCESS,
      SINGLE_INVOICE_PAGE_FAILURE
    )]: () => false,
  },
  false
);

const getTableRow = (state, id) => {
  const invoice = getInvoice(state, id);
  if (!invoice) return {};

  return {
    ...invoice,
    totalAmount: invoice.fiatAmount,
    uuid: invoice.id,
    details: {
      reference: invoice.reference,
      customer: invoice.customer,
      items: invoice.items,
    },
    createdAt: invoice.createdAt,
    status: invoice.status,
    action: null,
  };
};

const loadInvoicesErrorReducer = handleActions(
  {
    [combineActions(
      INVOICES_REQUEST,
      INVOICES_SUCCESS,
      SINGLE_INVOICE_PAGE_SUCCESS
    )]: () => null,
    [combineActions(INVOICES_FAILURE, SINGLE_INVOICE_PAGE_FAILURE)]: (
      state,
      action
    ) => action.payload,
  },
  null
);

const defaultFilter = {
  searchValue: undefined,
  statusValue: allStatusRegex,
  dateRangeValue: defaultDateRangeValue,
};
const invoicesTableFilterReducer = handleActions(
  {
    [SET_INVOICES_TABLE_FILTER]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [RESET_INVOICES_TABLE]: () => defaultFilter,
  },
  defaultFilter
);

const invoicesTablePageReducer = handleActions(
  {
    [SET_INVOICES_TABLE_PAGE]: (state, action) => action.payload,
    [INVOICES_TABLE_NEXT_PAGE]: (state) => state + 1,
    [INVOICES_TABLE_PREVIOUS_PAGE]: (state) => state - 1,
    [combineActions(RESET_INVOICES_TABLE, RESET_INVOICES_TABLE_PAGE)]: () => 1,
  },
  1
);

const defaultSort = { id: 'createdAt', sortAscending: false };
const invoicesTableSortReducer = handleActions(
  {
    [SET_INVOICES_TABLE_SORT]: (state, action) => action.payload,
    [RESET_INVOICES_TABLE]: () => defaultSort,
  },
  defaultSort
);

const invoicesTablePaginationReducer = handleActions(
  {
    [SET_INVOICES_TABLE_PAGE_SIZE]: (state, action) => ({
      ...state,
      pageSize: action.payload,
    }),
    [INVOICES_SUCCESS]: (state, action) => ({
      ...state,
      totalElements: action.payload.meta.invoices.meta.totalElements,
    }),
  },
  { pageSize: 1, totalElements: 0 }
);

export const getInvoiceContainer = getTableRow;

const getInvoices = (state) => {
  const {
    invoices: { byId: invoicesById },
  } = state;

  return map(invoicesById, (order, id) => getTableRow(state, id));
};

export const getCountriesList = (state) => state.invoices.countriesList;
export const isFetchingInvoices = (state) => state.invoices.isFetchingInvoices;
export const getLoadInvoicesError = (state) => state.invoices.loadInvoicesError;
export const getInvoicesTablePage = (state) => state.invoices.invoicesTablePage;
export const getInvoicesTableFilter = (state) =>
  state.invoices.invoicesTableFilter;
export const getInvoicesTableSort = (state) =>
  state.invoices.invoicessTableSort;
export const getInvoicesTableTotalElements = (state) =>
  state.invoices.invoicesTablePagination.totalElements;
export const getInvoicesTablePageSize = (state) =>
  state.invoices.invoicesTablePagination.pageSize;
export const getInvoicesTable = (state) => {
  const {
    invoices: {
      invoicesTablePage: page,
      invoicesTablePagination: { pageSize, totalElements },
      invoicesTableSort: sort,
      isFetchingInvoices: isFetching,
    },
  } = state;

  return {
    isFetching,
    page,
    pageSize,
    totalElements,
    sort,
    data: getInvoices(state),
  };
};

export const getInvoicesTableFilters = (state) => ({
  dateRanges,
  statuses: invoiceFilterStatuses,
  ...state.invoices.invoicesTableFilter,
});

export const getInvoicesScope = (state) => {
  const {
    invoices: {
      invoicesTablePage: page,
      invoicesTablePagination: { pageSize },
      invoicesTableSort: sort,
      invoicesTableFilter: {
        searchValue: search,
        statusValue: status,
        dateRangeValue: dateRange,
      },
    },
  } = state;

  return {
    dateRange,
    page,
    pageSize,
    search,
    sort,
    status,
  };
};

export default combineReducers({
  byId,
  countriesList: countriesListReducer,
  isFetchingInvoices: isFetchingInvoicesReducer,
  loadInvoicesError: loadInvoicesErrorReducer,
  invoicesTablePage: invoicesTablePageReducer,
  invoicesTableFilter: invoicesTableFilterReducer,
  invoicesTableSort: invoicesTableSortReducer,
  invoicesTablePagination: invoicesTablePaginationReducer,
});
