import * as moment from 'moment-timezone';
import { chain } from 'lodash';

const timezonesMap = {
  'Pacific/Samoa': 'Samoa', //-11
  'Pacific/Honolulu': 'Hawaii', //-10
  'US/Alaska': 'Alaska', //-9
  'America/Vancouver': 'Pacific Standard Time, Los Angeles, Tijuana, Vancouver', //-8
  'America/La_Paz': 'Arizona, Chihuahua, La Paz', //-7
  'America/Mexico_City': 'Guadalajara, Mexico City, Saskatchewan', //-6
  'America/Bogota': 'Eastern Standard Time, Bogota, New York, Lima', //-5
  'America/Santiago': 'Georgetown, San Juan, Santiago', //-4
  'Canada/Newfoundland': 'Newfoundland', //-3:30
  'Brazil/East': 'Brasília, Greenland', //-3
  'Atlantic/South_Georgia': 'Mid-Atlantic, South Georgia', //-2
  'Atlantic/Azores': 'Azores, Cape Verde', //-1
  'Europe/Lisbon': 'London, Lisbon, Reykjavík', //0
  'Europe/Prague': 'Amsterdam, Madrid, Prague, West Central Africa, Zagreb', //+1
  'Europe/Kiev': 'Athens, Cairo, Minsk, Jerusalem, Pretoria', //+2
  'Europe/Moscow': 'Baghdad, Moscow, Nairobi, Volgograd', //+3,
  'Asia/Tehran': 'Tehran', //+3:30
  'Asia/Yerevan': 'Abu Dhabi, Muscat, Baku, Yerevan', //+4
  'Asia/Kabul': 'Kabul', //+4:30
  'Asia/Tashkent': 'Tashkent', //+5
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi', //+5:30
  'Asia/Kathmandu': 'Kathmandu', //+5:45
  'Asia/Almaty': 'Almaty, Astana, Novosibirsk', //+6
  'Asia/Yangon': 'Yangon', //+6:30
  'Asia/Krasnoyarsk': 'Bangkok, Hanoi, Jakarta, Krasnoyarsk', //+7,
  'Australia/Perth':
    'Beijing, Kuala Lumpur, Perth, Singapore, Taipei, Ulaanbaatar', //+8
  'Asia/Seoul': 'Osaka, Seoul, Tokyo, Yakutsk', //+9
  'Australia/Adelaide': 'Adelaide', //+9:30
  'Asia/Vladivostok': 'Canberra, Guam, Sidney, Vladivostok', //+10
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia', //+11
  'Pacific/Fiji': 'Fiji, Auckland, Wellington', //+12
  'Pacific/Tongatapu': "Nuku'alofa, Tongatapu", //+13
};

export default chain(Object.keys(timezonesMap))
  .map((key) => ({
      label: `(GMT${moment.tz(key).format('Z')}) ${timezonesMap[key]}`,
      value: key,
      zone: moment.tz(key).utcOffset(),
    }))
  .orderBy('zone', 'asc')
  .value();
