import React, { useEffect, useRef, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import propTypes from 'prop-types';

import AuthPage from 'common/components/AuthPage';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import TextBox from 'common/components/TextBox';
import PasswordLabel from 'common/components/PasswordLabel';
import formUtils from 'common/formUtils.js';
import { supportEmail } from 'common/constants';

import styles from './index.module.css';
import authStyles from '../../index.module.css';

const QUESTION = "Don't have an account? ";

const Header = () => (
  <Heading level="3" size="3" weight="medium" id="page-title" color="black">
    Sign in
  </Heading>
);

const Footer = () => (
  <div className={styles.footer}>
    <span>{QUESTION}</span>
    <Link to="/onboarding/sign-up" data-test="sign-up-link">
      Create one now
    </Link>
  </div>
);

const ForgotPasswordLink = () => (
  <Link to="/forgot-password" data-test="forgot-password-link">
    Forgot Password?
  </Link>
);
const MemoForgotPasswordLink = memo(ForgotPasswordLink);

const SubmitButton = ({ disabled, loading }) => (
  <Button kind="primary" disabled={disabled} loading={loading} name="sign-in">
    <button type="submit" id={'progress-status'}>
      Sign in
    </button>
  </Button>
);
const MemoSubmitButton = memo(SubmitButton);

SubmitButton.propTypes = {
  disabled: propTypes.bool,
  loading: propTypes.bool.isRequired,
};

const Form = ({ apiError, onSubmit }) => {
  const passwordField = useRef(null);

  const [failedAttempt, setFailedAttempt] = useState(
    apiError === 'sign_in_failed'
  );

  useEffect(() => {
    if (apiError === 'sign_in_failed') setFailedAttempt(true);
  }, [apiError]);

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
      {({ touched, handleSubmit, isSubmitting, setFieldValue, errors }) => {
        const shouldButtonBeDisabled =
          formUtils.isSubmitDisabled(errors, touched) || failedAttempt;

        return (
          <form onSubmit={handleSubmit}>
            <Field name="email" validate={formUtils.validateEmail}>
              {({ field, meta }) => (
                <div className={authStyles.row}>
                  <TextBox
                    id="email"
                    labelText="Email"
                    labelType="email"
                    placeholder="Your email address"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    {...field}
                    onChange={(e) => {
                      if (failedAttempt) setFailedAttempt(false);
                      setFieldValue('email', e.target.value.trim());
                    }}
                  />
                </div>
              )}
            </Field>
            <Field name="password" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <>
                  {passwordField && <PasswordLabel input={passwordField} />}
                  <div className={styles.row}>
                    <TextBox
                      id="password"
                      labelType="password"
                      type="password"
                      placeholder="Your password"
                      error={meta.touched && meta.error}
                      errorText={meta.error}
                      inputRef={passwordField}
                      {...field}
                      onChange={(e) => {
                        setFieldValue('password', e.target.value);
                        if (failedAttempt) setFailedAttempt(false);
                      }}
                    />
                  </div>
                </>
              )}
            </Field>
            <div className={styles.formFooter}>
              <MemoForgotPasswordLink />
              <MemoSubmitButton
                disabled={shouldButtonBeDisabled}
                loading={isSubmitting}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: propTypes.func.isRequired,
};

const errorMessages = {
  sign_in_failed: 'Wrong email or password',
  'Sign in failed': 'Wrong email or password',
  account_locked: `For security reasons, your account is locked. Please contact ${supportEmail} or sign in with a different account.`,
};

function errorMessage(error) {
  if (!error) return '';
  return errorMessages[error] || 'Something went wrong';
}

const SignIn = ({ isMerchantRegistrationActive, onSubmit, error }) => (
  <AuthPage
    header={<Header />}
    form={<Form apiError={error} onSubmit={onSubmit} />}
    footer={<Footer />}
    isMerchantRegistrationActive={isMerchantRegistrationActive}
    error={errorMessage(error)}
  />
);

SignIn.propTypes = {
  error: propTypes.string,
  isMerchantRegistrationActive: propTypes.bool.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default SignIn;
