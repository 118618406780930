import PropTypes from 'prop-types';

const errorsPropType = {
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const paramsPropType = PropTypes.shape({
  eventType: PropTypes.string.isRequired,
  resource: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    uuid: PropTypes.string,
  }),
});

export const webhookProptype = PropTypes.shape({
  attempts: PropTypes.number.isRequired,
  errors: PropTypes.shape(errorsPropType).isRequired,
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  params: paramsPropType,
});
