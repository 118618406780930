import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { statuses, statusClass } from 'payments/translations';
import styles from './index.module.css';

const i18n = (value) => statuses[value];

const AmountMobile = ({ value }) => {
  const status = value.get('status');
  const amount = value.get('amount');
  const price = amount?.get('value');
  const currency = amount?.get('currency');

  const statusLabel = statusClass[status];
  const className = classNames(styles.status, styles[statusLabel]);

  return (
    <div className={styles.statusAmount}>
      <span className={className}>{i18n(status)}</span>
      <span className={styles.currencyMobile}>{`${price} ${currency}`}</span>
    </div>
  );
};

AmountMobile.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
};

export default AmountMobile;
