import React from 'react';

const LogoxMoney = () => (
  <svg
    viewBox="0 0 128 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="128"
    height="28"
  >
    <g clipPath="url(#clip0_89_2732)">
      <path
        d="M116.849 28H113.544L116.638 21.1641L108.681 4.06943H112.338L118.342 17.3771L124.345 4.06943H127.683L116.845 27.998L116.849 28Z"
        fill="black"
      />
      <path
        d="M9.9346 6.57075L17.6832 2.47715L16.3805 0L9.28329 2.8392C9.10057 2.91242 8.89805 2.91242 8.71532 2.8392L1.62007 0L0.317383 2.47715L8.06597 6.57274L0.317383 10.6664L1.62007 13.1435L8.71532 10.3043C8.89805 10.2311 9.10057 10.2311 9.28329 10.3043L16.3785 13.1435L17.6812 10.6664L9.9326 6.57075H9.9346Z"
        fill="#6932D4"
      />
      <path
        d="M34.2403 16.9189L40.6704 1.66422H45.4661V21.5546H42.3047V4.70921L35.1995 21.6615H32.9258L25.8921 4.81409V21.5546H22.8379V1.66422H27.8461L34.2403 16.9189Z"
        fill="black"
      />
      <path
        d="M67.2473 12.8129C67.2473 18.0165 63.234 21.9795 58.0114 21.9795C52.7887 21.9795 48.7754 18.0165 48.7754 12.8129C48.7754 7.60934 52.7529 3.64633 58.0114 3.64633C63.2698 3.64633 67.2473 7.60934 67.2473 12.8129ZM63.9092 12.8129C63.9092 9.30894 61.5302 6.62008 58.0133 6.62008C54.4965 6.62008 52.1175 9.38016 52.1175 12.8129C52.1175 16.2457 54.4965 19.0058 58.0133 19.0058C61.5302 19.0058 63.9092 16.3169 63.9092 12.8129Z"
        fill="black"
      />
      <path
        d="M73.8618 21.3984H70.6289V3.91591H73.8618V7.137C74.537 5.7223 76.5605 3.52612 79.8291 3.52612C84.2691 3.52612 87.3231 6.81844 87.3231 11.3473V21.3984H84.0546V11.9488C84.0546 8.65649 82.0651 6.49788 79.0467 6.49788C76.0283 6.49788 73.8598 8.76337 73.8598 11.9488V21.3984H73.8618Z"
        fill="black"
      />
      <path
        d="M108.174 13.2024H93.5755C93.7518 16.4592 96.0972 19.0075 99.5421 19.0075C102.988 19.0075 104.479 17.1319 104.765 16.566H107.962C107.679 18.0875 105.441 21.9813 99.5446 21.9813C94.1095 21.9813 90.3086 17.947 90.3086 12.8146C90.3086 7.68227 94.1095 3.64804 99.2244 3.64804C104.34 3.64804 108.177 7.47059 108.177 13.2044L108.174 13.2024ZM93.7876 10.5136H104.586C103.771 7.85838 101.888 6.40812 99.2228 6.40812C96.3808 6.40812 94.4629 7.96526 93.7876 10.5136Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_89_2732">
        <rect width="128" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoxMoney;
