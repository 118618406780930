const getTitlePrefix = (section, { isInvoicingEnabled, isOnrampEnabled }) => {
  const letters = ['A', 'B', 'C', 'D', 'E'];

  const sections = ['order', 'invoice', 'refund', 'fiatBill'];

  if (!isInvoicingEnabled) {
    sections.splice(sections.indexOf('invoice'), 1);
  }

  if (!isOnrampEnabled) {
    sections.splice(sections.indexOf('fiatBill'), 1);
  }

  return letters[sections.indexOf(section)];
};

export { getTitlePrefix };
