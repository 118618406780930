import React from 'react';
import PropTypes from 'prop-types';

import { displayUtcDate } from 'common/services/date';
import Cell from './Cell';

import styles from './index.module.css';

/**
 * confirmedAt -> orders
 * paidAt -> invoices
 * createdAt -> refunds or legacy orders/invoices
 */
const dateAndTime = (dateFields) =>
  displayUtcDate(
    dateFields?.confirmedAt || dateFields?.paidAt || dateFields?.createdAt,
    'yyyy/MM/dd HH:mm'
  );

const PaymentRow = ({
  data: {
    amount,
    currency,
    reference,
    referenceFiatAmount,
    settlementAmount,
    ...dateFields
  },
  index,
  referenceCurrency,
  settlementCurrency,
  shouldShowReferenceCurrency,
}) => (
  <tr className={styles.tr} key={reference} data-test="order-row">
    <Cell>{index + 1}</Cell>
    <Cell>{dateAndTime(dateFields)}</Cell>
    <Cell>{reference}</Cell>
    <Cell justify="end">{`${amount} ${currency}`}</Cell>
    {shouldShowReferenceCurrency && (
      <Cell justify="end">{`${referenceFiatAmount} ${referenceCurrency}`}</Cell>
    )}
    <Cell justify="end">{`${settlementAmount} ${settlementCurrency}`}</Cell>
  </tr>
);

PaymentRow.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
    referenceFiatAmount: PropTypes.string,
    settlementAmount: PropTypes.string.isRequired,
    confirmedAt: PropTypes.string,
    paidAt: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  shouldShowReferenceCurrency: PropTypes.bool.isRequired,
};

export default PaymentRow;
