import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const Cell = ({ children, weight, align }) => {
  const classes = classNames(styles.cell, styles[weight], styles[align]);
  const contentClasses = classNames(styles['cell-content'], styles[align]);

  return (
    <td className={classes}>
      <div className={contentClasses}>{children}</div>
    </td>
  );
};

Cell.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.oneOf(['bold']),
  align: PropTypes.oneOf(['right']),
};

export default Cell;
