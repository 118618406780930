import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { settlementCurrency as settlementCurrencySelector } from 'session/reducers';
import { actions as errorActions } from 'error';
import { mapCountries } from 'onboarding/utils/signUpUtils';
import BackButton from 'common/components/BackButton';
import Layout from 'common/containers/Layout';
import { api } from 'app/services';
import NewInvoice from '../components/NewInvoice';
import NewInvoiceConfirmationModal from '../components/NewInvoiceConfirmationModal';

const newInvoiceId = (response) =>
  _.chain(response.success)
    .values()
    .map((invoice) => invoice.id)
    .value()[0];

const NewInvoiceContainer = ({ apiError, settlementCurrency }) => {
  const history = useHistory();
  const { state } = useLocation();
  const [values, setValues] = useState();
  const [showModal, setShowModal] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [redirectPath, setRedirectPath] = useState();

  const [countries, setCountries] = useState([]);
  const [isFetchingCountries, setIsFetchingCountries] = useState(true);

  useEffect(() => {
    let isSubscribed = true;
    api.fetchBuyerCountries().then((response) => {
      if (isSubscribed) {
        if (response.response) {
          setCountries(mapCountries(response.response.country, true));
          setIsFetchingCountries(false);
        } else {
          apiError(response?.error);
          setNetworkError(true);
          setIsFetchingCountries(false);
        }
      }
    });
    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  const goBack = () => history.push('/payments/invoices');

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (newValues) => {
    setValues(newValues);
    setShowModal(true);
  };

  const submitRequest = async () => {
    setSubmitting(true);
    const { response, error } = await api.submitNewInvoice({
      ...values,
      errorPath: 'errors[0].eur_amount[0]',
    });
    if (error) {
      setSubmitting(false);
      setShowModal(false);
      setNetworkError(error);
      apiError(error);
    } else {
      const id = newInvoiceId(response);
      setRedirectPath(`/payments/invoices/${id}`);
    }
  };

  if (redirectPath)
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { showModal: true },
        }}
      />
    );

  return (
    <Layout heading="New invoice" sideAction={<BackButton onGoBack={goBack} />}>
      <NewInvoice
        countries={countries}
        errorMessage={networkError}
        isFetchingCountries={isFetchingCountries}
        onSubmit={onSubmit}
        prefillData={state?.initialFormState}
        submitting={submitting}
        settlementCurrency={settlementCurrency}
      />
      <NewInvoiceConfirmationModal
        isOpen={showModal}
        onClose={closeModal}
        onSubmit={submitRequest}
        values={values}
        submitting={submitting}
      />
    </Layout>
  );
};

NewInvoiceContainer.propTypes = {
  apiError: PropTypes.func,
  settlementCurrency: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    settlementCurrency: settlementCurrencySelector(state),
  }),
  {
    apiError: errorActions.apiError,
  }
)(NewInvoiceContainer);
