import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';
import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const Layout = ({ heading, children }) => (
  <div className={styles.root}>
    <div className={styles.logoWrapper}>
      <LogoxMoney />
    </div>
    <Heading
      level="1"
      size="2"
      weight="medium"
      id="reset-link-sent"
      color="black"
    >
      {heading}
    </Heading>
    {children}
  </div>
);

Layout.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
