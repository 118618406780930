import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';
import Modal from 'common/components/Modal';
import Text from 'common/components/Text';
import Button from 'common/components/Button';

import styles from './index.module.css';

const CancelInvoiceConfirmationModal = ({ isOpen, onClose, onSubmit }) => (
  <Modal isOpen={isOpen} onClose={onClose} closeOnClickOutside closeOnEscape>
    <div>
      <Heading size="3" level="3" weight="medium">
        Are you sure you want to cancel this invoice?
      </Heading>
      <br />
      <Text>
        {`Your client won't be able to pay and you cannot revert this action.`}
      </Text>
      <div className={styles.modalFooter}>
        <Button kind="subtle" onClick={onClose}>
          <button type="button">Take me back</button>
        </Button>
        <Button kind="destructive" onClick={onSubmit}>
          <button type="button">Cancel</button>
        </Button>
      </div>
    </div>
  </Modal>
);

CancelInvoiceConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CancelInvoiceConfirmationModal;
