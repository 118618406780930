import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { displayUtcDate } from 'common/services/date';

import styles from './index.module.css';
import icons from '../icons';

class Activity extends PureComponent {
  render() {
    const { createdAt, icon, title, content, eventType } = this.props;
    const ActivityIcon = icons[icon];

    return (
      <div className={styles.activity} data-test="activity">
        <span className={styles.time}>
          {displayUtcDate(createdAt, 'HH:mm')}
        </span>
        <div className={styles.statusWrapper}>
          <div className={styles.status}>
            {ActivityIcon && <ActivityIcon />}
            <p
              className={classNames(styles.statusLabel, styles[icon])}
              data-test={`event-${eventType}`}
            >
              {title}
            </p>
          </div>
          {content && (
            <p className={styles.content}>
              <span>{content.title}</span>
              {content.text}
            </p>
          )}
        </div>
      </div>
    );
  }
}

Activity.propTypes = {
  createdAt: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default Activity;
