import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const SymbolxMoney = ({ fill, ...rest }) => (
  <svg
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    {...rest}
  >
    <path d="M19.506 17.998 32 11.398l-2.1-3.994-11.444 4.578a1.228 1.228 0 0 1-.915 0L6.1 7.404 4 11.398l12.494 6.604L4 24.602l2.1 3.994 11.44-4.578c.295-.118.622-.118.916 0l11.44 4.578 2.1-3.994-12.493-6.604h.003Z" />
  </svg>
);

SymbolxMoney.propTypes = {
  fill: PropTypes.string,
};

SymbolxMoney.defaultProps = {
  fill: colors['color-white'],
};

export default SymbolxMoney;
