import { get, camelCase } from 'lodash';
import {
  take,
  put,
  call,
  select,
  all,
  fork,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import { watch } from 'common/sagas';
import { actions as errorActions } from 'error';
import { api } from 'app/services';
import actions from '../actions';
import {
  getRefund,
  isFetchingRefund,
  getOrdersTableFilter,
  getOrdersScope,
} from '../reducers/orders';
import { refundProposed, allStatusRegex } from '../propTypes';
import constants from '../../common/constants';

const sortOrderFields = {
  createdAt: 'created_at',
  detailsReference: 'reference',
  detailsCustomerEmail: 'customer_email',
  totalAmountCurrency: 'currency',
  totalAmountValue: 'total',
  settlementAmountValue: 'settlement_amount',
  status: 'status',
};

const translateSortField = (field) => {
  const fieldParsed = camelCase(field);

  return sortOrderFields[fieldParsed];
};

const translateStatus = (statusValue) =>
  statusValue === allStatusRegex ? 'all' : statusValue;

/** ***************************** REFUND ************************************ */
function* refundSelector() {
  return yield select(getRefund);
}

function* refundFetchStatus() {
  return yield select(isFetchingRefund);
}

function* fetchRefund(paymentId, billId) {
  yield put(actions.refundFetching());

  const { response, error } = yield call(api.fetchRefund, billId);
  const refundAttributes = get(
    response,
    `refunds.payment-${paymentId}.attributes`
  );

  if (refundAttributes || error === constants.NOT_FOUND) {
    const payload = error ? null : { paymentId, ...refundAttributes };
    yield put(actions.refundLoadSuccess(payload));
  } else
    yield all([
      put(actions.refundLoadFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

function* loadRefund({ paymentId, billId }) {
  const isFetching = yield refundFetchStatus();

  if (isFetching) {
    yield take([actions.refundLoadSuccess, actions.refundLoadFailure]);
  }

  const cachedRefund = yield refundSelector();

  if (cachedRefund && cachedRefund.paymentId === paymentId)
    // although the data is the same, we need to get out of the loading state
    return yield put(actions.refundLoadSuccess(cachedRefund));

  yield call(fetchRefund, paymentId, billId);
}

function* refund(payload) {
  const {
    values: { orderId, paymentId, ...formValues },
  } = payload;

  const { response, error } = yield call(api.refund, {
    billId: orderId,
    ...formValues,
  });

  if (response) {
    const {
      reason,
      amount: requestedAmount,
      currency: requestedCurrency,
    } = formValues;

    yield put(actions.refundSuccess({ orderId }));
    yield all([
      put(
        actions.refundLoadSuccess({
          paymentId,
          status: refundProposed,
          reason,
          requestedAmount,
          requestedCurrency,
        })
      ),
      put(actions.orderActivitiesRequest(orderId)),
    ]);
  } else {
    yield all([
      put(actions.refundFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

/** ***************************** ORDER ************************************ */
function* ordersTableFiltersSelector() {
  return yield select(getOrdersTableFilter);
}

function* ordersScopeSelector() {
  return yield select(getOrdersScope);
}

function* fetchOrders() {
  const {
    dateRange,
    page,
    pageSize,
    search,
    sort: { id: sortId, sortAscending },
    status,
  } = yield ordersScopeSelector();

  const { response, error } = yield call(api.fetchOrders, {
    dateRange,
    page,
    pageSize,
    search,
    sort: { id: translateSortField(sortId), sortAscending },
    status: translateStatus(status),
  });

  if (response) yield put(actions.ordersSuccess(response));
  else
    yield all([
      put(actions.ordersFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

function* fetchOrderPage({ id }) {
  const { response, error } = yield call(api.fetchOrderPage, id);
  if (response) yield put(actions.singleOrderPageSuccess(response));
  else
    yield all([
      put(actions.singleOrderPageFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

function* exportOrders() {
  const {
    searchValue,
    statusValue,
    dateRangeValue,
  } = yield ordersTableFiltersSelector();

  const { response, error } = yield call(api.exportOrdersToCsv, {
    search: searchValue,
    status: translateStatus(statusValue),
    dateRange: dateRangeValue,
  });

  if (response) yield put(actions.exportOrdersSuccess(response));
  else {
    yield all([
      put(
        actions.exportOrdersFailure(error),
        put(errorActions.apiError(error))
      ),
    ]);
  }
}

/** ***************************** ACTIVITIES ************************************ */

function* fetchOrderActivities({ orderId }) {
  const { response, error } = yield call(api.fetchOrderActivities, orderId);
  if (response) yield put(actions.orderActivitiesSuccess(response));
  else {
    yield all([
      put(actions.orderActivitiesFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

const actionsToTriggerOrdersFetch = [
  actions.ordersTableNextPage,
  actions.ordersTablePreviousPage,
  actions.resetOrdersTable,
  actions.setOrdersTableFilter,
  actions.setOrdersTablePage,
  actions.setOrdersTablePageSize,
  actions.setOrdersTableSort,
];

function* watchOrdersRequest() {
  yield takeLatest(actions.ordersRequest, fetchOrders);
}

function* triggerOrderRequest() {
  yield put(actions.ordersRequest());
}

function* watchOrdersScope() {
  yield takeEvery([actionsToTriggerOrdersFetch], triggerOrderRequest);
}

export default function* root() {
  yield fork(watchOrdersScope);
  yield fork(watchOrdersRequest);
  yield watch(actions.refundRequest, refund);
  yield watch(actions.singleOrderPageRequest, fetchOrderPage);
  yield watch(actions.refundLoadRequest, loadRefund);
  yield watch(actions.orderActivitiesRequest, fetchOrderActivities);
  yield watch(actions.exportOrdersRequest, exportOrders);
}
