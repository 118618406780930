import {
  all,
  currentDay,
  lastDay,
  currentWeek,
  lastWeek,
  currentMonth,
  lastMonth,
} from '../propTypes';

export const defaultDateRangeValue = all;

export const dateRanges = [
  { value: all, label: 'All' },
  { value: currentDay, label: 'Current day' },
  { value: lastDay, label: 'Last day' },
  { value: currentWeek, label: 'Current week' },
  { value: lastWeek, label: 'Last week' },
  { value: currentMonth, label: 'Current month' },
  { value: lastMonth, label: 'Last month' },
];

export default { defaultDateRangeValue, dateRanges };
