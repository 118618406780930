import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'AUTO_SIGN_IN',
  'TOKEN_SIGN_IN',
  'SIGN_IN_REQUEST',
  'SIGN_IN_SUCCESS',
  'SIGN_IN_FAILURE',
  'SIGN_OUT',
  'SIGN_OUT_SUCCESS',
  'IDENTIFY_SUCCESS',
  'IDENTIFY_FAILURE',
  'VERIFY_REQUEST',
  'VERIFY_FAILURE',
  'PERMISSIONS_REQUEST',
  'PERMISSIONS_SUCCESS',
  'PERMISSIONS_FAILURE',
  'OTP_EXCHANGE_REQUEST',
  'OTP_EXCHANGE_SUCCESS',
  'OTP_EXCHANGE_FAILURE',
  'TOKEN_REFRESH_REQUEST',
  'TOKEN_REFRESH_SUCCESS',
  'TOKEN_REFRESH_FAILURE',
  'PROCEED_ROUTING',
]);

const actionCreators = createActions(
  actionTypes.SIGN_IN_REQUEST,
  actionTypes.VERIFY_REQUEST,
  actionTypes.AUTO_SIGN_IN,
  actionTypes.PROCEED_ROUTING,
  actionTypes.TOKEN_SIGN_IN,
  actionTypes.SIGN_IN_SUCCESS,
  actionTypes.SIGN_IN_FAILURE,
  actionTypes.SIGN_OUT,
  actionTypes.SIGN_OUT_SUCCESS,
  actionTypes.IDENTIFY_SUCCESS,
  actionTypes.IDENTIFY_FAILURE,
  actionTypes.VERIFY_FAILURE,
  actionTypes.PERMISSIONS_REQUEST,
  actionTypes.PERMISSIONS_SUCCESS,
  actionTypes.PERMISSIONS_FAILURE,
  actionTypes.TOKEN_REFRESH_REQUEST,
  actionTypes.TOKEN_REFRESH_SUCCESS,
  actionTypes.TOKEN_REFRESH_FAILURE,
  actionTypes.OTP_EXCHANGE_REQUEST,
  actionTypes.OTP_EXCHANGE_SUCCESS,
  actionTypes.OTP_EXCHANGE_FAILURE
);

export default {
  ...actionTypes,
  ...actionCreators,
};
