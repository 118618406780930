import React, { Component, Children, cloneElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import Loading from 'common/components/Loading';
import styles from './index.module.css';

class Button extends Component {
  get buttonClasses() {
    const { kind, size, loading, full, disabled } = this.props;

    return classNames(styles.root, styles[kind], styles[size], {
      [styles.full]: full,
      [styles.disabled]: disabled,
      [styles.loading]: loading,
    });
  }

  handleClick = (evt) => {
    const { disabled, onClick } = this.props;

    if (disabled) {
      evt.preventDefault();
      return;
    }

    if (onClick) {
      evt.preventDefault();
      onClick(evt);
    }
  };

  render() {
    const { children, orderReverse, name, icon } = this.props;
    const child = Children.only(children);
    const { disabled, loading, progressValueText, kind } = this.props;
    const type = child.props.type || 'button';
    const wrapperStyles = classNames(styles.wrapper, {
      [styles.reverse]: orderReverse,
    });
    const childrenStyles = classNames(styles.children, {
      [styles.hidden]: loading,
    });

    return cloneElement(
      child,
      {
        name,
        className: this.buttonClasses,
        onClick: this.handleClick,
        disabled: disabled || loading,
        'aria-disabled': disabled || loading,
        type,
      },
      <div className={wrapperStyles}>
        {loading && (
          <Loading
            size="medium"
            kind={kind}
            progressValue={progressValueText}
          />
        )}
        {icon && !loading && <div className={styles.icon}>{icon}</div>}
        <span className={childrenStyles}>{child.props.children}</span>
      </div>
    );
  }
}

Button.propTypes = {
  name: PropTypes.string,
  /**
   * Content for the button
   */
  children(props, propName, componentName) {
    const { type } = props[propName];

    if (type.displayName === 'Link' || includes(['a', 'button'], type)) return;

    return new Error(
      `Invalid children prop of type <${type}> was supplied to ${componentName}.
      It must be an <a> or <button> element or a react-router's <Link> component.`
    );
  },

  /**
   * Function to run when the button is clicked or one of the keys (ENTER,
   * SPACE) are pressed
   */
  onClick: PropTypes.func,

  /**
   * Disable button
   */
  disabled: PropTypes.bool,

  /**
   * Displays a loading spinner
   */
  loading: PropTypes.bool,

  /**
   * Custom sizes
   */
  size: PropTypes.oneOf(['small', 'large']),

  /**
   * Allow button to span available width
   */
  full: PropTypes.bool,

  /**
   * The icon that shows besides the content
   */
  icon: PropTypes.node,

  /**
   * Color of the button
   */
  kind: PropTypes.oneOf([
    'primary',
    'secondary',
    'destructive',
    'test',
    'subtle',
    'destructiveSubtle',
  ]).isRequired,

  /**
   * Value for aria-valuetext, which is the human readable text alternative of
   * aria-valuenow for spinbutton
   */
  progressValueText: PropTypes.string,

  orderReverse: PropTypes.bool,
};

Button.defaultProps = {
  name: 'button',
  children: null,
  disabled: false,
  loading: false,
  full: false,
  size: null,
  progressValueText: '',
  onClick: null,
  icon: undefined,
  orderReverse: false,
};

export default Button;
