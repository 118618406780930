import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';

import Button from 'common/components/Button';
import TextBox from 'common/components/TextBox';
import formUtils from 'common/formUtils.js';

import styles from '../index.module.css';

const initialValues = {
  recoveryCode: '',
};

const RecoveryCodeInput = ({ onResetSubmit, onCancelClick }) => {
  const handleFormSubmit = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    const errorMessage = await onResetSubmit(values);
    if (errorMessage) {
      setSubmitting(false);
      setFieldError('recoveryCode', errorMessage);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
      {({ errors, touched, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.singleColumn}>
              <Field name="recoveryCode" validate={formUtils.validateRequired}>
                {({ field, meta }) => (
                  <TextBox
                    id="recoveryCode"
                    labelText="Recovery Code"
                    labelType="recoveryCode"
                    placeholder="Your recovery code"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className={styles.buttons}>
              <div className={styles['buttonWrapper-2-cols']}>
                <Button
                  full
                  kind="primary"
                  loading={isSubmitting}
                  disabled={formUtils.isSubmitDisabled(errors, touched)}
                >
                  <button
                    type="submit"
                    id="recovery-submit"
                    data-test="recovery-submit-button"
                  >
                    Reset credentials
                  </button>
                </Button>
              </div>
              <div className={styles.buttonWrapper}>
                <Button full kind="subtle" onClick={onCancelClick}>
                  <button
                    type="button"
                    id="recovery-cancel"
                    data-test="recovery-cancel-button"
                  >
                    Cancel
                  </button>
                </Button>
              </div>
            </div>
          </form>
        )}
    </Formik>
  );
};

RecoveryCodeInput.propTypes = {
  onResetSubmit: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default RecoveryCodeInput;
