import React from 'react';
import PropTypes from 'prop-types';

import { settlement as settlementPropType } from 'settlements/propTypes';
import { getTitlePrefix as getTitlePrefixBase } from 'settlements/utils/getTitlePrefix';
import ReportHeader from '../ReportHeader';
import ReportTable, { PaymentRow } from '../ReportTable';
import ReportTotals from '../ReportTotals';
import paymentHeaders from './helpers';

import styles from './index.module.css';

const Report = ({
  activeStoreName,
  companyOfficialName,
  isInvoicingEnabled,
  isOnrampEnabled,
  data: {
    endsAt,
    fees,
    paidInvoices,
    paidOrders,
    paidFiatBills,
    referenceFees,
    referenceFiatCurrency,
    referenceToBeSettledAmount,
    referenceTotalAmount,
    referenceTotalInvoicesAmount,
    referenceTotalOrdersAmount,
    referenceTotalRefundedAmount,
    referenceTotalFiatBillsAmount,
    settlementBlockchain,
    refundedOrders,
    settledOn,
    shortId: id,
    startsAt,
    toBeSettledAmount,
    totalAmount,
    totalInvoicesAmount,
    totalOrdersAmount,
    totalRefundedAmount,
    totalFiatBillsAmount,
  },
}) => {
  const settlementCurrency = toBeSettledAmount.currency;
  const shouldShowReferenceCurrency = !!referenceFiatCurrency;
  const paymentsTableHeader = shouldShowReferenceCurrency
    ? paymentHeaders(referenceFiatCurrency)
    : paymentHeaders();

  const getTitlePrefix = (section) =>
    getTitlePrefixBase(section, { isInvoicingEnabled, isOnrampEnabled });

  return (
    <div className={styles.root} role="main" aria-labelledby="page-title">
      <div className={styles.container}>
        <ReportHeader
          id={id}
          activeStoreName={activeStoreName}
          companyOfficialName={companyOfficialName}
          start={startsAt}
          end={endsAt}
          settledOn={settledOn}
          blockchain={settlementBlockchain}
        />
        <div className={styles.tables}>
          <ReportTable
            id="ordersPaid"
            title={`${getTitlePrefix('order')}. Orders Paid`}
            headers={paymentsTableHeader}
            data={paidOrders}
            total={totalOrdersAmount}
            totalReference={referenceTotalOrdersAmount}
            totalTitle="Total Orders Paid"
            emptyMessage="No orders"
            rowComponent={PaymentRow}
            referenceCurrency={referenceFiatCurrency}
            settlementCurrency={settlementCurrency}
            shouldShowReferenceCurrency={shouldShowReferenceCurrency}
          />
          {isInvoicingEnabled && (
            <ReportTable
              id="invoicesPaid"
              title={`${getTitlePrefix('invoice')}. Invoices Paid`}
              headers={paymentsTableHeader}
              data={paidInvoices}
              total={totalInvoicesAmount}
              totalReference={referenceTotalInvoicesAmount}
              totalTitle="Total Invoices Paid"
              emptyMessage="No invoices"
              rowComponent={PaymentRow}
              referenceCurrency={referenceFiatCurrency}
              settlementCurrency={settlementCurrency}
              shouldShowReferenceCurrency={shouldShowReferenceCurrency}
            />
          )}
          <ReportTable
            id="refundsPaid"
            title={`${getTitlePrefix('refund')}. Orders Refunded`}
            headers={paymentsTableHeader}
            data={refundedOrders}
            total={totalRefundedAmount}
            totalReference={referenceTotalRefundedAmount}
            totalTitle="Total Orders Refunded"
            emptyMessage="No refunds"
            rowComponent={PaymentRow}
            referenceCurrency={referenceFiatCurrency}
            settlementCurrency={settlementCurrency}
            shouldShowReferenceCurrency={shouldShowReferenceCurrency}
          />
          {isOnrampEnabled && (
            <ReportTable
              id="fiatBillsPaid"
              title={`${getTitlePrefix('fiatBill')}. Onramp`}
              headers={paymentsTableHeader}
              data={paidFiatBills}
              total={totalFiatBillsAmount}
              totalReference={referenceTotalFiatBillsAmount}
              totalTitle="Total Fiat Bills Paid"
              emptyMessage="No fiat bills"
              rowComponent={PaymentRow}
              referenceCurrency={referenceFiatCurrency}
              settlementCurrency={settlementCurrency}
              shouldShowReferenceCurrency={shouldShowReferenceCurrency}
            />
          )}
        </div>
        <br />
        <ReportTotals
          fees={fees}
          isInvoicingEnabled={isInvoicingEnabled}
          isOnrampEnabled={isOnrampEnabled}
          referenceCurrency={referenceFiatCurrency}
          referenceFees={referenceFees}
          shouldShowReferenceCurrency={shouldShowReferenceCurrency}
          toBeSettledAmount={toBeSettledAmount}
          toBeSettledReferenceAmount={referenceToBeSettledAmount}
          totalAmount={totalAmount}
          totalInvoicesAmount={totalInvoicesAmount}
          totalFiatBillsAmount={totalFiatBillsAmount}
          totalOrdersAmount={totalOrdersAmount}
          totalReferenceAmount={referenceTotalAmount}
          totalReferenceInvoicesAmount={referenceTotalInvoicesAmount}
          totalReferenceOrdersAmount={referenceTotalOrdersAmount}
          totalReferenceRefundedAmount={referenceTotalRefundedAmount}
          totalReferenceFiatBillsAmount={referenceTotalFiatBillsAmount}
          totalRefundedAmount={totalRefundedAmount}
        />
      </div>
    </div>
  );
};

Report.propTypes = {
  data: settlementPropType.isRequired,
  activeStoreName: PropTypes.string.isRequired,
  companyOfficialName: PropTypes.string.isRequired,
  isInvoicingEnabled: PropTypes.bool.isRequired,
  isOnrampEnabled: PropTypes.bool.isRequired,
};

export default Report;
