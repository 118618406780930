import { take, put, fork, call, select, all } from 'redux-saga/effects';
import _ from 'lodash';

import { actions as errorActions } from 'error';
import { api } from 'app/services';
import actions from '../actions';
import { actions as authActions } from '../../auth';
import { organizations, stores } from '../reducers';

function* sessionClear() {
  yield call(api.clearItem, 'identificationToken');
  yield call(api.clearItem, 'intercomUserHash');
  yield call(api.clearItem, 'signInToken');
  yield call(api.clearItem, 'userEmail');
  yield call(api.clearItem, 'accessToken');
  yield call(api.clearItem, 'refreshToken');
}

function* organizationsSelector() {
  return yield select(organizations);
}

function* fetchOrganizations() {
  yield put(actions.organizationsFetching());

  const { response, error } = yield call(api.fetchOrganizations);
  if (response) yield put(actions.organizationsSuccess(response));
  else
    yield all([
      put(actions.organizationsFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

function* storesSelector() {
  return yield select(stores);
}

function* fecthStores(organizationUUID) {
  const { response, error } = yield call(
    api.fecthOrganizationStores,
    organizationUUID
  );
  if (response) yield put(actions.storesSuccess(response));
  else
    yield all([
      put(actions.storesFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchSessionClear() {
  while (true) {
    yield take(actions.sessionClear);
    yield call(sessionClear);
  }
}

function* loadOrganizations() {
  yield fork(fetchOrganizations);

  const action = yield take([
    actions.organizationsSuccess,
    actions.organizationsFailure,
  ]);

  if (action.type === actions.ORGANIZATIONS_FAILURE) {
    return yield put(actions.sessionFailure());
  }

  const organization = _.head(yield organizationsSelector());
  yield all([
    put(actions.activeOrganization(organization)),
    put(actions.storesRequest(organization)),
  ]);
}

function* watchloadOrganizations() {
  while (true) {
    yield take([
      actions.organizationsRequest,
      authActions.signInSuccess,
      authActions.autoSignIn,
      authActions.tokenRefreshSuccess,
      authActions.otpExchangeSuccess,
    ]);
    yield put(actions.sessionLoad());

    yield call(loadOrganizations);
  }
}

function* selectActiveStore() {
  const store = _.head(yield storesSelector());

  yield put(actions.activeStore(store));
  return yield put(actions.sessionSuccess());
}

function* watchloadStores() {
  while (true) {
    const {
      payload: { id },
    } = yield take(actions.storesRequest);
    yield fork(fecthStores, id);

    const action = yield take([actions.storesSuccess, actions.storesFailure]);

    if (action.type === actions.STORES_SUCCESS) {
      yield call(selectActiveStore);
    } else {
      yield put(actions.sessionFailure());
    }
  }
}

export default function* root() {
  yield fork(watchloadOrganizations);
  yield fork(watchloadStores);
  yield fork(watchSessionClear);
}
