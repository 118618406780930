import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'SETTLEMENTS_REQUEST',
  'SETTLEMENTS_SUCCESS',
  'SETTLEMENTS_FAILURE',
  'SETTLEMENT_REQUEST',
  'SINGLE_SETTLEMENT_REQUEST',
  'SINGLE_SETTLEMENT_SUCCESS',
  'SINGLE_SETTLEMENT_FAILURE',
]);

const actionCreators = createActions(
  {
    [actionTypes.SINGLE_SETTLEMENT_REQUEST]: (id) => ({ id }),
    [actionTypes.SINGLE_ORDER_PAGE_FAILURE]: Error,
    [actionTypes.SETTLEMENTS_FAILURE]: Error,
  },
  actionTypes.SETTLEMENTS_REQUEST,
  actionTypes.SETTLEMENTS_SUCCESS,
  actionTypes.SINGLE_SETTLEMENT_SUCCESS
);

export default {
  ...actionTypes,
  ...actionCreators,
};
