import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { fiatValueWithCurrency } from 'common/currencies';
import { amount } from 'common/propTypes';
import Text from 'common/components/Text';
import Separator from 'common/components/Separator';
import {
  subtotalName,
  taxName,
  shippingFeeName,
  discountName,
} from '../../constants';

import Fee from './Fee';
import styles from './index.module.css';

const renderSubtotal = (subtotal) => (
  <>
    <Separator />
    <Fee description={subtotalName} fee={subtotal} />
  </>
);

const renderOrderDetails = (tax, shippingFee, discount) => {
  if (!tax?.value && !shippingFee?.value && !discount?.value) return null;
  return (
    <>
      <Separator />
      {tax?.value && <Fee description={taxName} fee={tax} />}
      {shippingFee?.value && (
        <Fee description={shippingFeeName} fee={shippingFee} />
      )}
      {discount?.value && <Fee description={discountName} fee={discount} />}
    </>
  );
};

const renderItems = (items) => (
  <ul className={styles.items}>
    {items.map((item) => (
      <li className={styles.lineItem} key={item.sku}>
        <Text>{`${item.quantity}.  ${item.name}`}</Text>
        <Text>{fiatValueWithCurrency(item.price, item.currency)}</Text>
      </li>
    ))}
  </ul>
);

const LineItems = ({ items, subtotal, tax, shippingFee, discount }) => (
  <div className={styles.content}>
    {items.length
      ? renderItems(items)
      : 'Unfortunately, we are unable to get your payment details for this order.'}
    {subtotal?.value && renderSubtotal(subtotal)}
    {renderOrderDetails(tax, shippingFee, discount)}
  </div>
);

LineItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sku: PropTypes.string,
    })
  ).isRequired,
  subtotal: amount,
  tax: amount,
  shippingFee: amount,
  discount: amount,
};

LineItems.defaultProps = {
  subtotal: null,
  tax: null,
  shippingFee: null,
  discount: null,
};

export default memo(LineItems);
