import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';

import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import TextBox from 'common/components/TextBox';
import formUtils from 'common/formUtils.js';
import AuthPage from 'common/components/AuthPage';

import styles from './index.module.css';

const TwoFactorVerification = ({ onAuthCodeSubmit, cancel, invalid }) => {
  const Header = () => (
      <div className={styles.header}>
        <Heading
          level="3"
          size="3"
          weight="medium"
          id="page-title"
          color="black"
        >
          Two Factor Authentication
        </Heading>
      </div>
    );

  const Form = () => (
      <Formik
        initialValues={{ authenticatorCode: '' }}
        onSubmit={onAuthCodeSubmit}
      >
        {({ touched, handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.instructions}>
              Please insert the verification code provided by the Authenticator
              app.
            </div>
            <Field
              name="authenticatorCode"
              validate={formUtils.validateTwoFactorCode}
            >
              {({ field, meta }) => (
                <div className={styles.row}>
                  <TextBox
                    id="authenticatorCode"
                    labelText="Authentication Code"
                    labelType="authenticatorCode"
                    placeholder="6-digit code"
                    {...field}
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                  />
                </div>
              )}
            </Field>
            <p className={styles.formFooter}>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={cancel}
              >
                Cancel
              </button>
              <Button
                loading={isSubmitting}
                progressValueText="Submitting..."
                kind="primary"
                disabled={formUtils.isSubmitDisabled(errors, touched)}
              >
                <button type="submit" data-test="sign-in-button">
                  Sign in
                </button>
              </Button>
            </p>
          </form>
        )}
      </Formik>
    );

  return (
    <AuthPage
      header={<Header />}
      form={<Form />}
      error={invalid ? 'Wrong code entered' : ''}
    />
  );
};

TwoFactorVerification.propTypes = {
  onAuthCodeSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
};

export default TwoFactorVerification;
