import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'common/components/Button';
import Icon from 'common/components/Icon';

import actions from '../../actions';

import styles from './index.module.css';

const ExportContainer = ({ exportOrders, disabled }) => (
    <div className={styles.exportWrapper}>
      <Button
        onClick={exportOrders}
        disabled={disabled}
        kind="secondary"
        icon={
          <Icon.Arrow
            description={`Arrow small down`}
            size="medium"
            direction="down"
          />
        }
        full
      >
        <button type="button" data-test="button-export">
          Export table
        </button>
      </Button>
    </div>
  );

ExportContainer.propTypes = {
  exportOrders: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  exportOrders: actions.exportOrdersRequest,
};

const MemoizedExportContainer = memo(ExportContainer);

export default connect(null, mapDispatchToProps)(MemoizedExportContainer);
