import _ from 'lodash';

const transform = {
  OrderPlaced: () => ({
    icon: 'pending',
    title: 'Order placed',
  }),
  OrderCancelled: () => ({
    icon: 'canceled',
    title: 'Order canceled',
  }),
  OrderExpired: () => ({
    icon: 'canceled',
    title: 'Order expired',
  }),
  OrderPaymentReceived: () => ({
    icon: 'paid',
    title: 'Order paid',
  }),
  DisputeOpened: (data) => ({
    icon: 'dispute',
    title: 'Dispute open',
    content: {
      title: 'Reason: ',
      text: _.startCase(data.reason),
    },
  }),
  DisputeCancelled: () => ({
    icon: 'canceled',
    title: 'Dispute canceled',
  }),
  DisputeEscalated: () => ({
    icon: 'dispute',
    title: 'Claim open',
  }),
  DisputeResolutionSubmitted: () => ({
    icon: 'completed',
    title: 'Claim closed',
  }),
  RefundProposed: (data, metadata) => ({
    icon: 'refundProcessing',
    title: 'Refund proposed',
    content: {
      text: metadata.userId ? `by ${metadata.userId}` : null,
    },
  }),
  RefundProposalAccepted: (metadata) => ({
    icon: 'refundProcessing',
    title: 'Refund processing',
    content: {
      text: metadata.userId ? `by ${metadata.userId}` : null,
    },
  }),
  RefundProposalRejected: () => ({
    icon: 'canceled',
    title: 'Refund rejected',
  }),
  RefundCompleted: () => ({
    icon: 'refundCompleted',
    title: 'Refund complete',
  }),
  InvoicePlaced: () => ({
    icon: 'pending',
    title: 'Pending',
  }),
  InvoicePaymentReceived: () => ({
    icon: 'paid',
    title: 'Paid',
  }),
  InvoiceCancelled: () => ({
    icon: 'canceled',
    title: 'Canceled',
  }),
};

const applyWarningMessage = (transformedEvents) => {
  const lastEvent = transformedEvents[0];

  if (lastEvent.eventType === 'RefundProposalAccepted') {
    lastEvent.content = { text: 'May take up to 24 hours' };
  }

  return [lastEvent, ..._.slice(transformedEvents, 1)];
};

const copyTransform = ({ eventType, id, createdAt, data, metadata }) => {
  if (transform[eventType])
    return {
      id,
      createdAt,
      eventType,
      ...transform[eventType](data, metadata || {}),
    };
};

export { applyWarningMessage, copyTransform };
