export const isInvalid = (page, totalPages) => page < 1 || page > totalPages;
export const isAfterLast = (page, lastPage) => page - 1 === lastPage;
export const getLastPage = (acc) => acc.length && acc[acc.length - 1];

const pagesReducer = (totalPages) => (acc, page) => {
  const lastPage = getLastPage(acc);

  if (isInvalid(page, totalPages)) return acc;
  if (isAfterLast(page, lastPage)) return [...acc, page];
  if (page - lastPage === 2) return [...acc, page - 1, page];
  if (acc.length) return [...acc, '...', page];
  return [page];
};

export default pagesReducer;
