import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hasFetchError as hasFetchErrorSelector } from '../reducers';
import SystemDown from '../components/SystemDown';

const Error = ({ hasFetchError, children }) => {
  if (hasFetchError) return <SystemDown />;

  return children;
};

Error.propTypes = {
  hasFetchError: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(state => ({
  hasFetchError: hasFetchErrorSelector(state),
}))(Error);
