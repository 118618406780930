import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';

import styles from './index.module.css';

const ActiveLabel = ({ text, name }) => (
  <div className={styles.root} name={name}>
    <Text color="rebranding-md-color-green">{text}</Text>{' '}
    <Icon.Checkmark
      fill={colors['rebranding-md-color-green']}
      description="Checkmark"
      size="medium"
    />
  </div>
);

ActiveLabel.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
};

ActiveLabel.defaultProps = {
  text: 'Active',
  name: 'active',
};

export default memo(ActiveLabel);
