import React from 'react';
import propTypes from 'prop-types';

import styles from './index.module.css';
import LogoxMoney from '../LogoxMoney';

const AuthPage = ({
  header,
  form,
  footer,
  error,
  isMerchantRegistrationActive,
}) => (
  <div className={styles.authPage}>
    <div className={styles.authContainer}>
      <div className={styles.logoWrapper}>
        <LogoxMoney />
      </div>
      <div className={styles.authForm}>
        <div className={styles.row}>{header}</div>
        {form}
      </div>
      {error && <div className={styles.formError}>{error}</div>}
      {isMerchantRegistrationActive && footer}
    </div>
  </div>
);

AuthPage.propTypes = {
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  form: propTypes.node.isRequired,
  footer: propTypes.node,
  header: propTypes.node.isRequired,
  isMerchantRegistrationActive: propTypes.bool,
};

export default AuthPage;
