import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const HeaderCell = ({ content, justify }) => {
  const classes = classNames(styles['cell-content'], styles[justify]);

  return (
    <th className={styles.th}>
      <div className={classes}>{content}</div>
    </th>
  );
};

HeaderCell.propTypes = {
  content: PropTypes.string.isRequired,
  justify: PropTypes.string,
};

HeaderCell.defaultProps = {
  justify: null,
};

export default HeaderCell;
