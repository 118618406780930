import React from 'react';

import AuthPage from 'common/components/AuthPage';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';

import styles from './index.module.css';

const ThankYou = () => (
  <AuthPage
    header={
      <>
        <Heading
          level="1"
          size="3"
          weight="medium"
          id="page-title"
          color="black"
        >
          Thank you!
        </Heading>
        <br />
        <Text color="rebranding-md-color-grey-60">
          <p id="page-description">
            Our dev team was notified and we’ll address this issue as soon as
            possible.
          </p>
        </Text>
      </>
    }
    form={
      <div className={styles.successFooter}>
        <div className={styles.successButton}>
          <Button kind="primary" name="back-to-app" full>
            <a href="/">Close</a>
          </Button>
        </div>
      </div>
    }
  />
);

export default ThankYou;
