import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Cog = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.0383 13.0136L30.0726 8.4358L27.5642 5.92741L22.9864 5.96168L19.7732 2.70001H16.2267L13.0135 5.96168L8.43502 5.9274L5.92734 8.43508L5.96162 13.0136L2.69995 16.2268V19.7733L5.96162 22.9865L5.92733 27.5661L8.43385 30.0726L13.0135 30.0383L16.2267 33.3H19.7732L22.9864 30.0383L27.565 30.0726L30.0726 27.5651L30.0383 22.9865L33.2999 19.7733V16.2268L30.0383 13.0136ZM31.5 16.9803L28.2326 13.7615L28.267 9.1758L26.8242 7.733L22.2385 7.76733L19.0197 4.50001H16.9802L13.7614 7.76733L9.17501 7.73299L7.73293 9.17507L7.76726 13.7615L4.49995 16.9803V19.0198L7.76726 22.2385L7.73292 26.8261L9.17384 28.267L13.7614 28.2327L16.9802 31.5H19.0197L22.2385 28.2327L26.825 28.267L28.267 26.8251L28.2326 22.2385L31.5 19.0198V16.9803Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 22.5C20.4852 22.5 22.5 20.4853 22.5 18C22.5 15.5147 20.4852 13.5 18 13.5C15.5147 13.5 13.5 15.5147 13.5 18C13.5 20.4853 15.5147 22.5 18 22.5ZM18 24.3C21.4793 24.3 24.3 21.4794 24.3 18C24.3 14.5206 21.4793 11.7 18 11.7C14.5206 11.7 11.7 14.5206 11.7 18C11.7 21.4794 14.5206 24.3 18 24.3Z"
    />
  </svg>
);

Cog.propTypes = {
  fill: PropTypes.string,
};

Cog.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Cog;
