import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';

import styles from './index.module.css';

const MultiLineField = ({ label, values }) => (
  <div>
    <h4 className={styles.header}>{label}</h4>
    {values[0] ? values.map((value) => <Text key={value}>{value}</Text>) : '-'}
  </div>
);

MultiLineField.propTypes = {
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default MultiLineField;
