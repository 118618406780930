import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Modal from 'common/components/Modal';
import Text from 'common/components/Text';

import styles from './index.module.css';

const emailSentCopy = {
  title: 'Invoice sent',
  subtitle: 'The invoice is on its way.',
  description:
    'You can check the status of all payments on your dashboard, but we’ll make sure to notify you by email once the payment is completed.',
};

const emailCreatedCopy = {
  title: 'Invoice created',
  subtitle: 'The invoice is created.',
  description:
    "You can find the payment link and check the status of all payments on your dashboard, but we'll make sure to notify you by email once the payment is completed.",
};

const InvoiceSentModal = ({ isOpen, onClose, emailSent }) => {
  const { title, subtitle, description } = emailSent
    ? emailSentCopy
    : emailCreatedCopy;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.root}>
        <Heading level="3" size="3" weight="medium">
          {title}
        </Heading>
        <Text>
          <p>{subtitle}</p>
          <p>{description}</p>
        </Text>
        <div className={styles.footer}>
          <Button kind="primary" onClick={onClose}>
            <button type="button" data-test="invoice-sent-ok">
              OK
            </button>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

InvoiceSentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  emailSent: PropTypes.bool.isRequired,
};

export default InvoiceSentModal;
