import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const Row = ({ title, values, isTotal }) => (
  <li className={classNames(styles.row, { [styles.total]: isTotal })}>
    <span className={styles.title}>{title}</span>
    {values.map((value) => (
      <span key={value.amount} className={styles.value}>{`${
        value.amount || '0'
      } ${value.currency}`}</span>
    ))}
  </li>
);

Row.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      currency: PropTypes.string,
    })
  ).isRequired,
  isTotal: PropTypes.bool,
};

Row.defaultProps = {
  isTotal: false,
};

export default Row;
