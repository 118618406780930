import React from 'react';
import PropTypes from 'prop-types';

const Plug = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M14.0012 4.19238H12.2012L12.2012 9.66235H5.80078V11.5524H7.66016L7.66016 22.897L9.46016 24.697L9.46016 11.5524H26.5511L26.5511 24.697L28.3511 22.897V11.5524H30.2008V9.66235H23.801V4.19238H22.001V9.66235H14.0012L14.0012 4.19238Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M17.1011 26.5624L17.1011 31.8077H18.9011L18.9011 26.5624L24.6857 26.5624L26.4857 24.7624H9.52554L11.3255 26.5624L17.1011 26.5624Z"
    />
  </svg>
);

Plug.propTypes = {
  fill: PropTypes.string,
};

Plug.defaultProps = {
  fill: 'none',
};

export default Plug;
