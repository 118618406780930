import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getItem, apiRoot } from 'app/services/api';
import AuthPage from 'common/components/AuthPage';
import Header from './Header';
import Form from './Form';
import ThankYou from './ThankYou';

const apiToken = () => getItem('signInToken') || getItem('authToken');
const SentryErrorForm = ({ errorId }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setError] = useState('');

  const sendMessage = (comments) => {
    const configs = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiToken()}`,
      },
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'error_report',
          attributes: { comments, event_id: errorId },
        },
      }),
    };
    fetch(`${apiRoot}/report-error`, configs).then(
      setIsSubmitted(true),
      (error) => {
        setError(error);
      }
    );
  };

  const handleSubmit = (comments) => {
    sendMessage(comments);
    setIsSubmitted(true);
  };

  if (isSubmitted) return <ThankYou />;

  return (
    <AuthPage
      header={<Header />}
      form={<Form onSubmit={handleSubmit} />}
      error={errorMessage}
    />
  );
};

SentryErrorForm.propTypes = {
  errorId: PropTypes.string,
};

SentryErrorForm.defaultProps = {
  errorId: '',
};

export default SentryErrorForm;
