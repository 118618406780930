import React from 'react';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import styles from './index.module.css';

const Header = () => (
  <>
    <Heading level="1" size="3" weight="medium" id="page-title" color="black">
      Looks like something went wrong
    </Heading>
    <br />
    <Text color="rebranding-md-color-grey-60">
      <p id="page-description">
        Sorry about this. If you want to help, please describe below what
        happened.
      </p>
    </Text>
    <div className={styles.subtitle}>
      <Text>What happened?</Text>
    </div>
    <Text color="rebranding-md-color-grey-60">
      <p>Briefly report the steps you took</p>
    </Text>
  </>
);

export default Header;
