import React from 'react';
import PropTypes from 'prop-types';

import FadeInTransition from 'common/components/FadeInTransition';
import ActivitiesContainer from '../../containers/Activities';
import OrderDetails from '../OrderDetails';
import { order as orderPropType } from '../../propTypes';

import styles from './index.module.css';

const Order = ({
  data: {
    details: { items, customer, reference },
    createdAt,
    totalAmount,
    referenceAmount,
    settlementAmount,
    status,
    pricingDetails: { subtotal, tax, shipping: shippingFee, discount },
    payment,
    paymentUuid,
    id,
  },
  refundComponent: RefundComponent,
}) => (
  <FadeInTransition>
    <div className={styles.root}>
      <div className={styles.main}>
        <OrderDetails
          createdAt={createdAt}
          items={items}
          customer={customer}
          totalAmount={totalAmount}
          settlementAmount={settlementAmount}
          subtotal={subtotal}
          tax={tax}
          shippingFee={shippingFee}
          discount={discount}
          reference={reference}
          referenceAmount={referenceAmount}
          status={status}
        />
      </div>
      <div className={styles.secondary}>
        {RefundComponent && (
          <RefundComponent
            orderId={id}
            paymentId={payment?.id || paymentUuid}
            status={status}
            totalAmount={totalAmount}
          />
        )}
        <ActivitiesContainer />
      </div>
    </div>
  </FadeInTransition>
);

Order.propTypes = {
  /* eslint-disable react/forbid-prop-types*/
  refundComponent: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  data: PropTypes.shape(orderPropType).isRequired,
};

export default Order;
