import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { track } from 'common/analytics';
import CodeDisplay from 'common/components/CodeDisplay';
import Text from 'common/components/Text';
import { ordersRoute } from 'app/containers/routes';
import { selectors as featuresSelectors } from 'features';

import styles from './index.module.css';

const hiddenSecret = '∗'.repeat(52);

const handleInteraction = (name) => () => {
  track(`Clicked ${name} code display`);
};

const ApiKey = ({ loading, code }) => (
  <div
    className={styles.codeRow}
    onClick={handleInteraction('API Key')}
    role="button"
    tabIndex="0"
    onKeyDown={handleInteraction('API Key')}
  >
    <CodeDisplay
      fullWidth
      clipboard
      loading={loading}
      id="apiKey"
      label="API key"
      code={code}
      dataTest="apiKey"
    />
  </div>
);
ApiKey.propTypes = {
  loading: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
};

const WebhookSecret = ({ loading, code, hideSecret }) => (
  <div
    className={styles.codeRow}
    onClick={handleInteraction('Webhook Secret')}
    role="button"
    tabIndex="0"
    onKeyDown={handleInteraction('Webhook Secret')}
  >
    <CodeDisplay
      fullWidth
      clipboard={!hideSecret}
      loading={loading}
      id="webhookSecret"
      label="Webhook secret"
      code={hideSecret ? hiddenSecret : code}
      dataTest="webhookSecret"
      hidden={hideSecret}
    />
  </div>
);
WebhookSecret.propTypes = {
  loading: PropTypes.bool.isRequired,
  code: PropTypes.string,
  hideSecret: PropTypes.bool,
};

const stepsPropTypes = {
  codes: PropTypes.shape({
    apiKey: PropTypes.string,
    secret: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  hideSecret: PropTypes.bool,
};

const OrdersLink = () => {
  const isInvoicingEnabled = useSelector(featuresSelectors.isInvoicingEnabled);

  if (!isInvoicingEnabled) return null;

  return (
    <li>
      That’s it, you’re done! Just watch your orders coming in through the
      <Link to={ordersRoute}> payments page</Link>.
    </li>
  );
};

const Magento1Steps = ({ codes, hideSecret, loading }) => (
  <div data-test="magento1-instructions">
    <li>
      If you {`haven't`} installed the Magento 1 extension do so{' '}
      <a
        href="https://marketplace.magento.com/utrust-utrust-payment.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      ;
    </li>
    <li>Copy the keys below:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      On your Magento’s admin dashboard paste the keys in:
      <br />
      <span className={styles.bold}>
        System → Configuration → Sales → Payment method → Utrust
      </span>
      <br />
      After that click <span className={styles.bold}>Save Config</span> and
      you’re done.
    </li>
    <OrdersLink />
  </div>
);
Magento1Steps.propTypes = stepsPropTypes;

const Magento2Steps = ({ codes, hideSecret, loading }) => (
  <div data-test="magento2-instructions">
    <li>
      If you {`haven't`} installed the Magento 2 extension do so{' '}
      <a
        href="https://marketplace.magento.com/utrust-module-payment.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      ;
    </li>
    <li>Copy the keys below:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      On your Magento’s admin dashboard paste the keys in:
      <br />
      <span className={styles.bold}>
        Stores → Configuration → Sales → Payment method → Utrust
      </span>
      <br />
      After that click the <span className={styles.bold}>Save Config</span>{' '}
      button on top and you’re done.
    </li>
    <OrdersLink />
  </div>
);
Magento2Steps.propTypes = stepsPropTypes;

const OpenCartSteps = ({ codes, hideSecret, loading }) => (
  <div data-test="opencart-instructions">
    <li>Go to your OpenCart admin dashboard;</li>
    <li>
      Click on <span className={styles.bold}>Extensions → Extensions</span> and
      choose <span className={styles.bold}>payment</span> on the dropdown
      selector;
    </li>
    <li>
      Find <span className={styles.bold}>Utrust</span> entry and click the{' '}
      <span className={styles.bold}>edit</span> icon;
    </li>
    <li>
      Add your API key and Webhook secret below (you can change other settings
      you find appropriate)
    </li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      Click the <span className={styles.bold}>save</span> icon on top right.
    </li>
    <OrdersLink />
  </div>
);
OpenCartSteps.propTypes = stepsPropTypes;

const PayrexxSteps = ({ codes, hideSecret, loading }) => (
  <div data-test="payrexx-instructions">
    <li>Go to your Payrexx admin dashboard;</li>
    <li>
      Go to{' '}
      <span className={styles.bold}>
        Payment providers → Add new payment providers → Wallets & alternative
        payment methods → Show
      </span>
      ;
    </li>
    <li>
      Find <span className={styles.bold}>Utrust</span> and click on{' '}
      <span className={styles.bold}>Setup</span>;
    </li>
    <li>Change the settings according to this information:</li>
    <div className={styles.subStepsWrapper}>
      <Text size="caption">
        <span className={styles.bold}>Mode:</span> Productive
      </Text>
      <br />
      <Text size="caption">
        <span className={styles.bold}>Currencies: </span>
        You can enable all of them
      </Text>
      <br />
      <Text size="caption" weight="semiBold">
        Productive account settings:
      </Text>
    </div>
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      Finally, click <span className={styles.bold}>Save</span>;
    </li>
    <OrdersLink />
  </div>
);
PayrexxSteps.propTypes = stepsPropTypes;

const PrestaShopSteps = ({ codes, hideSecret, loading }) => (
  <div data-test="prestashop-instructions">
    <li>
      If you {`haven't`} installed the PrestaShop module do so{' '}
      <a
        href="https://github.com/utrustdev/utrust-for-prestashop"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      ;
    </li>
    <li>Copy the keys below:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      On your PrestaShop admin dashboard paste the keys in:
      <br />
      <span className={styles.bold}>
        Modules and Services → Utrust → Configure
      </span>
      <br />
      After that click the <span className={styles.bold}>Save</span> button and
      you’re done.
    </li>
    <OrdersLink />
  </div>
);
PrestaShopSteps.propTypes = stepsPropTypes;

const ShopifySteps = ({ codes, hideSecret, loading }) => (
  <div data-test="shopify-instructions">
    <br />
    <Text>
      Once the Utrust app is installed in your Shopify store by our team, you
      can add the keys below to the configuration.
    </Text>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <div className={styles.note}>
      <Text size="caption" color="rebranding-md-color-grey-60">
        Note: Only merchants with a working Shopify integration are able to use
        this. xMoney Crypto stopped accepting new merchant requests to integrate
        with Shopify.{' '}
        <a
          href="https://support.xmoney.com/en/articles/7943211-how-to-integrate-with-shopify"
          target="_blank"
          rel="noreferrer"
        >
          Learn more.
        </a>
      </Text>
    </div>
  </div>
);
ShopifySteps.propTypes = stepsPropTypes;

const WeasySteps = ({ codes, hideSecret, loading }) => (
  <div data-test="woocommerce-instructions">
    <li>Go to your Weasy admin panel;</li>
    <li>
      Go to{' '}
      <span className={styles.bold}>
        Loja online → Configurações → Checkout → Tipos de pagamentos;
      </span>
    </li>
    <li>
      Search for <span className={styles.bold}>Pagar com criptomoedas</span> and
      click <span className={styles.bold}>Configurar</span>;
    </li>
    <li>
      In <span className={styles.bold}>Outras definições</span> change{' '}
      <span className={styles.bold}>Estado</span> to{' '}
      <span className={styles.bold}>Activo</span>;
    </li>
    <li>
      Go to the tab{' '}
      <span className={styles.bold}>Configurações do Fornecedor Serviço</span>;
    </li>
    <li>Add this API Key and Webhook secret:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      Click <span className={styles.bold}>Gravar</span>;
    </li>
    <OrdersLink />
  </div>
);
WeasySteps.propTypes = stepsPropTypes;

const WHMCSSteps = ({ codes, hideSecret, loading }) => (
  <div data-test="woocommerce-instructions">
    <li>Go to your WHMCS admin dashboard;</li>
    <li>
      Navigate to{' '}
      <span className={styles.bold}>
        System Settings → Payment Gateways → All Payment Gateways
      </span>{' '}
      and <span className={styles.bold}>activate Utrust</span>;
    </li>
    <li>
      Navigate to{' '}
      <span className={styles.bold}>
        System Settings → Payment Gateways → Manage Existing Gateways
      </span>
      ;
    </li>
    <li>Add your API Key and Webhook secret:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      Finally, click <span className={styles.bold}>Save</span>;
    </li>
    <OrdersLink />
  </div>
);
WHMCSSteps.propTypes = stepsPropTypes;

const WooCommerceSteps = ({ codes, hideSecret, loading }) => (
  <div data-test="woocommerce-instructions">
    <li>
      If you {`haven't`} installed the WooCommerce plugin do so{' '}
      <a
        href="https://wordpress.org/plugins/utrust-for-woocommerce"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      ;
    </li>
    <li>Copy the keys below:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <li>
      On your WooCommerce admin dashboard paste the keys in:
      <br />
      <span className={styles.bold}>
        WooCommerce → Settings → Payments → Utrust
      </span>
      <br />
      After that click the <span className={styles.bold}>Save</span> button and
      you’re done.
    </li>
    <OrdersLink />
  </div>
);
WooCommerceSteps.propTypes = stepsPropTypes;

const CustomSteps = ({ codes, hideSecret, loading }) => (
  <div data-test="custom-instructions">
    <li>
      Start by reading the xMoney Crypto basics{' '}
      <a
        href="https://docs.crypto.xmoney.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      ;
    </li>
    <li>Use the API key below to create orders:</li>
    <br />
    <ApiKey loading={loading} code={codes.apiKey} />
    <li>Optionally, use the Webhook secret below for notifications:</li>
    <br />
    <WebhookSecret
      loading={loading}
      code={codes.secret}
      hideSecret={hideSecret}
    />
    <OrdersLink />
  </div>
);
CustomSteps.propTypes = stepsPropTypes;

const instructions = {
  magento1: Magento1Steps,
  magento2: Magento2Steps,
  opencart: OpenCartSteps,
  payrexx: PayrexxSteps,
  prestashop: PrestaShopSteps,
  shopify: ShopifySteps,
  weasy: WeasySteps,
  whmcs: WHMCSSteps,
  woocommerce: WooCommerceSteps,
  custom: CustomSteps,
};

export { ApiKey, WebhookSecret };
export default instructions;
