import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { head, pick, values } from 'lodash';

import { api } from 'app/services';
import Card from '../components/Card';
import CodeInput from '../components/CodeInput';
import { Form, Success } from '../components/NewPassword';

const stepOptions = {
  initial: {
    Component: Form,
    propKeys: ['onPasswordSubmit', 'errorMessage', 'passwordInitialValues'],
  },
  confirmation: {
    Component: CodeInput,
    propKeys: ['onCodeSubmit', 'errorMessage'],
  },
  success: { Component: Success, propKeys: [] },
};

const NewPasswordWithTwoFactor = ({ apiError }) => {
  const [step, setStep] = useState('initial');
  const [passwordValues, setPasswordValues] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const handlePasswordSubmit = async (formPasswordValues) => {
    setPasswordValues(formPasswordValues);
    setErrorMessage('');
    setStep('confirmation');
  };
  const handleError = (error) => {
    if (error === 'password_mismatch') {
      setStep('initial');
      return setErrorMessage('Wrong password');
    }
    if (error === 'invalid_token') {
      return setErrorMessage("The code doesn't match your account");
    }

    setErrorMessage('Something went wrong');
    return apiError(error);
  };
  const handleCodeSubmit = async ({ authenticatorCode }) => {
    const data = await api.changePassword({
      ...passwordValues,
      authenticatorCode,
    });
    const { error, response } = data;

    if (error) {
      handleError(error);
      return error;
    }

    const { token } = head(values(response.password)).attributes;
    api.storeItem('signInToken', token);
    setErrorMessage('');
    setStep('success');
    return null;
  };

  const allProps = {
    onPasswordSubmit: handlePasswordSubmit,
    onCodeSubmit: handleCodeSubmit,
    errorMessage,
    passwordInitialValues: passwordValues,
  };
  const { Component, propKeys } = stepOptions[step];
  const props = pick(allProps, propKeys);

  return (
    <Card heading="Password" id="password-profile">
      <Component {...props} />
    </Card>
  );
};

NewPasswordWithTwoFactor.propTypes = {
  apiError: PropTypes.func.isRequired,
};

export default NewPasswordWithTwoFactor;
