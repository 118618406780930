import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Downlaod = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 10 10"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M1.44727 4.53279L4.8148 8H5.18499L8.55253 4.53279L7.97933 3.95492L5.44771 6.56148V-1H4.55209V6.56148L2.02046 3.95492L1.44727 4.53279Z"
        fill="#7C818B"
      />
      <rect y="9" width="10" height="1" fill="#7C818B" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Downlaod.propTypes = {
  fill: PropTypes.string,
};

Downlaod.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Downlaod;
