import React from 'react';

import Text from 'common/components/Text';

import styles from './index.module.css';

const EnableHelper = () => (
  <div className={styles.root}>
    <Text weight="semiBold">How do I do this?</Text>
    <ol className={styles.orderedList}>
      <li>
        Install an authenticator app (e.g.: Authy, Google Authenticator or
        Microsoft Authenticator).
      </li>
      <br />
      <li>
        <p className={styles.description}>
          Scan the QR code with the Authenticator app or do it manually by
          copying the authentication secret code.
        </p>
        <p className={styles.descriptionMobile}>
          Copy the authentication secret code.
        </p>
      </li>
    </ol>
  </div>
);

export default EnableHelper;
