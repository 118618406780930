import React from 'react';
import PropTypes from 'prop-types';

const CheckCircle = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 92 92"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 92C71.4051 92 92 71.4051 92 46C92 20.5949 71.4051 0 46 0C20.5949 0 0 20.5949 0 46C0 71.4051 20.5949 92 46 92ZM21.0071 48.3478L26.2446 43.1103L37.6822 54.5488L65.7866 26.4455L71.0235 31.6823L37.6899 65.0159L37.6845 65.0105L37.6772 65.0178L21.0071 48.3478Z"
      fill="#none"
    />
  </svg>
);

CheckCircle.propTypes = {
  fill: PropTypes.string,
};

CheckCircle.defaultProps = {
  fill: 'none',
};

export default CheckCircle;
