import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';

import styles from './index.module.css';

const Caret = ({ isOpen }) => (
  <div className={styles.icon}>
    <Icon.Caret direction={isOpen ? 'up' : 'down'} size="small" />{' '}
  </div>
);

Caret.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default memo(Caret);
