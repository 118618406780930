import { camelCase, isNil } from 'lodash';
import {
  put,
  call,
  select,
  all,
  fork,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import { watch } from 'common/sagas';
import { actions as errorActions } from 'error';
import { mapCountries } from 'onboarding/utils/signUpUtils';
import { api } from 'app/services';
import actions from '../actions';
import { getCountriesList, getInvoicesScope } from '../reducers/invoices';
import { allStatusRegex } from '../propTypes';

const sortInvoiceFields = {
  createdAt: 'created_at',
  detailsReference: 'reference',
  detailsCustomerEmail: 'customer_email',
  totalAmountCurrency: 'fiat_currency',
  totalAmountValue: 'fiat_amount',
  settlementAmountValue: 'settlement_amount',
  status: 'status',
};

const translateSortField = (field) => {
  const fieldParsed = camelCase(field);

  return sortInvoiceFields[fieldParsed];
};

const translateStatus = (statusValue) =>
  statusValue === allStatusRegex ? 'all' : statusValue;

function* invoicesScopeSelector() {
  return yield select(getInvoicesScope);
}

function* countriesListSelector() {
  return yield select(getCountriesList);
}

function* fetchInvoices() {
  const {
    dateRange,
    page,
    pageSize,
    search,
    sort: { id: sortId, sortAscending },
    status,
  } = yield invoicesScopeSelector();

  const { response, error } = yield call(api.fetchInvoices, {
    dateRange,
    page,
    pageSize,
    search,
    sort: { id: translateSortField(sortId), sortAscending },
    status: translateStatus(status),
  });

  if (response) yield put(actions.invoicesSuccess(response));
  else
    yield all([
      put(actions.invoicesFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

function* fetchInvoicePage({ id }) {
  const { response, error } = yield call(api.fetchInvoicePage, id);
  if (response) {
    const { countries } = yield countriesListSelector();
    if (isNil(countries)) {
      const { response: countriesResponse } = yield call(
        api.fetchBuyerCountries
      );
      yield put(
        actions.countriesListSuccess(
          mapCountries(countriesResponse.country, true) || []
        )
      );
    }
    yield put(actions.singleInvoicePageSuccess(response));
  } else
    yield all([
      put(actions.singleInvoicePageFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

const actionsToTriggerInvoicesFetch = [
  actions.invoicesTableNextPage,
  actions.invoicesTablePreviousPage,
  actions.resetInvoicesTable,
  actions.setInvoicesTableFilter,
  actions.setInvoicesTablePage,
  actions.setInvoicesTablePageSize,
  actions.setInvoicesTableSort,
];

function* watchInvoicesRequest() {
  yield takeLatest(actions.invoicesRequest, fetchInvoices);
}

function* triggerInvoicesRequest() {
  yield put(actions.invoicesRequest());
}

function* watchInvoicesScope() {
  yield takeEvery([actionsToTriggerInvoicesFetch], triggerInvoicesRequest);
}

export default function* root() {
  yield fork(watchInvoicesScope);
  yield fork(watchInvoicesRequest);
  yield watch(actions.singleInvoicePageRequest, fetchInvoicePage);
}
