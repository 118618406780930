import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Item from '../Item';
import { ITEM_HEIGHT, NO_RESULTS_OPTION, getMaxHeight } from './utils';
import { itemListProptypes, itemProptype } from '../propTypes';

import styles from './index.module.css';

const ItemsList = ({
  isOpen,
  items,
  selectedValue,
  withCheckbox,
  getProps,
  downshiftProps,
  downshiftState,
}) => (
  <div
    style={{ maxHeight: `${getMaxHeight(items.length, downshiftState)}px` }}
    className={classnames(styles.options, {
      [styles['has-items']]: isOpen && items.length,
    })}
  >
    {isOpen &&
      items.map((item, index) => (
        <Item
          style={{ minHeight: `${ITEM_HEIGHT}px` }}
          {...downshiftProps.getItemProps({
            item,
            ...getProps(downshiftProps, item, index, selectedValue),
          })}
          key={item.value}
          name={item.value}
          label={item.label}
          withCheckbox={withCheckbox}
          isDisabled={item.value === NO_RESULTS_OPTION.value}
        />
      ))}
  </div>
);

ItemsList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: itemListProptypes.isRequired,
  selectedValue: PropTypes.oneOfType([
    itemProptype,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  withCheckbox: PropTypes.bool.isRequired,
  getProps: PropTypes.func.isRequired,
  downshiftProps: PropTypes.shape({ getItemProps: PropTypes.func.isRequired }),
  downshiftState: PropTypes.string.isRequired,
};

export default ItemsList;
