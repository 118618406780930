import React from 'react';
import PropTypes from 'prop-types';
import { concat, indexOf } from 'lodash';
import { pullAt } from 'lodash/fp';

import DropdownBase from '../common/DropdownBase';
import { itemListProptypes } from '../common/propTypes';

const handleAllOption = (items, selected) =>
  selected.length === items.length ? [] : items.map(({ value }) => value);

const handleChange = (items, selected, value) => {
  const idx = indexOf(selected, value);
  return idx === -1 ? concat(selected, value) : pullAt(idx, selected);
};

class DropdownMulti extends React.Component {
  state = { selected: this.props.initialSelectedItem };

  setSelected = selected => {
    const { onChange } = this.props;

    this.setState({ selected }, onChange(selected));
  };

  onChange = (callback, value) => {
    const { items } = this.props;
    const { selected } = this.state;

    const newSelected = callback(items, selected, value);
    this.setSelected(newSelected);
  };

  onAllOption = () => {
    this.onChange(handleAllOption);
  };

  onOptionChange = value => {
    this.onChange(handleChange, value);
  };

  render() {
    const { selected } = this.state;
    const {
      placeholder,
      items,
      showAllOption,
      error,
      errorText,
      name,
    } = this.props;

    return (
      <DropdownBase
        type="multi"
        onChange={this.onOptionChange}
        onAllOption={showAllOption ? this.onAllOption : null}
        selectedItem={selected}
        items={items}
        placeholder={placeholder}
        name={name}
        errorText={errorText}
        error={error}
      />
    );
  }
}

DropdownMulti.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialSelectedItem: PropTypes.arrayOf(PropTypes.string),
  items: itemListProptypes.isRequired,
  showAllOption: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  name: PropTypes.string,
};

DropdownMulti.defaultProps = {
  placeholder: undefined,
  initialSelectedItem: [],
  showAllOption: true,
  name: 'MultiDropdown',
};

export default DropdownMulti;
