import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { pick } from 'lodash';

import { api } from 'app/services';
import { handleError, parseSecret } from './utils';
import Card from '../components/Card';
import {
  Enable,
  Inactive,
  RecoveryCode,
} from '../components/TwoFactorDisabled';
import { EnableHelper } from '../components/InstructionsHelper';

import styles from '../components/index.module.css';

const stepOptions = {
  initial: {
    Component: Inactive,
    propKeys: ['onEnableClick', 'errorMessage'],
    Helper: null,
  },
  enable: {
    Component: Enable,
    propKeys: ['onCodeSubmit', 'keyUri', 'secret', 'errorMessage'],
    Helper: EnableHelper,
  },
  success: {
    Component: RecoveryCode,
    propKeys: ['onComplete', 'recoveryCode'],
    Helper: null,
  },
};

const TwoFactorDisabled = ({
  apiError,
  twoFactorAuthChange,
  keyUri,
  secret,
  recoveryCode,
}) => {
  //if secret is loaded already it means that it comes from reset scenario
  const initalState = secret ? 'enable' : 'initial';
  const [step, setStep] = useState(initalState);
  const [errorMessage, setErrorMessage] = useState('');
  const [twoFactorParams, setTwoFactorParams] = useState({
    keyUri,
    recoveryCode,
    secret,
  });

  const onEnableClick = async () => {
    const { response, error } = await api.fetchTwoFactorAuthSecret();
    if (error) {
      setErrorMessage(handleError(error));
      return apiError(error);
    }

    setTwoFactorParams(parseSecret(response.tfaSecret));
    setStep('enable');
  };
  const onCodeSubmit = async ({ authenticatorCode }) => {
    const { error } = await api.enableTwoFactorAuth(authenticatorCode);

    if (!error) return setStep('success');

    setErrorMessage(handleError(error));
    if (error !== 'invalid_token') apiError(error);
    //Returning a truthy value signals form to reset
    return error;
  };
  const onComplete = () =>
    twoFactorAuthChange({
      tfaActive: true,
      keyUri: null,
      secret: null,
      recoveryCode: null,
    });

  const allProps = {
    onEnableClick,
    onCodeSubmit,
    onComplete,
    errorMessage,
    ...twoFactorParams,
  };
  const { Component, Helper, propKeys } = stepOptions[step];
  const props = pick(allProps, propKeys);

  return (
    <div className={styles.row}>
      <Card heading="Two-factor authentication" id="twofactor-profile-enable">
        <Component {...props} />
      </Card>
      {Helper && <Helper />}
    </div>
  );
};

TwoFactorDisabled.propTypes = {
  apiError: PropTypes.func.isRequired,
  twoFactorAuthChange: PropTypes.func.isRequired,
  keyUri: PropTypes.string,
  secret: PropTypes.string,
  recoveryCode: PropTypes.string,
};

export default TwoFactorDisabled;
