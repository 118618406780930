import React from 'react';
import PropTypes from 'prop-types';

const Mail = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 146 94"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.199219 11.779L0.199222 84.5001C0.199223 89.5259 4.27343 93.6001 9.29922 93.6001L136.699 93.6001C141.725 93.6001 145.799 89.5259 145.799 84.5001V6.08759L140.116 6.08759L140.113 6.08356L140.112 6.08446V0.400085L11.5781 0.400091L5.89058 6.08759L140.106 6.08759L72.9963 44.8335L5.87939 6.08356L5.8586 6.11957L0.199219 11.779ZM5.88672 12.6551L5.88672 84.5001C5.88672 86.3848 7.41455 87.9126 9.29922 87.9126L136.699 87.9126C138.584 87.9126 140.112 86.3848 140.112 84.5001V12.6518L73.0017 51.3978L72.999 51.4024L72.9963 51.4009L72.9937 51.4024L72.991 51.3978L5.88672 12.6551Z"
    />
  </svg>
);

Mail.propTypes = {
  fill: PropTypes.string,
};

Mail.defaultProps = {
  fill: 'none',
};

export default Mail;
