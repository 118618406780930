import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectors as featuresSelectors } from 'features';

import AmountStrip from 'common/components/AmountStrip';
import { amount } from 'common/propTypes';

const DetailsFooter = ({ totalAmount, referenceAmount, settlementAmount }) => {
  const showSettlementCurrency =
    settlementAmount?.currency &&
    totalAmount.currency !== settlementAmount.currency;

  const isReferenceCurrencyActive = useSelector(
    featuresSelectors.isReferenceCurrencyActive
  );

  const showReferenceCurrency =
    isReferenceCurrencyActive &&
    referenceAmount?.currency &&
    totalAmount.currency !== referenceAmount.currency;

  return (
    <footer>
      <AmountStrip
        heading="Total amount"
        amount={totalAmount}
        referenceAmount={showReferenceCurrency ? referenceAmount : null}
        background="rebranding-md-color-brand-purple-lightest"
        id="totalAmount"
      />
      {showSettlementCurrency && (
        <AmountStrip
          heading="Settlement amount"
          amount={settlementAmount}
          id="settlementAmount"
        />
      )}
    </footer>
  );
};

DetailsFooter.propTypes = {
  totalAmount: amount.isRequired,
  referenceAmount: amount,
  settlementAmount: amount,
};

export default memo(DetailsFooter);
