import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { route as routePropType } from 'common/propTypes';
import { complianceStatuses, integrationStatuses } from 'common/constants';
import { managePermission } from 'payments/propTypes';
import Layout from 'common/containers/Layout';
import { selectors as authSelectors } from 'auth';
import {
  selectors as sessionSelectors,
  actions as sessionActions,
} from 'session';
import { selectors as featuresSelectors } from 'features';
import NotFound from 'common/components/NotFound';

import SettlementsContainer from '../containers/Settlements';
import ReportContainer from '../containers/Report';

const NotFoundPage = () => (
  <Layout heading="Settlements">
    <NotFound />
  </Layout>
);

const Routes = ({
  bankInfo,
  match,
  isSandboxMode,
  integrationStatus,
  complianceStatus,
  suspendedSettlements,
  setIntegrationStatus,
  setComplianceStatus,
  settlementsPermission,
  isFetching,
}) => {
  if (isFetching) return null;
  const canManageSettlements = settlementsPermission === managePermission;

  return (
    <Switch>
      {canManageSettlements && (
        <>
          <Route
            path={match.path}
            render={() => (
              <SettlementsContainer
                bankInfo={bankInfo}
                integrationStatus={integrationStatus}
                complianceStatus={complianceStatus}
                suspendedSettlements={suspendedSettlements}
                isSandboxMode={isSandboxMode}
                setIntegrationStatus={setIntegrationStatus}
                setComplianceStatus={setComplianceStatus}
              />
            )}
            exact
          />
          <Route path={`${match.path}/:id`} component={ReportContainer} />
        </>
      )}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Routes.propTypes = {
  ...routePropType,
  bankInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  isSandboxMode: PropTypes.bool.isRequired,
  integrationStatus: PropTypes.oneOf(integrationStatuses),
  complianceStatus: PropTypes.oneOf(complianceStatuses),
  suspendedSettlements: PropTypes.bool.isRequired,
  settlementsPermission: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const ConnectedRoutes = connect(
  (state) => {
    const { sandboxMode } = featuresSelectors.getOnboardingFlags(state);
    const activeOrganization = sessionSelectors.activeOrganization(state);
    const { loading: isFetching, settlements: settlementsPermission } =
      authSelectors.getPermissions(state);

    return {
      bankInfo: activeOrganization?.bankInfo,
      isSandboxMode: !!sandboxMode,
      integrationStatus: activeOrganization?.integrationStatus,
      complianceStatus: activeOrganization?.complianceStatus,
      suspendedSettlements: !!activeOrganization?.isSuspended,
      settlementsPermission,
      isFetching: !!isFetching,
    };
  },
  {
    setIntegrationStatus: sessionActions.organizationIntegrationStatus,
    setComplianceStatus: sessionActions.organizationComplianceStatus,
  }
)(Routes);

export default ConnectedRoutes;
