import React from 'react';
import PropTypes from 'prop-types';

const directionToDegrees = {
  down: 0,
  left: 90,
  up: 180,
  right: 270,
};

const Arrow = ({ direction, fill, ...rest }) => {
  const degrees = directionToDegrees[direction];

  return (
    <svg
      width="100%"
      viewBox="0 0 20 20"
      preserveAspectRatio="xMidYMid meet"
      style={{ transform: `rotate(${degrees}deg)` }}
      fill="none"
      {...rest}
    >
      <path
        d="M2.895 12.066L9.63 19h.74l6.735-6.934-1.146-1.156-5.063 5.213V1H9.104v15.123L4.041 10.91l-1.146 1.156z"
        fill={fill}
      />
    </svg>
  );
};

Arrow.propTypes = {
  fill: PropTypes.string,
  direction: PropTypes.oneOf(['down', 'up', 'left', 'right']),
};

Arrow.defaultProps = {
  fill: '#fff',
  direction: 'down',
};

export default Arrow;
