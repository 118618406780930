import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';

import constants from '../../common/constants';
import { actions as sessionActions } from '../../session';
import actions from '../actions';

const proceedRoutingReducer = handleActions(
  {
    [actions.PROCEED_ROUTING]: () => true,
  },
  false
);

const signInReducer = handleActions(
  {
    [combineActions(actions.SIGN_IN_SUCCESS, actions.AUTO_SIGN_IN)]: () => true,
    [combineActions(sessionActions.SESSION_CLEAR)]: () => false,
  },
  false
);

const signInErrorReducer = handleActions(
  {
    [actions.SIGN_IN_FAILURE]: (state, { payload }) => payload,
    [combineActions(
      actions.SIGN_IN_REQUEST,
      actions.SIGN_IN_SUCCESS,
      actions.AUTO_SIGN_IN,
      actions.IDENTIFY_SUCCESS,
      sessionActions.SESSION_CLEAR
    )]: () => null,
  },
  null
);

const initialState = {
  loading: false,
  error: null,
  accessToken: null,
  refreshToken: null,
  authenticated: false,
};

const otpExchangeReducer = handleActions(
  {
    [sessionActions.SESSION_CLEAR]: () => initialState,
    [combineActions(
      actions.OTP_EXCHANGE_REQUEST,
      actions.TOKEN_REFRESH_REQUEST
    )]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [combineActions(
      actions.OTP_EXCHANGE_SUCCESS,
      actions.TOKEN_REFRESH_SUCCESS
    )]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken,
      authenticated: true,
    }),
    [combineActions(
      actions.OTP_EXCHANGE_FAILURE,
      actions.TOKEN_REFRESH_FAILURE
    )]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      authenticated: false,
    }),
  },
  initialState
);

const indentifyReducer = handleActions(
  {
    [actions.IDENTIFY_SUCCESS]: () => true,
    [combineActions(sessionActions.SESSION_CLEAR)]: () => false,
  },
  false
);

const twoFactorErrorReducer = handleActions(
  {
    [actions.VERIFY_FAILURE]: (state, { payload }) => payload,
    [combineActions(
      actions.SIGN_IN_REQUEST,
      actions.SIGN_IN_SUCCESS,
      actions.AUTO_SIGN_IN,
      actions.IDENTIFY_SUCCESS,
      sessionActions.SESSION_CLEAR
    )]: () => null,
  },
  null
);

const isTwoFactorWrongReducer = handleActions(
  {
    [actions.VERIFY_FAILURE]: (state, { payload }) =>
      payload === constants.invalid_token,
    [actions.SIGN_IN_SUCCESS]: () => false,
  },
  false
);

const permissionsReducer = handleActions(
  {
    [actions.PERMISSIONS_SUCCESS]: (state, action) => ({
      loading: false,
      error: null,
      lastRequestAt: state.lastRequestAt,
      ...action.payload,
    }),
    [actions.PERMISSIONS_FAILURE]: (state, action) => ({
      loading: false,
      error: action.payload,
    }),
    [actions.PERMISSIONS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      lastRequestAt: Date.now(),
    }),
  },
  { loading: false, error: null }
);

const signOutReducer = handleActions(
  {
    [actions.SIGN_OUT_SUCCESS]: () => ({
      loading: false,
      success: true,
    }),
    [actions.SIGN_OUT]: () => ({
      loading: true,
      success: false,
    }),
  },
  {
    loading: false,
    success: false,
  }
);

export default combineReducers({
  isAuthenticated: signInReducer,
  isIdentified: indentifyReducer,
  isTwoFactorWrong: isTwoFactorWrongReducer,
  twoFactorError: twoFactorErrorReducer,
  permissions: permissionsReducer,
  signInError: signInErrorReducer,
  otpExchange: otpExchangeReducer,
  signOut: signOutReducer,
  proceedRouting: proceedRoutingReducer,
});

export const isOtpAuthenticated = (state) =>
  state.auth.otpExchange.authenticated;
export const otpExchangeError = (state) => state.auth.otpExchange.error;
export const refreshTokenError = (state) => state.auth.refreshToken.error;
export const refreshToken = (state) => state.auth.refreshToken;
export const accessToken = (state) => state.auth.accessToken;
export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const isIdentified = (state) => state.auth.isIdentified;
export const isTwoFactorWrong = (state) => state.auth.isTwoFactorWrong;
export const twoFactorError = (state) => state.auth.twoFactorError;
export const getPermissions = (state) => state.auth.permissions;
export const signInError = (state) => state.auth.signInError;
export const signOutSuccess = (state) => state.auth.signOut.success;
export const proceedRouting = (state) => state.auth.proceedRouting;
export const upholdPermission = (state) =>
  _.get(state.auth.permissions, 'upholdConnect');
export const refundPermission = (state) =>
  _.get(state.auth.permissions, 'refunds');
export const storeCredentialsPermission = (state) =>
  _.get(state.auth.permissions, 'storeCredentials');
export const invoiceCreationPermission = (state) =>
  _.get(state.auth.permissions, 'invoiceCreation');
