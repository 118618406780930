import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import actions from '../actions';

const { FAILED_FETCH } = actions;

const fetchErrorsReducer = handleActions(
  {
    [FAILED_FETCH]: () => true,
  },
  false
);

export const hasFetchError = (state) => state.error.fetchError;

export default combineReducers({ fetchError: fetchErrorsReducer });
