import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  tableFilterDateRangesProp,
  tableFilterStatusesProp,
  statusTableFilter,
  dateRangeTableFilter,
} from 'payments/propTypes';
import { getOrdersTableFilters } from '../reducers/orders';
import { getInvoicesTableFilters } from '../reducers/invoices';
import PaymentsFilter from '../components/PaymentsFilter';
import { actionsMap } from '../tableUtils';

const PaymentsFilterContainer = ({
  dateRanges,
  disabled,
  statuses,
  searchValue,
  statusValue,
  dateRangeValue,
  setFilter,
  resetPage,
}) => {
  const handleSearchFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter({ searchValue: value });
    },
    [setFilter, resetPage]
  );

  const handleStatusFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter({ statusValue: value });
    },
    [setFilter, resetPage]
  );

  const handleDateRangeFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter({ dateRangeValue: value });
    },
    [setFilter, resetPage]
  );

  return (
    <PaymentsFilter
      disabled={disabled}
      onStatusChange={handleStatusFilterChange}
      onSearchChange={handleSearchFilterChange}
      onDateRangeChange={handleDateRangeFilterChange}
      statuses={statuses}
      dateRanges={dateRanges}
      searchValue={searchValue}
      statusValue={statusValue}
      dateRangeValue={dateRangeValue}
    />
  );
};

PaymentsFilterContainer.propTypes = {
  dateRanges: tableFilterDateRangesProp.isRequired,
  disabled: PropTypes.bool,
  statuses: tableFilterStatusesProp.isRequired,
  searchValue: PropTypes.string,
  statusValue: statusTableFilter.isRequired,
  dateRangeValue: dateRangeTableFilter.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetPage: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const { paymentType } = ownProps;
  if (paymentType === 'orders') return getOrdersTableFilters(state);
  return getInvoicesTableFilters(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { paymentType } = ownProps;
  const paymentActions = actionsMap[paymentType];

  return {
    setFilter: (values) => dispatch(paymentActions.setFilter(values)),
    resetPage: () => dispatch(paymentActions.resetPage()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsFilterContainer);
