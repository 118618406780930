import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

class Amount extends PureComponent {
  render() {
    const { value } = this.props;

    return <span className={styles.amount}>{value}</span>;
  }
}

Amount.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Amount;
