import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Modal from 'common/components/Modal';
import Icon from 'common/components/Icon';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';

import colors from 'common/styles/colors';
import styles from './index.module.css';

const AllDone = () => (
  <>
    <div className={styles.iconWrapper}>
      <Icon.CheckCircle fill={colors['rebranding-md-color-green']} />
    </div>
    <Heading level="2" size="2" color="green">
      All done!
    </Heading>
    <Heading level="3" size="3" color="green">
      {`You're now ready to accept crypto payments.`}
    </Heading>
    <div className={styles.bottomText}>
      <Text>
        All that’s left now is setting up settlements.
        <br />
        You can do that in the settlements page at any point.
      </Text>
    </div>
  </>
);

const DontForget = () => (
  <>
    <Heading level="2" size="2" color="blue">
      Thank you
    </Heading>
    <Heading level="3" size="3" color="blue">
      {`Don't forget there's still a few things to do.`}
    </Heading>
    <br />
    <br />
    <div className={styles.links}>
      <Text>
        Setup integration on your <Link to="/organization">Store page</Link>.
        <br />
        <br />
        Setup your settlements on your{' '}
        <Link to="/settlements">Settlements page</Link>.
      </Text>
    </div>
  </>
);

const DocsSubmitted = () => (
  <>
    <div className={styles.iconWrapper}>
      <Icon.CheckCircle fill={colors['rebranding-md-color-green']} />
    </div>
    <Heading level="2" size="2" color="green">
      Documents submitted
    </Heading>
    <br />
    <Heading level="3" size="3" color="green">
      Our team will review them and get in touch via email.
    </Heading>
    <div className={styles.bottomText}>
      <Text>This usually takes no longer than 1 working day.</Text>
    </div>
  </>
);

const contentMap = {
  allDone: AllDone,
  dontForget: DontForget,
  docsSubmitted: DocsSubmitted,
};

const OnboardingCompleteModal = ({ isOpen, onClose, content }) => {
  const ContentComponent = contentMap[content];

  const handleKeyDown = ({ key = '' }) => {
    if (key === 'Enter') onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.topRow}>
        <span
          className={styles.closeButton}
          onClick={onClose}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex="0"
          data-test="modal-close"
        >
          <Icon.Cross
            size="large"
            fill={colors['rebranding-md-color-grey-60']}
          />
        </span>
      </div>
      {content && (
        <div className={styles.content} data-test={content}>
          <ContentComponent />
        </div>
      )}
      <span className={styles.okButton}>
        <Button kind="primary" onClick={onClose}>
          <button type="button">Ok</button>
        </Button>
      </span>
    </Modal>
  );
};

OnboardingCompleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string,
};

export default OnboardingCompleteModal;
