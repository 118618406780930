import React from 'react';
import PropTypes from 'prop-types';

const Share = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99898 0.5L2.75879 4.7402L3.82237 5.80377L6.24707 3.37907V10.3947H7.7512V3.37897L10.1914 5.81918L11.255 4.7556L7.00946 0.510072L7.00926 0.510274L6.99898 0.5ZM1.5 12.9992V6.49917H0V14.4992H14V6.49917H12.5V12.9992H1.5Z"
      fill={fill}
    />
  </svg>
);

Share.propTypes = {
  fill: PropTypes.string,
};

Share.defaultProps = {
  fill: '#5B47FF',
};

export default Share;
