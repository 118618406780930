import React from 'react';
import PropTypes from 'prop-types';

import { lost2faSupport } from 'common/constants';
import Text from 'common/components/Text';
import CodeInput from '../../CodeInput';

import styles from './index.module.css';

const Disable = ({ onCodeSubmit, onCancelClick, errorMessage }) => (
  <>
    <Text color="rebranding-md-color-grey-70">
      To disable Two-factor authentication, enter a two-factor token from the
      application you are using.
    </Text>
    <br />
    <Text color="rebranding-md-color-grey-70">
      If you have lost access to your authenticator device, please check{' '}
      <a
        className={styles.link}
        href={lost2faSupport}
        target="_blank"
        rel="noopener noreferrer"
      >
        how to proceed
      </a>
      .
    </Text>
    <br />
    <CodeInput
      labelText="Insert the verification code provided by the Authenticator app"
      buttonLabel="Deactivate"
      onCodeSubmit={onCodeSubmit}
      errorMessage={errorMessage}
      onCancelClick={onCancelClick}
      disableMode
    />
  </>
);

Disable.propTypes = {
  onCodeSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
};

export default Disable;
