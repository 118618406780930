import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import ErrorForm from 'common/components/SentryErrorForm';

class SentryErrorBondary extends Component {
  constructor(props) {
    super(props);
    this.state = { errorId: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: !!error };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const errorId = Sentry.captureException(error);
        this.setState({ errorId });
      });
    }
  }

  render() {
    const { children } = this.props;
    const { errorId, hasError } = this.state;

    if (hasError) return <ErrorForm errorId={errorId} />;

    return children;
  }
}

SentryErrorBondary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SentryErrorBondary;
