import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

class Reference extends PureComponent {
  render() {
    const { value } = this.props;

    return <div className={styles.reference}>{value}</div>;
  }
}

Reference.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Reference;
