const apiURLForEnvironment = (hostname) => {
  const [subdomain, ...domain] = hostname.split('.');

  return `https://${[subdomain, 'api', ...domain].join('.')}/api`;
};

const apiURLForPreviewEnvironment = (hostname) => {
  const subdomain = hostname.split('.')[1];

  return `https://${subdomain}.api.dev.crypto.xmoney.com/api`;
};

const apiURLForUOEnvironment = (hostname) => {
  const subdomain = hostname.split('.')[1];

  // merchants.crypto.xmoney.com or merchants.sandbox.crypto.xmoney.com
  if (subdomain === 'crypto' || subdomain === 'sandbox') {
    return 'https://identity.xmoney.com/api';
  }

  // merchants.{dev, staging}.crypto.xmoney.com
  return `https://identity-dev.xmoney.com/api`;
};

export default (optionalHostname, useUOApi = false) => {
  const hostname = optionalHostname || window.location.hostname;

  if (useUOApi) {
    return apiURLForUOEnvironment(hostname);
  }

  if (hostname.includes('preview.dev.crypto.xmoney.com')) {
    return apiURLForPreviewEnvironment(hostname);
  }

  return apiURLForEnvironment(hostname);
};
