import React from 'react';
import PropTypes from 'prop-types';

import { attachment as attachmentType } from 'common/propTypes';
import Icon from 'common/components/Icon';
import Button from 'common/components/Button';
import AttachmentLoading from './Loading';

import styles from './index.module.css';

const Attachment = ({ id, url, name, removeAction }) => {
  /* eslint-disable no-alert*/
  const handleRemove = () => {
    if (window.confirm(`Do you really want to remove attachment: ${name}?`)) {
      removeAction({ id });
    }
  };
  /* eslint-enable */

  return (
    <div className={styles.root} data-test="attachment">
      <div className={styles.labelGroup}>
        <Icon.Document description="Your document" size="large" />
        <div className={styles.link}>
          <Button kind="subtle">
            <a href={url} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          </Button>
        </div>
      </div>
      <Button
        name="delete-attachment"
        kind="destructiveSubtle"
        onClick={handleRemove}
      >
        <button type="button">Delete</button>
      </Button>
    </div>
  );
};

Attachment.propTypes = {
  ...attachmentType,
  removeAction: PropTypes.func.isRequired,
};

export { AttachmentLoading };

export default Attachment;
