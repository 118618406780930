import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Modal from 'common/components/Modal';
import Text from 'common/components/Text';
import MultiLineField from 'common/components/MultiLineField';
import formUtils from 'common/formUtils.js';

import styles from './index.module.css';

const valuePropTypes = PropTypes.shape({
  amount: PropTypes.string,
  currency: PropTypes.string,
  customerEmail: PropTypes.string,
  customerName: PropTypes.string,
  description: PropTypes.string,
  invoiceReference: PropTypes.string,
  billingAddress: PropTypes.string,
  postCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.objectOf({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  state: PropTypes.string,
});

const InvoiceInfo = ({ values }) => (
  <div className={styles.invoiceRow}>
    <div className={styles.column}>
      <span className={styles.title}>
        <Text weight="semiBold">Customer information</Text>
      </span>
      <MultiLineField label="Name" values={[values.customerName]} />
      <MultiLineField label="Email" values={[values.customerEmail]} />
      <MultiLineField
        label="Billing address"
        values={[
          values.billingAddress,
          `${values.postCode} ${values.city}`,
          `${values.state && `${values.state}, `}${values.country?.label}`,
        ]}
      />
    </div>
    <div className={styles.column}>
      <span className={styles.title}>
        <Text weight="semiBold">Payment information</Text>
      </span>
      <MultiLineField label="Currency" values={[values.currency]} />
      <MultiLineField label="Amount" values={[values.amount]} />
      <MultiLineField label="Description" values={[values.description]} />
    </div>
  </div>
);

InvoiceInfo.propTypes = {
  values: valuePropTypes,
};

const nextSteps = (sendIssuedEmail) =>
  formUtils.checkBoxValue(sendIssuedEmail)
    ? 'We will send the invoice details and payment instructions to your customer via email.'
    : 'We won’t notify your customer, but you’ll still be able to provide the payment link to your customer.';

const NewInvoiceConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  submitting,
  values,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className={styles.modalBody}>
      <Heading level="3" size="3" weight="medium">
        Invoice confirmation
      </Heading>
      <br />
      <Text>Please confirm the details of your invoice below.</Text>
      <Text>Once you confirm this, they’re final.</Text>
      <InvoiceInfo values={values} />
      <div className={styles.modalFooter}>
        <Text weight="caption" color="rebranding-md-color-grey-60">
          {nextSteps(values?.sendIssuedEmail)}
        </Text>
        <div className={styles.buttons}>
          <Button kind="subtle" onClick={onClose}>
            <button type="button">Cancel</button>
          </Button>
          <Button kind="primary" onClick={onSubmit} loading={submitting}>
            <button type="button" data-test="submit-new-invoice-button">
              Generate invoice
            </button>
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);

NewInvoiceConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  values: valuePropTypes,
};

export default NewInvoiceConfirmationModal;
