import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';

import AmountInput from 'common/components/AmountInput';
import Button from 'common/components/Button';
import ContentPanel from 'common/components/ContentPanel';
import Text from 'common/components/Text';

import { selectors as authSelectors } from 'auth';

import formUtils from 'common/formUtils.js';
import {
  orderStatus as orderStatusProp,
  amountType,
  refund as refundProp,
  managePermission,
} from 'payments/propTypes';
import {
  getRefund as refundSelector,
  isLoadingRefund as isSubmittingSelector,
} from '../../reducers/orders';

import actions from '../../actions';

import styles from './index.module.css';

const RefundForm = ({
  totalAmount: { value: maxValue, currency },
  orderId,
  paymentId,
  submitRefund,
  cleanRefundReducer,
  refund,
  submitting,
  hasRefundPermission,
}) => {
  const error = refund?.error;
  const handleSubmitRefund = (values) => {
    submitRefund({ orderId, paymentId, currency, ...values });
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(
    () =>
      function cleanup() {
        if (error) cleanRefundReducer(null);
      },
    [error]
  );
  /* eslint-enable react-hooks/exhaustive-deps*/

  return (
    <ContentPanel title="Initiate a refund">
      <Formik initialValues={{ amount: '' }} onSubmit={handleSubmitRefund}>
        {({ handleSubmit, errors, touched, setFieldValue }) => {
          const handleSetMaxValue = () => {
            setFieldValue('amount', maxValue);
          };
          return (
            <form
              data-test="refund-form"
              className={styles.form}
              onSubmit={handleSubmit}
            >
              <Field
                name="amount"
                validate={formUtils.validateRefundAmount(maxValue)}
              >
                {({ field, meta }) => (
                  <AmountInput
                    disabled={!hasRefundPermission}
                    currency={currency}
                    data-lpignore="true"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    id="amount-refund-input"
                    label="Amount"
                    maxValue={maxValue}
                    name="amount-refund-input"
                    partial={!!field.value && field.value < maxValue}
                    setMaxValue={handleSetMaxValue}
                    {...field}
                  />
                )}
              </Field>
              <div className={styles.buttonWrapper}>
                <Button
                  kind="primary"
                  loading={submitting}
                  disabled={
                    formUtils.isSubmitDisabled(errors, touched) ||
                    !hasRefundPermission
                  }
                  full
                >
                  <button
                    data-test="refund-order-button"
                    type="submit"
                    id="progress-status"
                  >
                    Initiate a refund
                  </button>
                </Button>
              </div>
              {error && (
                <div className={styles.formError}>
                  <Text color="rebranding-md-color-red">
                    Something went wrong, please contact support.
                  </Text>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </ContentPanel>
  );
};

RefundForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
  status: orderStatusProp.isRequired,
  totalAmount: amountType.isRequired,
  submitRefund: PropTypes.func.isRequired,
  cleanRefundReducer: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  refund: refundProp,
};

export default connect(
  (state) => ({
    refund: refundSelector(state),
    submitting: isSubmittingSelector(state),
    hasRefundPermission:
      authSelectors.getPermissions(state).refunds === managePermission,
  }),
  {
    cleanRefundReducer: actions.refundLoadSuccess,
    submitRefund: actions.refundRequest,
  }
)(RefundForm);
