import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectors as authSelectors } from 'auth';

import { unauthorizedPermission } from 'payments/propTypes';

import Layout, {
  propTypes as LayoutPropTypes,
} from '../components/Layout/index';

const LayoutContainer = ({
  children,
  refundPermission,
  invoiceCreationPermission,
  ...otherProps
}) => {
  const hasLimitedAccess = [refundPermission, invoiceCreationPermission].some(
    (permission) => permission === unauthorizedPermission
  );

  return (
    <Layout hasLimitedAccess={hasLimitedAccess} {...otherProps}>
      {children}
    </Layout>
  );
};

LayoutContainer.propTypes = {
  ...LayoutPropTypes,
  refundPermission: PropTypes.string,
  invoiceCreationPermission: PropTypes.string,
};

export default connect((state) => ({
  refundPermission: authSelectors.refundPermission(state),
  invoiceCreationPermission: authSelectors.invoiceCreationPermission(state),
}))(LayoutContainer);
