import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const PaginationHelper = ({
  className,
  data: { page, pageSize, pageSizeOptions, setPageSize, totalElements },
}) => {
  const rangeMin = (page - 1) * pageSize + 1;
  const rangeMax =
    page * pageSize > totalElements ? totalElements : page * pageSize;

  const handleClick = (opt) => (e) => {
    e.preventDefault();
    setPageSize(opt);
  };

  const handleKeyPress =
    (opt) =>
    ({ key = '' }) => {
      if (key === 'Enter') setPageSize(opt);
    };

  return (
    <div className={classNames(styles.paginationHelper, className)}>
      {`Showing ${rangeMin}-${rangeMax} of ${totalElements} rows | Show`}{' '}
      {pageSizeOptions.map((opt) => (
        <span
          className={classNames(styles.paginationHelperOption, {
            [styles.paginationHelperOptionSelected]: opt === pageSize,
          })}
          key={opt}
          onClick={handleClick(opt)}
          onKeyDown={handleKeyPress}
          role="button"
          tabIndex="0"
        >
          {opt}{' '}
        </span>
      ))}
    </div>
  );
};

export const paginationHelperProptypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    setPageSize: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
};

PaginationHelper.propTypes = paginationHelperProptypes;

export default PaginationHelper;
