import React from 'react';
import { Link } from 'react-router-dom';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';

import styles from './index.module.css';
import LogoxMoney from '../LogoxMoney';

const NotFound = () => (
  <div className={styles.root} data-test="not-found-page">
    <div>
      <LogoxMoney />
    </div>
    <h1 className={styles.errorCode}>404</h1>
    <Heading level="2" size="2" color="blue">
      Something’s missing
    </Heading>
    <Text size="large">
      This page is missing or you typed the link incorrectly.
    </Text>
    <Button kind="primary" name="back-home">
      <Link to="/sign-in">Take me home</Link>
    </Button>
  </div>
);

export default NotFound;
