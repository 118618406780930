import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Activity from '../Activity';

import styles from './index.module.css';

const DayActivities = ({ day, events }) => (
  <div className={styles.container}>
    <span className={styles.date}>{day}</span>
    <div className={styles.events}>
      {_.map(events, ({ createdAt, icon, title, content, id, eventType }) => (
        <Activity
          createdAt={createdAt}
          icon={icon}
          title={title}
          content={content}
          key={id}
          eventType={eventType}
        />
      ))}
    </div>
  </div>
);

DayActivities.propTypes = {
  day: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
      id: PropTypes.string.number,
    })
  ).isRequired,
};

export default DayActivities;
