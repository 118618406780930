import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QRious from 'qrious';

class QRCode extends PureComponent {
  assignCanvas = (el) => {
    const { content } = this.props;

    this.qr = new QRious({
      backgroundAlpha: 0,
      element: el,
      size: 200,
      level: 'Q',
      value: content,
    });
  };

  render() {
    return (
      <canvas
        style={{ width: '100%', height: '100%' }}
        ref={this.assignCanvas}
      />
    );
  }
}

QRCode.propTypes = {
  content: PropTypes.string.isRequired,
};

export default QRCode;
