import React from 'react';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';

import Text from 'common/components/Text';

import styles from './index.module.css';

const labels = {
  bankAccountNumber: 'Bank account number',
  bankCountry: 'Country',
  iban: 'IBAN',
  routingNumber: 'Routing number',
  swiftCode: 'SWIFT',
  sortCode: 'Sort code',
};

const Field = ({ label, value }) => (
  <div className={styles.bankField}>
    <Text size="caption" color="rebranding-md-color-grey-60">
      {label}
    </Text>
    <Text>{value}</Text>
  </div>
);

const BankAccountDetails = ({ bankInfo, countries }) => {
  const fullCountryName = (countryCode) => {
    const countryObject = countries.find((obj) => obj.value === countryCode);
    if (countryObject) {
      return countryObject.label;
    }

    return countryCode;
  };

  const bankFields = reduce(
    labels,
    (result, value, key) => {
      let propValue = bankInfo[key];

      if (value === 'Country' && countries) {
        propValue = fullCountryName(bankInfo[key]);
      }

      if (propValue) {
        result.push({
          label: value,
          value: propValue,
          key,
        });
      }
      return result;
    },
    []
  );

  return (
    <>
      {bankFields.map(({ label, value }) => (
        <Field label={label} value={value} key={value} />
      ))}
    </>
  );
};

BankAccountDetails.propTypes = {
  bankInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      labe: PropTypes.string,
    })
  ),
};

export default BankAccountDetails;
