import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const EmptyRow = ({ message }) => (
    <tr className={styles.tr}>
      <td colSpan="3" className={styles['cell-content']}>
        {message}
      </td>
    </tr>
  );

EmptyRow.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptyRow;
