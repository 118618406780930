import React from 'react';
import propTypes from 'prop-types';
import { emailConfirmedIntegration, kybStarted } from 'common/constants';

import { KybSteps, OnboardingSteps } from 'onboarding/components/ProgressSteps';

import styles from './index.module.css';

const integrationStatuses = [emailConfirmedIntegration];

const Timeline = ({ integrationStatus, complianceStatus }) => {
  if (
    !integrationStatuses.includes(integrationStatus) &&
    complianceStatus !== kybStarted
  )
    return null;

  const StepsComponent = integrationStatuses.includes(integrationStatus)
    ? OnboardingSteps
    : KybSteps;

  return (
    <div className={styles.timeline}>
      <StepsComponent
        integrationStatus={integrationStatus}
        complianceStatus={complianceStatus}
      />
    </div>
  );
};

Timeline.propTypes = {
  integrationStatus: propTypes.string.isRequired,
  complianceStatus: propTypes.string.isRequired,
};

export default Timeline;
