import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const Total = ({ title, totals }) => (
  <div className={styles.total}>
    <span>{`${title}`}</span>
    {totals.map(({ currency, amount }) => (
      <span
        key={currency}
        className={styles.totalValue}
      >{`${amount} ${currency}`}</span>
    ))}
  </div>
);

Total.propTypes = {
  title: PropTypes.string.isRequired,
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Total;
