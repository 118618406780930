import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Orders = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4 7.64996V9.44996H3.66543L5.46543 7.64996H32.4ZM32.4 9.44996H34.2001V26.55C34.2001 27.5441 33.3942 28.35 32.4 28.35H3.60005C2.60594 28.35 1.80005 27.5441 1.80005 26.55V11.3153L3.60005 9.51535V25.65C3.60005 26.147 4.00299 26.55 4.50005 26.55H31.5C31.9971 26.55 32.4 26.147 32.4 25.65V9.44996ZM6.30005 20.25V22.05H24.3V20.25H6.30005ZM13.3 14.8499H15.3V16.6499H13.3H8.30005H6.30005V14.8499H8.30005H13.3Z"
      fill={fill}
    />
  </svg>
);

Orders.propTypes = {
  fill: PropTypes.string,
};

Orders.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Orders;
