import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { isString } from 'lodash';
import { useLocation, Redirect } from 'react-router-dom';

import { api } from 'app/services';
import AuthPage from 'common/components/AuthPage';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import TextBox from 'common/components/TextBox';
import Button from 'common/components/Button';
import PasswordLabel from 'common/components/PasswordLabel';
import formUtils, { SIMPLE_PASSWORD_MESSAGE } from 'common/formUtils.js';
import PasswordSubmitted from '../components/PasswordSubmitted';

import styles from '../index.module.css';

const Header = () => (
  <>
    <Heading level="1" size="2" weight="medium" id="page-title" color="black">
      Set new password
    </Heading>
    <br />
    <Text color="rebranding-md-color-grey-60">
      <p id="page-description">Please set your account password below.</p>
    </Text>
  </>
);

const Form = ({ onSubmit, submitting }) => {
  const passwordField = useRef(null);

  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={(values) => onSubmit(values.password)}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="password" validate={formUtils.validatePassword}>
            {({ field, meta }) => (
              <div className={styles.row}>
                <>
                  {passwordField && (
                    <PasswordLabel
                      customLabel="New Password"
                      input={passwordField}
                    />
                  )}
                  <TextBox
                    id="password"
                    labelType="password"
                    type="password"
                    autoComplete="new-password"
                    placeholder="Your password"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    inputRef={passwordField}
                    {...field}
                  />
                  {!(meta.error && meta.touched) && (
                    <div className={styles.passwordHelper}>
                      <Text size="caption" color="rebranding-md-color-grey-60">
                        {SIMPLE_PASSWORD_MESSAGE}
                      </Text>
                    </div>
                  )}
                </>
              </div>
            )}
          </Field>
          <span className={styles.formFooter}>
            <div className={styles.buttonWrapperReset}>
              <Button
                kind="primary"
                loading={!!submitting}
                disabled={!!formUtils.validatePassword(values.password)}
                full
              >
                <button
                  type="submit"
                  id="progress-status"
                  data-test="reset-password-button"
                >
                  Set new password
                </button>
              </Button>
            </div>
          </span>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: propTypes.func.isRequired,
  submitting: propTypes.bool,
};

Form.defaultProps = {
  submitting: false,
};

const ResetPassword = () => {
  const [done, setDone] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [submitting, setSubmitting] = useState(null);

  const onSubmit = (password) => {
    setSubmitting(password);
  };

  const query = new URLSearchParams(useLocation().search);
  const resetCode = query.get('reset_code');

  useEffect(() => {
    if (submitting && resetCode) {
      setRequestError('');
      api.resetPassword(submitting, resetCode).then(({ error }) => {
        if (isString(error)) {
          setRequestError(error);
        } else {
          setDone(true);
        }
        setSubmitting(null);
      });
    } else setSubmitting(null);
  }, [submitting, resetCode]);

  if (!resetCode) {
    return <Redirect to="/sign-in" />;
  }

  if (done) return <PasswordSubmitted />;

  return (
    <AuthPage
      header={<Header />}
      form={<Form onSubmit={onSubmit} submitting={!!submitting} />}
      error={requestError}
    />
  );
};

export default ResetPassword;
