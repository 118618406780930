import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import build from 'redux-object';
import _ from 'lodash';

import reduxUtils from 'common/reduxUtils';
import actions from '../actions';

const {
  SETTLEMENTS_REQUEST,
  SETTLEMENTS_FAILURE,
  SETTLEMENTS_SUCCESS,
  SINGLE_SETTLEMENT_REQUEST,
  SINGLE_SETTLEMENT_FAILURE,
  SINGLE_SETTLEMENT_SUCCESS,
} = actions;

const defaults = {
  loading: false,
  error: null,
};

const transformSettlements = (payload) => {
  const settlements = build(payload, 'settlements');

  return _.reduce(
    settlements,
    (result, settlement) => ({
      ...result,
      [settlement.id]: settlement,
    }),
    []
  );
};

const transformReport = (payload) => {
  const settlementsList = build(payload, 'settlements');

  return settlementsList[0];
};

const settlementsReducer = handleActions(
  {
    [SETTLEMENTS_REQUEST]: () => ({
      ...defaults,
      loading: true,
    }),
    [SETTLEMENTS_SUCCESS]: (state, action) => ({
      ...defaults,
      byId: transformSettlements(action.payload),
      totals: reduxUtils.getMetaInfo(action.payload, 'totals'),
      settlementCurrency: reduxUtils.getMetaInfo(
        action.payload,
        'settlementCurrency'
      ),
    }),
    [SETTLEMENTS_FAILURE]: (state, action) => ({
      ...defaults,
      error: action.payload,
    }),
  },
  defaults
);

const singleSettlementReducer = handleActions(
  {
    [SINGLE_SETTLEMENT_REQUEST]: () => ({
      ...defaults,
      loading: true,
    }),
    [SINGLE_SETTLEMENT_SUCCESS]: (state, action) => ({
      ...defaults,
      data: transformReport(action.payload),
      settlementCurrency: reduxUtils.getMetaInfo(
        action.payload,
        'settlementCurrency'
      ),
    }),
    [SINGLE_SETTLEMENT_FAILURE]: (state, action) => ({
      ...defaults,
      error: action.payload,
    }),
  },
  defaults
);

export default combineReducers({
  all: settlementsReducer,
  report: singleSettlementReducer,
});

export const settlementCurrency = (state) =>
  state.settlements.all.settlementCurrency;
export const settlements = (state) => state.settlements.all.byId;
export const totals = (state) => state.settlements.all.totals;
export const isFetchingSettlements = (state) => state.settlements.all.loading;
export const report = (state) => state.settlements.report.data;
export const isFetchingReport = (state) => state.settlements.report.loading;
