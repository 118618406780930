import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { statuses, invoiceStatuses, statusClass } from 'payments/translations';
import { orderStatus } from '../../propTypes';
import styles from './index.module.css';

const i18n = (value, paymentType) =>
  paymentType === 'invoices' ? invoiceStatuses[value] : statuses[value];

class Status extends PureComponent {
  render() {
    const {
      value,
      cellProperties: {
        customComponentMetadata: { paymentType },
      },
    } = this.props;
    const status = statusClass[value];
    const className = classNames(styles.status, styles[status]);

    return (
      <div className={className} data-test="status">
        {i18n(value, paymentType)}
      </div>
    );
  }
}

Status.propTypes = {
  value: orderStatus.isRequired,
  cellProperties: PropTypes.shape({
    customComponentMetadata: PropTypes.shape({ paymentType: PropTypes.string }),
  }).isRequired,
};

export default Status;
