import React from 'react';
import PropTypes from 'prop-types';

const Eye = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 20 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M2 9.99999C3.16447 6.7053 6.30659 4.34479 10 4.34479C13.6935 4.34479 16.8356 6.7053 18.0001 10C16.8356 13.2947 13.6935 15.6552 10 15.6552C6.30659 15.6552 3.16447 13.2947 2 9.99999ZM13.7811 10.0009C13.7811 12.0831 12.0932 13.771 10.0111 13.771C7.92891 13.771 6.24098 12.0831 6.24098 10.0009C6.24098 7.91878 7.92891 6.23086 10.0111 6.23086C12.0932 6.23086 13.7811 7.91878 13.7811 10.0009ZM10.0114 11.8844C11.0525 11.8844 11.8965 11.0404 11.8965 9.99936C11.8965 8.95828 11.0525 8.11432 10.0114 8.11432C8.97036 8.11432 8.1264 8.95828 8.1264 9.99936C8.1264 11.0404 8.97036 11.8844 10.0114 11.8844Z"
    />
  </svg>
);

Eye.propTypes = {
  fill: PropTypes.string,
};

Eye.defaultProps = {
  fill: 'none',
};

export default Eye;
