import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'common/components/Icon';

import CTA from './CTA';
import styles from './index.module.css';

const AnnouncementBanner = ({ env, onDismiss }) => (
  <div
    className={classNames(styles.root, { [styles.prod]: env === 'production' })}
  >
    <div className={styles.announcement}>
      <div className={styles.logo}>
        <Icon.BannerLogo />
      </div>
      <div className={styles.announcementText}>Utrust is xMoney</div>
    </div>
    <div className={styles.cta}>
      <CTA />
      <div
        className={styles.close}
        onClick={onDismiss}
        onKeyDown={({ key }) => key === 'Enter' && onDismiss()}
        role="button"
        tabIndex="0"
      >
        <Icon.CrossThin
          fill="white"
          size="medium"
          description="Close announcement banner"
        />
      </div>
    </div>
  </div>
);

AnnouncementBanner.propTypes = {
  env: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default AnnouncementBanner;
