import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PaginationButton } from 'common/components/Pagination/PaginationButton';

class NextButton extends PureComponent {
  render() {
    const { hasNext, onClick } = this.props;

    return (
      <PaginationButton
        direction="right"
        callback={onClick}
        disabled={!hasNext}
      />
    );
  }
}

NextButton.propTypes = {
  hasNext: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NextButton;
