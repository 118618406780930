import React from 'react';
import PropTypes from 'prop-types';

import { amount, customerPropType } from 'common/propTypes';
import { orderStatus } from 'payments/propTypes';
import { getCountryLabel } from 'common/countryHelpers';
import FormattedDate from 'common/components/FormattedDate';
import Separator from 'common/components/Separator';
import Heading from 'common/components/Heading';
import StatusTag from 'common/components/StatusTag';
import ViewProperty from 'common/components/ViewProperty';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import MultiLineField from 'common/components/MultiLineField';
import { invalidBillExpirationRequest } from 'common/constants';

import DetailsFooter from '../DetailsFooter';
import ExportInvoiceButton from '../ExportInvoiceButton';
import PaymentLinkDetails from '../PaymentLinkDetails';

import styles from './index.module.css';

const InvoiceDetails = ({
  cancel,
  countries,
  createdAt,
  customer: { name, email, billingAddress, postCode, city, state, country },
  description,
  duplicate,
  error,
  paymentUrl,
  reference,
  referenceAmount,
  sendIssuedEmail,
  settlementAmount,
  status,
  totalAmount,
  invoiceDuplicateDisabled,
}) => {
  const shouldDisplayCancelButton =
    status === 'pending' && error !== invalidBillExpirationRequest;

  return (
    <div className={styles.root} data-test="invoice-details">
      <div className={styles.panel}>
        <Heading level="3" size="3">
          <span>
            <strong className={styles.strong}>Reference: </strong>
            {reference}
          </span>
        </Heading>
        <div
          data-test={`invoice-details-order-status-${status}`}
          className={styles.status}
        >
          <StatusTag status={status} paymentType="invoices" />
        </div>
        <div className={styles.details}>
          <ViewProperty label="Customer name" value={name} />
          <MultiLineField
            label="Billing address"
            values={[
              billingAddress,
              `${postCode} ${city}`,
              `${state ? `${state}, ` : ''}${getCountryLabel(
                countries,
                country
              )}`,
            ]}
          />
          <ViewProperty label="Customer email" value={email} />
        </div>
        <Text size="caption" color="rebranding-md-color-grey-60">
          <FormattedDate value={createdAt} />
        </Text>
        <div className={styles.separator}>
          <Separator />
        </div>
        <ViewProperty label="Description " value={description || '-'} />
        <div className={styles.separator}>
          <Separator />
        </div>
        <PaymentLinkDetails
          email={email}
          paymentUrl={paymentUrl}
          emailSent={sendIssuedEmail}
          reference={reference}
          totalAmount={totalAmount}
          customerName={name}
        />
      </div>
      <DetailsFooter
        totalAmount={totalAmount}
        referenceAmount={referenceAmount}
        settlementAmount={settlementAmount}
      />
      <div className={styles.mobileButtons}>
        <ExportInvoiceButton full />
        <Button
          full
          kind="secondary"
          onClick={duplicate}
          disabled={invoiceDuplicateDisabled}
        >
          <button type="button">Duplicate invoice</button>
        </Button>
        {shouldDisplayCancelButton && (
          <Button full kind="destructiveSubtle" onClick={cancel}>
            <button type="button">Cancel invoice</button>
          </Button>
        )}
      </div>
      {shouldDisplayCancelButton && (
        <div className={styles.cancelWrapper}>
          <Button kind="destructiveSubtle" onClick={cancel}>
            <button type="button">Cancel invoice</button>
          </Button>
        </div>
      )}
      {error && (
        <div className={styles.errorMessage}>
          {error === invalidBillExpirationRequest
            ? 'Invoice status has been updated. Please refresh this page.'
            : 'Something went wrong'}
        </div>
      )}
    </div>
  );
};

const invoiceDetailsProptypes = {
  cancel: PropTypes.func.isRequired,
  countries: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  createdAt: PropTypes.string.isRequired,
  customer: PropTypes.shape(customerPropType).isRequired,
  description: PropTypes.string,
  duplicate: PropTypes.func,
  error: PropTypes.string,
  reference: PropTypes.string.isRequired,
  settlementAmount: amount.isRequired,
  status: orderStatus.isRequired,
  totalAmount: amount.isRequired,
  paymentUrl: PropTypes.string.isRequired,
  sendIssuedEmail: PropTypes.bool.isRequired,
  invoiceDuplicateDisabled: PropTypes.bool,
};

InvoiceDetails.propTypes = invoiceDetailsProptypes;

export default InvoiceDetails;

export { invoiceDetailsProptypes };
