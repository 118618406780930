import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { api } from 'app/services';
import { actions as errorActions } from 'error';
import { actions as settingsActions } from 'settings';
import { getBusinessInfo, getSectors, getCountries } from 'settings/reducers';
import { selectors as sessionSelectors } from 'session';

const useCompanyInfo = ({ withCountries, withSectors }) => {
  const [errorMessage, setErrorMessage] = useState();
  const activeOrganizationId = useSelector((state) =>
    sessionSelectors.activeOrganization(state)
  )?.id;
  const businessInfo = useSelector((state) => getBusinessInfo(state));
  const sectors = useSelector((state) => getSectors(state));
  const countries = useSelector((state) => getCountries(state));
  const dispatch = useDispatch();

  useEffect(() => {
    let isSubscribed = true;

    function handleError(error) {
      dispatch(errorActions.apiError(error));
      return setErrorMessage(error);
    }

    async function fetchSectors() {
      const { error, response } = await api.fetchSectors();

      if (isSubscribed) {
        if (error) return handleError(error);

        dispatch(settingsActions.sectorsSuccess(response));
      }
    }

    async function fetchCompanyInfo() {
      const { error, response } = await api.fetchCompanyInfo(
        activeOrganizationId
      );
      if (isSubscribed) {
        if (error) return handleError(error);

        dispatch(settingsActions.businessInfoSuccess(response));
      }
    }

    async function fetchCountries() {
      const { error, response } = await api.fetchMerchantCountries();
      if (isSubscribed) {
        if (error) return handleError(error);
        dispatch(settingsActions.merchantCountriesSuccess(response));
      }
    }

    if (!sectors && withSectors) fetchSectors();
    if (!countries && withCountries) fetchCountries();
    if (!businessInfo) fetchCompanyInfo();

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  return { errorMessage, sectors, countries, businessInfo };
};

export default useCompanyInfo;
