import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import { selectors as sessionSelectors } from 'session';
import { selectors as authSelectors } from 'auth';

import { useWindowWidth } from 'common/hooks';
import { pageSizeOptions, defaultPageSize } from 'payments/tableUtils';
import { getOrdersTable, getOrdersTableFilters } from '../reducers/orders';
import actions from '../actions';
import Payments from '../components/Payments';
import { order as orderPropType, managePermission } from '../propTypes';

const Orders = ({
  data,
  dateRangeValue,
  isFetching,
  isInvoicingEnabled,
  page,
  pageSize,
  searchValue,
  setPageSize,
  statusValue,
  totalElements,
  complianceStatus,
}) => {
  const tableRef = useRef();
  const noOrders = isEmpty(data);
  const windowWidth = useWindowWidth();

  const noDataYet =
    dateRangeValue === 'all' &&
    !searchValue &&
    statusValue === '.*' &&
    noOrders;

  // setPagesize action triggers ordersRequest through sagas
  useEffect(() => {
    setPageSize(defaultPageSize);
  }, []);

  return (
    <Payments
      isFetching={isFetching}
      noData={noOrders}
      noDataYet={noDataYet}
      complianceStatus={complianceStatus}
      paymentType="orders"
      paginationHelperData={{
        page,
        pageSize,
        pageSizeOptions,
        setPageSize,
        totalElements,
      }}
      tableRef={tableRef}
      windowWidth={windowWidth}
      dateRangeValue={dateRangeValue}
      searchValue={searchValue}
      statusValue={statusValue}
      isInvoicingEnabled={isInvoicingEnabled}
    />
  );
};

Orders.propTypes = {
  setPageSize: PropTypes.func.isRequired,
  complianceStatus: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape(orderPropType)),
  isFetching: PropTypes.bool.isRequired,
  totalElements: PropTypes.number,
  dateRangeValue: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  statusValue: PropTypes.string.isRequired,
  isInvoicingEnabled: PropTypes.bool.isRequired,
};

const ConnectedOrdersContainer = connect(
  (state) => {
    const { data, isFetching, totalElements, page, pageSize } =
      getOrdersTable(state);
    const { dateRangeValue, searchValue, statusValue } =
      getOrdersTableFilters(state);

    return {
      isInvoicingEnabled:
        authSelectors.getPermissions(state)?.invoicing === managePermission,
      complianceStatus:
        sessionSelectors.activeOrganization(state)?.complianceStatus,
      data,
      dateRangeValue,
      isFetching,
      page,
      pageSize,
      searchValue,
      statusValue,
      totalElements,
    };
  },
  {
    setPageSize: actions.setOrdersTablePageSize,
  }
)(Orders);

export default ConnectedOrdersContainer;
