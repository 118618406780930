import React from 'react';
import { Link } from 'react-router-dom';

import Heading from 'common/components/Heading';
import Icon from 'common/components/Icon';
import Button from 'common/components/Button';
import colors from 'common/styles/colors';

import Layout from '../Layout';
import styles from './index.module.css';

const PasswordSubmitted = () => (
  <Layout heading="Password submitted">
    <div className={styles.iconWrapper}>
      <Icon.Lock
        fill={colors['rebranding-md-color-brand-purple']}
        description="Lock Icon"
      />
    </div>
    <Heading level="1" size="3">
      Your password has been set successfully.
    </Heading>
    <br />
    <Button kind="subtle" name="back-to-login">
      <Link to="/sign-in">Take me to dashboard</Link>
    </Button>
  </Layout>
);

export default PasswordSubmitted;
