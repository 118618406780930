import React, { memo } from 'react';

import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const Title = () => (
  <div className={styles.title}>
    <LogoxMoney />
  </div>
);

export default memo(Title);
