import React from 'react';
import propTypes from 'prop-types';

import styles from './index.module.css';

const ValidationError = ({ errorString, id }) => (
    <span role="alert" id={`${id}-error-message`} className={styles.error}>
      {errorString}
    </span>
  );

ValidationError.propTypes = {
  errorString: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
};

export default ValidationError;
