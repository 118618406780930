import { values } from 'lodash';

const errorMessages = {
  invalid_token: 'Invalid authentication code. Please try again.',
  invalid_recovery_code: 'Invalid recovery code. Please try again.',
};

const handleError = (error) => errorMessages[error] || 'Something went wrong';

const parseSecret = (tfaSecret) =>
  values(tfaSecret).reduce(
    (secret, s) => Object.assign(secret, s.attributes),
    {}
  );

export { handleError, parseSecret };
