import {
  all,
  put,
  call,
  fork,
  take,
  takeEvery,
  select,
} from 'redux-saga/effects';
import _ from 'lodash';

import { actions as errorActions } from 'error';
import { api } from 'app/services';
import actions from '../actions';
import { selectors as authSelectors } from '../../auth';

function* storeCredentialsPermission() {
  return yield select(authSelectors.storeCredentialsPermission);
}

function* fetchCredentials({ storeId }) {
  const { response, error } = yield call(api.fetchStorePage, storeId);

  if (response) {
    const storeInfo = _.head(_.values(response.storeSettings)).attributes;
    yield put(actions.credentialsSuccess(storeInfo));
  } else
    yield all([
      put(errorActions.apiError(error)),
      put(actions.credentialsFailure(error)),
    ]);
}

function* generateNewCredentials({ storeId }) {
  const { response, error } = yield call(
    api.generateNewStoreCredentials,
    storeId
  );
  if (response) {
    const credentials = _.head(_.values(response.storeCredentials)).attributes;
    yield put(actions.newCredentialsSuccess(credentials));
  } else {
    yield all([
      put(errorActions.apiError(error)),
      yield put(actions.newCredentialsError(error)),
    ]);
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchGenerateNewCredentials() {
  while (true) {
    const { payload } = yield take(actions.newCredentialsRequest);
    yield call(generateNewCredentials, payload);
  }
}

function* watchFetchCredentials() {
  let credentialsPermission;
  while (true) {
    const { payload } = yield take(actions.credentialsRequest);
    credentialsPermission = yield storeCredentialsPermission();

    // Only request page if the user has permission to see something on it
    if (credentialsPermission) yield call(fetchCredentials, payload);
  }
}

function* fetchWebhooks({ payload: { page, size } }) {
  const { response, error } = yield call(api.fetchWebhooks, {
    page,
    size,
  });

  if (response) yield put(actions.webhooksSuccess(response));
  else {
    yield all([
      put(actions.webhooksFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* fetchSingleWebhook({ payload: { id } }) {
  const { response, error } = yield call(api.fetchSingleWebhook, id);

  if (response) yield put(actions.singleWebhookSuccess(response));
  else {
    yield all([
      put(actions.singleWebhookFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* watchFetchWebhooks() {
  yield takeEvery(actions.webhooksRequest, fetchWebhooks);
}

function* watchFetchSingleWebhook() {
  yield takeEvery(actions.singleWebhookRequest, fetchSingleWebhook);
}

export default function* root() {
  yield fork(watchGenerateNewCredentials);
  yield fork(watchFetchCredentials);
  yield fork(watchFetchWebhooks);
  yield fork(watchFetchSingleWebhook);
}
