import { handleActions } from 'redux-actions';
import _ from 'lodash';

import actions from '../actions';

const { FEATURES_SUCCESS, FEATURES_FAILURE, FEATURES_REQUEST } = actions;

const defaultFlags = {
  merchant_enabled_on_registration: false,
  merchant_registration: false,
  uphold_connect: false,
  settlements: false,
  md_reference_fiat_currency: false,
  md_crypto_settlements: false,
  sso: false,
};

const featuresReducer = handleActions(
  {
    [FEATURES_REQUEST]: () => ({ ...defaultFlags, isFetching: true }),
    [FEATURES_SUCCESS]: (state, action) => ({
      ...defaultFlags,
      ..._.mapValues(action.payload, 'attributes.enabled'),
      isFetching: false,
    }),
    [FEATURES_FAILURE]: () => ({
      ...defaultFlags,
      isFetching: false,
      error: true,
    }),
  },
  { ...defaultFlags, isFetching: false }
);

export default featuresReducer;

export const getFeatureFlags = (state) => state.features;
/* eslint-disable camelcase */
export const isSandboxMode = (state) => state.features?.sandbox_mode;
export const getOnboardingFlags = ({ features }) => ({
  isMerchantRegistrationActive: features?.merchant_registration,
  sandboxMode: features?.sandbox_mode,
});
/* eslint-enable camelcase */
export const isSSOActive = (state) => _.get(state.features, 'sso');
export const isMerchantRegistrationActive = (state) =>
  _.get(state.features, 'merchant_registration');
export const isInvoicingEnabled = (state) => _.get(state.features, 'invoicing');
export const isUpholdConnectActive = (state) =>
  _.get(state.features, 'uphold_connect');
export const isSettlementsActive = (state) =>
  _.get(state.features, 'settlements');
export const isPayoutLimitsActive = (state) =>
  _.get(state.features, 'payout_min_limits');
export const isWebhooksActive = (state) => _.get(state.features, 'webhooks');
export const isReferenceCurrencyActive = (state) =>
  _.get(state.features, 'md_reference_fiat_currency');
export const isRonReferenceCurrencyActive = (state) =>
  _.get(state.features, 'md_ron_reference_fiat_currency');
export const isChfReferenceCurrencyActive = (state) =>
  _.get(state.features, 'md_chf_reference_fiat_currency');
export const isChfSettlementCurrencyActive = (state) =>
  _.get(state.features, 'md_chf_settlement_currency');
export const isBannerAnnouncementActive = (state) =>
  _.get(state.features, 'md_header_banner_announcement');
export const isCryptoSettlementsActive = (state) =>
  _.get(state.features, 'md_crypto_settlements');
