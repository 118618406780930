import React from 'react';
import { motion } from 'framer-motion';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const FadeInTransition = ({ children, className }) => (
  <motion.div
    className={className}
    initial="hidden"
    animate="visible"
    variants={variants}
  >
    {children}
  </motion.div>
);

export default FadeInTransition;
