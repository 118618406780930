import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const BACKDROP_ID = 'popup-backdrop';

const PopUp = ({ content, children }) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => setVisible(!visible);

  const backDropClassnames = classNames(styles.backdrop, {
    [styles.backdropHidden]: !visible,
  });

  const contentClassnames = classNames(styles.content, {
    [styles.visible]: visible,
  });

  return (
    <>
      <div
        className={backDropClassnames}
        id={BACKDROP_ID}
        onClick={(evt) => evt.target.id === BACKDROP_ID && setVisible(false)}
        onKeyDown={({ key }) => key === 'Escape' && setVisible(false)}
        role="button"
        tabIndex="0"
      />
      <div className={styles.root}>
        <div
          className={styles.toggler}
          onClick={toggleVisibility}
          onKeyDown={({ key }) => {
            if (key === 'Enter') toggleVisibility();
          }}
          role="button"
          tabIndex="0"
        >
          {children}
        </div>
        <div className={contentClassnames}>{content}</div>
      </div>
    </>
  );
};

PopUp.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default PopUp;
