import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PaginationButton } from 'common/components/Pagination/PaginationButton';

class PreviousButton extends PureComponent {
  render() {
    const { hasPrevious, onClick } = this.props;

    return (
      <PaginationButton
        direction="left"
        callback={onClick}
        disabled={!hasPrevious}
      />
    );
  }
}

PreviousButton.propTypes = {
  hasPrevious: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PreviousButton;
