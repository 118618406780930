import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const HeaderCell = ({ content, align, helper }) => {
  const classes = classNames(styles.cell, styles[align]);

  return (
    <th>
      <div className={classes}>
        {content}
        {helper && <span className={styles.helper}>{` (${helper})`}</span>}
      </div>
    </th>
  );
};

HeaderCell.propTypes = {
  content: PropTypes.string.isRequired,
  helper: PropTypes.string,
  align: PropTypes.oneOf(['right']),
};

export default HeaderCell;
