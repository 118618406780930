import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Caret, Close } from '../Icons';

import styles from './index.module.css';

// Avoid collapse of the options dropdown, which is the default for button
const handleKeystroke = (defaultButtonBehaviour) => (evt) => {
  // 32 = spacebar
  if (evt.which === 32) return null;

  return defaultButtonBehaviour(evt);
};

const SearchableInput = ({
  open,
  inputProps: { value, ...restInputProps },
  buttonProps,
  name,
  selectedItem,
  clearSelection,
  error,
}) => {
  const inputClassNames = classNames(styles.input, {
    [styles.open]: open,
    [styles.error]: error,
  });
  const { onKeyUp, onKeyDown, ...otherButtonProps } = buttonProps;

  return (
    <div
      tabIndex="-1"
      className={styles.root}
      role="button"
      type="button"
      {...otherButtonProps}
      onKeyDown={handleKeystroke(onKeyDown)}
      onKeyUp={handleKeystroke(onKeyUp)}
    >
      <input
        className={inputClassNames}
        {...restInputProps}
        value={value || ''}
        name={name}
        data-lpignore="true"
      />
      {selectedItem ? (
        <Close onClick={clearSelection} />
      ) : (
        <Caret isOpen={open} />
      )}
    </div>
  );
};

SearchableInput.propTypes = {
  error: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  inputProps: PropTypes.shape({ value: PropTypes.string }).isRequired,
  buttonProps: PropTypes.shape({
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
  }).isRequired,
  name: PropTypes.string.isRequired,
  selectedItem: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  clearSelection: PropTypes.func.isRequired,
};

const areEqual = (prevProps, nextProps) => {
  if (
    prevProps.inputProps?.value === nextProps.inputProps?.value &&
    prevProps.open === nextProps.open &&
    prevProps.selectedItem === nextProps.selectedItem &&
    prevProps.error === nextProps.error
  )
    return true;

  return false;
};

export default memo(SearchableInput, areEqual);
