import React from 'react';

import styles from './index.module.css';

const CTA = () => (
  <a
    className={styles.root}
    href="https://utrust.com/blog/xmoney-launch/"
    target="_blank"
    rel="noreferrer"
  >
    Learn more
  </a>
);

export default CTA;
