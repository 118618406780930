import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'common/components/Checkbox';

import styles from './index.module.css';

const Item = ({
  isActive,
  isSelected,
  isIndeterminate,
  label,
  onClick: click,
  withCheckbox,
  role,
  isDisabled,
  isInnerItem,
  name,
  ...props
}) => {
  const rootClassNames = classNames(styles.root, {
    [styles.active]: isActive && !isDisabled,
    [styles.selected]: isSelected,
    [styles.disabled]: isDisabled,
    [styles.innerItem]: isInnerItem,
  });
  let onClick = () => {};

  if (!isDisabled) {
    onClick = (evt) => {
      if (withCheckbox) evt.preventDefault();
      click(evt);
    };
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      {...props}
      role={role}
      onClick={onClick}
      className={rootClassNames}
      data-test={`option-${name}`}
    >
      {withCheckbox ? (
        <Checkbox
          value={isSelected}
          name={label}
          id={label}
          label={label}
          indeterminate={isIndeterminate}
        />
      ) : (
        label
      )}
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events */
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isIndeterminate: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInnerItem: PropTypes.bool,
};

Item.defaultProps = {
  isIndeterminate: false,
  isDisabled: false,
  isInnerItem: false,
};

export default Item;
