import { getAccessToken, getRefreshToken } from 'app/services/api';
import { actions } from 'auth';
import useTokenRefresher, { isTokenExpired } from 'auth/hooks/useTokenRefresh';
import {
  isOtpAuthenticated,
  otpExchangeError as otpExchangeErrorSelector,
  signOutSuccess as signOutSuccessSelector,
} from 'auth/reducers';
import { getAuthServiceUrl } from 'auth/utils/getAuthServiceUrl';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UO_loginRoute } from './routes';

const goToLogin = () => {
  const authServiceUrl = getAuthServiceUrl();
  window.location = `${authServiceUrl}${UO_loginRoute}`;
};

const AuthHandler = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector(isOtpAuthenticated);
  const isSignOutSuccess = useSelector(signOutSuccessSelector);
  const otpExchangeError = useSelector(otpExchangeErrorSelector);

  useEffect(() => {
    if (isSignOutSuccess) {
      goToLogin();
    }
  }, [isSignOutSuccess]);

  useTokenRefresher();

  useEffect(() => {
    if (otpExchangeError) {
      goToLogin();
    }
  }, [otpExchangeError]);

  function trySignIn() {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (
      !accessToken ||
      !refreshToken ||
      (refreshToken && isTokenExpired(refreshToken))
    ) {
      return goToLogin();
    }

    dispatch(actions.tokenRefreshRequest({ refreshToken }));
  }

  useEffect(() => {
    const startAuthentication = () => {
      if (isAuthenticated) {
        return;
      }

      const queryParams = queryString.parse(location.search);
      const otpToken = queryParams.otp;

      if (!otpToken) {
        return trySignIn();
      }

      dispatch(actions.otpExchangeRequest(otpToken));
    };

    startAuthentication();
  }, [dispatch, location, isAuthenticated]);

  return isAuthenticated ? <>{children}</> : null;
};

AuthHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthHandler;
