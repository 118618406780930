import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fp from 'lodash/fp';

import { track } from 'common/analytics';
import { api } from 'app/services';
import { attachment as attachmentType } from 'common/propTypes';
import normalizeS3Params from 'common/normalizeS3Params';
import Attachments from 'common/components/Attachments';
import { kybDocumentsByType } from '../reducers';
import actions from '../actions';
import {
  bDoc,
  bCertificate,
  bMembers,
  bDirectors,
  bAuthorization,
  bShareholders,
  boId,
  boPa,
} from '../components/BusinessDocumentsForm/constants';

const KybDocuments = ({
  createDocument,
  deleteDocument,
  documentLabel,
  listByType,
  missing,
}) => {
  const uploadFile = (file, callback) => api
      .getUploadCredentials(
        file.name,
        file.type,
        `kyb/${documentLabel}`,
        'kyb_documents'
      )
      .then((payload) => {
        const data = fp.values(payload.response.uploadRequests)[0].attributes;
        data.params = normalizeS3Params(data.params);
        callback(file, data);
      });
  const onUploadFinish = (uploadResult, file) => {
    track('Business Details document uploaded');
    createDocument({
      name: file.name,
      url: uploadResult.location,
      label: documentLabel,
    });
  };

  return (
    <Attachments
      list={listByType[documentLabel] || []}
      uploadFile={uploadFile}
      onUploadFinish={onUploadFinish}
      deleteAttachment={deleteDocument}
      missing={missing}
    />
  );
};

KybDocuments.propTypes = {
  documentLabel: PropTypes.oneOf([
    bDoc,
    bCertificate,
    bMembers,
    bDirectors,
    bAuthorization,
    bShareholders,
    boId,
    boPa,
  ]),
  createDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  listByType: PropTypes.objectOf(PropTypes.arrayOf(attachmentType)).isRequired,
  missing: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    listByType: kybDocumentsByType(state),
  }),
  {
    createDocument: actions.kybCreateDocumentRequest,
    deleteDocument: actions.kybDeleteDocumentRequest,
  }
)(KybDocuments);
