import React from 'react';

import Loading from 'common/components/Loading';

import styles from './index.module.css';

const LoadingPage = () => (
  <div className={styles.root}>
    <Loading kind="secondary" size="xLarge" />
  </div>
);

export default LoadingPage;
