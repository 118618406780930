import React from 'react';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import Layout from '../Layout';

import styles from './index.module.css';

const PasswordEmailSent = () => (
  <Layout heading="Password reset link sent">
    <div className={styles.iconWrapper}>
      <Icon.Mail
        fill={colors['rebranding-md-color-brand-purple']}
        description="Mail Icon"
      />
    </div>
    <Heading level="1" size="3">
      <p>We just sent you an email with instructions to reset your password.</p>
      <p>This link will be valid for the next hour.</p>
    </Heading>
    <div className={styles.resendMessage}>
      <Text color="rebranding-md-color-grey-60">{`Don't forget to check your spam box.`}</Text>
    </div>
  </Layout>
);

export default PasswordEmailSent;
