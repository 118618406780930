import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const Layout = ({ Table, Pagination }) => (
  <div className={styles.root}>
    <Table />
    <Pagination />
  </div>
);

Layout.propTypes = {
  Table: PropTypes.func.isRequired,
  Pagination: PropTypes.func.isRequired,
};

export default Layout;
