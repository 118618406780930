import React from 'react';

import ToolTip from 'common/components/ToolTip';
import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import styles from './index.module.css';

const PendingTooltip = () => (
  <span className={styles.root}>
    <ToolTip
      message={
        <>
          {`Payouts can take up to 2 working days to be processed.
      We will email you once it's done.`}
        </>
      }
    >
      <Icon.Info
        description="Settlement is processing"
        size="medium"
        fill={colors['rebranding-md-color-brand-purple']}
        circleStroke={colors['rebranding-md-color-brand-purple']}
      />
    </ToolTip>
  </span>
);

export default PendingTooltip;
