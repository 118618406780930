import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'common/components/Icon';

import styles from './index.module.css';

// progress statuses
const stepCompleted = 'completed';
const stepCurrent = 'current';
const stepIdle = 'idle';

const ProgressStep = ({ status, text }) => (
    <div className={classNames(styles.root, styles[status])}>
      <div className={classNames(styles.icon, styles[status])}>
        {status === stepCompleted && (
          <div className={styles.checkmark}>
            <Icon.Checkmark size="auto" description={status} fill="white" />
          </div>
        )}
      </div>
      <div className={classNames(styles.text, styles[status])}>{text}</div>
    </div>
  );

ProgressStep.propTypes = {
  status: PropTypes.oneOf([stepIdle, stepCurrent, stepCompleted]).isRequired,
  text: PropTypes.string.isRequired,
};

export default ProgressStep;

export { stepCompleted, stepCurrent, stepIdle };
