import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AlertBase from './AlertBase';

const AlertDismissable = ({ color, text, buttonText, onDismiss }) => {
  const handleKeyDown = ({ key = '' }) => {
    if (key === 'Enter') onDismiss();
  };

  return (
    <AlertBase color={color} text={text}>
      <span
        onClick={onDismiss}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        {buttonText}
      </span>
    </AlertBase>
  );
};

AlertDismissable.propTypes = {
  color: PropTypes.oneOf(['blue', 'red']),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    ),
  ]).isRequired,
  buttonText: PropTypes.string,
  onDismiss: PropTypes.func.isRequired,
};

AlertDismissable.defaultProps = {
  buttonText: 'Dismiss',
};

export default memo(AlertDismissable);
