import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { route as routePropType } from 'common/propTypes';
import NotFound from 'common/components/NotFound';
import { useCompanyInfo } from 'common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInvoiceContainer,
  getLoadInvoicesError,
  getCountriesList,
} from '../reducers/invoices';
import actions from '../actions';
import InvoiceReport from '../components/InvoiceReport';

const useInvoiceData = ({ id }) => {
  const invoice = useSelector((state) => getInvoiceContainer(state, id));
  const error = useSelector((state) => getLoadInvoicesError(state));
  const countries = useSelector((state) => getCountriesList(state))?.countries;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(invoice)) {
      dispatch(actions.singleInvoicePageRequest(id));
    }
  }, []);

  return { invoice, countries, error };
};

const InvoiceReportPage = ({ match }) => {
  const {
    errorMessage: errorCompanyInfo,
    countries: merchantCountries,
    businessInfo,
  } = useCompanyInfo({
    withCountries: true,
  });
  const {
    invoice,
    countries: buyerCountries,
    error: errorInvoice,
  } = useInvoiceData({
    id: match?.params?.id,
  });

  if (errorCompanyInfo || errorInvoice) return <NotFound />;
  if (
    isEmpty(invoice) ||
    isEmpty(businessInfo) ||
    isEmpty(merchantCountries) ||
    isEmpty(buyerCountries)
  )
    return null;

  return (
    <InvoiceReport
      invoice={invoice}
      businessInfo={businessInfo}
      merchantCountries={merchantCountries}
      buyerCountries={buyerCountries}
    />
  );
};

InvoiceReportPage.propTypes = routePropType;

export default InvoiceReportPage;
