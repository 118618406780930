import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import Button from 'common/components/Button';

import localStyles from './index.module.css';
import settingsStyles from '../../index.module.css';

const Inactive = ({ onEnableClick }) => (
  <>
    <div className={localStyles.root}>
      <Text color="rebranding-md-color-grey-70">
        We highly recommend you to enable two-factor authentication in order to
        add an extra layer of security to your account.
      </Text>
    </div>
    <div className={settingsStyles.buttonWrapper}>
      <Button onClick={onEnableClick} full kind="primary">
        <button
          type="button"
          id="enable-twofactor-start"
          data-test="enable-twofactor-start"
        >
          Enable
        </button>
      </Button>
    </div>
  </>
);

Inactive.propTypes = {
  onEnableClick: PropTypes.func.isRequired,
};

export default Inactive;
