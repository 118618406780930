import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { displayUtcDate } from 'common/services/date';
import Heading from 'common/components/Heading';
import ViewProperty from 'common/components/ViewProperty';

import Card from '../Card';

import styles from './index.module.css';

const labels = {
  attempts: 'Attempts',
  entityReference: 'Entity reference',
  eventType: 'Event type',
  updatedAt: 'Updated at',
  orderUUID: 'Order UUID',
  paymentUUID: 'Payment UUID',
  url: 'Callback URL',
};

const orderedFields = [
  'eventType',
  'attempts',
  'updatedAt',
  'entityReference',
  'orderUUID',
  'paymentUUID',
  'url',
];

const viewPropertyValue = (key, value) =>
  key === 'updatedAt'
    ? `${displayUtcDate(value, 'yyyy-MM-dd HH:mm')} UTC`
    : value;

const webhookStatus = (status) =>
  status === 'failed_and_notified' ? 'failed' : status;

const WebhookBasicDetails = ({ data }) => {
  const fields = _.omit(data, ['id', 'status']);
  const pillClassNames = classNames(
    styles.pill,
    styles[webhookStatus(data.status)]
  );

  return (
    <Card>
      <Heading level="3" size="3">
        <span className={styles.reference} data-test="webhook-id">
          <strong className={styles.strong}>Event ID: </strong>
          {data.id}
        </span>
      </Heading>
      <br />
      <span className={pillClassNames}>
        {_.capitalize(webhookStatus(data.status))}
      </span>
      {orderedFields.map((key) => (
        <div key={key}>
          {fields[key] && (
            <div className={styles.fieldWrapper} data-test={`webhook-${key}`}>
              <ViewProperty
                label={labels[key]}
                value={viewPropertyValue(key, fields[key])}
              />
            </div>
          )}
        </div>
      ))}
    </Card>
  );
};

WebhookBasicDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    attempts: PropTypes.number.isRequired,
    entityReference: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    orderUUID: PropTypes.string,
    paymentUUID: PropTypes.string,
    url: PropTypes.string.isRequired,
  }),
};

export default WebhookBasicDetails;
