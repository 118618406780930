import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { debounce } from 'lodash';

import Label from 'common/components/Label';
import Icon from 'common/components/Icon';

import styles from './index.module.css';

const afterCopiedInterval = (fn) => setTimeout(fn, 3000);

const CopiedMessage = ({ height }) => (
  <div
    className={styles.copiedToClipboard}
    style={{ minHeight: height }}
    role="alert"
  >
    <div className={styles.message}>
      <Icon.Checkmark
        className={styles.checkIcon}
        size="medium"
        description="Checkmark"
      />
      Copied to clipboard
    </div>
  </div>
);

CopiedMessage.propTypes = {
  height: PropTypes.number.isRequired,
};

const CopyButton = ({
  code,
  codeClasses,
  loading,
  valueCopied,
  setValueCopied,
  wrapperHeight,
}) => {
  const handleClick = useCallback(() => {
    if (!copy(code) || loading) return;

    setValueCopied(true);
    afterCopiedInterval(() => setValueCopied(false));
  }, [code, loading, setValueCopied]);

  if (valueCopied) {
    return <CopiedMessage height={wrapperHeight} />;
  }

  return (
    <button
      type="button"
      className={codeClasses}
      onClick={handleClick}
      aria-label="You can click on this button to copy automatically the value to your clipboard"
    >
      <div className={styles.text}>{code}</div>
      {!loading && <Icon.Copy size="medium" className={styles.clipboardIcon} />}
    </button>
  );
};

CopyButton.propTypes = {
  code: PropTypes.string.isRequired,
  codeClasses: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  valueCopied: PropTypes.bool.isRequired,
  setValueCopied: PropTypes.func.isRequired,
  wrapperHeight: PropTypes.number.isRequired,
};

const ShareIcon = ({ url, text }) => {
  const handleClick = () => {
    if (navigator.share) {
      navigator.share({
        text,
      });
    } else {
      copy(url);
    }
  };

  return (
    <button
      type="button"
      className={styles.shareIcon}
      onClick={handleClick}
      aria-label="You can click on this button to share this message"
    >
      <Icon.Share size="medium" />
    </button>
  );
};

ShareIcon.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const CodeDisplay = ({
  code,
  clipboard,
  hidden,
  label,
  loading,
  dataTest,
  fullWidth,
  shareable,
  shareText,
  shareUrl,
}) => {
  const [valueCopied, setValueCopied] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const refWrapper = useRef(null);

  const codeClasses = classNames(styles.code, {
    [styles.isButton]: clipboard && !loading,
    [styles.isLoading]: loading,
    [styles.isHidden]: hidden,
  });

  useEffect(() => {
    const resize = () =>
      refWrapper?.current && setWrapperHeight(refWrapper.current.clientHeight);
    resize();
    const handleResize = debounce(resize, 150);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className={fullWidth ? styles.fullWidth : ''}>
      {label ? <Label>{label}</Label> : null}
      <div
        className={styles.displayWrapper}
        ref={refWrapper}
        data-test={dataTest}
      >
        {clipboard ? (
          <CopyButton
            code={code}
            codeClasses={codeClasses}
            loading={loading}
            valueCopied={valueCopied}
            setValueCopied={setValueCopied}
            wrapperHeight={wrapperHeight}
          />
        ) : (
          <div className={codeClasses}>
            {code}
            {shareable && <ShareIcon url={shareUrl} text={shareText} />}
          </div>
        )}
      </div>
    </div>
  );
};

CodeDisplay.propTypes = {
  clipboard: PropTypes.bool,
  code: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  dataTest: PropTypes.string,
  fullWidth: PropTypes.bool,
  shareable: PropTypes.bool,
  shareText: PropTypes.string,
  shareUrl: PropTypes.string,
};

CodeDisplay.defaultProps = {
  clipboard: false,
  hidden: false,
  label: null,
  loading: false,
  dataTest: '',
  fullWidth: false,
  shareable: false,
  shareText: '',
  shareUrl: '',
};

export default CodeDisplay;
