import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import colors from 'common/styles/colors.js';
import Icon from '../Icon';
import styles from './index.module.css';

const PaginationButton = ({ callback, direction, disabled }) => {
  const onKeyDown = ({ key = '' }) => {
    if (key === 'Enter') callback();
  };

  return (
    <motion.div
      initial={{
        opacity: 0.5,
      }}
      animate={{
        opacity: 1,
      }}
      className={classNames({
        [styles.next]: direction === 'right',
        [styles.previous]: direction === 'left',
        [styles.disabled]: disabled,
      })}
      role="button"
      tabIndex="0"
      onClick={disabled ? null : callback}
      onKeyDown={disabled ? null : onKeyDown}
      data-test={direction === 'right' ? 'next-button' : 'previous-button'}
    >
      <Icon.Arrow
        description={`Arrow small ${direction}`}
        fill={colors['rebranding-md-color-brand-black']}
        size="small"
        direction={direction}
      />
    </motion.div>
  );
};

const Previous = ({ setPage, currentPage }) => {
  const onPrevious = () => setPage(currentPage - 1);

  const hasPrevious = currentPage > 1;

  return (
    <PaginationButton
      callback={onPrevious}
      direction="left"
      disabled={!hasPrevious}
    />
  );
};

const Next = ({ setPage, totalPages, currentPage }) => {
  const onNext = () => setPage(currentPage + 1);

  const hasNext = currentPage < totalPages;

  return (
    <PaginationButton callback={onNext} direction="right" disabled={!hasNext} />
  );
};

PaginationButton.propTypes = {
  direction: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

Previous.propTypes = {
  setPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

Next.propTypes = {
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export { PaginationButton, Previous, Next };
