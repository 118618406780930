import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Label from 'common/components/Label';
import Icon from 'common/components/Icon';

import colors from 'common/styles/colors';
import styles from './index.module.css';

const EyeCrossed = <Icon.EyeCrossed fill={colors['rebranding-color-black']} />;
const Eye = <Icon.Eye fill={colors['rebranding-color-black']} />;

const PasswordLabel = ({ customLabel, input }) => {
  const [icon, setIcon] = useState(Eye);

  const toggle = () => {
    const ref = input;
    if (ref.current.type === 'password') {
      ref.current.type = 'text';
      setIcon(EyeCrossed);
    } else {
      ref.current.type = 'password';
      setIcon(Eye);
    }
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') toggle();
  };

  return (
    <div className={styles.root}>
      <Label>{customLabel || 'Password'}</Label>
      <span
        className={styles.iconWrapper}
        onClick={toggle}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex="0"
      >
        {icon}
      </span>
    </div>
  );
};

PasswordLabel.propTypes = {
  customLabel: PropTypes.string,
  input: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default PasswordLabel;
