import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Dropdown from 'common/components/Dropdown';
import Label from 'common/components/Label';
import Text from 'common/components/Text';

import { companyTypesOptions } from './constants';

import { allFields } from './Fields';

import styles from './index.module.css';

const Fields = ({ fields, companyType, missingFields }) =>
  fields.map((field) => {
    const Component = field.component;
    return (
      <Component
        companyType={companyType}
        key={field.name}
        missing={missingFields.includes(field.name)}
      />
    );
  });

Fields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      companyTypes: PropTypes.arrayOf(PropTypes.string),
      component: PropTypes.func,
    })
  ).isRequired,
  companyType: PropTypes.string.isRequired,
  missingFields: PropTypes.arrayOf(PropTypes.string),
};

const BusinessDocumentsForm = ({
  onSubmit,
  submitting,
  errorMessage,
  missingFields,
}) => {
  const [companyType, setCompanyType] = useState(null);
  const [fields, setFields] = useState([]);
  const handleSubmit = () => {
    onSubmit(companyType, fields);
  };

  useEffect(() => {
    setFields(allFields.filter((f) => f.companyTypes.includes(companyType)));
  }, [companyType]);

  return (
    <>
      <div className={styles.row}>
        <Label>Type of company</Label>
        <Dropdown
          id="companyType"
          name="companyType"
          placeholder="Choose your type of company"
          items={companyTypesOptions}
          onChange={(value) => {
            setCompanyType(value);
          }}
        />
      </div>
      {companyType && (
        <>
          <Fields
            fields={fields}
            companyType={companyType}
            missingFields={missingFields}
          />
        </>
      )}
      <span className={styles.formFooter}>
        <Text color="rebranding-md-color-red">
          {errorMessage
            ? errorMessage.split('\n').map((item) => (
                <React.Fragment key={item}>
                  {item}
                  <br />
                </React.Fragment>
              ))
            : ''}
        </Text>
        <div className={styles.buttonWrapper}>
          <Button
            name="submit-business-details-button"
            kind="primary"
            full
            disabled={!companyType}
            loading={submitting}
            onClick={handleSubmit}
          >
            <button
              type="submit"
              id="progress-status"
              data-test="submit-business-documents-button"
            >
              Submit
            </button>
          </Button>
        </div>
      </span>
    </>
  );
};

BusinessDocumentsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  missingFields: PropTypes.arrayOf(PropTypes.string),
};

export default BusinessDocumentsForm;
