import React from 'react';
import PropTypes from 'prop-types';

import DownloadButton from './DownloadButton';

const InvoiceDocuments = ({ list }) => {
  if (!list.length) return <span>—</span>;

  return (
    <>
      {list.map(({ id, name, url }) => (
        <DownloadButton key={id}>
          <a
            href={url}
            data-test="invoice-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </DownloadButton>
      ))}
    </>
  );
};

InvoiceDocuments.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      statementId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};
export default InvoiceDocuments;
