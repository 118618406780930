import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import LoadingPage from 'common/components/LoadingPage';
import { contractPropType } from 'common/propTypes';
import { useOnMountDataRequest } from 'common/hooks';

import { actions as errorActions } from 'error';
import { api } from 'app/services';
import { mapCountries } from 'onboarding/utils/signUpUtils';
import { settlements as settlementsSelector } from '../../../settlements/reducers';
import { actions as settlementsActions } from '../../../settlements';
import PayoutDestination from '../../components/PayoutDestination';
import ErrorPage from '../../components/ErrorPage';
import SettlementsSettingsForm from '../../components/SettlementsSettingsForm';
import actions from '../../actions';
import { getContract, getPayoutAccounts } from '../../reducers';
import { selectors as sessionSelectors } from '../../../session';

import styles from './index.module.css';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const SettlementsSettings = ({
  apiError,
  activeOrganizationId,
  bankInfo,
  contract,
  payoutAccounts,
  savePayoutAccounts,
  saveContract,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [countries, setCountries] = useState();

  const { isMounting } = useOnMountDataRequest(
    settlementsActions.settlementsRequest
  );

  const settlements = useSelector(settlementsSelector);

  const handleContractSubmit = async (alwaysPayout) => {
    const { error, response } = await api.submitContract(
      activeOrganizationId,
      alwaysPayout,
      ''
    );
    if (!error) {
      saveContract(response);
      return setErrorMessage('');
    }
    apiError(error);
    return setErrorMessage('updateError');
  };

  useEffect(() => {
    let isSubscribed = true;

    async function fetchContract() {
      const { error, response } = await api.fetchContract(activeOrganizationId);

      if (isSubscribed) {
        if (error) return setErrorMessage(error);

        saveContract(response);
      }
    }

    async function fetchCountries() {
      const { error, response } = await api.fetchBankCountries();

      if (isSubscribed) {
        if (!error) {
          setCountries(mapCountries(response.country));
        }
      }
    }

    async function fetchPayoutAccounts() {
      const { error, response } = await api.fetchPayoutAccounts(
        activeOrganizationId
      );

      if (isSubscribed) {
        if (error) return setErrorMessage(error);

        savePayoutAccounts(response);
      }
    }

    if (!contract) fetchContract();
    if (!payoutAccounts) fetchPayoutAccounts();
    if (!countries) fetchCountries();

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  if (errorMessage && errorMessage !== 'updateError') return <ErrorPage />;
  if (!contract) return <LoadingPage />;

  const ongoingSettlement = Object.values(settlements || {}).filter(
    (el) => el.status === 'accumulating'
  )[0];

  const getDayAfter = (date) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toISOString();
  };

  const ongoingSettlementEndDate =
    ongoingSettlement?.paidInvoices ||
    ongoingSettlement?.paidOrders ||
    ongoingSettlement?.refundedOrders
      ? getDayAfter(ongoingSettlement.periodEndsAt)
      : getDayAfter(new Date());

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className={styles.root}
    >
      {!isMounting && contract.payoutMinLimit > 0 && (
        <SettlementsSettingsForm
          contract={contract}
          error={errorMessage === 'updateError'}
          handleContractSubmit={handleContractSubmit}
        />
      )}
      <PayoutDestination
        settlementCurrency={contract?.settlementCurrency}
        nextSettlementCurrency={contract?.nextSettlementCurrency}
        accountInfo={bankInfo}
        countries={countries}
        payoutAccounts={payoutAccounts}
        ongoingSettlementEndDate={ongoingSettlementEndDate}
      />
    </motion.div>
  );
};

SettlementsSettings.propTypes = {
  activeOrganizationId: PropTypes.string.isRequired,
  bankInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  contract: contractPropType,
  companyOfficialName: PropTypes.string.isRequired,
  payoutAccounts: PropTypes.objectOf(contractPropType),
  savePayoutAccounts: PropTypes.func.isRequired,
  saveContract: PropTypes.func.isRequired,
  apiError: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const activeOrganization = sessionSelectors.activeOrganization(state);
    const companyOfficialName =
      sessionSelectors.activeStore(state)?.companyOfficialName;
    const contract = getContract(state);
    const payoutAccounts = getPayoutAccounts(state);

    return {
      activeOrganizationId: activeOrganization?.id,
      bankInfo: activeOrganization?.bankInfo,
      contract,
      companyOfficialName,
      payoutAccounts,
    };
  },
  {
    saveContract: actions.contractSuccess,
    savePayoutAccounts: actions.payoutAccountsSuccess,
    apiError: errorActions.apiError,
  }
)(SettlementsSettings);
