import React from 'react';
import { useSelector } from 'react-redux';

import { route as routePropType } from 'common/propTypes';
import { useOnMountDataRequest } from 'common/hooks';
import { selectors as authSelectors } from 'auth';
import {
  isFetchingReport as isFetchingReportSelector,
  report as reportSelector,
} from '../reducers';
import { activeStore as getActiveStore } from '../../session/reducers';
import actions from '../actions';
import Report from '../components/Report';
import { managePermission } from '../../payments/propTypes';

const useReportData = (id) => {
  const { isMounting } = useOnMountDataRequest(
    actions.singleSettlementRequest,
    id
  );
  const isFetchingReport = useSelector(isFetchingReportSelector);
  const report = useSelector(reportSelector);
  const activeStore = useSelector(getActiveStore);
  const isInvoicingEnabled =
    useSelector(authSelectors.getPermissions)?.invoicing === managePermission;
  const isOnrampEnabled =
    useSelector(authSelectors.getPermissions)?.onramp === managePermission;

  return {
    activeStore,
    isFetching: isMounting || isFetchingReport,
    isInvoicingEnabled,
    isOnrampEnabled,
    report,
  };
};

const ReportContainer = ({
  match: {
    params: { id },
  },
}) => {
  const {
    activeStore: { name, companyOfficialName },
    isFetching,
    isInvoicingEnabled,
    isOnrampEnabled,
    report,
  } = useReportData(id);

  if (isFetching) return null;

  return (
    <Report
      activeStoreName={name}
      companyOfficialName={companyOfficialName}
      isInvoicingEnabled={isInvoicingEnabled}
      isOnrampEnabled={isOnrampEnabled}
      data={report}
    />
  );
};

ReportContainer.propTypes = {
  ...routePropType,
};

export default ReportContainer;
