import { takeEvery, call, put, all } from 'redux-saga/effects';
import _ from 'lodash';

import constants, {
  failedToFetchChrome,
  failedToFetchFirefox,
  killSwitchStatusText,
} from 'common/constants';
import { actions as authActions } from 'auth';
import { actions as featuresActions } from 'features';

import actions from '../actions';

const errorMap = {
  [failedToFetchChrome]: actions.failedFetch,
  [failedToFetchFirefox]: actions.failedFetch,
  [killSwitchStatusText]: actions.failedFetch,
  // TODO: We only fetch the features once on app load.
  // So if we enable or disable the SSO feature, we need to refetch the features
  // to redirect user to appropriate login page
  // We can remove the featuresRequest once the SSO is table
  [constants.invalid_token]: [
    featuresActions.featuresRequest,
    authActions.signOut,
  ],
  [constants.signature_error]: [
    featuresActions.featuresRequest,
    authActions.signOut,
  ],
};

function* checkError({ payload: error }) {
  const actionsToTrigger = errorMap[error] || errorMap[_.get(error, 'message')];

  if (actionsToTrigger) {
    if (Array.isArray(actionsToTrigger)) {
      yield all(actionsToTrigger.map((action) => put(action())));
    } else {
      yield put(actionsToTrigger());
    }
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchApiError() {
  yield takeEvery(actions.apiError, checkError);
}

export default function* root() {
  yield call(watchApiError);
}
