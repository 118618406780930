import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { activeStore as activeStoreSelector } from 'session/reducers';
import { selectors as featuresSelectors } from 'features';
import { actions as errorActions } from 'error';
import Layout from 'common/containers/Layout';
import LoadingPage from 'common/components/LoadingPage';

import {
  storeCredentials,
  isFetching,
  isFetchingNewCredentials as isFetchingNewCredentialsSelector,
} from '../../reducers';
import actions from '../../actions';

import APIIntegration from '../../components/APIIntegration';
import IntegrationsTabs from '../../components/IntegrationsTabs';

const APIIntegrationsPage = ({
  activeStore,
  apiError,
  clearSecret,
  credentials,
  credentialsRequest,
  integrationType,
  isFetchingCredentials,
  loadingPage,
  newCredentialsRequest,
  setIntegrationType,
  isWebhooksActive,
}) => {
  const storeId = activeStore.id;

  useEffect(() => {
    credentialsRequest({ storeId });
  }, []);

  const handleGenerate = () => {
    newCredentialsRequest({ storeId });
  };

  return (
    <Layout heading="Integrations">
      {isWebhooksActive && <IntegrationsTabs />}
      {!loadingPage ? (
        <APIIntegration
          activeStoreId={activeStore?.id}
          apiError={apiError}
          codes={credentials}
          onGenerate={handleGenerate}
          isFetchingCredentials={isFetchingCredentials}
          storeIntegrationType={integrationType}
          setIntegrationType={setIntegrationType}
          clearSecret={clearSecret}
        />
      ) : (
        <LoadingPage />
      )}
    </Layout>
  );
};

APIIntegrationsPage.propTypes = {
  activeStore: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  apiError: PropTypes.func.isRequired,
  clearSecret: PropTypes.func.isRequired,
  credentials: PropTypes.shape({
    apiKey: PropTypes.string,
    secret: PropTypes.string,
  }).isRequired,
  credentialsRequest: PropTypes.func.isRequired,
  integrationType: PropTypes.string,
  isFetchingCredentials: PropTypes.bool.isRequired,
  isWebhooksActive: PropTypes.bool.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  newCredentialsRequest: PropTypes.func.isRequired,
  setIntegrationType: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { integrationType, ...credentials } = storeCredentials(state);
    return {
      activeStore: activeStoreSelector(state),
      integrationType,
      credentials,
      loadingPage: isFetching(state),
      isFetchingCredentials: isFetchingNewCredentialsSelector(state),
      isWebhooksActive: featuresSelectors.isWebhooksActive(state),
    };
  },
  {
    newCredentialsRequest: actions.newCredentialsRequest,
    apiError: errorActions.apiError,
    credentialsRequest: actions.credentialsRequest,
    setIntegrationType: actions.setIntegrationType,
    clearSecret: actions.clearCredentialsSecret,
  }
)(APIIntegrationsPage);
