import React from 'react';
import propTypes from 'prop-types';

import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import KybDocuments from '../../../containers/KybDocuments';

import styles from './index.module.css';

import {
  soleTraders,
  charitiesCommunityOrganisations,
  partnershipLimitedPartnerships,
  limitedLiabilityPartnerships,
  limitedCompanies,
  publicTradedCompanies,
  documentLabels,
} from '../constants';

const businessDocsHeading = ({ companyType }) => (
  <>
    {companyType === soleTraders && (
      <>
        <h3 className={styles.sectionTitle}>Business documents</h3>
        <Text color="rebranding-md-color-grey-60">
          Please provide the following documents:
        </Text>
        <Text>
          <ul className={styles.list}>
            <li className={styles.listItem}>Proof of business registration;</li>
            <li className={styles.listItem}>
              A business bank statement, dated within the last 3 months (should
              correspond to the one provided upon the account opening);
            </li>
            <li className={styles.listItem}>
              Proof of address, dated within the last 3 months (utility bill,
              bank statement, rental contract, etc);
            </li>
            <li className={styles.listItem}>
              Identity document, front and back (Passport, national ID card or
              driver license);
            </li>
            <li className={styles.listItem}>Selfie with identity document.</li>
          </ul>
        </Text>
      </>
    )}
    {companyType === partnershipLimitedPartnerships && (
      <>
        <Heading size="2">Provide one of the following documents</Heading>
        <br />
        <Text>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              An existing business bank statement (dated within the last 3
              months);
            </li>
            <li className={styles.listItem}>
              A utility bill (dated within the last 3 months);
            </li>
            <li className={styles.listItem}>
              An invoice from a supplier (dated within the last 3 months);
            </li>
            <li className={styles.listItem}>
              Tax Authority correspondence (dated within the last 12 months);
            </li>
            <li className={styles.listItem}>
              Business premises rates bill (dated within the last 12 months);
            </li>
          </ul>
        </Text>
        <Text color="rebranding-md-color-grey-70" size="caption">
          The document must contain at least the name of the business owner,
          business address and the trading name (if applicable).
        </Text>
      </>
    )}
    {companyType === charitiesCommunityOrganisations && (
      <>
        <Heading size="2">Provide the following documents</Heading>
        <br />
        <Text>
          {`Constitution documents which confirm what the organisation has been
        setup to do, who the appointed Board/Committee members are, who the Key
        Officials are (Chairperson/Treasurer/Secretary).`}
        </Text>
      </>
    )}
  </>
);

businessDocsHeading.propTypes = { companyType: propTypes.string.isRequired };

export const bDoc = ({ missing }) => (
  <div name="bDoc" className={styles.field}>
    <KybDocuments documentLabel={documentLabels.bDoc} missing={missing} />
  </div>
);

export const bCertificate = ({ companyType, missing }) => (
  <div name="bCertificate" className={styles.field}>
    <Heading size="2">
      Provide a certificate of Incorporation or equivalent
    </Heading>
    <br />
    {[limitedCompanies, publicTradedCompanies].includes(companyType) && (
      <>
        <Text size="caption" color="rebranding-md-color-grey-60">
          {`The document(s) should be clearly visible and should include at least the
        business name, tax ID, the business' legal address and beneficial
        owner(s) details.`}
        </Text>
        <br />
      </>
    )}
    <KybDocuments
      documentLabel={documentLabels.bCertificate}
      missing={missing}
    />
  </div>
);

export const allFields = [
  {
    name: 'businessDocsHeading',
    companyTypes: [
      charitiesCommunityOrganisations,
      partnershipLimitedPartnerships,
      soleTraders,
    ],
    component: businessDocsHeading,
  },
  {
    name: 'bDoc',
    companyTypes: [
      charitiesCommunityOrganisations,
      partnershipLimitedPartnerships,
      soleTraders,
    ],
    component: bDoc,
  },
  {
    name: 'bCertificate',
    companyTypes: [
      limitedCompanies,
      limitedLiabilityPartnerships,
      publicTradedCompanies,
    ],
    component: bCertificate,
  },
];

const missingPropTypes = {
  missing: propTypes.bool.isRequired,
};

const companyTypePropTypes = {
  missing: propTypes.bool.isRequired,
  companyType: propTypes.string.isRequired,
};

bDoc.propTypes = missingPropTypes;

bCertificate.propTypes = companyTypePropTypes;
