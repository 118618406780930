import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Icon from 'common/components/Icon';

const DownloadButton = ({ children }) => (
  <div>
    <Button kind="subtle" icon={<Icon.Download size="small" />} orderReverse>
      {children}
    </Button>
  </div>
);

DownloadButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DownloadButton;
