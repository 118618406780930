import PropTypes from 'prop-types';

import { amount as amountType } from 'common/propTypes';

export const settlementsTotals = PropTypes.shape({
  fee: PropTypes.string.isRequired,
  paid: PropTypes.string.isRequired,
  refunded: PropTypes.string.isRequired,
  settled: PropTypes.string.isRequired,
});

export const settlementPayment = PropTypes.shape({
  amount: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  confirmedAt: PropTypes.string,
  paidAt: PropTypes.string,
});

export const settlementPayoutBankDetails = PropTypes.shape({
  bankAccountNumber: PropTypes.string,
  iban: PropTypes.string,
  routingNumber: PropTypes.string,
  swiftCode: PropTypes.string,
});

export const feesProp = PropTypes.shape({
  utrustFeeMultiplier: PropTypes.string,
  utrustFeeTotal: PropTypes.string,
  utrustFeeVat: PropTypes.string,
  utrustFeeVatAmount: PropTypes.string,
  utrustPayoutExtraFee: PropTypes.string,
});

export const status = PropTypes.oneOf(['ready', 'accumulating', 'settled']);

export const settlement = PropTypes.shape({
  endsAt: PropTypes.string,
  fees: feesProp,
  paidInvoices: PropTypes.arrayOf(settlementPayment),
  paidOrders: PropTypes.arrayOf(settlementPayment),
  paidFiatBills: PropTypes.arrayOf(settlementPayment),
  payoutBankDetails: settlementPayoutBankDetails,
  periodEndsAt: PropTypes.string,
  refundedOrders: PropTypes.arrayOf(settlementPayment),
  regularPeriodEndsAt: PropTypes.string,
  settledOn: PropTypes.string,
  startsAt: PropTypes.string,
  status,
  timezone: PropTypes.string.isRefund,
  toBeSettledAmount: amountType,
  totalAmount: amountType,
  totalInvoicesAmount: amountType,
  totalOrdersAmount: amountType,
  totalFiatBillsAmount: amountType,
  totalRefundedAmount: amountType,
});

export default settlementsTotals;
