import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

import { actions as errorActions } from 'error';
import { actions as authActions } from 'auth';
import { passwordAlreadyAdded } from 'common/constants';
import { watch } from 'common/sagas';
import { api } from 'app/services';
import actions from '../actions';

const knowErrors = {
  [passwordAlreadyAdded]: 'Password for this account was already defined',
};

function* setPassword(values) {
  const { response, error } = yield call(api.setPassword, values);

  if (response) {
    const { token } = _.head(_.values(response.password)).attributes;

    yield call(api.storeItem, 'signInToken', token);
    yield all([
      put(authActions.signInSuccess({ token })),
      put(actions.setPasswordSuccess(response)),
    ]);
  } else {
    yield all([
      put(actions.setPasswordFailure(knowErrors[error] || error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* fetchDocuments() {
  const { response, error } = yield call(api.fetchKybDocuments);
  if (response) yield put(actions.kybDocumentsSuccess(response));
  else {
    yield all([
      put(actions.kybDocumentsFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* createDocument({ payload }) {
  const { response, error } = yield call(api.createKybDocument, payload);
  if (response) yield put(actions.kybCreateDocumentSuccess(response));
  else {
    yield all([
      put(actions.kybCreateDocumentFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* deleteDocument({ payload: { id } }) {
  const { response, error } = yield call(api.deleteKybDocument, id);
  if (response) yield put(actions.kybDeleteDocumentSuccess({ id }));
  else {
    yield all([
      put(actions.kybDeleteDocumentFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchSetPassword() {
  while (true) {
    const { payload } = yield take(actions.setPasswordRequest);

    yield call(setPassword, payload);
  }
}

function* watchCreateDocument() {
  yield takeEvery(actions.kybCreateDocumentRequest, createDocument);
}

function* watchDeleteDocument() {
  yield takeEvery(actions.kybDeleteDocumentRequest, deleteDocument);
}

export default function* root() {
  yield fork(watchSetPassword);
  yield fork(watchCreateDocument);
  yield fork(watchDeleteDocument);
  yield watch(actions.kybDocumentsRequest, fetchDocuments);
}
