import _ from 'lodash';

export default function normalizeS3Params(params) {
  return _.reduce(
    params,
    (acc, value, key) => {
      if (key === '__typename') return acc;

      if (key === 'successActionStatus') {
        acc[_.snakeCase(key)] = value;
      } else {
        acc[_.kebabCase(key)] = value;
      }

      return acc;
    },
    {}
  );
}
