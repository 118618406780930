import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Organization = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3001 5.40002H7.2001H8.1001H27.9001H28.8001H29.7001L32.4001 13.5C32.4001 15.1775 31.2528 16.587 29.7001 16.9866V27.9044L26.1045 31.5H9.89659L6.3001 27.9035V16.9866C4.74739 16.587 3.6001 15.1775 3.6001 13.5L6.3001 5.40002ZM8.1001 16.9866L8.1001 25.2V29.7H8.99685L9.0001 29.7H27.0001L27.0033 29.7H27.9001V28.8V25.2V16.9866C27.1925 16.8045 26.5691 16.4126 26.1001 15.8813C25.4404 16.6286 24.4753 17.1 23.4001 17.1C22.3249 17.1 21.3597 16.6286 20.7001 15.8813C20.0404 16.6286 19.0753 17.1 18.0001 17.1C16.9249 17.1 15.9597 16.6286 15.3001 15.8813C14.6404 16.6286 13.6753 17.1 12.6001 17.1C11.5249 17.1 10.5597 16.6286 9.9001 15.8813C9.43111 16.4126 8.8077 16.8045 8.1001 16.9866ZM5.4001 13.5L7.5001 7.20002H28.5001L30.6001 13.5C30.6001 14.4941 29.7942 15.3 28.8001 15.3C27.806 15.3 27.0001 14.4941 27.0001 13.5H25.2001C25.2001 14.4941 24.3942 15.3 23.4001 15.3C22.406 15.3 21.6001 14.4941 21.6001 13.5H19.8001C19.8001 14.4941 18.9942 15.3 18.0001 15.3C17.006 15.3 16.2001 14.4941 16.2001 13.5H14.4001C14.4001 14.4941 13.5942 15.3 12.6001 15.3C11.606 15.3 10.8001 14.4941 10.8001 13.5H9.0001C9.0001 14.4941 8.19421 15.3 7.2001 15.3C6.20598 15.3 5.4001 14.4941 5.4001 13.5Z"
      fill={fill}
    />
  </svg>
);

Organization.propTypes = {
  fill: PropTypes.string,
};

Organization.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Organization;
