import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import Button from 'common/components/Button';
import ExportInvoiceButton from '../ExportInvoiceButton';

import styles from './index.module.css';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const InvoiceActions = ({ duplicate, disabled }) => (
  <motion.div
    initial="hidden"
    animate="visible"
    variants={variants}
    className={styles.root}
  >
    <Button onClick={duplicate} kind={'secondary'} disabled={disabled}>
      <button type="button">Duplicate invoice</button>
    </Button>
    <ExportInvoiceButton />
  </motion.div>
);

InvoiceActions.propTypes = {
  duplicate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default InvoiceActions;
