import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { utcDateFormatter } from 'common/services/date';

import styles from './index.module.css';

/**
 * Can't change this component to function while Griddle doesn't support it
 * @extends PureComponent
 */
class Timestamp extends PureComponent {
  render() {
    const { value } = this.props;
    const displayUtcDate = utcDateFormatter(value);
    const dateTime = displayUtcDate('yyyy-MM-ddTHH:mm:ss');
    const hoursMinutes = displayUtcDate('HH:mm');
    const dayAndMonth = displayUtcDate('dd MMM');

    return (
      <time className={styles.date} dateTime={dateTime}>
        <span className={styles['date-day']}>{dayAndMonth}</span>
        <span>{hoursMinutes}</span>
      </time>
    );
  }
}

Timestamp.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Timestamp;
