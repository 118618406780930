import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import { webhookProptype } from 'integrations/propTypes';
import Card from '../Card';
import CodeBlock from '../CodeBlock';
import WebhookBasicDetails from '../WebhookBasicDetails';

import styles from './index.module.css';

const WebhookDetails = ({ data }) => {
  const basicDetails = {
    ..._.pick(data, ['id', 'status', 'attempts', 'url', 'updatedAt']),
    eventType: _.get(data, 'params.eventType'),
    entityReference: _.get(data, 'params.resource.reference'),
    orderUUID: _.get(data, 'metadata.orderUuid'),
    paymentUUID: _.get(data, 'params.resource.uuid'),
  };

  const errorKeys = Object.keys(data.errors);

  return (
    <>
      <br />
      <WebhookBasicDetails data={basicDetails} />
      <Card>
        <Heading level="3" size="3" weight="medium">
          Request
        </Heading>
        <br />
        <CodeBlock jsonObject={data.params} />
      </Card>
      <Card>
        <Heading level="3" size="3" weight="medium">
          Response
        </Heading>
        <br />
        {errorKeys &&
          errorKeys.map((attempt) => {
            const error = data.errors[attempt];
            return (
              <div key={attempt}>
                <Text weight="semiBold">
                  {`Attempt ${attempt}`}
                  {error.status && (
                    <span
                      className={styles.status}
                    >{`Status: ${error.status}`}</span>
                  )}
                </Text>
                <br />
                <CodeBlock
                  message={typeof error === 'string' ? error : error.body}
                />
                <br />
              </div>
            );
          })}
        {data.status === 'success' && (
          <div data-test="webhook-success">
            <Text weight="semiBold">
              {`Attempt ${errorKeys?.length + 1 || '1'}`}
              <span className={classNames(styles.status, styles.green)}>
                Status: 200
              </span>
            </Text>
            <br />
            <CodeBlock message={'success'} />
          </div>
        )}
      </Card>
    </>
  );
};

WebhookDetails.propTypes = {
  data: webhookProptype,
};

export default WebhookDetails;
