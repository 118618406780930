import { get, head, find, filter, includes } from 'lodash';
import fp from 'lodash/fp';
import { matchSorter } from 'match-sorter';

import Downshift from 'downshift';

const MAX_VISIBLE_ITEMS = 6;
const ITEM_HEIGHT = 44;
const ENTERED_STATE = 'entered';
const ALL_OPTION = { label: 'All', value: '__all' };
const NO_RESULTS_OPTION = { label: 'No results found.', value: '__noResults' };

const getMaxHeight = (numberOfItems, state) => {
  if (numberOfItems && state === ENTERED_STATE) {
    const visibleItems =
      numberOfItems > MAX_VISIBLE_ITEMS ? MAX_VISIBLE_ITEMS : numberOfItems;
    const borderSize = 1;
    return borderSize + ITEM_HEIGHT * visibleItems;
  }

  return 0;
};

const isAllOption = (value) => value === ALL_OPTION.value;

const stateReducer = (isMulti, isSearchable) => (state, changes) => {
  // this prevents the menu from being closed when the user
  // selects an item with a keyboard or mouse
  // <MultiDropdown only>

  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownSpaceButton:
      if (!isSearchable) return changes;

      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      };
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem: {
      if (!isMulti) return changes;

      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      };
    }
    default:
      return changes;
  }
};

const getMultiLabel = (selectedItem, placeholder) => {
  if (!selectedItem.length) return placeholder;
  if (selectedItem.length === 1) return get(head(selectedItem), 'label');
  return `${selectedItem.length} selected`;
};

const getLabel = (selectedItem, placeholder) =>
  get(selectedItem, 'label') || placeholder;

const selectedItemsMultiple = (items, selectedItem) =>
  filter(items, (i) => includes(selectedItem, i.value));
const selectedItemDropdownList = (items, selectedItem) =>
  find(items, { value: selectedItem });
const selectedItemDropdownGroup = (items, selectedItem) =>
  fp.pipe(
    fp.flatMap((group) => group.groupItems),
    fp.find({ value: selectedItem })
  )(items);

const selectedItemCallbacks = {
  multi: selectedItemsMultiple,
  dropdownList: selectedItemDropdownList,
  dropdownGroup: selectedItemDropdownGroup,
  searchableList: selectedItemDropdownList,
  searchableGroup: selectedItemDropdownGroup,
};

const itemToString = (item) => (item ? item.value : '');

const searchableListItems = (items, inputValue) => {
  const selectedItem = selectedItemCallbacks.searchableList(items, inputValue);
  const searchValue = selectedItem?.label || inputValue || '';

  return matchSorter(items, searchValue, {
    keys: ['label'],
  });
};

const searchableGroupItems = (items, inputValue) => {
  const selectedItem = selectedItemCallbacks.searchableGroup(items, inputValue);
  const searchValue = selectedItem?.label || inputValue || '';
  return items.reduce((result, { groupItems, ...groupAttr }) => {
    const matches = matchSorter(groupItems, searchValue, {
      keys: ['label'],
    });

    if (matches.length) result.push({ ...groupAttr, groupItems: matches });

    return result;
  }, []);
};

export {
  ITEM_HEIGHT,
  NO_RESULTS_OPTION,
  ALL_OPTION,
  getLabel,
  getMaxHeight,
  getMultiLabel,
  isAllOption,
  itemToString,
  selectedItemCallbacks,
  stateReducer,
  searchableListItems,
  searchableGroupItems,
};
