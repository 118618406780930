import React from 'react';
import { useSelector } from 'react-redux';

import { activeStore as getActiveStore } from '../../../session/reducers';
import Heading from '../Heading';

const StoreName = () => {
  const activeStore = useSelector(getActiveStore);

  if (!activeStore) return null;

  return (
    <Heading level="3" size="3" id="store-name">
      {activeStore.name}
    </Heading>
  );
};

export default StoreName;
