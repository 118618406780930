import _ from 'lodash';
import { format, parseISO, addMinutes, subMinutes } from 'date-fns';

//Set timeout uses a 32 bit int to store the delay 2 ^ (31 - 1)
const MAX_TIMEOUT_VALUE = 2147483647;
const now = () => new Date();

const pad = (value) => {
  const str = value.toString();
  return str.length < 2 ? `0${str}` : str;
};

const timeToEnd = (date) => {
  const distance = date - now();
  const days = pad(Math.floor(distance / (1000 * 60 * 60 * 24)));
  const hours = pad(
    Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );
  const minutes = pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = pad(Math.floor((distance % (1000 * 60)) / 1000));

  return { days, hours, minutes, seconds };
};

const timeToEndMilliseconds = (date) => {
  const toEnd = date.getTime() - now().getTime();

  return toEnd < 0 ? 0 : toEnd;
};

const isExpired = (remainingTime) =>
  _.some(remainingTime, (value) => parseInt(value, 10) < 0);

const isSameDay = (start, end) => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  return (
    startDate.getUTCDate() === endDate.getUTCDate() &&
    startDate.getUTCMonth() === endDate.getUTCMonth()
  );
};

const getTimeoutValue = (timeInMilliseconds) =>
  timeInMilliseconds > MAX_TIMEOUT_VALUE
    ? MAX_TIMEOUT_VALUE
    : timeInMilliseconds;

const formatDate = (stringDate, dateFormat) =>
  format(parseISO(stringDate), dateFormat);

const toUTC = (date) => {
  const offset = date.getTimezoneOffset();

  return Math.sign(offset) !== -1
    ? addMinutes(date, offset)
    : subMinutes(date, Math.abs(offset));
};

const displayUtcDate = (stringDate, dateFormat) => {
  const date = parseISO(stringDate);
  const utcDate = toUTC(date);

  return format(utcDate, dateFormat);
};

const getNextDay = (date) => {
  const utcDateObj = parseISO(date);
  return addMinutes(utcDateObj, 1440).toISOString();
};

const utcDateFormatter = (stringDate) => (dateFormat) =>
  displayUtcDate(stringDate, dateFormat);

const dateFormatter = (stringDate) => (dateFormat) =>
  formatDate(stringDate, dateFormat);

export {
  toUTC,
  timeToEnd,
  timeToEndMilliseconds,
  isExpired,
  isSameDay,
  getNextDay,
  getTimeoutValue,
  formatDate,
  dateFormatter,
  utcDateFormatter,
  displayUtcDate,
};
