import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import S3PostUploader from '@utrustdev/react-s3-post-uploader';

import Text from 'common/components/Text';
import Icon from 'common/components/Icon';

import styles from './index.module.css';

const Uploader = ({
  getCredentials,
  onUploadStart,
  onUploadProgress,
  onUploadFinish,
  onUploadError,
  missing,
}) => {
  const [inputRef, setInputRef] = useState();
  const setInputRefCallback = useCallback((input) => {
    setInputRef(input);
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    inputRef.click();
  };

  return (
    <div id="upploader">
      <S3PostUploader
        onStart={onUploadStart}
        onProgress={onUploadProgress}
        onFinish={onUploadFinish}
        onError={onUploadError}
        getCredentials={getCredentials}
        inputRef={setInputRefCallback}
      />
      <button
        data-test="upload-button"
        type="button"
        onClick={handleClick}
        className={classNames(styles.root, { [styles.missing]: missing })}
      >
        <Icon.PlusBubble description="Add you file here" size="large" />
        <Text color="rebranding-md-color-grey-70">Click to upload</Text>
        <Text size="caption" color="rebranding-md-color-grey-50">
          Supports: pdf, doc, jpg and png (5MB max size)
        </Text>
      </button>
    </div>
  );
};

Uploader.propTypes = {
  getCredentials: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func,
  onUploadProgress: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  onUploadError: PropTypes.func.isRequired,
  missing: PropTypes.bool.isRequired,
};

Uploader.defaultProps = {
  onUploadStart: () => {},
};

export default Uploader;
