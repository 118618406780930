import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TableHeader from './TableHeader';
import Total from './Total';
import PaymentRow from './PaymentRow';
import EmptyRow from './EmptyRow';

import styles from './index.module.css';

const ReportTable = ({
  title,
  id,
  headers,
  data,
  total,
  totalReference,
  totalTitle,
  emptyMessage,
  shouldShowReferenceCurrency,
  referenceCurrency,
  settlementCurrency,
  rowComponent: RowComponent,
}) => {
  const totals = total
    ? [{ currency: settlementCurrency, amount: total.value }]
    : [];

  if (shouldShowReferenceCurrency) {
    totals.unshift({
      currency: referenceCurrency,
      amount: totalReference,
    });
  }
  return (
    <>
      <h2 className={styles.heading}>{title}</h2>
      <table
        id={title}
        className={styles.table}
        data-test={`report-table-${id}`}
      >
        <tbody>
          <TableHeader headers={headers} />
          {data.length ? (
            _.map(data, (element, index) => (
              <RowComponent
                data={element}
                index={index}
                key={index}
                shouldShowReferenceCurrency={shouldShowReferenceCurrency}
                referenceCurrency={referenceCurrency}
                settlementCurrency={settlementCurrency}
              />
            ))
          ) : (
            <EmptyRow message={emptyMessage} />
          )}
        </tbody>
      </table>
      {total && <Total title={totalTitle} totals={totals} />}
    </>
  );
};

ReportTable.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      createdAt: PropTypes.string,
      reference: PropTypes.string,
      utrustFeeMultiplier: PropTypes.string,
      utrustFeeTotal: PropTypes.string,
      utrustFeeVat: PropTypes.string,
      utrustFeeVatAmount: PropTypes.string,
      totalPaid: PropTypes.string,
    })
  ),
  total: PropTypes.shape({
    value: PropTypes.string.isRequired,
    currency: PropTypes.string,
  }),
  totalReference: PropTypes.string,
  totalTitle: PropTypes.string,
  shouldShowReferenceCurrency: PropTypes.bool.isRequired,
  referenceCurrency: PropTypes.string,
  settlementCurrency: PropTypes.string,
  emptyMessage: PropTypes.string.isRequired,
  rowComponent: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      justify: PropTypes.string,
    })
  ).isRequired,
};

ReportTable.defaultProps = {
  data: null,
  total: null,
  totalTitle: null,
};

export default ReportTable;

export { PaymentRow };
