import React from 'react';
import PropTypes from 'prop-types';
import {
  emailConfirmedIntegration,
} from 'common/constants';

import ProgressStep, {
  stepCompleted,
  stepCurrent,
  stepIdle,
} from 'common/components/ProgressStep';

import styles from './index.module.css';

const statusesMap = {
  [emailConfirmedIntegration]: [stepCompleted, stepCurrent, stepIdle],
};

const OnboardingSteps = ({ integrationStatus }) => (
  <div className={styles.root}>
    <ProgressStep
      status={statusesMap[integrationStatus][0]}
      text="Confirm your email"
    />
    <ProgressStep
      status={statusesMap[integrationStatus][1]}
      text="Complete your registration"
    />
  </div>
);

OnboardingSteps.propTypes = {
  integrationStatus: PropTypes.oneOf([emailConfirmedIntegration]).isRequired,
};

export default OnboardingSteps;
