import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';

import Button from 'common/components/Button';
import PasswordLabel from 'common/components/PasswordLabel';
import Text from 'common/components/Text';
import TextBox from 'common/components/TextBox';
import formUtils, { SIMPLE_PASSWORD_MESSAGE } from 'common/formUtils.js';

import styles from '../index.module.css';

const Form = ({ onPasswordSubmit, passwordInitialValues, errorMessage }) => {
  const currentField = useRef(null);
  const newField = useRef(null);

  const handleFormSubmit = async (
    values,
    { setSubmitting, setFieldTouched }
  ) => {
    setSubmitting(true);
    const error = await onPasswordSubmit(values);

    if (error) {
      setSubmitting(false);
      setFieldTouched('currentPassword', false, false);
    }
  };

  return (
    <Formik initialValues={passwordInitialValues} onSubmit={handleFormSubmit}>
      {({ errors, touched, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.grid}>
            <Field name="currentPassword" validate={formUtils.validateRequired}>
              {({ field, meta }) => (
                <div>
                  {currentField && (
                    <PasswordLabel customLabel="Current" input={currentField} />
                  )}
                  <TextBox
                    id="current-password"
                    name="current-password"
                    type="password"
                    labelType="password"
                    placeholder="Your current password"
                    autoComplete="current-password"
                    error={
                      (meta.touched && meta.error) ||
                      (!meta.touched && errorMessage)
                    }
                    errorText={meta.error || errorMessage}
                    inputRef={currentField}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="newPassword" validate={formUtils.validatePassword}>
              {({ field, meta }) => (
                <div>
                  {newField && (
                    <PasswordLabel customLabel="New" input={newField} />
                  )}
                  <TextBox
                    id="new-password"
                    name="new-password"
                    type="password"
                    labelType="password"
                    placeholder="Your new password"
                    autoComplete="new-password"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    inputRef={newField}
                    {...field}
                  />
                  {!(meta.error && meta.touched) && (
                    <div className={styles.passwordHelper}>
                      <Text size="caption" color="rebranding-md-color-grey-60">
                        {SIMPLE_PASSWORD_MESSAGE}
                      </Text>
                    </div>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              full
              kind="primary"
              loading={isSubmitting}
              disabled={formUtils.isSubmitDisabled(errors, touched)}
            >
              <button
                type="submit"
                id="password-submit"
                data-test="update-password-button"
              >
                Save
              </button>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  passwordInitialValues: PropTypes.shape({
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
  }),
  onPasswordSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

Form.defaultProps = {
  passwordInitialValues: {
    currentPassword: '',
    newPassword: '',
  },
};

export default Form;
