import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import colors from 'common/styles/colors';
import Heading from '../Heading';
import Sidebar from '../../containers/Sidebar';
import StoreName from './StoreName';

import styles from './index.module.css';
import ToolTip from '../ToolTip';
import Icon from '../Icon';
import { Text } from '../Text';

const Layout = ({
  heading,
  children,
  sideAction,
  sideContent,
  hasLimitedAccess,
}) => {
  const env = process.env.REACT_APP_ENV || 'local';

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    return () => window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <div className={styles.layout} id="layout">
      <aside className={styles.sidebar}>
        <Sidebar />
      </aside>
      <article
        className={classNames(styles.content, {
          [styles['content-production']]: env === 'production',
        })}
        data-test="layout-content"
      >
        <div className={styles.header}>
          <div className={styles.sideAction}>{sideAction}</div>
          {heading && (
            <div className={styles.headerContent}>
              <div className={styles.storeInfoWrapper}>
                <div className={styles.storeName} data-test="storeName">
                  <StoreName />
                </div>
                {hasLimitedAccess && (
                  <div className={styles.accountInfo}>
                    <div className={styles.accountBadge}>
                      <Text size="caption" color="white" weight="semiBold">
                        Limited access
                      </Text>
                    </div>
                    <ToolTip
                      message={
                        <>
                          We’ve limited your access to certain features. Our
                          team will reach out, but if you have not been
                          contacted yet, you can email us at{' '}
                          <a href="mailto:support@xmoney.com">
                            support@xmoney.com
                          </a>
                        </>
                      }
                    >
                      <Icon.Info
                        description="What is minimum payout?"
                        size="medium"
                        fill={colors['rebranding-md-color-brand-purple']}
                        circleStroke={
                          colors['rebranding-md-color-brand-purple']
                        }
                      />
                    </ToolTip>
                  </div>
                )}
              </div>
              <Heading level="1" size="1" weight="medium" color="blue">
                {heading}
              </Heading>
            </div>
          )}
          {sideContent && (
            <div className={styles.sideContent}>{sideContent}</div>
          )}
        </div>
        <div className={styles.main}>{children}</div>
      </article>
    </div>
  );
};

export const propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  sideAction: PropTypes.element,
  sideContent: PropTypes.node,
  hasLimitedAccess: PropTypes.bool,
};

Layout.propTypes = propTypes;

Layout.defaultProps = {
  heading: null,
};

export default Layout;
