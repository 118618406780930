import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Pagination from 'common/components/Pagination';
import PaginationHelper from 'common/components/Pagination/PaginationHelper';
import HeaderCell from 'common/components/HeaderCell';
import { webhookProptype } from 'integrations/propTypes';

import WebhooksEmptyTable from '../WebhooksEmptyTable';
import WebhooksTableRow from '../WebhooksTableRow';

import styles from './index.module.css';

const WebhooksTable = ({ webhooks, paginationData }) => (
  <div className={styles.root}>
    {Object.keys(webhooks).length > 0 ? (
      <>
        <table
          id="webhooks"
          className={styles.table}
          data-test="webhooks-table"
        >
          <tbody>
            <tr className={styles.header}>
              <HeaderCell content="Event type" />
              <HeaderCell content="Event ID" />
              <HeaderCell content="Entity reference" />
              <HeaderCell align="right" content="Updated at" />
              <HeaderCell align="right" content="Status" />
            </tr>
            {_.map(webhooks, (webhook) => (
              <WebhooksTableRow key={webhook.id} webhook={webhook} />
            ))}
          </tbody>
        </table>
        <div className={styles.paginationWrapper}>
          <PaginationHelper
            className={styles.paginationHelper}
            data={paginationData}
          />
          <Pagination
            className={styles.pagination}
            currentPage={paginationData.page}
            setPage={paginationData.setPage}
            totalPages={Math.ceil(
              paginationData.totalElements / paginationData.pageSize
            )}
          />
        </div>
      </>
    ) : (
      <WebhooksEmptyTable />
    )}
  </div>
);

WebhooksTable.propTypes = {
  webhooks: PropTypes.arrayOf(webhookProptype),
  paginationData: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    setPageSize: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
};

export default WebhooksTable;
