import React from 'react';

import Tabs, { Tab } from 'common/components/Tabs';
import { apiIntegrationsRoute, webhooksRoute } from 'app/containers/routes.js';

const IntegrationsTabs = () => (
  <Tabs dataTest="IntegrationsTabs">
    <Tab to={apiIntegrationsRoute}>API Keys</Tab>
    <Tab to={webhooksRoute}>Webhooks</Tab>
  </Tabs>
);

IntegrationsTabs.propTypes = {};

export default IntegrationsTabs;
