import React from 'react';
import propTypes from 'prop-types';

import styles from './index.module.css';

const Layout = ({ form, description, error }) => (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.description}>{description}</div>
        <div className={styles.column}>
          <div className={styles.form}>{form}</div>
          {error && <div className={styles.formError}>{error}</div>}
        </div>
      </div>
    </div>
  );

Layout.propTypes = {
  form: propTypes.node.isRequired,
  description: propTypes.node.isRequired,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
};

export default Layout;
