const vatRequiredCountries = [
  // Germany
  'DEU',
  // Malta
  'MLT',
  // Denmark
  'DNK',
  // Poland
  'POL',
  //// Netherland
  'NLD',
  // Belgium
  'BEL',
  // Lithuania
  'LTU',
  // Latvia
  'LVA',
  // Cyprus
  'CYP',
  // Hungary
  'HUN',
  // France
  'FRA',
  // Finland
  'FIN',
  // Ireland
  'IRL',
  // Bulgaria
  'BGR',
  // Greece
  'GRC',
  // Sweden
  'SWE',
  // Italy
  'ITA',
  // Czech Republic
  'CZE',
  // Romania
  'ROU',
  // Spain
  'ESP',
  // Luxembourg
  'LUX',
  // Austria
  'AUT',
  // Slovakia
  'SVK',
  // Slovenia
  'SVN',
  // Estonia
  'EST',
  // Croatia
  'HRV',
  // Portugal
  'PRT',
];

export const onboardingDisabledCountries = [
  'FRA', // France
  'GUF', // French Guyana
  'PYF', // French Polynesia
  'ATF', // French Southern Territories
  'GLP', // Guadeloupe
  'MTQ', // Martinique
  'MYT', // Mayotte
  'NCL', // New Caledonia
  'REU', // Réunion
  'MAF', // Saint Martin (French part)
  'BLM', // Saint Barthélemy
  'SPM', // Saint Pierre and Miquelon
  'WLF', // Wallis and Futuna
];

const requiresVat = (country) => vatRequiredCountries.includes(country);

const getCountryLabel = (countryList, countryCode) => {
  const countryObject = countryList.find((obj) => obj.value === countryCode);
  if (countryObject) {
    return countryObject.label;
  }

  return countryCode;
};

export { getCountryLabel, requiresVat };
