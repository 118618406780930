import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import MerchantPurposeOption from '../MerchantPurposeOption';

import styles from './index.module.css';

const MerchantPurposeForm = ({ onChange, selected }) => {
  const handleClick = (id) => (e) => {
    e.preventDefault();
    onChange(id);
  };

  return (
    <div>
      <Heading level="3" size="3" id="merchant-purpose">
        How are you planning to use xMoney?
      </Heading>
      <br />
      <Text>
        The safest, fastest and cheapest way to pay and get paid globally is
        here for you. Feel free to select multiple options.
      </Text>
      <br />
      <MerchantPurposeOption
        active={selected.includes('orders')}
        onClick={handleClick('orders')}
        icon="store"
        id="orders"
        heading="I have an online store(s)"
        description="The perfect crypto payments checkout solution for your customers. Account creation and integration are simple and quick with all major ecommerce platforms."
      />
      <br />
      <MerchantPurposeOption
        active={selected.includes('invoicing')}
        onClick={handleClick('invoicing')}
        icon="invoice"
        id="invoicing"
        heading="I’d like to request payments"
        description="Issue invoices directly from your dashboard via email or direct link. Easy as it should be."
      />
      <div className={styles.optionSeparator}>
        <Text color="rebranding-md-color-grey-60">OR</Text>
      </div>
      <MerchantPurposeOption
        active={selected.includes('unsure')}
        onClick={handleClick('unsure')}
        icon="questionCircle"
        id="unsure"
        heading="I’m not sure yet"
        description="Please feel free to get in touch with our team via the chat option on your dashboard and let’s chat. We are more than happy to help and guide you."
      />
    </div>
  );
};

MerchantPurposeForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MerchantPurposeForm;
