import jwtDecode from 'jwt-decode';
import { chain } from 'lodash';

import segment from './segment';
import loadIntercom from './intercom';

export function startAnalytics() {
  if (process.env.REACT_APP_SEGMENT_API_KEY) {
    segment(process.env.REACT_APP_SEGMENT_API_KEY);
  }
}

export const startIntercom = (email, userHash) => {
  if (!process.env.REACT_APP_INTERCOM_APP_ID) {
    return;
  }

  loadIntercom();

  if (!window.Intercom) {
    return;
  }

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    email,
    user_hash: userHash,
  });
};

function rebootIntercom() {
  if (window.Intercom) {
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_WORKSPACE_ID,
    });
  }
}

export function identifyByToken(token) {
  if (window.analytics) {
    const userId = chain(jwtDecode(token).sub).split(':').last().value();

    window.analytics.identify(userId);
    rebootIntercom();
  }
}

export function track(event, properties) {
  if (window.analytics) {
    window.analytics.track(event, properties);
  }
}

export function page(name, properties) {
  if (window.analytics) {
    window.analytics.page(name, properties);
  }
}

export function reset() {
  if (window.analytics) {
    window.analytics.reset();
  }
  rebootIntercom();
}

export function stopAnalytics() {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
  if (window.analytics) {
    window.analytics.reset();
    window.analytics = null;
  }
}
