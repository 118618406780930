import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { fiatValueWithCurrency } from '../../currencies';
import { amount } from '../../propTypes';
import { discountName } from '../../constants';
import Text from '../Text';

import styles from './index.module.css';

const Fee = ({ description, fee: { value, currency } }) => (
  <li className={styles.lineItem} data-test={description}>
    <Text color="rebranding-md-color-grey-60">{description}</Text>
    <Text color="rebranding-md-color-grey-60">
      {description === discountName ? '- ' : null}
      {fiatValueWithCurrency(value, currency)}
    </Text>
  </li>
);

Fee.propTypes = {
  description: PropTypes.string.isRequired,
  fee: amount.isRequired,
};
export default memo(Fee);
