import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';

import colors from 'common/styles/colors';
import { contractPropType } from 'common/propTypes';
import { settlement as settlementPropType } from 'settlements/propTypes';

import Icon from 'common/components/Icon';
import Text from 'common/components/Text';
import ToolTip from 'common/components/ToolTip';
import { selectors as featuresSelectors } from '../../../features';

import styles from './index.module.css';

const periodMap = {
  weekly: 'Every monday',
  monthly: 'Every 1st day of the month',
  daily: 'Every day',
};

const payoutPeriodCopy = (period) => periodMap[period] || capitalize(period);

const PayoutInfo = ({ contract, isPayoutLimitsActive, ongoingSettlement }) => {
  if (!contract) return null;

  const referenceToBeSettledAmount =
    ongoingSettlement?.referenceToBeSettledAmount || '0';

  const { alwaysPayout, payoutMinLimit, payoutPeriod, referenceFiatCurrency } =
    contract;

  const period = payoutPeriodCopy(payoutPeriod);
  const alwaysPayoutToolTipCopy = alwaysPayout
    ? `regardless of the accumulated ${referenceFiatCurrency}. If that value is below ${payoutMinLimit} ${referenceFiatCurrency}, you will incur an extra fee.`
    : `if your accumulated ${referenceFiatCurrency} is greater than ${payoutMinLimit} ${referenceFiatCurrency}. If it is below that amount, we will keep accumulating payments until the next payout date.`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.fieldRow}>
        {isPayoutLimitsActive && payoutMinLimit > 0 && (
          <div className={styles.field}>
            <Text size="caption" color="rebranding-md-color-grey-60">
              {`Accumulated ${referenceFiatCurrency}`}
            </Text>
            <div className={styles.info} data-test="minimum-payout">
              <Text>{`${referenceToBeSettledAmount}/${payoutMinLimit} ${referenceFiatCurrency}`}</Text>
              <ToolTip
                message={
                  <>
                    This value is a sum of all your accumulated payments after
                    calculations. For a more detailed view, you can check the
                    settlement report.
                  </>
                }
              >
                <Icon.Info
                  description="What is minimum payout?"
                  size="medium"
                  fill={colors['rebranding-md-color-brand-purple']}
                  circleStroke={colors['rebranding-md-color-brand-purple']}
                />
              </ToolTip>
            </div>
          </div>
        )}

        {isPayoutLimitsActive && payoutMinLimit > 0 && (
          <div className={styles.field}>
            <Text size="caption" color="rebranding-md-color-grey-60">
              Always payout
            </Text>
            <div className={styles.info}>
              <Text>{alwaysPayout ? 'Enabled' : 'Disabled'}</Text>
              <ToolTip
                message={
                  <>
                    {period} we will do a payout {`${alwaysPayoutToolTipCopy}`}{' '}
                    To change this behaviour go to the{' '}
                    <Link to="/settings/settlements">
                      Settlements tab in your Settings
                    </Link>
                    .
                  </>
                }
              >
                <Icon.Info
                  description="What is minimum payout?"
                  size="medium"
                  fill={colors['rebranding-md-color-brand-purple']}
                  circleStroke={colors['rebranding-md-color-brand-purple']}
                />
              </ToolTip>
            </div>
          </div>
        )}
      </div>
      <div className={styles.fieldRow}>
        <div className={styles.field} data-test="periodicity-value">
          <Text size="caption" color="rebranding-md-color-grey-60">
            Periodicity
          </Text>
          <Text>{period}</Text>
        </div>
      </div>
    </div>
  );
};

PayoutInfo.propTypes = {
  contract: contractPropType,
  ongoingSettlement: settlementPropType,
  isPayoutLimitsActive: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isPayoutLimitsActive: featuresSelectors.isPayoutLimitsActive(state),
}))(PayoutInfo);
