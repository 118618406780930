import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const CrossThin = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    {...rest}
  >
    <rect
      x="6.01807"
      y="28.6455"
      width="32"
      height="1.89"
      transform="rotate(-45 6.01807 28.6455)"
      fill={fill}
    />
    <rect
      width="32"
      height="1.89"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 29.9814 28.6445)"
      fill={fill}
    />
  </svg>
);

CrossThin.propTypes = {
  fill: PropTypes.string,
};

CrossThin.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default CrossThin;
