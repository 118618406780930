import React from 'react';
import PropTypes from 'prop-types';

import { countriesPropType } from 'common/propTypes';
import InvoiceDetails, { invoiceDetailsProptypes } from '../InvoiceDetails';

import styles from './index.module.css';

const Invoice = ({
  cancel,
  countries,
  duplicate,
  error,
  invoiceDuplicateDisabled,
  invoice: {
    createdAt,
    customer,
    description,
    reference,
    status,
    totalAmount,
    settlementAmount,
    referenceAmount,
    paymentUrl,
    sendIssuedEmail,
  },
}) => (
  <div className={styles.root}>
    <InvoiceDetails
      cancel={cancel}
      countries={countries}
      createdAt={createdAt}
      customer={customer}
      description={description}
      duplicate={duplicate}
      error={error}
      reference={reference}
      referenceAmount={referenceAmount}
      settlementAmount={settlementAmount}
      status={status}
      totalAmount={totalAmount}
      paymentUrl={paymentUrl}
      sendIssuedEmail={sendIssuedEmail}
      invoiceDuplicateDisabled={invoiceDuplicateDisabled}
    />
  </div>
);

Invoice.propTypes = {
  invoiceDuplicateDisabled: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  duplicate: PropTypes.func.isRequired,
  countries: countriesPropType,
  error: PropTypes.string,
  invoice: PropTypes.shape(invoiceDetailsProptypes).isRequired,
};

export default Invoice;
