import React from 'react';
import PropTypes from 'prop-types';

const Lock = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 68 72"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.223 20.4277H49.2212C49.2212 11.6267 42.0866 4.49202 33.2855 4.49202C24.4845 4.49202 17.3498 11.6267 17.3498 20.4277H17.348L17.348 26.142H49.223V20.4277ZM13.2855 20.4277C13.2855 9.38204 22.2398 0.427734 33.2855 0.427734C44.3312 0.427734 53.2855 9.38204 53.2855 20.4277V26.142H59.158L63.2205 30.2045H7.21436C5.86816 30.2045 4.77686 31.2958 4.77686 32.642V65.0706C4.77686 66.4168 5.86816 67.5081 7.21435 67.5081H63.2233V71.5706H7.21435C3.6245 71.5706 0.714355 68.6604 0.714355 65.0706V32.642C0.714355 29.0522 3.62451 26.142 7.21436 26.142H13.2855V20.4277ZM63.2233 67.5081V30.2073L67.2858 34.2698V67.5081H63.2233ZM36.1426 46.1423C37.0101 45.4907 37.5712 44.4533 37.5712 43.2849C37.5712 41.3124 35.9722 39.7134 33.9998 39.7134C32.0273 39.7134 30.4284 41.3124 30.4284 43.2849C30.4284 44.4533 30.9895 45.4907 31.8569 46.1423V58.2849H36.1426V46.1423Z"
      fill={fill}
    />
  </svg>
);

Lock.propTypes = {
  fill: PropTypes.string,
};

Lock.defaultProps = {
  fill: 'none',
};

export default Lock;
