import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';

import styles from './index.module.css';

const ViewProperty = ({ label, value }) => (
    <div className={styles.root} data-test={label}>
      <h4 className={styles.header}>{label}</h4>
      <Text>{value}</Text>
    </div>
  );

ViewProperty.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
};

export default memo(ViewProperty);
