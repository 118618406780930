import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Play = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M6.91406 6.52376L10.5111 4.44702L31.2139 16.3998V18.4782L8.71406 5.48798V31.4687L31.2139 18.4785V20.5569L10.5101 32.5102L6.91406 30.4341V6.52376Z" />
  </svg>
);

Play.propTypes = {
  fill: PropTypes.string,
};

Play.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Play;
