import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const Label = ({ disabled, children, labelType, for: htmlFor }) => {
  const classes = classNames(
    styles.root,
    { [styles.disabled]: disabled },
    styles[labelType]
  );

  return (
    <label className={classes} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelType: PropTypes.oneOf(['main', 'optional']),
  for: PropTypes.string,
  disabled: PropTypes.bool,
};

Label.defaultProps = {
  disabled: false,
  for: null,
  labelType: 'main',
};

export default Label;
