import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import FormattedDate from 'common/components/FormattedDate';
import Heading from 'common/components/Heading';
import StatusTag from 'common/components/StatusTag';
import ViewProperty from 'common/components/ViewProperty';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import MultiLineField from 'common/components/MultiLineField';
import AmountStrip from 'common/components/AmountStrip';
import { getCountryLabel } from 'common/countryHelpers';
import { countriesPropType } from 'common/propTypes';
import { invoicePropType } from 'payments/propTypes';

import styles from './index.module.css';

const InvoiceReport = ({
  businessInfo: {
    companyOfficialName,
    country: merchantCountry,
    registeredAddress,
    vatNumber,
  },
  merchantCountries,
  buyerCountries,
  invoice: {
    createdAt,
    customer: {
      name,
      email,
      billingAddress,
      postCode,
      city,
      state,
      country: customerCountry,
    },
    description,
    reference,
    status,
    totalAmount,
    paymentUrl,
  },
}) => {
  useEffect(() => window.print(), []);

  return (
    <div className={styles.root}>
      <Heading level="3" size="3" weight="semiBold">
        Invoice
      </Heading>
      <Text weight="semiBold">{companyOfficialName}</Text>
      <br />
      <Text>{registeredAddress}</Text>
      <Text>{getCountryLabel(merchantCountries, merchantCountry)}</Text>
      <br />
      <Text>VAT: {vatNumber}</Text>
      <div className={styles.reference}>
        <Text>Reference: {reference}</Text>
      </div>
      <div
        data-test={`invoice-details-order-status-${status}`}
        className={styles.status}
      >
        <StatusTag status={status} paymentType="invoices" />
      </div>
      <div className={styles.details}>
        <ViewProperty label="Customer name" value={name} />
        <MultiLineField
          label="Billing address"
          values={[
            billingAddress,
            `${postCode} ${city}`,
            `${state ? `${state}, ` : ''}${getCountryLabel(
              buyerCountries,
              customerCountry
            )}`,
          ]}
        />
        <ViewProperty label="Customer email" value={email} />
      </div>
      <Text color="rebranding-md-color-grey-60">
        <FormattedDate value={createdAt} />
      </Text>
      <br />
      <ViewProperty label="Description " value={description || '-'} />
      <AmountStrip heading="Total amount" amount={totalAmount} id="total" />
      <br />
      {status === 'pending' && (
        <>
          <Text weight="semiBold" size="caption">
            Payment link
          </Text>
          <div className={styles.url}>
            <Button kind="subtle">
              <a
                href={paymentUrl}
                target="_blank"
                rel="noopener noreferrer"
                data-test="invoice-payment-url"
              >
                {paymentUrl}
              </a>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

InvoiceReport.propTypes = {
  businessInfo: PropTypes.shape({
    companyOfficialName: PropTypes.string,
    country: PropTypes.string,
    registeredAddress: PropTypes.string,
  }).isRequired,
  merchantCountries: countriesPropType,
  buyerCountries: countriesPropType,
  invoice: invoicePropType.isRequired,
};

export default InvoiceReport;
