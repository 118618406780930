import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Settlements = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 36 36"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45005 4.04999H1.80005V5.93999H7.65005L7.65005 28.2846L9.45005 30.0846L9.45005 5.93999H26.5501V6.74999V9.44999V29.25C26.5501 29.747 26.1471 30.15 25.6501 30.15L9.51543 30.15L11.3154 31.95H26.5501C27.5442 31.95 28.3501 31.1441 28.3501 30.15L28.35 5.93999H34.2001V4.04999H26.5501H9.45005ZM18.2157 12.8882C20.1237 12.8882 21.6537 13.6982 21.7617 15.7142H23.3457V15.4442C23.1297 13.2662 21.5637 11.8622 18.9717 11.6282V9.6662H17.5497V11.6102C14.8677 11.8082 13.2657 13.1762 13.2657 15.1202C13.2657 17.2082 14.8137 18.1622 17.2617 18.7202L18.6117 19.0262C20.3757 19.4222 21.6177 19.8362 21.6177 21.3482C21.6177 22.8422 20.2317 23.6522 18.2337 23.6522C16.2177 23.6522 14.5257 22.8422 14.2737 20.6462H12.6897V20.9162C12.9957 23.2562 14.7777 24.6962 17.4777 24.9122V26.8742H18.8997V24.9302C21.6537 24.7322 23.2557 23.3102 23.2557 21.2222C23.2557 18.9902 21.5817 18.1442 19.1337 17.5862L17.7837 17.2802C16.0377 16.8842 14.9037 16.3982 14.9037 15.0662C14.9037 13.7162 16.2897 12.8882 18.2157 12.8882Z"
      fill={fill}
    />
  </svg>
);

Settlements.propTypes = {
  fill: PropTypes.string,
};

Settlements.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Settlements;
