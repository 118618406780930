import { call, put, all, take } from 'redux-saga/effects';

import { actions as errorActions } from 'error';
import { api } from 'app/services';
import actions from '../actions';

function* fetchFeatures() {
  const { response, error } = yield call(api.fetchFeatures);

  if (response) {
    yield put(actions.featuresSuccess(response.flags));
  } else {
    yield all([
      put(actions.featuresFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchFetchFeatures() {
  while (true) {
    yield take(actions.featuresRequest);

    yield call(fetchFeatures);
  }
}

export default function* root() {
  yield call(watchFetchFeatures);
}
