import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pick, uniqueId } from 'lodash';
import classNames from 'classnames';

import TextBoxLabel from './Label';
import ValidationError from '../ValidationError';
import TextBoxInput from './Input';

import styles from './index.module.css';

class TextBox extends PureComponent {
  id = this.props.id || uniqueId('text-input-');

  errorId = this.props.errorId || `${this.id}-error-message`;

  get textInputProps() {
    const props = pick(this.props, [
      'placeholder',
      'type',
      'disabled',
      'value',
      'required',
      'onBlur',
      'onFocus',
      'autoComplete',
      'name',
      'data-lpignore',
      'inputRef',
    ]);

    return {
      ...props,
      id: this.id,
      errorId: this.errorId,
      onChange: this.handleChange,
      onClick: this.handleClick,
    };
  }

  handleChange = (evt) => {
    const { disabled, onChange } = this.props;

    if (!disabled) onChange(evt);
  };

  handleClick = (evt) => {
    const { disabled, onClick } = this.props;

    if (!disabled) onClick(evt);
  };

  render() {
    const {
      disabled,
      error,
      errorText,
      full,
      optionalLabel,
      labelText,
      hideLabel,
      required,
      id,
    } = this.props;

    const classes = classNames(styles.root, { [styles.full]: full });

    return (
      <div className={classes}>
        <TextBoxLabel
          id={id}
          labelText={labelText}
          disabled={disabled}
          optionalLabel={optionalLabel}
          hideLabel={hideLabel}
          required={required}
        />
        <div className={styles.inputWrapper}>
          <TextBoxInput {...this.textInputProps} error={error} />
        </div>
        {error && errorText && (
          <ValidationError id={id} errorString={errorText} />
        )}
      </div>
    );
  }
}

TextBox.propTypes = {
  active: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorText: PropTypes.string,
  full: PropTypes.bool,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  errorId: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  optionalLabel: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextBox.defaultProps = {
  active: false,
  autoComplete: '',
  disabled: false,
  description: '',
  full: false,
  error: false,
  errorText: '',
  hideLabel: false,
  id: undefined,
  errorId: undefined,
  labelText: '',
  name: undefined,
  onChange: () => {},
  onClick: () => {},
  onBlur: () => {},
  onFocus: () => {},
  optionalLabel: '',
  placeholder: '',
  required: false,
  type: 'text',
  value: '',
};

export default TextBox;
