import React, { memo } from 'react';
import _ from 'lodash';

import ViewProperty from 'common/components/ViewProperty';
import FormattedDate from 'common/components/FormattedDate';
import { customerPropType } from 'common/propTypes';

import styles from './index.module.css';

const Customer = ({
  email,
  address1,
  address2,
  city,
  state,
  country,
  createdAt,
}) => {
  const address = _.compact(
    _.values({ address1, address2, city, state, country })
  ).join(', ');

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <ViewProperty
          label="Order date"
          value={<FormattedDate value={createdAt} />}
        />
        <ViewProperty label="Customer email" value={email} />
      </div>
      <ViewProperty label="Billing address" value={address} />
    </div>
  );
};

Customer.propTypes = customerPropType;

export default memo(Customer);
