import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from 'common/components/Heading';

import styles from './index.module.css';

const ContentPanel = ({ title, children, padding, border, name }) => (
  <div
    className={classNames(
      styles.root,
      styles[`padding-${padding}`],
      styles[`border-${border}`]
    )}
    name={name}
  >
    {title && (
      <Heading level="3" size="3" weight="medium">
        {title}
      </Heading>
    )}
    {children}
  </div>
);

ContentPanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  padding: PropTypes.oneOf(['full', 'horizontal', 'vertical', null]),
  border: PropTypes.oneOf(['full', 'no-bottom', null]),
  name: PropTypes.string,
};

ContentPanel.defaultProps = {
  padding: 'full',
  border: 'full',
};

export default ContentPanel;
