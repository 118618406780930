import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const TextBoxInput = ({ error, errorId, id, inputRef, ...textInputProps }) => {
  const inputClasses = classNames(styles.input, {
    [styles.error]: error,
  });
  return (
    <input
      {...textInputProps}
      aria-invalid={error}
      aria-describedby={errorId}
      error={error.toString()}
      id={id}
      className={inputClasses}
      ref={inputRef}
    />
  );
};

TextBoxInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorId: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

TextBoxInput.defaultProps = {
  error: false,
  errorId: undefined,
  id: undefined,
  required: false,
  inputRef: null,
};

export default TextBoxInput;
