import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import Button from 'common/components/Button';
import CodeDisplay from 'common/components/CodeDisplay';

import styles from './index.module.css';

const RecoveryCode = ({ onComplete, recoveryCode }) => (
  <>
    <Text color="rebranding-md-color-grey-70">
      Write or print this code and keep it in a safe location. If you lose
      access to your authenticator app, this code is the only way for you to
      regain access to your account.
    </Text>
    <div className={styles.column}>
      <CodeDisplay label="Recovery Code" code={recoveryCode} clipboard />
      <div className={styles.buttonWrapper}>
        <Button onClick={onComplete} kind="primary">
          <button
            type="button"
            id="enable-twofactor-complete"
            data-test="enable-twofactor-complete"
          >
            I have my code
          </button>
        </Button>
      </div>
    </div>
  </>
);

RecoveryCode.propTypes = {
  onComplete: PropTypes.func.isRequired,
  recoveryCode: PropTypes.string.isRequired,
};

export default RecoveryCode;
