import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import timezones from 'common/timezones';
import { actions as errorActions } from 'error';
import { api } from 'app/services';
import { kybBaseInfoSubmitted } from 'common/constants';
import { mapCountries } from 'onboarding/utils/signUpUtils';
import { track, page } from 'common/analytics';

import Button from 'common/components/Button';
import CompanyInfoForm from '../components/CompanyInfoForm';
import MerchantPurposeForm from '../components/MerchantPurposeForm';
import { OnboardingHeaderWithButton } from '../components/OnboardingHeading';

import {
  selectors as sessionSelectors,
  actions as sessionActions,
} from '../../session';
import { selectors as featuresSelectors } from '../../features';

const CompanyInfoContainer = ({
  updateComplianceStatus,
  activeOrganizationId,
  apiError,
  isSandboxMode,
  updateStore,
}) => {
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [missingPurposeError, setMissingPurposeError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [fillFakeData, setFillFakeData] = useState(false);

  const [countries, setCountries] = useState([]);
  const [isFetchingCountries, setIsFetchingCountries] = useState(true);

  const [purposes, setPurposes] = useState([]);

  useEffect(() => {
    page('Company Info');

    let isSubscribed = true;
    api.fetchMerchantCountries().then((response) => {
      if (isSubscribed) {
        if (response.response) {
          setCountries(mapCountries(response.response.country));
          setIsFetchingCountries(false);
        } else {
          apiError(response?.error);
          setNetworkError(true);
          setIsFetchingCountries(false);
        }
      }
    });
    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  const handlePurposeChange = (id) => {
    if (purposes.includes(id)) {
      return setPurposes(_.without(purposes, id));
    }
    if (id === 'unsure') {
      setPurposes(['unsure']);
    } else {
      setPurposes(_.without([...purposes, id], 'unsure'));
    }
  };

  const handleSubmit = (values) => {
    if (!purposes.length) {
      setMissingPurposeError(true);
      return null;
    }
    setSubmitting(true);
    setMissingPurposeError(false);
    track('Company Info Submitted');
    api
      .onboardingSubmitCompanyInfo({
        ...values,
        id: activeOrganizationId,
        merchantPurpose: purposes,
        settlementCurrency: values.settlementCurrency,
      })
      .then(({ error }) => {
        if (!error) {
          updateStore({
            name: values.storeName,
            companyOfficialName: values.companyOfficialName,
          });
          updateComplianceStatus(kybBaseInfoSubmitted);
        } else {
          apiError(error);
          setSubmissionFailed(true);
          setSubmitting(false);
        }
      });
  };

  const handleFillFakeData = () => {
    setFillFakeData(true);
  };

  return (
    <div id="companyInfo">
      <OnboardingHeaderWithButton dataTest="companyInfo-header">
        <span />
        {isSandboxMode && (
          <Button
            kind="test"
            disabled={!!networkError}
            onClick={handleFillFakeData}
          >
            <button type="button">Fill with fake data</button>
          </Button>
        )}
      </OnboardingHeaderWithButton>

      {networkError ? (
        'Something went wrong'
      ) : (
        <>
          <MerchantPurposeForm
            selected={purposes}
            onChange={handlePurposeChange}
          />
          <br />
          <CompanyInfoForm
            fillFakeData={fillFakeData}
            isFetchingCountries={isFetchingCountries}
            countries={countries}
            onSubmit={handleSubmit}
            timezoneItems={timezones}
            submitting={submitting}
            submissionFailed={submissionFailed}
            missingPurposeError={missingPurposeError}
            purposes={purposes}
          />
        </>
      )}
    </div>
  );
};

CompanyInfoContainer.propTypes = {
  apiError: PropTypes.func.isRequired,
  updateComplianceStatus: PropTypes.func.isRequired,
  activeOrganizationId: PropTypes.string,
  isSandboxMode: PropTypes.bool,
  updateStore: PropTypes.func.isRequired,
};

const ConnectedCompanyInfoContainer = connect(
  (state) => ({
    activeOrganizationId: sessionSelectors.activeOrganization(state)?.id,
    isSandboxMode: featuresSelectors.isSandboxMode(state),
  }),
  {
    apiError: errorActions.apiError,
    updateStore: sessionActions.activeStoreUpdate,
  }
)(CompanyInfoContainer);

export default memo(ConnectedCompanyInfoContainer);
