import { ordersRoute } from 'app/containers/routes';
import BackButton from 'common/components/BackButton';
import LoadingPage from 'common/components/LoadingPage';
import NotFound from 'common/components/NotFound';
import Layout from 'common/containers/Layout';
import { route as routePropTypes } from 'common/propTypes';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import Order from '../components/Order';
import RefundForm from '../components/RefundForm';
import Refund from '../containers/Refund';
import {
  order as orderPropType,
  orderRefundCompletedStatus,
  paidStatus,
  refundProcessingStatus,
  refundProposedStatus,
} from '../propTypes';
import { getLoadOrdersError, getOrderContainer } from '../reducers/orders';

const refundStatus = [
  orderRefundCompletedStatus,
  refundProposedStatus,
  refundProcessingStatus,
];

class OrderContainer extends PureComponent {
  componentDidMount() {
    const {
      orderPageRequest,
      activitiesRequest,
      match: {
        params: { id },
      },
    } = this.props;

    orderPageRequest(id);
    activitiesRequest(id);
  }

  goBack = () => {
    const { history } = this.props;

    return history.push(ordersRoute);
  };

  isRefund = () => {
    const { data } = this.props;

    return _.includes(refundStatus, data.status);
  };

  showRefundForm = () => {
    const { data } = this.props;
    const isPaidStatus = data.status === paidStatus;

    return isPaidStatus && data.refundable;
  };

  refundComponent = () => {
    if (this.showRefundForm()) return RefundForm;
    if (this.isRefund()) return Refund;

    return null;
  };

  render() {
    const { data, errors } = this.props;

    if (errors) return <NotFound />;

    return (
      <Layout
        heading="Order details"
        sideAction={<BackButton onGoBack={this.goBack} />}
      >
        {_.isEmpty(data) ? (
          <LoadingPage />
        ) : (
          <Order data={data} refundComponent={this.refundComponent()} />
        )}
      </Layout>
    );
  }
}

OrderContainer.propTypes = {
  ...routePropTypes,
  data: PropTypes.shape(orderPropType),
  errors: PropTypes.objectOf(PropTypes.string),
};

OrderContainer.defaultProps = {
  errors: null,
};

export default connect(
  (state, props) => ({
    data: getOrderContainer(state, props.match.params.id),
    errors: getLoadOrdersError(state),
  }),
  {
    orderPageRequest: actions.singleOrderPageRequest,
    activitiesRequest: actions.orderActivitiesRequest,
  }
)(OrderContainer);
