import React from 'react';
import PropTypes from 'prop-types';

import { amount as amountPropType } from 'common/propTypes';
import { getTitlePrefix as getTitlePrefixBase } from 'settlements/utils/getTitlePrefix';
import { feesProp } from '../../propTypes';
import parseNegativeValues from '../../utils/parseNegativeValues';
import relativeToPercentage from '../../utils/relativeToPercentage';

import Row from './Row';
import styles from './index.module.css';

const ReportTotals = ({
  totalAmount,
  toBeSettledAmount: { value: toBeSettledValue, currency: settlementCurrency },
  toBeSettledReferenceAmount,
  totalInvoicesAmount,
  totalFiatBillsAmount,
  totalOrdersAmount,
  totalReferenceAmount,
  totalReferenceInvoicesAmount,
  totalReferenceOrdersAmount,
  totalReferenceRefundedAmount,
  totalReferenceFiatBillsAmount,
  totalRefundedAmount,
  fees: {
    utrustFeeMultiplier,
    utrustFeeTotal,
    utrustFeeVat,
    utrustFeeVatAmount,
    utrustPayoutExtraFee,
  },
  referenceFees,
  referenceCurrency,
  shouldShowReferenceCurrency,
  isInvoicingEnabled,
  isOnrampEnabled,
}) => {
  const payoutFeeNumber = Number(utrustPayoutExtraFee);

  const getTitlePrefix = (section) =>
    getTitlePrefixBase(section, { isInvoicingEnabled, isOnrampEnabled });

  return (
    <>
      <ul className={styles.root} data-test="report-totals">
        <Row
          title={`${getTitlePrefix('order')}. Total Orders Paid`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: totalReferenceOrdersAmount,
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: totalOrdersAmount.value,
              currency: totalOrdersAmount.currency,
            },
          ]}
        />
        {isInvoicingEnabled && (
          <Row
            title={`${getTitlePrefix('invoice')}. Total Invoices Paid`}
            values={[
              ...(shouldShowReferenceCurrency
                ? [
                    {
                      amount: totalReferenceInvoicesAmount,
                      currency: referenceCurrency,
                    },
                  ]
                : []),
              {
                amount: totalInvoicesAmount.value,
                currency: totalInvoicesAmount.currency,
              },
            ]}
          />
        )}
        {isOnrampEnabled && (
          <Row
            title={`${getTitlePrefix('fiatBill')}. Total Onramp`}
            values={[
              ...(shouldShowReferenceCurrency
                ? [
                    {
                      amount: totalReferenceFiatBillsAmount,
                      currency: referenceCurrency,
                    },
                  ]
                : []),
              {
                amount: totalFiatBillsAmount.value,
                currency: totalFiatBillsAmount.currency,
              },
            ]}
          />
        )}
        <Row
          title={`Subtotal payments`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [{ amount: totalReferenceAmount, currency: referenceCurrency }]
              : []),
            { amount: totalAmount.value, currency: settlementCurrency },
          ]}
          isTotal
        />
        <Row
          title={`xMoney
          service fee - ${relativeToPercentage(utrustFeeMultiplier)}%`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: parseNegativeValues(referenceFees?.utrustFeeTotal),
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: parseNegativeValues(utrustFeeTotal),
              currency: settlementCurrency,
            },
          ]}
        />
        {payoutFeeNumber > 0 && (
          <Row
            title="xMoney payout fee"
            values={[
              ...(shouldShowReferenceCurrency
                ? [
                    {
                      amount: parseNegativeValues(
                        referenceFees?.utrustPayoutExtraFee
                      ),
                      currency: referenceCurrency,
                    },
                  ]
                : []),
              {
                amount: parseNegativeValues(utrustPayoutExtraFee),
                currency: settlementCurrency,
              },
            ]}
          />
        )}
        <Row
          title={`VAT fee - ${relativeToPercentage(utrustFeeVat)}%`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: parseNegativeValues(
                      referenceFees?.utrustFeeVatAmount
                    ),
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: parseNegativeValues(utrustFeeVatAmount),
              currency: settlementCurrency,
            },
          ]}
        />
        <Row
          title={`${getTitlePrefix('refund')}. Total Orders Refunded `}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: parseNegativeValues(totalReferenceRefundedAmount),
                    currency: referenceCurrency,
                  },
                ]
              : []),
            {
              amount: parseNegativeValues(totalRefundedAmount.value),
              currency: settlementCurrency,
            },
          ]}
        />
        <Row
          title={`Total payout`}
          values={[
            ...(shouldShowReferenceCurrency
              ? [
                  {
                    amount: toBeSettledReferenceAmount,
                    currency: referenceCurrency,
                  },
                ]
              : []),
            { amount: toBeSettledValue, currency: settlementCurrency },
          ]}
          isTotal
        />
      </ul>
    </>
  );
};

ReportTotals.propTypes = {
  fees: feesProp,
  toBeSettledAmount: amountPropType.isRequired,
  totalAmount: amountPropType,
  totalInvoicesAmount: amountPropType,
  totalFiatBillsAmount: amountPropType,
  totalOrdersAmount: amountPropType,
  totalRefundedAmount: amountPropType,
  totalReferenceAmount: PropTypes.string,
  totalReferenceInvoicesAmount: PropTypes.string,
  totalReferenceOrdersAmount: PropTypes.string,
  totalReferenceRefundedAmount: PropTypes.string,
  totalReferenceFiatBillsAmount: PropTypes.string,
  referenceCurrency: PropTypes.string,
  shouldShowReferenceCurrency: PropTypes.bool,
  vatInfo: PropTypes.shape({
    vat: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    totalAmount: PropTypes.string.isRequired,
  }),
  isInvoicingEnabled: PropTypes.bool.isRequired,
  isOnrampEnabled: PropTypes.bool.isRequired,
};

export default ReportTotals;
