import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import build from 'redux-object';
import _ from 'lodash';

import actions from '../actions';

const {
  CREDENTIALS_REQUEST,
  CREDENTIALS_SUCCESS,
  CREDENTIALS_FAILURE,
  NEW_CREDENTIALS_REQUEST,
  NEW_CREDENTIALS_SUCCESS,
  NEW_CREDENTIALS_FAILURE,
  CLEAR_CREDENTIALS_SECRET,
  SET_INTEGRATION_TYPE,
  WEBHOOKS_REQUEST,
  WEBHOOKS_SUCCESS,
  WEBHOOKS_FAILURE,
  SINGLE_WEBHOOK_REQUEST,
  SINGLE_WEBHOOK_SUCCESS,
  SINGLE_WEBHOOK_FAILURE,
} = actions;

// Credentials

const checkWebhookKey = (webhookSignKey) => {
  // means secret is hidden
  if (webhookSignKey.includes('******')) return null;
  return webhookSignKey;
};

const storeCredentialsReducer = handleActions(
  {
    [combineActions(NEW_CREDENTIALS_SUCCESS, CREDENTIALS_SUCCESS)]: (
      state,
      { payload }
    ) => ({
      apiKey: payload.apiKey,
      secret: checkWebhookKey(payload.webhookSignKey),
      integrationType: payload.integrationType || state.integrationType,
    }),
    [NEW_CREDENTIALS_FAILURE]: (state, error) => error,
    [CLEAR_CREDENTIALS_SECRET]: (state) => ({
      ...state,
      secret: null,
    }),
    [SET_INTEGRATION_TYPE]: (state, { payload }) => ({
      ...state,
      integrationType: payload.integrationType,
    }),
  },
  {
    apiKey: null,
    secret: null,
    integrationType: null,
  }
);

const isFetchingReducer = handleActions(
  {
    [combineActions(CREDENTIALS_SUCCESS, CREDENTIALS_FAILURE)]: () => false,
    [CREDENTIALS_REQUEST]: () => true,
  },
  true
);

const isFetchingNewCredentialsReducer = handleActions(
  {
    [combineActions(NEW_CREDENTIALS_SUCCESS, NEW_CREDENTIALS_FAILURE)]: () =>
      false,
    [NEW_CREDENTIALS_REQUEST]: () => true,
  },
  false
);

export const isFetching = (state) => state.integrations.isFetching;
export const isFetchingNewCredentials = (state) =>
  state.integrations.isFetchingNewCredentials;
export const storeCredentials = (state) => state.integrations.storeCredentials;

// Webhooks

const defaults = {
  loading: false,
  error: null,
};

const transformWebhooks = (payload) => {
  const webhooks = build(payload, 'webhooks');

  return _.chain(webhooks)
    .reduce(
      (result, webhook) => ({
        ...result,
        [webhook.id]: webhook,
      }),
      []
    )
    .orderBy('updatedAt', 'desc')
    .value();
};

const transformSingleWebhook = (payload) => {
  const webhooksList = build(payload, 'webhooks');

  return webhooksList[0];
};

const webhooksReducer = handleActions(
  {
    [WEBHOOKS_REQUEST]: () => ({
      ...defaults,
      loading: true,
    }),
    [WEBHOOKS_SUCCESS]: (state, action) => ({
      ...defaults,
      byId: transformWebhooks(action.payload),
      totalElements: action.payload.meta.webhooks.meta.totalElements,
    }),
    [WEBHOOKS_FAILURE]: (state, action) => ({
      ...defaults,
      error: action.payload,
    }),
  },
  defaults
);

const singleWebhookReducer = handleActions(
  {
    [SINGLE_WEBHOOK_REQUEST]: () => ({
      ...defaults,
      loading: true,
    }),
    [SINGLE_WEBHOOK_SUCCESS]: (state, action) => ({
      ...defaults,
      data: transformSingleWebhook(action.payload),
    }),
    [SINGLE_WEBHOOK_FAILURE]: (state, action) => ({
      ...defaults,
      error: action.payload,
    }),
  },
  defaults
);

export default combineReducers({
  isFetching: isFetchingReducer,
  isFetchingNewCredentials: isFetchingNewCredentialsReducer,
  storeCredentials: storeCredentialsReducer,
  webhooks: webhooksReducer,
  singleWebhook: singleWebhookReducer,
});

export const webhooks = (state) => state.integrations.webhooks;
export const isFetchingWebhooks = (state) =>
  state.integrations.webhooks.loading;
export const webhook = (state) => state.integrations.singleWebhook.data;
export const isFetchingSingleWebhook = (state) =>
  state.integrations.singleWebhook.loading;
