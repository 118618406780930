import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { api } from 'app/services';
import { actions as errorActions } from 'error';
import { actions as settingsActions } from 'settings';
import { selectors as sessionSelectors } from '../../session';
import { selectors as settingsSelectors } from '../../settings';

const useContract = () => {
  const activeOrganization = useSelector((state) =>
    sessionSelectors.activeOrganization(state)
  );
  const contract = useSelector((state) => settingsSelectors.getContract(state));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    async function fetchContract() {
      const { error, response } = await api.fetchContract(
        activeOrganization.id
      );

      if (isSubscribed) {
        if (error) {
          dispatch(errorActions.apiError(error));
          return setLoading(false);
        }
        dispatch(settingsActions.contractSuccess(response));
        setLoading(false);
      }
    }

    if (!contract) {
      setLoading(true);
      fetchContract();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  return [contract, loading];
};

export default useContract;
