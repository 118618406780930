import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AlertDismissable } from 'common/components/Alert';

import {
  kybT1InProgress,
  kybT1Submitted,
  kybStarted,
  kybBaseInfoSubmitted,
} from 'common/constants';

const SettlementAlerts = ({
  complianceStatus,
  suspendedSettlements,
  displayKyb,
}) => {
  const verifying = [kybT1InProgress, kybT1Submitted].includes(
    complianceStatus
  );
  const notSubmitted = [kybStarted, kybBaseInfoSubmitted].includes(
    complianceStatus
  );

  const [showSuspendedAlert, setShowSuspendedAlert] =
    useState(suspendedSettlements);
  const [showVerifyingAlert, setShowVerifyingAlert] = useState(verifying);

  const dismissAlerts = () => {
    setShowVerifyingAlert(false);
    setShowSuspendedAlert(false);
  };

  if (!notSubmitted && !showSuspendedAlert && !showVerifyingAlert) return null;

  if (showSuspendedAlert) {
    return (
      <AlertDismissable
        text={[
          'Settlements are currently suspended.',
          <br key="break" />,
          `Our team will contact you shortly.`,
        ]}
        buttonText="Dismiss"
        onDismiss={dismissAlerts}
      />
    );
  }

  if (showVerifyingAlert) {
    return (
      <AlertDismissable
        text={[
          'Settlements are not enabled yet.',
          <br key="break" />,
          `You'll get an email once we verify your business documents.`,
        ]}
        buttonText="Dismiss"
        onDismiss={dismissAlerts}
      />
    );
  }

  if (notSubmitted) {
    return (
      <AlertDismissable
        text={[
          'Settlements are not enabled yet.',
          <br key="break" />,
          `Please submit your business documents to enable them.`,
        ]}
        buttonText="Submit Now"
        onDismiss={displayKyb}
      />
    );
  }
};

SettlementAlerts.propTypes = {
  complianceStatus: PropTypes.string.isRequired,
  suspendedSettlements: PropTypes.bool.isRequired,
  displayKyb: PropTypes.func.isRequired,
};

export default SettlementAlerts;
