import mirrorCreator from 'mirror-creator';

export default mirrorCreator([
  'UNAUTHORIZED',
  'invalid_token',
  'NOT_FOUND',
  'signature_error',
]);

export const subtotalName = 'Subtotal';
export const taxName = 'Tax';
export const shippingFeeName = 'Shipping Fee';
export const discountName = 'Discount';

//merchant onboarding status
export const businessDetailsEntered = 'business_details_entered';
export const registered = `registered`;
export const emailConfirmed = 'email_confirmed';
export const companyInfoSubmitted = 'company_info_submitted';
export const credentialsGenerated = 'credentials_generated';
export const kybSubmitted = 'kyb_submitted';
export const kybInProgress = 'kyb_in_progress';
export const onboardingCompleted = 'completed';

//merchant compliance statuses
export const kybStarted = 'kyb_started';
export const kybBaseInfoSubmitted = 'kyb_base_info_submitted';
export const kybT1Submitted = 'kyb_t1_submitted';
export const kybT1InProgress = 'kyb_t1_in_progress';
export const kybT1Approved = 'kyb_t1_approved';
export const kybT2Submitted = 'kyb_t2_submitted';
export const kybT2InProgress = 'kyb_t2_in_progress';
export const kybT2Approved = 'kyb_t2_approved';

//merchant integration statuses
export const businessDetailsEnteredIntegration = 'business_details_entered';
export const signedUp = 'signed_up';
export const emailConfirmedIntegration = 'email_confirmed';
export const credentialsGeneratedIntegration = 'credentials_generated';
export const live = 'live';

export const onboardingStatuses = [
  businessDetailsEntered,
  companyInfoSubmitted,
  credentialsGenerated,
  emailConfirmed,
  kybSubmitted,
  kybInProgress,
  onboardingCompleted,
  registered,
];

export const complianceStatuses = [
  kybStarted,
  kybBaseInfoSubmitted,
  kybT1Submitted,
  kybT1InProgress,
  kybT1Approved,
  kybT2Submitted,
  kybT2InProgress,
  kybT2Approved,
];

export const integrationStatuses = [
  signedUp,
  emailConfirmedIntegration,
  credentialsGeneratedIntegration,
  businessDetailsEnteredIntegration,
  live,
];

// merchant status
export const active = 'active';
export const settlementsSuspended = 'settlements_suspended';
export const hardBlocked = 'hard_blocked';

export const supportUrl = 'https://support.xmoney.com';

export const lost2faSupport =
  'https://support.xmoney.com/en/articles/8410245-i-lost-access-to-2fa-what-to-do';

export const supportEmail = 'support@xmoney.com';

export const buyersCountriesSupport =
  'https://support.xmoney.com/en/articles/4879553-merchants-is-xmoney-available-for-all-of-my-clients';

export const failedToFetchChrome = 'Failed to fetch';
export const failedToFetchFirefox =
  'NetworkError when attempting to fetch resource.';
export const killSwitchStatusText = 'Service Temporarily Disabled';
export const killSwitchStatus = 555;
export const passwordAlreadyAdded = 'password_authentication_already_added';
export const invalidBillExpirationRequest = 'invalid_bill_expiration_request';
