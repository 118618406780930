import { take, fork, call } from 'redux-saga/effects';

/* eslint-disable redux-saga/yield-effects */
const watch = (pattern, saga) =>
  fork(function* watcher() {
    while (true) {
      const { payload } = yield take(pattern);
      yield call(saga, payload);
    }
  });
/* eslint-enable redux-saga/yield-effects */

export default watch;
