import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useActionDispatcher = action => {
  const dispatch = useDispatch();

  return useCallback(payload => dispatch(action(payload)), [dispatch, action]);
};

export default useActionDispatcher;
