const paymentHeaders = (referenceFiatCurrency) => {
  const headers = [
    { label: 'No.' },
    { label: 'Date & Time' },
    { label: 'Reference' },
    { label: 'Order Currency', justify: 'end' },
  ];

  if (referenceFiatCurrency) {
    return headers.concat([
      { label: 'Reference Currency', justify: 'end' },
      { label: 'Settlement Currency', justify: 'end' },
    ]);
  }
  return headers.concat([{ label: 'Settlement Currency', justify: 'end' }]);
};

export default paymentHeaders;
