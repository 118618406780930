import React from 'react';
import PropTypes from 'prop-types';

import { utcDateFormatter } from 'common/services/date';

import styles from './index.module.css';

const ReferenceDate = ({ value }) => {
  const reference = value.get('reference');

  const date = value.get('date');
  const displayUtcDate = utcDateFormatter(date);
  const dateTime = displayUtcDate('yyyy-MM-ddTHH:mm:ss');
  const hoursMinutes = displayUtcDate('HH:mm');
  const dayAndMonth = displayUtcDate('dd MMM');

  return (
    <div className={styles.referenceDate}>
      <span>{reference}</span>
      <time className={styles.date} dateTime={dateTime}>
        <span>{`${dayAndMonth} ${hoursMinutes}`}</span>
      </time>
    </div>
  );
};

ReferenceDate.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
};

export default ReferenceDate;
