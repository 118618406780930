import { actions } from 'auth';
import {
  accessToken as getAccessToken,
  refreshToken as getRefreshToken,
} from 'auth/reducers';
import jwtDecode from 'jwt-decode';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000;

function getTokenExpirationTime(token) {
  if (!token) return null;

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000;
  } catch (error) {
    return null;
  }
}

export const isTokenExpired = (token) => {
  const expirationTime = getTokenExpirationTime(token);
  if (!expirationTime) return true;

  return Date.now() >= expirationTime;
};

const useTokenRefresh = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessToken);
  const refreshToken = useSelector(getRefreshToken);

  const refreshTokenIfNeeded = useCallback(() => {
    if (!accessToken || !refreshToken) return;

    const tokenExpirationTime = getTokenExpirationTime(accessToken);
    if (!tokenExpirationTime) return;

    const currentTime = Date.now();
    const timeUntilExpiration = tokenExpirationTime - currentTime;

    const shouldRefreshToken = timeUntilExpiration < TOKEN_REFRESH_THRESHOLD;

    if (shouldRefreshToken) {
      return dispatch(actions.tokenRefreshRequest(refreshToken));
    }

    const timeoutDelay = timeUntilExpiration - TOKEN_REFRESH_THRESHOLD;
    setTimeout(refreshTokenIfNeeded, timeoutDelay);
  }, [dispatch, accessToken, refreshToken]);

  useEffect(() => {
    refreshTokenIfNeeded();
  }, [refreshTokenIfNeeded]);
};

export default useTokenRefresh;
