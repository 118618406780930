import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from 'common/containers/Layout';
import { kybBaseInfoSubmitted, kybStarted } from 'common/constants';
import { route as routePropType } from 'common/propTypes';
import { selectors as featuresSelectors } from 'features';
import { selectors as authSelectors } from 'auth';
import { selectors as sessionSelectors } from 'session';
import { managePermission } from 'payments/propTypes';
import Tabs, { Tab } from 'common/components/Tabs';
import NotFound from 'common/components/NotFound';
import PersonalPage from './Personal';
import BusinessPage from './Business';
import SettlementsSettingsPage from './SettlementsSettings';

const Routes = ({
  match,
  isPayoutLimitsActive,
  settlementsPermission,
  complianceStatus,
}) => {
  const personalRoute = `${match.path}/personal`;
  const businessRoute = `${match.path}/business`;
  const settlementsSettingsRoute = `${match.path}/settlements`;
  const canManageSettlements = settlementsPermission === managePermission;
  const isKybMissing = [kybStarted, kybBaseInfoSubmitted].includes(
    complianceStatus
  );
  const showSettlementsTab =
    !isKybMissing && isPayoutLimitsActive && canManageSettlements;

  return (
    <Layout heading="Settings">
      <Tabs>
        <Tab to={personalRoute}>Personal</Tab>
        <Tab to={businessRoute}>Business</Tab>
        {showSettlementsTab && (
          <Tab to={settlementsSettingsRoute}>Settlements</Tab>
        )}
      </Tabs>
      <Switch>
        <Route path={personalRoute} component={PersonalPage} />
        <Route path={businessRoute} component={BusinessPage} />
        {showSettlementsTab && (
          <Route
            path={settlementsSettingsRoute}
            component={SettlementsSettingsPage}
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

Routes.propTypes = {
  isPayoutLimitsActive: PropTypes.bool.isRequired,
  ...routePropType,
};

export default connect((state) => ({
  isPayoutLimitsActive: featuresSelectors.isPayoutLimitsActive(state),
  settlementsPermission: authSelectors.getPermissions(state)?.settlements,
  complianceStatus:
    sessionSelectors.activeOrganization(state)?.complianceStatus,
}))(Routes);
