import build from 'redux-object';

const getMetaInfo = (payload, field) => {
  const meta = build(payload, 'meta');

  return meta[0].meta[field];
};

export default {
  getMetaInfo,
};
