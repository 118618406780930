import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { route as routePropType } from 'common/propTypes';
import OrdersPage from './Orders';
import OrderPage from './Order';
import InvoicesPage from './Invoices';
import NewInvoicePage from './NewInvoice';
import InvoiceReportPage from './InvoiceReport';
import InvoicePage from './Invoice';

class Routes extends Component {
  render() {
    const { match } = this.props;
    const ordersRoute = `${match.path}/orders`;
    const invoicesRoute = `${match.path}/invoices`;

    return (
      <Switch>
        <Route
          exact
          path={match.path}
          component={() => <Redirect to={ordersRoute} />}
        />
        <Route exact path={ordersRoute} component={OrdersPage} />
        <Route path={`${ordersRoute}/:id`} component={OrderPage} />
        <Route exact path={`${invoicesRoute}/new`} component={NewInvoicePage} />
        <Route exact path={invoicesRoute} component={InvoicesPage} />
        <Route
          exact
          path={`${invoicesRoute}/:id/export`}
          component={InvoiceReportPage}
        />
        <Route path={`${invoicesRoute}/:id`} component={InvoicePage} />
      </Switch>
    );
  }
}

Routes.propTypes = routePropType;

export default Routes;
