import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.css';

const Cell = ({ children, justify }) => {
  const classes = classNames(styles['cell-content'], {
    [styles[justify]]: !!justify,
  });
  return (
    <td className={styles.td}>
      <div className={classes}>{children}</div>
    </td>
  );
};

Cell.propTypes = {
  children: PropTypes.node.isRequired,
  justify: PropTypes.string,
};

Cell.defaultProps = {
  justify: null,
};

export default Cell;
