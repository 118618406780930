import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { settlement as settlementPropType } from 'settlements/propTypes';
import Heading from 'common/components/Heading';
import HeaderCell from 'common/components/HeaderCell';
import Row from './Row';

import styles from './index.module.css';

const SettlementsTable = ({ settlements }) => (
  <>
    <div className={styles.headingWrapper}>
      <Heading level="3" size="3" weight="medium">
        History
      </Heading>
    </div>
    {Object.keys(settlements).length > 0 ? (
      <table
        id="settlements"
        className={styles.table}
        data-test="settlements-table"
      >
        <tbody>
          <tr className={styles.header}>
            <HeaderCell content="Start date" />
            <HeaderCell content="End date" />
            <HeaderCell content="Settlement" />
            <HeaderCell content="Fee invoice" />
            <HeaderCell content="Destination" />
            <HeaderCell align="right" content="Transferred on" />
            <HeaderCell align="right" content="Total" />
          </tr>
          {_.map(settlements, (settlement) => (
            <Row key={settlement.id} settlement={settlement} />
          ))}
        </tbody>
      </table>
    ) : (
      <div className={styles.emptyHistory}>
        <Heading
          level="2"
          size="2"
          color="grey-60"
        >{`You don't have any past settlements`}</Heading>
      </div>
    )}
  </>
);

SettlementsTable.propTypes = {
  settlements: PropTypes.objectOf(settlementPropType).isRequired,
};

export default SettlementsTable;
