import PropTypes from 'prop-types';

const itemProptype = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

const itemListProptypes = PropTypes.arrayOf(itemProptype);

const groupProptypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    groupLabel: PropTypes.string.isRequired,
    groupItems: itemListProptypes.isRequired,
  })
);

const dropdownItemProptypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })
);

export {
  itemListProptypes,
  groupProptypes,
  itemProptype,
  dropdownItemProptypes,
};
