import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import { apiIntegrationsRoute } from 'app/containers/routes';

import noOrders from './noOrders.svg';
import noInvoices from './noInvoices.svg';
import styles from './index.module.css';

const imageMap = {
  orders: noOrders,
  invoices: noInvoices,
};

const NoPaymentsYet = ({ paymentType }) => {
  const history = useHistory();

  const navigateToNewInvoice = () => {
    history.push('/payments/invoices/new');
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.message}>
          <Heading size="2" level="2" color="blue">
            You {`don't`} have any {paymentType} yet
          </Heading>
          <img src={imageMap[paymentType]} alt="Empty shopping cart" />
        </div>
        {paymentType === 'invoices' ? (
          <>
            <Text>{`We're`} just waiting for the first one</Text>
            <span className={styles.buttonWrapper}>
              <Button
                full
                icon={<Icon.Plus description={`Plus`} size="medium" />}
                kind="primary"
                onClick={navigateToNewInvoice}
              >
                <button type="button" data-test="button-new-invoice">
                  New invoice
                </button>
              </Button>
            </span>
          </>
        ) : (
          <Text>
            If you followed the{' '}
            <span className={styles.link}>
              <Link to={apiIntegrationsRoute}>integration</Link>
            </span>{' '}
            instructions
            <br />
            you can wait for your first order to appear here
          </Text>
        )}
      </div>
    </div>
  );
};

NoPaymentsYet.propTypes = {
  paymentType: PropTypes.string.isRequired,
};

export default NoPaymentsYet;
