import React from 'react';
import PropTypes from 'prop-types';

import { paramsPropType } from 'integrations/propTypes';

import styles from './index.module.css';

const CodeBlock = ({ jsonObject, message }) => (
  <div className={styles.root}>
    <pre>
      {jsonObject && (
        <code className={`language-json}`}>
          {JSON.stringify(jsonObject, null, 2)}
        </code>
      )}
      {message && <code>{message}</code>}
    </pre>
  </div>
);

CodeBlock.propTypes = {
  jsonObject: paramsPropType,
  message: PropTypes.string,
};

export default CodeBlock;
