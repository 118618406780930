// company types
export const soleTraders = 'sole_traders';
export const partnershipLimitedPartnerships =
  'partnership_limited_partnerships';
export const limitedLiabilityPartnerships = 'limited_liability_partnerships';
export const limitedCompanies = 'limited_companies';
export const publicTradedCompanies = 'public_traded_companies';
export const charitiesCommunityOrganisations =
  'charities_community_organisations';

export const companyTypesOptions = [
  {
    label: 'Charities/community organisations',
    value: 'charities_community_organisations',
  },
  {
    label: 'Limited companies',
    value: 'limited_companies',
  },
  {
    label: 'Limited liability partnerships',
    value: 'limited_liability_partnerships',
  },
  {
    label: 'Partnership/Limited partnerships',
    value: 'partnership_limited_partnerships',
  },
  {
    label: 'Public traded companies',
    value: 'public_traded_companies',
  },
  {
    label: 'Sole Traders',
    value: 'sole_traders',
  },
];

export const bDoc = 'b_doc';
export const bCertificate = 'b_certificate';
export const bMembers = 'b_members';
export const bDirectors = 'b_directors';
export const bAuthorization = 'b_authorization';
export const bShareholders = 'b_shareholders';
export const boId = 'bo_id';
export const boPa = 'bo_pa';

export const documentLabels = {
  bDoc,
  bCertificate,
  bMembers,
  bDirectors,
  bAuthorization,
  bShareholders,
  boId,
  boPa,
};
