import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';

import styles from './index.module.css';

const Card = ({ heading, id, children }) => (
    <div className={styles.root}>
      <Heading level="3" size="3" weight="medium" id={id}>
        {heading}
      </Heading>
      <br />
      {children}
    </div>
  );

Card.propTypes = {
  heading: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Card;
