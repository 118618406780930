import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'common/components/Modal';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import { ApiKey, WebhookSecret } from 'onboarding/components/Credentials/steps';

import styles from './index.module.css';

const CredentialsModal = ({ codes, isOpen, onClose, onSubmit, loading }) => {
  const [generated, setGenerated] = useState(false);

  const handleClickProceed = () => {
    onSubmit();
    setGenerated(true);
  };

  useEffect(() => {
    setGenerated(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnClickOutside>
      <div className={styles.content}>
        {generated && (
          <>
            <Heading level="3" size="3" weight="semiBold">
              New credentials
            </Heading>
            <br />
            <Text>
              Please copy these credentials and keep them somewhere safe.
            </Text>
            <br />
            <Text weight="semiBold">
              For security reasons, we cannot show the Webhook secret again.
            </Text>
            <br />
            <ApiKey loading={loading} code={codes?.apiKey} />
            <WebhookSecret loading={loading} code={codes?.secret} />
            <div className={styles.modalFooter}>
              <Button kind="primary" onClick={onClose}>
                <button type="button" data-test="credentials-done">
                  Done
                </button>
              </Button>
            </div>
          </>
        )}
        {!generated && (
          <>
            <Heading level="3" size="3" weight="semiBold">
              Generate new credentials?
            </Heading>
            <br />
            <Text>
              Generating a new API key and Webhook secret will revoke the
              <br />
              current one, which mean the existing integrations with xMoney will
              <br />
              stop working.
            </Text>
            <br />
            <Text weight="semiBold">Are you sure you want to proceed?</Text>
            <div className={styles.modalFooter}>
              <Button kind="subtle" onClick={onClose}>
                <button type="button">Cancel</button>
              </Button>
              <div>
                <Button kind="primary" onClick={handleClickProceed} full>
                  <button type="button" data-test="generate-new-credentials">
                    Proceed
                  </button>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

CredentialsModal.propTypes = {
  codes: PropTypes.shape({
    apiKey: PropTypes.string,
    secret: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CredentialsModal;
