import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator(['FAILED_FETCH', 'API_ERROR']);

const actionCreators = createActions(
  actionTypes.FAILED_FETCH,
  actionTypes.API_ERROR
);

export default {
  ...actionTypes,
  ...actionCreators,
};
