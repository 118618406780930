import { combineReducers } from 'redux';
import { map, reduce } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import build from 'redux-object';

import { orderFilterStatuses } from './filterStatusesUtils';
import { defaultDateRangeValue, dateRanges } from './filterDateRangeUtils';
import { refundProposedStatus, allStatusRegex } from '../propTypes';
import actions from '../actions';

const {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAILURE,
  SINGLE_ORDER_PAGE_REQUEST,
  SINGLE_ORDER_PAGE_SUCCESS,
  SINGLE_ORDER_PAGE_FAILURE,
  REFUND_REQUEST,
  REFUND_SUCCESS,
  REFUND_FAILURE,
  REFUND_LOAD_REQUEST,
  REFUND_LOAD_SUCCESS,
  REFUND_LOAD_FAILURE,
  REFUND_FETCHING,
  SET_ORDERS_TABLE_PAGE,
  RESET_ORDERS_TABLE_PAGE,
  SET_ORDERS_TABLE_PAGE_SIZE,
  ORDERS_TABLE_NEXT_PAGE,
  ORDERS_TABLE_PREVIOUS_PAGE,
  SET_ORDERS_TABLE_FILTER,
  SET_ORDERS_TABLE_SORT,
  RESET_ORDERS_TABLE,
  ORDER_ACTIVITIES_REQUEST,
  ORDER_ACTIVITIES_SUCCESS,
  ORDER_ACTIVITIES_FAILURE,
} = actions;

const transformOrders = (payload) => {
  const orders = build(payload, 'orders');

  return reduce(
    orders,
    (result, order) => ({
      ...result,
      [order.id]: order,
    }),
    {}
  );
};

export const getOrder = (state, id) => state.orders.byId[id];

const byId = handleActions(
  {
    [ORDERS_SUCCESS]: (state, action) => ({
      ...transformOrders(action.payload),
    }),
    [SINGLE_ORDER_PAGE_SUCCESS]: (state, action) => ({
      ...state,
      ...transformOrders(action.payload),
    }),
    [REFUND_SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.orderId]: {
        ...state[action.payload.orderId],
        status: refundProposedStatus,
      },
    }),
  },
  {}
);

const replaceState = (state, action) => action.payload;
const errorState = () => ({ error: true });

const activitiesReducer = handleActions(
  {
    [ORDER_ACTIVITIES_SUCCESS]: (state, action) =>
      build(action.payload, 'orderActivities') || [],
    [ORDER_ACTIVITIES_FAILURE]: () => [],
    [ORDER_ACTIVITIES_REQUEST]: () => [],
  },
  []
);

const refundReducer = handleActions(
  {
    [REFUND_LOAD_SUCCESS]: replaceState,
    [combineActions(REFUND_LOAD_FAILURE, REFUND_FAILURE)]: errorState,
  },
  null
);

const isFetchingRefundReducer = handleActions(
  {
    [combineActions(REFUND_FETCHING, REFUND_REQUEST)]: () => true,
    [combineActions(REFUND_LOAD_SUCCESS, REFUND_LOAD_FAILURE, REFUND_FAILURE)]:
      () => false,
  },
  false
);

const isLoadingRefundReducer = handleActions(
  {
    [combineActions(REFUND_LOAD_REQUEST, REFUND_REQUEST)]: () => true,
    [combineActions(REFUND_LOAD_SUCCESS, REFUND_LOAD_FAILURE, REFUND_FAILURE)]:
      () => false,
  },
  false
);

const isFetchingOrdersReducer = handleActions(
  {
    [combineActions(ORDERS_REQUEST, SINGLE_ORDER_PAGE_REQUEST, REFUND_REQUEST)]:
      () => true,
    [combineActions(
      ORDERS_SUCCESS,
      ORDERS_FAILURE,
      SINGLE_ORDER_PAGE_SUCCESS,
      SINGLE_ORDER_PAGE_FAILURE,
      REFUND_SUCCESS,
      REFUND_FAILURE
    )]: () => false,
  },
  false
);

const getProductTableRow = (state, id) => {
  const order = getOrder(state, id);
  if (!order) return {};

  return {
    ...order,
    uuid: order.id,
    details: {
      reference: order.reference,
      customer: order.customer,
      items: order.items,
    },
    createdAt: order.createdAt,
    status: order.status,
    action: null,
  };
};

const loadOrdersErrorReducer = handleActions(
  {
    [combineActions(
      SINGLE_ORDER_PAGE_REQUEST,
      SINGLE_ORDER_PAGE_SUCCESS,
      ORDERS_REQUEST,
      ORDERS_SUCCESS
    )]: () => null,
    [combineActions(SINGLE_ORDER_PAGE_FAILURE, ORDERS_FAILURE)]: (
      state,
      action
    ) => action.payload,
  },
  null
);

const defaultFilter = {
  searchValue: undefined,
  statusValue: allStatusRegex,
  dateRangeValue: defaultDateRangeValue,
};
const ordersTableFilterReducer = handleActions(
  {
    [SET_ORDERS_TABLE_FILTER]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [RESET_ORDERS_TABLE]: () => defaultFilter,
  },
  defaultFilter
);

const ordersTablePageReducer = handleActions(
  {
    [SET_ORDERS_TABLE_PAGE]: (state, action) => action.payload,
    [ORDERS_TABLE_NEXT_PAGE]: (state) => state + 1,
    [ORDERS_TABLE_PREVIOUS_PAGE]: (state) => state - 1,
    [combineActions(RESET_ORDERS_TABLE, RESET_ORDERS_TABLE_PAGE)]: () => 1,
  },
  1
);

const defaultSort = { id: 'createdAt', sortAscending: false };
const ordersTableSortReducer = handleActions(
  {
    [SET_ORDERS_TABLE_SORT]: (state, action) => action.payload,
    [RESET_ORDERS_TABLE]: () => defaultSort,
  },
  defaultSort
);

const ordersTablePaginationReducer = handleActions(
  {
    [SET_ORDERS_TABLE_PAGE_SIZE]: (state, action) => ({
      ...state,
      pageSize: action.payload,
    }),
    [ORDERS_SUCCESS]: (state, action) => ({
      ...state,
      totalElements: action.payload.meta.orders.meta.totalElements,
    }),
  },
  { pageSize: 1, totalElements: 0 }
);

export const getOrderContainer = getProductTableRow;

const getOrders = (state) => {
  const {
    orders: { byId: ordersById },
  } = state;

  return map(ordersById, (order, id) => getProductTableRow(state, id));
};

export const isFetchingOrders = (state) => state.orders.isFetchingOrders;
export const getRefund = (state) => state.orders.refund;
export const isFetchingRefund = (state) => state.orders.isFetchingRefund;
export const isLoadingRefund = (state) => state.orders.isLoadingRefund;
export const getOrderActivities = (state) => state.orders.activities;
export const getLoadOrdersError = (state) => state.orders.loadOrdersError;
export const getOrdersTablePage = (state) => state.orders.ordersTablePage;
export const getOrdersTableFilter = (state) => state.orders.ordersTableFilter;
export const getOrdersTableSort = (state) => state.orders.ordersTableSort;
export const getOrdersTableTotalElements = (state) =>
  state.orders.ordersTablePagination.totalElements;
export const getOrdersTablePageSize = (state) =>
  state.orders.ordersTablePagination.pageSize;
export const getOrdersTable = (state) => {
  const {
    orders: {
      ordersTablePage: page,
      ordersTablePagination: { pageSize, totalElements },
      ordersTableSort: sort,
      isFetchingOrders: isFetching,
    },
  } = state;

  return {
    isFetching,
    page,
    pageSize,
    totalElements,
    sort,
    data: getOrders(state),
  };
};

export const getOrdersTableFilters = (state) => ({
  dateRanges,
  statuses: orderFilterStatuses,
  ...state.orders.ordersTableFilter,
});

export const getOrdersScope = (state) => {
  const {
    orders: {
      ordersTablePage: page,
      ordersTablePagination: { pageSize },
      ordersTableSort: sort,
      ordersTableFilter: {
        searchValue: search,
        statusValue: status,
        dateRangeValue: dateRange,
      },
    },
  } = state;

  return {
    dateRange,
    page,
    pageSize,
    search,
    sort,
    status,
  };
};

export default combineReducers({
  byId,
  activities: activitiesReducer,
  isFetchingOrders: isFetchingOrdersReducer,
  refund: refundReducer,
  isFetchingRefund: isFetchingRefundReducer,
  isLoadingRefund: isLoadingRefundReducer,
  loadOrdersError: loadOrdersErrorReducer,
  ordersTablePage: ordersTablePageReducer,
  ordersTableFilter: ordersTableFilterReducer,
  ordersTableSort: ordersTableSortReducer,
  ordersTablePagination: ordersTablePaginationReducer,
});
