import {
  utcDateFormatter,
  displayUtcDate,
  isSameDay,
} from 'common/services/date';

const settlementsPeriodFormatter = (start, end) => {
  const startDateFormatter = utcDateFormatter(start);
  const endDateFormatter = utcDateFormatter(end);

  const sameDay = isSameDay(start, end);

  return `${startDateFormatter('dd MMM, yy')}${
    sameDay ? '' : ` - ${endDateFormatter('dd MMM, yy')}`
  }`;
};

const reportPeriodFormatter = (start, end) =>
  `${displayUtcDate(start, 'yyyy/MM/dd')} - ${displayUtcDate(
    end,
    'yyyy/MM/dd'
  )}`;

export { settlementsPeriodFormatter, reportPeriodFormatter };
