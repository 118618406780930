import React from 'react';
import propTypes from 'prop-types';
import { motion } from 'framer-motion';

import { KybSteps } from '../ProgressSteps';

import styles from './index.module.css';

const KybLayout = ({ children, integrationStatus, complianceStatus }) => (
  <div className={styles.root}>
    <div className={styles.container}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={styles.column}
      >
        <div className={styles.timeline}>
          <KybSteps
            integrationStatus={integrationStatus}
            complianceStatus={complianceStatus}
          />
        </div>
      </motion.div>
      <div className={styles.column}>{children}</div>
    </div>
  </div>
);

KybLayout.propTypes = {
  children: propTypes.node.isRequired,
  integrationStatus: propTypes.string.isRequired,
  complianceStatus: propTypes.string.isRequired,
};

export default KybLayout;
