import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';

import { api } from 'app/services';
import Card from '../components/Card';
import { Active, Disable, Reset } from '../components/TwoFactorEnabled';

import { handleError, parseSecret } from './utils';

const stepOptions = {
  initial: {
    Component: Active,
    propKeys: ['onDisableClick', 'onResetClick'],
  },
  disable: {
    Component: Disable,
    propKeys: ['onCodeSubmit', 'onCancelClick', 'errorMessage'],
  },
  reset: {
    Component: Reset,
    propKeys: ['onCancelClick', 'onResetSubmit'],
  },
};

const TwoFactorEnabled = ({ apiError, id, twoFactorAuthChange }) => {
  const [step, setStep] = useState('initial');
  const [errorMessage, setErrorMessage] = useState();

  const handleCodeSubmit = async ({ authenticatorCode }) => {
    const { error } = await api.disableTwoFactorAuth(id, authenticatorCode);

    if (!error) return twoFactorAuthChange({ tfaActive: false });
    const newErrorMessage = handleError(error);

    setErrorMessage(newErrorMessage);
    if (error !== 'invalid_token') apiError(error);
    //Returning a truthy value signals form to reset
    return newErrorMessage;
  };

  const handleResetSubmit = async ({ recoveryCode }) => {
    const { error, response } = await api.resetTwoFactorauth(id, recoveryCode);
    if (response) {
      return twoFactorAuthChange({
        tfaActive: false,
        ...parseSecret(response.tfaSecret),
      });
    }

    const newErrorMessage = handleError(error);
    setErrorMessage(newErrorMessage);
    if (error !== 'invalid_token') apiError(error);
    return newErrorMessage;
  };

  const handleCancelClick = () => {
    setErrorMessage('');
    setStep('initial');
  };

  const allProps = {
    onDisableClick: () => setStep('disable'),
    onResetClick: () => setStep('reset'),
    onCancelClick: handleCancelClick,
    onCodeSubmit: handleCodeSubmit,
    onResetSubmit: handleResetSubmit,
    errorMessage,
  };
  const { Component, propKeys } = stepOptions[step];
  const props = pick(allProps, propKeys);

  return (
    <Card heading="Two-factor authentication" id="twofactor-profile-enable">
      <Component {...props} />
    </Card>
  );
};

TwoFactorEnabled.propTypes = {
  apiError: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  twoFactorAuthChange: PropTypes.func.isRequired,
};

export default TwoFactorEnabled;
