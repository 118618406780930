import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';

import { displayUtcDate } from 'common/services/date';
import { webhookProptype } from 'integrations/propTypes';

import Cell from 'common/components/Cell';
import Text from 'common/components/Text';

import styles from './index.module.css';

const webhookStatus = (status) =>
  status === 'failed_and_notified' ? 'failed' : status;

const WebhooksTableRow = ({ webhook }) => {
  const history = useHistory();
  const navigate = () => {
    history.push(`/integrations/webhooks/${webhook.id}`);
  };

  const eventType = _.get(webhook, 'params.eventType');
  const entityReference = _.get(webhook, 'params.resource.reference');

  const pillClassNames = classNames(styles.pill, styles[webhook.status]);

  return (
    <>
      <tr
        className={classNames(styles.row, styles.desktop)}
        onClick={navigate}
        data-test="webhooks-table-row"
      >
        <Cell>{eventType}</Cell>
        <Cell>{webhook.id}</Cell>
        <Cell>{entityReference}</Cell>
        <Cell align="right">{`${displayUtcDate(
          webhook.updatedAt,
          'yyyy-MM-dd HH:mm'
        )} UTC`}</Cell>
        <Cell align="right">
          <span className={pillClassNames}>
            {_.capitalize(webhookStatus(webhook.status))}
          </span>
        </Cell>
      </tr>
      <tr className={classNames(styles.row, styles.mobile)} onClick={navigate}>
        <Cell>
          <Text>{eventType}</Text>
          <Text size="caption" color="rebranding-md-color-grey-60">
            {`${displayUtcDate(webhook.updatedAt, 'yyyy-MM-dd HH:mm')} UTC`}
          </Text>
          <span className={pillClassNames}>
            {_.capitalize(webhookStatus(webhook.status))}
          </span>
        </Cell>
      </tr>
    </>
  );
};

WebhooksTableRow.propTypes = {
  webhook: webhookProptype,
};

export default WebhooksTableRow;
