import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const Title = ({ heading }) => (
  <>
    <div className={styles.logoWrapper}>
      <LogoxMoney />
    </div>
    <Heading level="1" size="1" color="blue">
      {heading}
    </Heading>
  </>
);

Title.propTypes = {
  heading: PropTypes.string.isRequired,
};

const Description = () => (
  <>
    <Title heading="Accept crypto payments now" />
    <div className={styles.headingWrapper}>
      <Heading level="3" size="3" color="grey-60">
        It takes under 5 minutes to complete this registration. Just please make
        sure you have the following info at hand:
      </Heading>
    </div>
    <ul className={styles.list}>
      <li className={styles.listItem}>Legal name</li>
      <li className={styles.listItem}>Legal address</li>
      <li className={styles.listItem}>VAT number (if applicable)</li>
    </ul>
    <span className={styles.sandboxLink}>
      <p>Are you a developer? Just want to test it?</p>
      <Button kind="subtle">
        <a
          href="https://merchants.sandbox.crypto.xmoney.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Try our sandbox
        </a>
      </Button>
      .
    </span>
  </>
);

const DescriptionSandbox = () => (
  <>
    <Title heading="Welcome to the xMoney sandbox environment" />
    <span className={styles.productionLink}>
      Ready to go live?{' '}
      <Button kind="subtle">
        <a
          href="https://merchants.crypto.xmoney.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to production
        </a>
      </Button>
    </span>
  </>
);

export { Description, DescriptionSandbox };
