import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'SET_PASSWORD_REQUEST',
  'SET_PASSWORD_SUCCESS',
  'SET_PASSWORD_FAILURE',
  'KYB_DOCUMENTS_REQUEST',
  'KYB_DOCUMENTS_SUCCESS',
  'KYB_DOCUMENTS_FAILURE',
  'KYB_CREATE_DOCUMENT_REQUEST',
  'KYB_CREATE_DOCUMENT_SUCCESS',
  'KYB_CREATE_DOCUMENT_FAILURE',
  'KYB_DELETE_DOCUMENT_REQUEST',
  'KYB_DELETE_DOCUMENT_SUCCESS',
  'KYB_DELETE_DOCUMENT_FAILURE',
]);

const actionsCreators = createActions(
  actionTypes.SET_PASSWORD_REQUEST,
  actionTypes.SET_PASSWORD_SUCCESS,
  actionTypes.SET_PASSWORD_FAILURE,
  actionTypes.KYB_DOCUMENTS_REQUEST,
  actionTypes.KYB_DOCUMENTS_SUCCESS,
  actionTypes.KYB_DOCUMENTS_FAILURE,
  actionTypes.KYB_CREATE_DOCUMENT_REQUEST,
  actionTypes.KYB_CREATE_DOCUMENT_SUCCESS,
  actionTypes.KYB_CREATE_DOCUMENT_FAILURE,
  actionTypes.KYB_DELETE_DOCUMENT_REQUEST,
  actionTypes.KYB_DELETE_DOCUMENT_SUCCESS,
  actionTypes.KYB_DELETE_DOCUMENT_FAILURE
);

export default {
  ...actionTypes,
  ...actionsCreators,
};
