import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Item from '../Item';
import GroupLabel from '../GroupLabel';

import { ITEM_HEIGHT, getMaxHeight } from './utils';
import { groupProptypes } from '../propTypes';

import styles from './index.module.css';

// each group length + group label
const totalItems = (items) =>
  items.reduce((acc, group) => acc + group.groupItems.length + 1, 0);

const ItemsGroup = ({
  isOpen,
  items,
  selectedValue,
  getProps,
  downshiftProps,
  downshiftState,
}) => {
  const maxHeight = `${getMaxHeight(totalItems(items), downshiftState)}px`;

  const itemsReducer = (result, group, groupIndex) => {
    const newResult = { ...result };

    newResult.groups.push(
      <div key={groupIndex}>
        <GroupLabel
          style={{ height: `${ITEM_HEIGHT}px` }}
          key={group.value}
          label={group.groupLabel}
        />
        {group.groupItems.map((item) => {
          const index = newResult.itemIndex++;
          return (
            <Item
              style={{ height: `${ITEM_HEIGHT}px` }}
              {...downshiftProps.getItemProps({
                item,
                ...getProps(downshiftProps, item, index, selectedValue),
              })}
              key={item.value}
              name={item.value}
              label={item.label}
              isInnerItem
              withCheckbox={false}
            />
          );
        })}
      </div>
    );

    return newResult;
  };

  return (
    <div
      style={{ maxHeight }}
      className={classnames(styles.options, {
        [styles['has-items']]: isOpen && items.length,
      })}
    >
      {isOpen &&
        items.reduce(itemsReducer, { groups: [], itemIndex: 0 }).groups}
    </div>
  );
};

ItemsGroup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: groupProptypes.isRequired,
  selectedValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  getProps: PropTypes.func.isRequired,
  downshiftProps: PropTypes.shape({ getItemProps: PropTypes.func.isRequired }),
  downshiftState: PropTypes.string.isRequired,
};

export default ItemsGroup;
