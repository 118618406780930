import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, reject } from 'lodash';

import styles from './index.module.css';

const Heading = ({ level, size, color, weight, children, id }) => {
  const Level = `h${level}`;
  const sizeHeading = `h${size}`;
  const rootStyles = reject(
    [styles[sizeHeading], styles[color], styles[weight]],
    isEmpty
  ).join(' ');

  return (
    <Level className={rootStyles} id={id}>
      {children}
    </Level>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf(['1', '2', '3']),
  size: PropTypes.oneOf(['1', '2', '3']),
  weight: PropTypes.oneOf(['regular', 'medium', 'semiBold']),
  color: PropTypes.oneOf([
    'white',
    'black',
    'blue',
    'grey-60',
    'grey-40',
    'green',
  ]),
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Heading.defaultProps = {
  level: '1',
  size: '1',
  color: 'black',
  weight: 'regular',
  id: null,
};

export default Heading;
