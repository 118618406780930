import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';

import styles from './index.module.css';

const BackButton = ({ onGoBack }) => {
  const handleClick = (evt) => {
    evt.preventDefault();
    onGoBack();
  };

  return (
    <button
      type="button"
      role="link"
      className={styles.root}
      data-test="back-button"
      onClick={handleClick}
    >
      <Icon.Arrow description="Back button" size="large" direction="left" />
    </button>
  );
};

BackButton.propTypes = {
  onGoBack: PropTypes.func.isRequired,
};

export default BackButton;
