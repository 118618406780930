import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import Text from 'common/components/Text';
import SortIcon from '../SortIcon';

import styles from './index.module.css';

const ColumnHeader = ({ title, sortProperty, columnId }) => (
    <span
      className={classNames(
        styles['column-header'],
        styles[_.camelCase(columnId)],
        {
          [styles['column-active']]: !!sortProperty,
        }
      )}
    >
      <Text weight="semiBold" size="caption">
        {title}
      </Text>
      {sortProperty && (
        <span className={styles['icon-sort']}>
          <SortIcon sortAscending={sortProperty.sortAscending} />
        </span>
      )}
    </span>
  );

ColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  sortProperty: PropTypes.shape({
    id: PropTypes.string,
    sortAscending: PropTypes.bool,
  }),
  columnId: PropTypes.string.isRequired,
};

ColumnHeader.defaultProps = {
  sortProperty: null,
};

export default ColumnHeader;
