import React from 'react';

import Heading from 'common/components/Heading';
import Icon from 'common/components/Icon';
import Button from 'common/components/Button';

import styles from './index.module.css';

const ErrorPage = () => {
  const reloadPage = () => window.location.reload(true);
  return (
    <div className={styles.root}>
      <Heading level="2" size="2" color="grey-40">
        Could not load your settings
      </Heading>
      <Icon.NoPersonal description="Something went wrong. Try to reload th page." />
      <Button
        kind="primary"
        onClick={reloadPage}
        icon={<Icon.Retry description="Press to Reload" />}
        orderReverse
      >
        <button type="submit">Retry</button>
      </Button>
    </div>
  );
};

export default ErrorPage;
