import React, { Component } from 'react';
import { includes } from 'lodash';
import Downshift from 'downshift';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';

import ValidationError from 'common/components/ValidationError';
import Button from '../Button';
import SearchableInput from '../SearchableInput';
import ItemsList from './ItemsList';
import ItemsGroup from './ItemsGroup';
import {
  NO_RESULTS_OPTION,
  ALL_OPTION,
  getLabel,
  getMultiLabel,
  isAllOption,
  itemToString,
  selectedItemCallbacks,
  stateReducer,
  searchableListItems,
  searchableGroupItems,
} from './utils';
import { itemListProptypes, groupProptypes } from '../propTypes';

import styles from './index.module.css';

class DropdownBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isListOpen: false,
      items:
        this.isMulti() && props.onAllOption
          ? [ALL_OPTION, ...props.items]
          : props.items,
    };
  }

  getOptionsFromType = {
    multi: (props) => ({
      selectedValue: props.selectedItem.map(({ value }) => value),
      items: this.state.items,
      getProps: this.itemMultiProps,
    }),
    dropdownList: (props) => ({
      selectedValue: props.selectedItem,
      items: this.state.items,
      getProps: this.itemProps,
    }),
    searchableList: (props) => ({
      selectedValue: props.selectedItem,
      items: searchableListItems(this.state.items, props.inputValue),
      getProps: this.itemProps,
    }),
    dropdownGroup: (props) => ({
      selectedValue: props.selectedItem,
      items: this.state.items,
      getProps: this.itemProps,
    }),
    searchableGroup: (props) => ({
      selectedValue: props.selectedItem,
      items: searchableGroupItems(this.state.items, props.inputValue),
      getProps: this.itemProps,
    }),
  };

  isMulti = () => this.props.type === 'multi';

  isSearchable = () => {
    const { type } = this.props;

    return type === 'searchableList' || type === 'searchableGroup';
  };

  isGroup = () => {
    const { type } = this.props;

    return type === 'searchableGroup' || type === 'dropdownGroup';
  };

  setIsListOpen = (value) => {
    this.setState({ isListOpen: value });
  };

  showList = () => {
    this.setIsListOpen(true);
  };

  hideList = () => {
    this.setIsListOpen(false);
  };

  handleChange = (option) => {
    const { onChange, onAllOption } = this.props;

    if (!option && this.isSearchable()) return onChange(null);

    // Prevent `esc` from triggering onChange(null) - library's bug: https://github.com/downshift-js/downshift/issues/719
    if (option) {
      const { value } = option;
      const callback = isAllOption(value) ? onAllOption : onChange;
      callback(value);
    }
  };

  itemProps = ({ highlightedIndex }, item, index, selectedValue) => ({
    isActive: highlightedIndex === index,
    isSelected: selectedValue === item,
  });

  itemMultiProps = ({ highlightedIndex }, { value }, index, selectedValues) => {
    const { items } = this.state;
    const itemsLength = items.length - 1;
    const selectedValuesLength = selectedValues.length;

    return {
      isActive: highlightedIndex === index,
      isSelected: isAllOption(value)
        ? selectedValuesLength === itemsLength
        : includes(selectedValues, value),
      isIndeterminate:
        isAllOption(value) &&
        selectedValuesLength > 0 &&
        selectedValuesLength !== itemsLength,
    };
  };

  displayNoResults = (filteredItems) => {
    const { items } = this.props;
    return this.isSearchable() && !filteredItems.length && !!items.length;
  };

  renderOptions = (props, state) => {
    const { type } = this.props;
    const { isListOpen } = this.state;
    const { selectedValue, items, getProps } =
      this.getOptionsFromType[type](props);
    const displayNoResults = this.displayNoResults(items);
    const itemsToDisplay = displayNoResults ? [NO_RESULTS_OPTION] : items;
    const OptionsComponent =
      this.isGroup() && !displayNoResults ? ItemsGroup : ItemsList;

    return (
      <OptionsComponent
        isOpen={isListOpen}
        items={itemsToDisplay}
        selectedValue={selectedValue}
        withCheckbox={this.isMulti()}
        getProps={getProps}
        downshiftProps={props}
        downshiftState={state}
      />
    );
  };

  selectedItem = () => {
    const { selectedItem, type } = this.props;
    const { items } = this.state;

    const selectedItemCallback = selectedItemCallbacks[type];

    return selectedItemCallback(items, selectedItem);
  };

  getDisplayedValue = () => {
    const { placeholder } = this.props;
    const labelCallback = this.isMulti() ? getMultiLabel : getLabel;

    return labelCallback(this.selectedItem(), placeholder);
  };

  getInputDisplayValue = (inputValue) => {
    const selectedItem = this.selectedItem();

    // return to previous value when leaving input with no match
    if (inputValue === undefined && selectedItem) return selectedItem.label;

    // pick matched label when selected
    if (selectedItem && selectedItem.value === inputValue)
      return selectedItem.label;

    return inputValue;
  };

  renderButton = (props) => {
    const { isOpen, getToggleButtonProps } = props;
    const { name, error } = this.props;

    return (
      <Button
        open={isOpen}
        name={`dropdown-button-${name}`}
        buttonProps={getToggleButtonProps()}
        error={error}
      >
        {this.getDisplayedValue()}
      </Button>
    );
  };

  renderInput = (props) => {
    const {
      isOpen,
      getInputProps,
      getToggleButtonProps,
      clearSelection,
      inputValue,
    } = props;
    const { placeholder, name, error } = this.props;

    return (
      <SearchableInput
        error={error}
        open={isOpen}
        name={`dropdown-input-${name}`}
        selectedItem={this.selectedItem()}
        buttonProps={getToggleButtonProps()}
        inputProps={getInputProps({
          placeholder,
          value: this.getInputDisplayValue(inputValue),
          autoComplete: `new-${name}`, // disables browser autofill which blocks the dropdown
        })}
        clearSelection={clearSelection}
      />
    );
  };

  renderDownshift = (props) => {
    const { isOpen } = props;

    return (
      <div className={styles.root}>
        {this.isSearchable()
          ? this.renderInput(props)
          : this.renderButton(props)}
        <div className={styles.optionsHolder}>
          <Transition
            classNames="dropdown"
            in={isOpen}
            timeout={{ exit: 300 }}
            onEnter={() => this.showList()}
            onExited={() => this.hideList()}
          >
            {(state) =>
              this.renderOptions(
                {
                  ...props,
                  selectedItem: this.selectedItem(),
                },
                state
              )
            }
          </Transition>
        </div>
      </div>
    );
  };

  render() {
    const { id, name, error, errorText } = this.props;

    return (
      <>
        <Downshift
          id={id}
          name={name}
          selectedItem={this.selectedItem}
          itemToString={itemToString}
          onChange={this.handleChange}
          stateReducer={stateReducer(this.isMulti(), this.isSearchable())}
        >
          {this.renderDownshift}
        </Downshift>
        {error && errorText && (
          <ValidationError errorString={errorText} id={name} />
        )}
      </>
    );
  }
}

DropdownBase.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf([
    'multi',
    'dropdownList',
    'searchableList',
    'dropdownGroup',
    'searchableGroup',
  ]).isRequired,
  onAllOption: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  items: PropTypes.oneOfType([itemListProptypes, groupProptypes]).isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorText: PropTypes.string,
};

DropdownBase.defaultProps = {
  id: undefined,
  name: undefined,
  placeholder: 'Select an item',
  selectedItem: null,
  onChange: () => {},
  onAllOption: () => {},
};

export default DropdownBase;
