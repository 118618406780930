import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';
import classNames from 'classnames';

import styles from './index.module.css';
import files from './svg';

const withIcon = (WrappedComponent) => {
  //storybook stories break if converted to functional + memo
  class Icon extends PureComponent {
    render() {
      const { size, description, className, ...rest } = this.props;

      return (
        <div className={classNames(styles[size], className)}>
          <WrappedComponent role="img" aria-label={description} {...rest} />
        </div>
      );
    }
  }

  Icon.propTypes = {
    description: PropTypes.string,
    size: PropTypes.oneOf([
      'auto',
      'small',
      'medium',
      'xSmall',
      'large',
      'xLarge',
    ]),
    className: PropTypes.string,
  };

  Icon.defaultProps = {
    description: 'Icon',
    size: 'auto',
    className: '',
  };

  return Icon;
};

const Icons = reduce(
  files,
  (res, value, key) => ({
    ...res,
    [key]: withIcon(value),
  }),
  {}
);

export { withIcon, files as file };

export default {
  ...Icons,
};
