import PropTypes from 'prop-types';
import { amount as amountType } from 'common/propTypes';

export const paidStatus = 'paid';
export const inDisputeStatus = 'in_dispute';
export const orderRefundCompletedStatus = 'refund_completed';
export const refundProposedStatus = 'refund_proposed';
export const refundProcessingStatus = 'refund_processing';
export const resentStatus = 'resent';
export const pendingStatus = 'pending';
export const canceledStatus = 'canceled';
export const cancelledStatus = 'cancelled'; //retro compatibile
export const expiredStatus = 'expired';
export const inClaimStatus = 'in_claim';

const orderStatusList = [
  paidStatus,
  inDisputeStatus,
  refundProcessingStatus,
  orderRefundCompletedStatus,
  refundProposedStatus,
  resentStatus,
  pendingStatus,
  canceledStatus,
  cancelledStatus,
  expiredStatus,
  inClaimStatus,
];
export const orderStatus = PropTypes.oneOf(orderStatusList);
export const refundAction = 'refund';
export const resendAction = 'resend';
export const orderAction = PropTypes.oneOf([refundAction, resendAction]);

export const paymentActivity = PropTypes.shape({
  id: PropTypes.number,
  createdAt: PropTypes.string,
  eventType: PropTypes.string,
  data: PropTypes.shape({}),
});

export const buyer = 'buyer';
export const merchant = 'merchant';

export const store = {
  id: PropTypes.string,
  name: PropTypes.string,
};

export const pricingDetails = PropTypes.shape({
  subtotal: amountType,
  tax: amountType,
  shipping: amountType,
  discount: amountType,
});

export const order = {
  id: PropTypes.string,
  type: PropTypes.string,
  totalAmount: amountType,
  settlementAmount: amountType,
  details: PropTypes.shape({}),
  createdAt: PropTypes.string,
  status: orderStatus,
  action: orderAction,
  pricingDetails,
};

export const refundProposed = 'proposed';
export const refundRejected = 'rejected';
export const refundAccepted = 'accepted';
export const refundProcessing = 'processing';
export const refundComplete = 'complete';

export const refundStatus = PropTypes.oneOf([
  refundProposed,
  refundRejected,
  refundAccepted,
  refundProcessing,
  refundComplete,
]);

export const refund = PropTypes.shape({
  status: refundStatus.isRequired,
  reason: PropTypes.string,
  requestedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  requestedCurrency: PropTypes.string.isRequired,
  error: PropTypes.string,
});

export const refundingStatusRegex = `${refundProposedStatus},${refundProcessingStatus}`;
export const allStatusRegex = '.*';
export const statusTableFilter = PropTypes.oneOf([
  ...orderStatusList,
  refundingStatusRegex,
  allStatusRegex,
]);

export const tableFilterStatusesProp = PropTypes.arrayOf(
  PropTypes.shape({
    value: statusTableFilter.isRequired,
    label: PropTypes.string,
  })
);

export const all = 'all';
export const currentDay = 'current_day';
export const lastDay = 'last_day';
export const currentWeek = 'current_week';
export const lastWeek = 'last_week';
export const currentMonth = 'current_month';
export const lastMonth = 'last_month';

export const dateRangeTableFilter = PropTypes.oneOf([
  all,
  currentDay,
  lastDay,
  currentWeek,
  lastWeek,
  currentMonth,
  lastMonth,
]);

export const tableFilterDateRangesProp = PropTypes.arrayOf(
  PropTypes.shape({
    value: dateRangeTableFilter.isRequired,
    label: PropTypes.string,
  })
);

export const tableFilter = PropTypes.shape({
  searchValue: PropTypes.string,
  statusValue: statusTableFilter.isRequired,
});

const sortFields = [
  'createdAt',
  'details.reference',
  'details.customer.email',
  'totalAmount.currency',
  'totalAmount.value',
  'status',
];

export const ordersSortProp = PropTypes.shape({
  id: PropTypes.oneOf(sortFields).isRequired,
  sortAscending: PropTypes.bool.isRequired,
});

export const managePermission = 'manage';
export const unauthorizedPermission = 'unauthorized';
export const permission = PropTypes.oneOf([
  managePermission,
  unauthorizedPermission,
]);

export const permissions = PropTypes.shape({
  loading: PropTypes.bool,
  error: PropTypes.string,
  refunds: permission,
  storeCredentials: permission,
  upholdConnect: permission,
  invoicing: permission,
});

export const paymentsTablePropTypes = {
  components: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(order)).isRequired,
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  paginationEvents: PropTypes.func.isRequired,
  sort: ordersSortProp.isRequired,
  totalElements: PropTypes.number.isRequired,
};

export const invoicePropType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    billingAddress: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string,
    country: PropTypes.string.isRequired,
  }),
  description: PropTypes.string,
  reference: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  totalAmount: amountType.isRequired,
  paymentUrl: PropTypes.string.isRequired,
});

export const paginationHelperProptypes = {
  data: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    setPageSize: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
  }),
};

export { amountType };
