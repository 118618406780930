import React from 'react';
import PropTypes from 'prop-types';

import { amount as amountPropType } from 'common/propTypes';
import {
  isCrypto,
  cryptoValueWithCurrency,
  fiatValueWithCurrency,
  roundFiat,
} from 'common/currencies';
import colors from 'common/styles/colors';
import Text from 'common/components/Text';

import styles from './index.module.css';

const AmountStrip = ({
  heading,
  amount,
  referenceAmount,
  color,
  background,
  id,
}) => (
  <div
    className={styles.root}
    style={{ background: colors[background], color: colors[color] }}
    id={id}
  >
    <Text size="caption" color={color}>
      {heading}
    </Text>
    <span className={styles.value}>
      {isCrypto(amount.currency)
        ? cryptoValueWithCurrency(amount.value, amount.currency)
        : fiatValueWithCurrency(roundFiat(amount.value), amount.currency)}
    </span>
    {referenceAmount && (
      <Text>
        {fiatValueWithCurrency(
          roundFiat(referenceAmount.value),
          referenceAmount.currency
        )}
      </Text>
    )}
  </div>
);

AmountStrip.propTypes = {
  heading: PropTypes.string,
  amount: amountPropType.isRequired,
  referenceAmount: amountPropType,
  color: PropTypes.oneOf(Object.keys(colors)),
  background: PropTypes.oneOf(Object.keys(colors)),
  id: PropTypes.string,
};

AmountStrip.defaultProps = {
  heading: 'Total',
  color: 'rebranding-md-color-brand-black',
  background: 'color-white',
  id: null,
};

export default AmountStrip;
