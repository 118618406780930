import { fiatValueWithCurrencySymbol } from 'common/currencies';

const shortenAddress = (address, head, tail) => {
  if (!address) return null;
  return `${address.slice(0, head || 6)}...${address.slice(-tail || -4)}`;
};

const buildInvoiceSharingString = ({
  amount,
  currency,
  customerName,
  reference,
  url,
}) =>
  `Hey ${customerName} 👋,

You have a payment request labeled ${reference} in the amount of ${fiatValueWithCurrencySymbol(
    amount,
    currency
  )}.
All you need to do is open this link, select your preferred cryptocurrency and pay from any wallet of your choice:

${url}

Payments are processed on-chain by xMoney. ✅`;

export default {
  buildInvoiceSharingString,
  shortenAddress,
};
