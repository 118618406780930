import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { selectors as authSelectors } from 'auth';
import { useWindowWidth } from 'common/hooks';

import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import { selectors as sessionSelectors } from 'session';
import { pageSizeOptions, defaultPageSize } from 'payments/tableUtils';
import {
  getInvoicesTable,
  getInvoicesTableFilters,
} from '../reducers/invoices';
import actions from '../actions';
import Payments from '../components/Payments';
import { order as orderPropType, managePermission } from '../propTypes';

const Invoices = ({
  isInvoicingEnabled,
  canCreateInvoice,
  setPageSize,
  data,
  isFetching,
  totalElements,
  dateRangeValue,
  searchValue,
  statusValue,
  page,
  pageSize,
  complianceStatus,
}) => {
  const tableRef = useRef();
  const noInvoices = isEmpty(data);
  const windowWidth = useWindowWidth();

  const noDataYet =
    dateRangeValue === 'all' &&
    !searchValue &&
    statusValue === '.*' &&
    noInvoices;

  const history = useHistory();
  const navigateToNewInvoice = () => {
    history.push('/payments/invoices/new');
  };

  // setPagesize action triggers invoicesRequest through sagas
  useEffect(() => {
    setPageSize(defaultPageSize);
  }, []);

  if (!isInvoicingEnabled) {
    return <Redirect to="/payments/orders" />;
  }

  return (
    <Payments
      isFetching={isFetching}
      newAction={
        <Button
          full
          icon={<Icon.Plus description={`Plus`} size="medium" />}
          kind="primary"
          onClick={navigateToNewInvoice}
          disabled={!canCreateInvoice}
        >
          <button type="button" data-test="button-new-invoice">
            New invoice
          </button>
        </Button>
      }
      noData={noInvoices}
      noDataYet={noDataYet}
      complianceStatus={complianceStatus}
      paymentType="invoices"
      paginationHelperData={{
        page,
        pageSize,
        pageSizeOptions,
        setPageSize,
        totalElements,
      }}
      tableRef={tableRef}
      windowWidth={windowWidth}
      isInvoicingEnabled={isInvoicingEnabled}
    />
  );
};

Invoices.propTypes = {
  isInvoicingEnabled: PropTypes.bool.isRequired,
  canCreateInvoice: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func.isRequired,
  complianceStatus: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape(orderPropType)),
  isFetching: PropTypes.bool.isRequired,
  totalElements: PropTypes.number,
  dateRangeValue: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  statusValue: PropTypes.string.isRequired,
};

const ConnectedInvoicesContainer = connect(
  (state) => {
    const { data, isFetching, totalElements, page, pageSize } =
      getInvoicesTable(state);
    const { dateRangeValue, searchValue, statusValue } =
      getInvoicesTableFilters(state);

    return {
      isInvoicingEnabled:
        authSelectors.getPermissions(state)?.invoicing === managePermission,
      complianceStatus:
        sessionSelectors.activeOrganization(state)?.complianceStatus,
      canCreateInvoice:
        authSelectors.invoiceCreationPermission(state) === managePermission,
      data,
      dateRangeValue,
      isFetching,
      page,
      pageSize,
      searchValue,
      statusValue,
      totalElements,
    };
  },
  {
    setPageSize: actions.setInvoicesTablePageSize,
  }
)(Invoices);

export default ConnectedInvoicesContainer;
