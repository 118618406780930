import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors as featuresSelectors } from 'features';
import NotFound from 'common/components/NotFound';

import { route as routePropType } from 'common/propTypes';
import APIIntegrationsPage from './APIIntegrations';
import WebhooksContainer from '../containers/Webhooks';
import WebhookContainer from '../containers/Webhook';

const Routes = ({ isWebhooksActive, match }) => {
  const apiRoute = `${match.path}/api_keys`;
  const webhooksRoute = `${match.path}/webhooks`;

  return (
    <Switch>
      <Route exact path={apiRoute} component={APIIntegrationsPage} />
      <Route
        exact
        path={webhooksRoute}
        component={isWebhooksActive ? WebhooksContainer : NotFound}
      />
      <Route
        path={`${webhooksRoute}/:id`}
        component={isWebhooksActive ? WebhookContainer : NotFound}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Routes.propTypes = {
  isWebhooksActive: PropTypes.bool.isRequired,
  ...routePropType,
};

export default connect((state) => ({
  isWebhooksActive: featuresSelectors.isWebhooksActive(state),
}))(Routes);
