import React from 'react';
import PropTypes from 'prop-types';

const Info = ({ circleFill, fill, circleStroke, stroke, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    stroke={stroke}
    {...rest}
  >
    <circle
      cx="10"
      cy="10"
      r="7.25"
      fill={circleFill}
      stroke={circleStroke}
      strokeWidth="1.5"
    />
    <path
      d="M10 7.243c-.4 0-.694-.089-.881-.267a.985.985 0 01-.267-.708v-.293c0-.285.089-.517.267-.695.187-.187.48-.28.88-.28.401 0 .69.093.869.28.187.178.28.41.28.695v.293a.96.96 0 01-.28.708c-.178.178-.468.267-.868.267zm-.988.748h1.976V15H9.012V7.99z"
      fill="#none"
    />
  </svg>
);

Info.propTypes = {
  circleFill: PropTypes.string,
  circleStroke: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

Info.defaultProps = {
  circleFill: 'none',
  circleStroke: 'none',
  fill: 'none',
  stroke: 'none',
};

export default Info;
