import React from 'react';
import PropTypes from 'prop-types';

const Invoice = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 20 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29032 0H12.2112L13.5015 1.29032L1.93548 1.29032C1.57917 1.29032 1.29032 1.57917 1.29032 1.93548L1.29032 18.0645C1.29032 18.4208 1.57917 18.7097 1.93548 18.7097H13.5484V20H1.29032C0.577697 20 0 19.4223 0 18.7097V1.29032C0 0.577698 0.577698 0 1.29032 0ZM13.5484 18.7097L13.5484 1.33719L14.8387 2.62751L14.8387 18.7097H13.5484ZM3.11821 3.54895H11.7204V4.98264H3.11821V3.54895ZM3.1184 7.13358H11.7205V8.56727H3.1184V7.13358ZM11.7205 10.7178H3.1184V12.1515H11.7205V10.7178ZM8.85316 14.302H11.7205V15.7357H8.85316V14.302Z"
      fill={fill}
    />
  </svg>
);

Invoice.propTypes = {
  fill: PropTypes.string,
};

Invoice.defaultProps = {
  fill: 'none',
};

export default Invoice;
