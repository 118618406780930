import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { kybBaseInfoSubmitted, kybStarted } from 'common/constants';
import Layout from 'common/containers/Layout';
import Icon from 'common/components/Icon';
import Loading from 'common/components/Loading';
import { AlertDismissable } from 'common/components/Alert';

import { paginationHelperProptypes } from 'payments/propTypes';
import PaymentsFilterContainer from '../../containers/PaymentsFilter';
import ExportContainer from '../../containers/Export';
import PaymentsTableContainer from '../../containers/PaymentsTable';
import PaymentsTabs from '../PaymentsTabs';

import styles from './index.module.css';

const Payments = ({
  isFetching,
  newAction,
  noData,
  noDataYet,
  complianceStatus,
  paymentType,
  settlementCurrency,
  paginationHelperData,
  tableRef,
  windowWidth,
  isInvoicingEnabled,
}) => {
  const [showAlert, setShowAlert] = useState(
    [kybStarted, kybBaseInfoSubmitted].includes(complianceStatus)
  );
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const closeAlert = () => {
    setShowAlert(false);
  };

  const toggleFilters = () => {
    setShowMobileFilters(!showMobileFilters);
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') toggleFilters();
  };
  const filterClasses = classNames(styles.contentFilter, {
    [styles.hiddenMobile]: !showMobileFilters,
  });

  return (
    <Layout heading="Payments">
      <div className={styles.root} role="main" aria-labelledby="page-title">
        <div className={styles.container}>
          <div className={styles.tabsWrapper}>
            <PaymentsTabs isInvoicingEnabled={isInvoicingEnabled} />
            <div
              className={styles.filterTogglerTabs}
              onClick={toggleFilters}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
            >
              {showMobileFilters ? (
                <Icon.CrossThin size="auto" />
              ) : (
                <Icon.Search description="Search input" size="auto" />
              )}
            </div>
            {newAction && (
              <div className={styles.newInvoiceDesktop}>{newAction}</div>
            )}
          </div>
          <div className={filterClasses}>
            <PaymentsFilterContainer
              disabled={noDataYet}
              paymentType={paymentType}
            />
            {/* Line below should be changed when invoice export is implemented */}
            {paymentType === 'orders' && (
              <ExportContainer disabled={noData || isFetching} />
            )}
          </div>
          {newAction && !noDataYet && (
            <div className={styles.newInvoiceMobile}>{newAction}</div>
          )}
          {isFetching ? (
            <div className={styles.spinnerWrapper} ref={tableRef}>
              <Loading kind="secondary" size="large" />
            </div>
          ) : (
            <PaymentsTableContainer
              isEmpty={noData}
              tableRef={tableRef}
              noDataYet={noDataYet}
              paymentType={paymentType}
              settlementCurrency={settlementCurrency}
              windowWidth={windowWidth}
              paginationHelperData={paginationHelperData}
            />
          )}
        </div>
      </div>
      {showAlert && (
        <AlertDismissable
          text={[
            'Do you want to enable settlements right now?',
            <br key="line-break" />,
            `Head over to the Settlements page.`,
          ]}
          onDismiss={closeAlert}
        />
      )}
    </Layout>
  );
};

Payments.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  newAction: PropTypes.node,
  noData: PropTypes.bool.isRequired,
  noDataYet: PropTypes.bool.isRequired,
  complianceStatus: PropTypes.string,
  paymentType: PropTypes.oneOf(['invoices', 'orders']).isRequired,
  settlementCurrency: PropTypes.string,
  paginationHelperData: paginationHelperProptypes,
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  windowWidth: PropTypes.number.isRequired,
  isInvoicingEnabled: PropTypes.bool,
};

export default Payments;
