import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors as featuresSelectors } from '../../features';
import * as settings from '../../settings';
import { pages as auth, selectors as authSelectors } from '../../auth';
import * as onboarding from '../../onboarding';
import * as payments from '../../payments';
import routeSelectionPages from '../../routeSelection';
import * as integrations from '../../integrations';
import * as settlements from '../../settlements';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import {
  settingsRoute,
  forgotPasswordRoute,
  integrationsRoute,
  oldOrdersRoute,
  onboardingRoute,
  ordersRoute,
  paymentsRoute,
  resetPasswordRoute,
  rootRoute,
  settlementsRoute,
  signInRoute,
  signOutRoute,
} from './routes';
import AuthHandler from './AuthHandler';

const RedirectToPayments = ({ location: { pathname } }) => {
  const uuid = pathname?.split('/orders')[1];
  return <Redirect to={ordersRoute + (uuid || '')} />;
};

RedirectToPayments.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const Router = ({ isSSOActive, shouldProceed }) => {
  const [proceeded, setProceeded] = useState(false);

  useEffect(() => {
    if (shouldProceed) {
      setProceeded(true);
    }
  }, [shouldProceed]);

  if (!proceeded) return null;

  const ssoActiveRoutes = [
    <Redirect key={onboardingRoute} from={onboardingRoute} to={rootRoute} />,
    <Redirect key={signInRoute} from={signInRoute} to={rootRoute} />,
    <Redirect
      key={forgotPasswordRoute}
      from={forgotPasswordRoute}
      to={rootRoute}
    />,
    <Redirect
      key={resetPasswordRoute}
      from={resetPasswordRoute}
      to={rootRoute}
    />,
  ];

  const ssoInactiveRoutes = [
    <Route
      key={onboardingRoute}
      path={onboardingRoute}
      component={onboarding.pages}
    />,
    <Route key={signInRoute} path={signInRoute} component={auth.SignInPage} />,
    <PublicRoute
      key={forgotPasswordRoute}
      path={forgotPasswordRoute}
      component={auth.ForgotPasswordPage}
    />,
    <PublicRoute
      key={resetPasswordRoute}
      path={resetPasswordRoute}
      component={auth.ResetPasswordPage}
    />,
  ];

  const privateRoutes = [
    <PrivateRoute
      key={signOutRoute}
      path={signOutRoute}
      component={auth.SignOutPage}
    />,
    <PrivateRoute
      key={oldOrdersRoute}
      path={oldOrdersRoute}
      component={RedirectToPayments}
    />,
    <PrivateRoute
      key={paymentsRoute}
      path={paymentsRoute}
      component={payments.pages}
    />,
    <PrivateRoute
      key={settlementsRoute}
      path={settlementsRoute}
      component={settlements.pages}
    />,
    <PrivateRoute
      key={settingsRoute}
      path={settingsRoute}
      component={settings.pages}
    />,
    <PrivateRoute
      key={integrationsRoute}
      path={integrationsRoute}
      component={integrations.pages}
    />,
    <PrivateRoute
      key={rootRoute}
      path={rootRoute}
      component={routeSelectionPages}
    />,
  ];

  const routerContent = (
    <Switch>
      {isSSOActive
        ? ssoActiveRoutes.concat(privateRoutes)
        : ssoInactiveRoutes.concat(privateRoutes)}
    </Switch>
  );

  return isSSOActive ? (
    <AuthHandler>{routerContent}</AuthHandler>
  ) : (
    routerContent
  );
};
Router.propTypes = {
  shouldProceed: PropTypes.bool.isRequired,
  isSSOActive: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  shouldProceed: authSelectors.proceedRouting(state),
  isSSOActive: featuresSelectors.isSSOActive(state),
}))(Router);
