import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const Clock = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 16 16"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
    />
    <rect x="7.25" y="3" width="1.5" height="6" />
    <rect
      x="12.25"
      y="7.5"
      width="1.5"
      height="5"
      transform="rotate(90 12.25 7.5)"
    />
  </svg>
);

Clock.propTypes = {
  fill: PropTypes.string,
};

Clock.defaultProps = {
  fill: colors['rebranding-md-color-brand-purple'],
};

export default Clock;
