import {
  paidStatus,
  inDisputeStatus,
  refundProposedStatus,
  refundProcessingStatus,
  orderRefundCompletedStatus,
  resentStatus,
  pendingStatus,
  canceledStatus,
  cancelledStatus,
  expiredStatus,
  inClaimStatus,
} from './propTypes';

export const translations = {
  status: 'Status',
  orderId: 'Order ID',
  uuid: 'UUID',
  expiresOn: 'Expires On',
  [paidStatus]: 'Paid',
  [inDisputeStatus]: 'Dispute Open',
  [refundProposedStatus]: 'Refund Proposed',
  [refundProcessingStatus]: 'Refund Processing',
  [orderRefundCompletedStatus]: 'Refund Complete',
  [resentStatus]: 'Resent',
  [pendingStatus]: 'Placed',
  [canceledStatus]: 'Canceled',
  [cancelledStatus]: 'Canceled', //retro compatibile
  [expiredStatus]: 'Expired',
  [inClaimStatus]: 'Claim',
};

export const statuses = {
  [pendingStatus]: 'Placed',
  [canceledStatus]: 'Canceled',
  [cancelledStatus]: 'Canceled', //retro compatibile
  [expiredStatus]: 'Expired',
  [paidStatus]: 'Paid',
  [refundProposedStatus]: 'Refunding',
  [refundProcessingStatus]: 'Refunding',
  [inDisputeStatus]: 'Dispute',
  [orderRefundCompletedStatus]: 'Refunded',
  [resentStatus]: 'Resent',
  [inClaimStatus]: 'Claim',
};

export const invoiceStatuses = {
  [pendingStatus]: 'Pending',
  [canceledStatus]: 'Canceled',
  [cancelledStatus]: 'Canceled',
  [expiredStatus]: 'Expired',
  [paidStatus]: 'Paid',
};

export const statusClass = {
  [paidStatus]: 'paid',
  [pendingStatus]: 'pending',
  [canceledStatus]: 'canceled',
  [cancelledStatus]: 'canceled', //retro compatibile
  [expiredStatus]: 'expired',
  [inDisputeStatus]: 'dispute',
  [inClaimStatus]: 'claim',
  [refundProcessingStatus]: 'refunding',
  [refundProposedStatus]: 'refunding',
  [orderRefundCompletedStatus]: 'refunded',
};
