import React from 'react';
import propTypes from 'prop-types';
import { motion } from 'framer-motion';

import Title from './Title';
import Timeline from './Timeline';

import styles from './index.module.css';

const OnboardingLayout = ({ children, integrationStatus, complianceStatus }) => (
  <div className={styles.root}>
    <div className={styles.container}>
      <Title />
      <div className={styles.row}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.column}
        >
          <Timeline
            integrationStatus={integrationStatus}
            complianceStatus={complianceStatus}
          />
        </motion.div>
        <div className={styles.column}>{children}</div>
      </div>
    </div>
  </div>
);

OnboardingLayout.propTypes = {
  children: propTypes.node.isRequired,
  integrationStatus: propTypes.string.isRequired,
  complianceStatus: propTypes.string.isRequired,
};

export default OnboardingLayout;
