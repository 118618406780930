import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { isString } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';

import { api } from 'app/services';
import AuthPage from '../../common/components/AuthPage';
import Button from '../../common/components/Button';
import formUtils from '../../common/formUtils';
import Heading from '../../common/components/Heading';
import PasswordEmailSent from '../components/PasswordEmailSent';
import TextBox from '../../common/components/TextBox';

import styles from '../index.module.css';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

const Header = () => (
  <>
    <Heading level="1" size="2" weight="medium" id="page-title" color="black">
      Forgot Password
    </Heading>
    <br />
    <Heading level="1" size="3" id="page-description" color="grey-60">
      It happens!
      <br />
      {`We just need your email and we'll get you back to your account in no time.`}
    </Heading>
  </>
);

const Form = ({ onSubmit, submitting, recaptchaRef }) => (
  <Formik
    initialValues={{ email: '' }}
    onSubmit={(values) => onSubmit(values.email)}
  >
    {({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field name="email" validate={formUtils.validateEmail}>
          {({ field, meta }) => (
            <div className={styles.row}>
              <TextBox
                id="email"
                labelText="Email"
                labelType="email"
                placeholder="Your email address"
                error={meta.touched && meta.error}
                errorText={meta.error}
                {...field}
              />
            </div>
          )}
        </Field>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={RECAPTCHA_KEY}
        />
        <span className={styles.formFooter}>
          <div className={styles.buttonWrapperForgot}>
            <Button
              kind="primary"
              loading={submitting}
              disabled={!formUtils.testEmail(values.email)}
              full
            >
              <button
                type="submit"
                id="progress-status"
                data-test="forgot-password-button"
              >
                Reset Password
              </button>
            </Button>
          </div>
        </span>
      </form>
    )}
  </Formik>
);

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  recaptchaRef: PropTypes.shape({}).isRequired,
};

Form.defaultProps = {
  submitting: false,
};

const ForgotPassword = () => {
  const [done, setDone] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState(null);
  const recaptchaRef = React.useRef();

  const onSubmit = async (email) => {
    setRequestError('');
    setSubmitting(true);
    const captchaToken = await recaptchaRef.current.executeAsync();
    if (captchaToken) return setValues({ email, captchaToken });
    setRequestError('Unable to verify user.');
  };

  useEffect(() => {
    if (submitting && values) {
      api.sendForgottenPasswordEmail(values).then(({ error }) => {
        if (isString(error)) {
          setRequestError(error);
        } else {
          setDone(true);
        }
        setSubmitting(null);
      });
    }
  }, [submitting, values]);

  if (done) return <PasswordEmailSent />;

  return (
    <AuthPage
      header={<Header />}
      form={
        <Form
          onSubmit={onSubmit}
          submitting={submitting}
          recaptchaRef={recaptchaRef}
        />
      }
      error={requestError}
    />
  );
};

export default ForgotPassword;
