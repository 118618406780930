import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContentPanel from 'common/components/ContentPanel';
import AmountStrip from 'common/components/AmountStrip';
import Text from 'common/components/Text';
import FadeInTransition from 'common/components/FadeInTransition';

import actions from '../../actions';
import { getRefund, isLoadingRefund } from '../../reducers/orders';

import {
  refundComplete,
  orderStatus as orderStatusProp,
  refund as refundPropType,
} from '../../propTypes';

import styles from './index.module.css';

const options = (refund) => {
  if (refund.status === refundComplete)
    return {
      title: 'Refund complete',
      content:
        'Your refund request was processed and the funds have been transferred to the customer.',
      color: 'rebranding-md-color-pink',
      stripTitle: 'Refunded',
    };

  return {
    title: 'Processing refund',
    content:
      'An email has been sent to your customer with all the steps needed to finalise the transfer. Once completed we’ll inform you by email.',
    color: 'rebranding-md-color-orange',
    stripTitle: 'Refunding',
  };
};

class Refund extends Component {
  state = {
    mounting: true,
  };

  componentDidMount() {
    const { paymentId, fetchRefund, orderId } = this.props;

    fetchRefund(paymentId, orderId);
    this.setState({ mounting: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      state: { mounting },
      props: { status, isLoading, refund },
    } = this;
    const { mounting: nextMouting } = nextState;
    const {
      status: nextStatus,
      isLoading: nextLoading,
      refund: nextRefund,
    } = nextProps;

    if (
      status !== nextStatus ||
      isLoading !== nextLoading ||
      refund !== nextRefund ||
      mounting !== nextMouting
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    const { clearCache } = this.props;

    clearCache(null);
  }

  render() {
    const { refund } = this.props;
    if (!refund) return null;

    const { title, content, color, stripTitle } = options(refund);
    const amount = {
      value: refund.requestedAmount,
      currency: refund.requestedCurrency,
    };

    return (
      <FadeInTransition>
        <ContentPanel title={title} border="no-bottom">
          <div className={styles.text}>
            <Text color="rebranding-md-color-grey-70">{content}</Text>
          </div>
        </ContentPanel>
        <AmountStrip
          heading={stripTitle}
          amount={amount}
          background={`${color}-lightest`}
          color={color}
          id="totalAmount"
        />
      </FadeInTransition>
    );
  }
}

Refund.propTypes = {
  paymentId: PropTypes.string.isRequired,
  status: orderStatusProp.isRequired,
  fetchRefund: PropTypes.func.isRequired,
  refund: refundPropType,
  isLoading: PropTypes.bool.isRequired,
  clearCache: PropTypes.func.isRequired,
};

Refund.defaultProps = {
  refund: null,
};

export default connect(
  (state) => ({ refund: getRefund(state), isLoading: isLoadingRefund(state) }),
  {
    fetchRefund: actions.refundLoadRequest,
    clearCache: actions.refundLoadSuccess,
  }
)(Refund);
