import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import Text from 'common/components/Text';
import colors from 'common/styles/colors';

import styles from './index.module.css';

const iconsMap = {
  invoice: Icon.Invoice,
  questionCircle: Icon.QuestionCircle,
  store: Icon.Store,
};

const MerchantPurposeOption = ({
  active,
  description,
  heading,
  icon,
  id,
  onClick,
}) => {
  const handleKeyDown = ({ key = '' }) => {
    if (key === 'Enter') onClick();
  };

  const cardClasses = classNames(styles.root, {
    [styles.active]: active,
  });

  const CardIcon = iconsMap[icon];
  const iconFill = active
    ? colors['rebranding-md-color-brand-purple']
    : colors['rebranding-md-color-grey-40'];

  return (
    <div
      className={cardClasses}
      data-test={`purpose-option-${id}`}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      <CardIcon size="medium" fill={iconFill} />
      <Text weight="semiBold">{heading}</Text>
      <Text size="caption">{description}</Text>
    </div>
  );
};

MerchantPurposeOption.propTypes = {
  active: PropTypes.bool.isRequired,
  description: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MerchantPurposeOption;
