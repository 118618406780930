import React from 'react';
import PropTypes from 'prop-types';

import colors from 'common/styles/colors';

const NavMenu = ({ fill, ...rest }) => (
    <svg
      width="100%"
      viewBox="0 0 28 28"
      preserveAspectRatio="xMidYMid meet"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="1.3999" y="5.25" width="25.2" height="2.1" fill="white" />
      <rect x="1.3999" y="12.9492" width="25.2" height="2.1" fill="white" />
      <rect x="1.3999" y="20.6504" width="25.2" height="2.1" fill="white" />
    </svg>
  );

NavMenu.propTypes = {
  fill: PropTypes.string,
};

NavMenu.defaultProps = {
  fill: colors['color-white'],
};

export default NavMenu;
