import React from 'react';
import PropTypes from 'prop-types';

import DropdownBase from '../common/DropdownBase';
import {
  itemListProptypes,
  groupProptypes,
  dropdownItemProptypes,
} from '../common/propTypes';

class Dropdown extends React.Component {
  state = {
    selected: this.props.initialSelectedItem,
    isGroup: this.props.items?.[0]?.groupLabel,
  };

  // Re-set value when form is reinitialized with different defaults
  componentDidUpdate(prevProps) {
    if (prevProps.initialSelectedItem !== this.props.initialSelectedItem)
      this.updateSelected(this.props.initialSelectedItem);
  }

  updateSelected = (value) => {
    this.setState({ selected: value });
  };

  onOptionChange = (value) => {
    const { onChange } = this.props;

    this.setState({ selected: value }, onChange(value));
  };

  render() {
    const { selected, isGroup } = this.state;
    const {
      placeholder,
      items,
      isSearchable,
      name,
      error,
      errorText,
    } = this.props;
    const type = isSearchable ? 'searchable' : 'dropdown';
    const typeSuffix = isGroup ? 'Group' : 'List';

    return (
      <DropdownBase
        type={type + typeSuffix}
        onChange={this.onOptionChange}
        selectedItem={selected}
        items={items}
        placeholder={placeholder}
        name={name}
        errorText={errorText}
        error={error}
      />
    );
  }
}

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialSelectedItem: PropTypes.string,
  items: PropTypes.oneOfType([
    itemListProptypes,
    groupProptypes,
    dropdownItemProptypes,
  ]).isRequired,
  isSearchable: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorText: PropTypes.string,
};

Dropdown.defaultProps = {
  placeholder: undefined,
  initialSelectedItem: null,
  isSearchable: false,
  name: 'dropdown',
};

export default Dropdown;
