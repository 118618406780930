import React from 'react';
import PropTypes from 'prop-types';

const Cross = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill={fill}
    {...rest}
  >
    <path
      d="M13.0539 14.1928L14.1925 13.0542L11.1385 10.0004L14.1925 6.94638L13.0541 5.80794L10 8.86188L6.94594 5.80792L5.8075 6.94637L8.8615 10.0004L5.8075 13.0542L6.94609 14.1928L10 11.1389L13.0539 14.1928Z"
      fill="#none"
    />
  </svg>
);

Cross.propTypes = {
  fill: PropTypes.string,
};

Cross.defaultProps = {
  fill: 'none',
};

export default Cross;
