export function getAuthServiceUrl(optionalHostname) {
  const hostname = optionalHostname || window.location.hostname;
  const subdomain = hostname.split('.')[1];

  if (process.env.NODE_ENV === 'development') {
    return 'https://dash-dev.xmoney.com';
  }

  // merchants.crypto.xmoney.com or merchants.sandbox.crypto.xmoney.com
  if (subdomain === 'crypto' || subdomain === 'sandbox') {
    // TODO: Make sure the subdomain is correct
    return 'https://identity.xmoney.com';
  }

  // merchants.{dev, staging}.crypto.xmoney.com
  return `https://dash-dev.xmoney.com`;
}
