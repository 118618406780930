import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import queryString from 'query-string';

import NotFound from 'common/components/NotFound';
import AuthPage from 'common/components/AuthPage';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import TextBox from 'common/components/TextBox';
import PasswordLabel from 'common/components/PasswordLabel';
import Button from 'common/components/Button';
import formUtils, { SIMPLE_PASSWORD_MESSAGE } from 'common/formUtils.js';
import actions from '../../actions';
import { isSettingPassword } from '../../reducers';

import styles from './index.module.css';

const Form = ({ onSubmit, submitting }) => {
  const passwordField = useRef(null);

  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={(values) => onSubmit(values.password)}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="password" validate={formUtils.validatePassword}>
            {({ field, meta }) => (
              <div className={styles.row}>
                <>
                  {passwordField && <PasswordLabel input={passwordField} />}
                  <TextBox
                    id="password"
                    labelType="password"
                    type="password"
                    autoComplete="new-password"
                    placeholder="Your password"
                    error={meta.touched && meta.error}
                    errorText={meta.error}
                    inputRef={passwordField}
                    {...field}
                  />
                  {!(meta.error && meta.touched) && (
                    <div className={styles.passwordHelper}>
                      <Text size="caption" color="rebranding-md-color-grey-60">
                        {SIMPLE_PASSWORD_MESSAGE}
                      </Text>
                    </div>
                  )}
                </>
              </div>
            )}
          </Field>
          <span className={styles.formFooter}>
            <div className={styles.buttonWrapperReset}>
              <Button
                kind="primary"
                loading={!!submitting}
                disabled={!!formUtils.validatePassword(values.password)}
                full
              >
                <button
                  type="submit"
                  id="progress-status"
                  data-test="set-password-button"
                >
                  Set password
                </button>
              </Button>
            </div>
          </span>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

Form.defaultProps = {
  submitting: false,
};

const Header = ({ merchantName }) => (
  <>
    <Heading level="1" size="2" weight="medium" id="page-title" color="black">
      Set Password
    </Heading>
    <br />
    <Text color="rebranding-md-color-grey-60">
      <p id="page-description">
        Just choose a password and your account on {merchantName} store will be
        ready afterwards.
      </p>
    </Text>
  </>
);

const SetPasswordPage = ({
  merchantId,
  userId,
  merchantName,
  setPassword,
  submittingPassword,
}) => {
  if (!merchantId || !userId || !merchantName) return <NotFound />;

  const handleSubmit = (newPassword) =>
    setPassword({ newPassword, merchantId, userId });

  return (
    <AuthPage
      header={<Header merchantName={merchantName} />}
      form={<Form onSubmit={handleSubmit} submitting={submittingPassword} />}
      error={null}
    />
  );
};

SetPasswordPage.propTypes = {
  merchantId: PropTypes.string,
  merchantName: PropTypes.string,
  userId: PropTypes.string,
  setPassword: PropTypes.func.isRequired,
  submittingPassword: PropTypes.bool.isRequired,
};

SetPasswordPage.defaultProps = {
  merchantId: null,
  merchantName: null,
  userId: null,
};

const getQueryParams = (location) => queryString.parse(location.search);

const mapStateToProps = (state, ownProps) => {
  const params = getQueryParams(ownProps.location);

  return {
    ...ownProps,
    ...params,
    submittingPassword: isSettingPassword(state),
  };
};

const mapDispatchToProps = {
  setPassword: actions.setPasswordRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage);
