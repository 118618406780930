import React, { PureComponent } from 'react';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import store from './store.svg';
import styles from './index.module.css';

class NoOrdersResults extends PureComponent {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.message}>
            <Heading size="2" level="2" color="blue">
              {`Couldn't`} find any records for your search
            </Heading>
            <img src={store} alt="Empty shopping cart" />
            <Text>Please try another term</Text>
          </div>
        </div>
      </div>
    );
  }
}

export default NoOrdersResults;
