import React from 'react';
import PropTypes from 'prop-types';

const PlugCrossed = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 68 88"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.0407 0.597656L8.92513 87.4004H14.6986L26.1063 67.6418L31.5156 67.6419L31.5156 82.1257H36.4859L36.4859 67.6418L52.4587 67.6418L57.429 62.6716H28.9759L50.0355 26.1952H57.6096L57.6096 62.491L62.5798 57.5207V26.1952H67.6874V20.9764H53.0486L64.8142 0.597656H59.0407ZM50.0158 5.87238V6.53081L45.0455 15.1396V5.87238H50.0158ZM38.6626 26.1952L41.6756 20.9764H22.956L22.956 5.87238H17.9857L17.9857 20.9764H0.3125V26.1952H5.44672L5.44673 57.5207L10.417 62.491L10.417 26.1952H38.6626ZM15.0388 67.1128L17.6029 62.6716H10.5975L15.0388 67.1128Z"
      fill={fill}
    />
  </svg>
);

PlugCrossed.propTypes = {
  fill: PropTypes.string,
};

PlugCrossed.defaultProps = {
  fill: 'none',
};

export default PlugCrossed;
