import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { selectors as featuresSelectors } from '../../features';
import { kybStarted } from '../../common/constants';
import { selectors } from '../../auth';
import { selectors as sessionSelectors } from '../../session';
import { getStartedRoute, signInRoute, signOutRoute } from './routes';

// TODO: Remove old auth logic when SSO is stable

class PrivateRoute extends Component {
  redirectTo = (pathname) => (
    <Redirect to={{ pathname, state: { from: this.props.location } }} />
  );

  renderRoute = (routeProps) => {
    const {
      authenticated,
      isSSOActive,
      isSettingUp,
      activeOrganization,
      activeStore,
      component: Comp,
      ...props
    } = this.props;
    const {
      location: { pathname },
    } = props;
    const needsToLoadStore = !activeOrganization || !activeStore;

    if (!authenticated && !isSSOActive) {
      return this.redirectTo(signInRoute);
    }

    if (isSettingUp || needsToLoadStore) return null;

    const isRestrictedToGetStarted =
      activeOrganization?.complianceStatus === kybStarted;

    if (
      !needsToLoadStore &&
      isRestrictedToGetStarted &&
      pathname !== getStartedRoute &&
      pathname !== signOutRoute
    ) {
      return this.redirectTo(getStartedRoute);
    }

    return <Comp {...props} {...routeProps} />;
  };

  render() {
    const props = omit(this.props, ['authenticated', 'component']);

    return <Route {...props} render={this.renderRoute} />;
  }
}

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  isSettingUp: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.shape({}),
  ]),
  activeOrganization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    integrationStatus: PropTypes.string,
  }),
  activeStore: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

const ConnectedPrivateRoute = connect((state) => ({
  authenticated: selectors.isAuthenticated(state),
  isSSOActive: featuresSelectors.isSSOActive(state),
  isSettingUp: sessionSelectors.isSettingUpSession(state),
  activeOrganization: sessionSelectors.activeOrganization(state),
  activeStore: sessionSelectors.activeStore(state),
}))(PrivateRoute);

export default withRouter(ConnectedPrivateRoute);
