import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import {
  orderInClaimStatus,
  orderInDisputeStatus,
  orderPaidStatus,
  orderPendingStatus,
  orderCanceledStatus,
  orderCancelledStatus,
  orderExpiredStatus,
  orderRefundProcessingStatus,
  orderRefundProposedStatus,
  orderRefundCompletedStatus,
  merchantPaid,
  buyerPaid,
} from 'common/propTypes';

import styles from './index.module.css';

const statusDetailsMap = {
  orders: {
    [orderPendingStatus]: {
      tag: 'pending',
      label: 'Placed',
    },
    [orderPaidStatus]: {
      tag: 'paid',
      label: 'Paid',
    },
    [orderCancelledStatus]: {
      tag: 'canceled',
      label: 'Canceled',
    },
    [orderCanceledStatus]: {
      tag: 'canceled',
      label: 'Canceled',
    },
    [orderExpiredStatus]: {
      tag: 'canceled',
      label: 'Expired',
    },
    [orderRefundProcessingStatus]: {
      tag: 'refundProcessing',
      label: 'Refunding',
    },
    [orderRefundProposedStatus]: {
      tag: 'refundProcessing',
      label: 'Refunding',
    },
    [orderRefundCompletedStatus]: {
      tag: 'refundCompleted',
      label: 'Refunded',
    },
    //retro compatible statuses
    [orderInClaimStatus]: {
      tag: 'legacy',
      label: 'Open Claim',
    },
    [orderInDisputeStatus]: {
      tag: 'legacy',
      label: 'Dispute Open',
    },
    [merchantPaid]: {
      tag: 'legacy',
      label: 'Claim closed',
    },
    [buyerPaid]: {
      tag: 'legacy',
      label: 'Claim closed',
    },
  },
  invoices: {
    [orderPendingStatus]: {
      tag: 'pending',
      label: 'Pending',
    },
    [orderPaidStatus]: {
      tag: 'paid',
      label: 'Paid',
    },
    [orderCancelledStatus]: {
      tag: 'canceled',
      label: 'Canceled',
    },
    [orderExpiredStatus]: {
      tag: 'expired',
      label: 'Expired',
    },
  },
};

const IconRefund = (props) => <Icon.Arrow direction="left" {...props} />;

const tagMap = {
  pending: {
    fill: colors['rebranding-md-color-blue'],
    icon: Icon.Info,
  },
  paid: {
    fill: colors['rebranding-md-color-green'],
    icon: Icon.Checkmark,
    size: 'small',
  },
  canceled: { fill: colors['rebranding-md-color-grey-60'], icon: Icon.Cross },
  expired: { fill: colors['rebranding-md-color-grey-60'], icon: Icon.Cross },
  refundProcessing: {
    fill: colors['rebranding-md-color-orange'],
    icon: Icon.Dots,
  },
  refundCompleted: {
    fill: colors['rebranding-md-color-pink'],
    icon: IconRefund,
    size: 'small',
  },
  legacy: {
    fill: colors['rebranding-md-color-brand-black'],
    icon: Icon.Cross,
  },
};

const StatusTag = ({ status, paymentType }) => {
  const { tag, label } = statusDetailsMap[paymentType][status.toUpperCase()];
  const { fill, icon: IconComponent, size } = tagMap[tag];

  return (
    <>
      <div className={styles.root} style={{ background: fill }}>
        <div className={styles.wrapper}>
          <IconComponent
            size={size || 'medium'}
            description={label}
            fill={fill}
          />
        </div>
        <span className={styles.label} data-test={`order-status-tag-${status}`}>
          {label}
        </span>
      </div>
      <div className={styles.printingLabel} style={{ color: fill }}>
        {label}
      </div>
    </>
  );
};

const allowedStatus = [
  orderInClaimStatus,
  orderInDisputeStatus,
  orderPaidStatus,
  orderPendingStatus,
  orderRefundProcessingStatus,
  orderRefundProposedStatus,
  orderRefundCompletedStatus,
  orderCanceledStatus,
  orderCancelledStatus,
  merchantPaid,
  buyerPaid,
];

StatusTag.propTypes = {
  status: PropTypes.oneOf([
    ...allowedStatus,
    ...allowedStatus.map((status) => status.toLowerCase()),
  ]).isRequired,
  paymentType: PropTypes.oneOf(['invoices', 'orders']),
};

StatusTag.defaultProps = {
  paymentType: 'orders',
};

export default StatusTag;
