import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'common/components/Icon';

import styles from './index.module.css';

const Loading = ({ kind, size, progressValue }) => {
  const circleStyles = classNames(styles.progress, styles[kind]);

  return (
    <Icon.Circle
      className={circleStyles}
      size={size}
      description="loader"
      role="progressbar"
      ariavaluetext={progressValue}
    />
  );
};

Loading.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'destructive']).isRequired,
  size: PropTypes.string,
  progressValue: PropTypes.string,
};

Loading.defaultProps = {
  size: 'auto',
  progressValue: '',
};

export default Loading;
