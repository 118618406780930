import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'FEATURES_REQUEST',
  'FEATURES_SUCCESS',
  'FEATURES_FAILURE',
]);

const actionCreators = createActions(
  actionTypes.FEATURES_REQUEST,
  actionTypes.FEATURES_SUCCESS,
  actionTypes.FEATURES_FAILURE
);

export default {
  ...actionTypes,
  ...actionCreators,
};
