import React from 'react';
import classNames from 'classnames';

import PaginationHelperBase from 'common/components/Pagination/PaginationHelper';
import { paginationHelperProptypes } from 'payments/propTypes';
import styles from './index.module.css';

const PaginationHelper = ({ data }) => (
  <PaginationHelperBase className={classNames(styles.root)} data={data} />
);

PaginationHelper.propTypes = paginationHelperProptypes;

export default PaginationHelper;
