import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Activities from 'common/components/Activities';
import ContentPanel from 'common/components/ContentPanel';
import FadeInTransition from 'common/components/FadeInTransition';
import { paymentActivity as paymentActivityPropType } from 'payments/propTypes';
import { getOrderActivities } from '../reducers/orders';

const ActivitiesContainer = ({ activities }) => {
  if (!activities.length) {
    return null;
  }

  return (
    <ContentPanel title="Activity feed">
      <FadeInTransition>
        <Activities activities={activities} />
      </FadeInTransition>
    </ContentPanel>
  );
};

ActivitiesContainer.propTypes = {
  activities: PropTypes.arrayOf(paymentActivityPropType).isRequired,
};

const mapStateToProps = (state) => ({
  activities: getOrderActivities(state),
});

export default connect(mapStateToProps)(memo(ActivitiesContainer));
