import React from 'react';
import classNames from 'classnames';

import { displayUtcDate } from 'common/services/date';
import { settlement as settlementPropType } from 'settlements/propTypes';
import { Link, useRouteMatch } from 'react-router-dom';
import Text from 'common/components/Text';
import PopUp from 'common/components/PopUp';
import Icon from 'common/components/Icon';
import stringUtils from 'common/stringUtils';
import Cell from 'common/components/Cell';
import PendingTooltip from '../PendingTooltip';
import ReportLinks from '../ReportLinks';

import { settlementsPeriodFormatter } from '../../utils/periodFormatter';
import TransferredOnMobile from './TransferredOnMobile';
import InvoiceDocuments from './InvoiceDocuments';
import Total from './Total';
import { getScenario } from './utils';

import styles from './index.module.css';

const Row = ({ settlement }) => {
  const {
    documents,
    endsAt,
    id,
    payoutBankDetails,
    settledOn,
    shortId,
    startsAt,
    toBeSettledAmount: { value: total, currency },
  } = settlement;
  const match = useRouteMatch();
  const formattedPeriod = settlementsPeriodFormatter(startsAt, endsAt);
  const formatDate = (date) => displayUtcDate(date, 'dd MMM, yy');
  const {
    color: statusColor,
    content: statusContent,
    info: InfoComponent,
    icon: IconComponent,
    showTooltip: pendingTooltip,
  } = getScenario(settledOn);

  const isFiatSettlement = ['EUR', 'USD', 'GBP'].includes(currency);

  return (
    <>
      <tr
        className={classNames(styles.row, styles.desktopColumns)}
        data-test="settlements-table-row"
      >
        <Cell>{formatDate(startsAt)}</Cell>
        <Cell>{formatDate(endsAt)}</Cell>
        <Cell>
          <PopUp content={<ReportLinks settlementId={id} />}>
            <span data-test="report-link" className={styles.link}>
              {shortId}
            </span>
            <Icon.Download size="small" />
          </PopUp>
        </Cell>
        <Cell>
          <InvoiceDocuments list={documents} />
        </Cell>
        <Cell>
          <span data-test="destination">
            {isFiatSettlement
              ? payoutBankDetails?.iban ||
                payoutBankDetails?.bankAccountNumber ||
                '—'
              : stringUtils.shortenAddress(
                  payoutBankDetails?.cryptoWalletAddress
                ) || '—'}
          </span>
        </Cell>
        <Cell align="right">
          <div className={styles.transferred}>
            {InfoComponent && <InfoComponent periodEnd={endsAt} />}
            <Text color={statusColor}>{statusContent}</Text>
            {IconComponent && <IconComponent />}
            {pendingTooltip && <PendingTooltip />}
          </div>
        </Cell>
        <Cell align="right">
          <Total value={total} currency={currency} />
        </Cell>
      </tr>

      <tr className={classNames(styles.row, styles.mobileColumns)}>
        <Cell>
          <Link
            to={`${match.path}/${id}`}
            target="_blank"
            className={styles['link-reset']}
            data-test="report-link"
          />
          <time className={styles.mobilePeriod} dateTime={formattedPeriod}>
            {formattedPeriod}
          </time>
          <Total value={total} currency={currency} />
          <TransferredOnMobile settledOn={settledOn} />
          <div className={styles.mobileDocuments}>
            <div className={styles.mobileDocumentColumn}>
              <Text size="caption">Settlement</Text>
              <PopUp content={<ReportLinks settlementId={id} />}>
                <span className={styles.link}>{shortId}</span>
                <Icon.Download size="small" />
              </PopUp>
            </div>
            <div className={styles.mobileDocumentColumn}>
              <Text size="caption">Fee Invoice</Text>
              <InvoiceDocuments list={documents} />
            </div>
          </div>
        </Cell>
      </tr>
    </>
  );
};

Row.propTypes = {
  settlement: settlementPropType.isRequired,
};

export default Row;
