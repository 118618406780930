import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'griddle-react';

import styles from './index.module.css';

const Pagination = ({ Next, Previous, PageDropdown }) => (
  <nav aria-label="Pagination Navigation">
    <ul className={styles.pagination}>
      <li>
        <Previous />
      </li>
      <li className={styles.pages}>
        <PageDropdown />
      </li>
      <li>
        <Next />
      </li>
    </ul>
  </nav>
);

Pagination.propTypes = {
  Next: PropTypes.func.isRequired,
  Previous: PropTypes.func.isRequired,
  PageDropdown: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ noData: state.get('data').isEmpty() });

const ConnectedPagination = ({ noData, ...rest }) =>
  noData ? null : <Pagination {...rest} />;

ConnectedPagination.propTypes = {
  noData: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ConnectedPagination);
