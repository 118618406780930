import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import styles from './index.module.css';

const SearchInput = ({ onChange, value, placeholder, disabled }) => (
  <div className={styles.root}>
    <input
      placeholder={placeholder}
      className={styles.input}
      value={value}
      onChange={onChange}
      name="search"
      disabled={disabled}
    />
    <div className={styles.searchIcon}>
      <Icon.Search description="Search input" size="medium" />
    </div>
  </div>
);

SearchInput.defaultProps = {
  placeholder: '',
  value: '',
  disabled: false,
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchInput;
