import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const GroupLabel = ({ label, ...props }) => (
  <div {...props} className={styles.root} data-test="filter-group-inner-item">
    {label}
  </div>
);

GroupLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default GroupLabel;
