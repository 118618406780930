import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { utcDateFormatter } from 'common/services/date';

const FormattedDate = ({ value }) => {
  const displayUtcDate = utcDateFormatter(value);

  return (
    <time dateTime={`${displayUtcDate('yyyy-MM-ddTHH:mm:ss')} UTC`}>
      {`${displayUtcDate('yyyy/MM/dd HH:mm')} UTC`}
    </time>
  );
};

FormattedDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
};

export default memo(FormattedDate);
