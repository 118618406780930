import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'LOAD_PERSONAL_PAGE',
  'ACCOUNT_PROFILE_REQUEST',
  'ACCOUNT_PROFILE_SUCCESS',
  'ACCOUNT_PROFILE_FAILURE',
  'UPDATE_PROFILE_REQUEST',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_FAILURE',
  'TWO_FACTOR_AUTH_CHANGE',
  'BUSINESS_INFO_SUCCESS',
  'SECTORS_SUCCESS',
  'CONTRACT_SUCCESS',
  'PAYOUT_ACCOUNTS_SUCCESS',
  'NEW_PAYOUT_ACCOUNT_SUCCESS',
  'MERCHANT_COUNTRIES_SUCCESS',
]);

const actionCreators = createActions(
  {
    [actionTypes.ACCOUNT_PROFILE_FAILURE]: Error,
  },
  actionTypes.UPDATE_PROFILE_REQUEST,
  actionTypes.LOAD_PERSONAL_PAGE,
  actionTypes.ACCOUNT_PROFILE_REQUEST,
  actionTypes.ACCOUNT_PROFILE_SUCCESS,
  actionTypes.UPDATE_PROFILE_FAILURE,
  actionTypes.UPDATE_PROFILE_SUCCESS,
  actionTypes.TWO_FACTOR_AUTH_CHANGE,
  actionTypes.BUSINESS_INFO_SUCCESS,
  actionTypes.SECTORS_SUCCESS,
  actionTypes.CONTRACT_SUCCESS,
  actionTypes.PAYOUT_ACCOUNTS_SUCCESS,
  actionTypes.NEW_PAYOUT_ACCOUNT_SUCCESS,
  actionTypes.MERCHANT_COUNTRIES_SUCCESS
);

export default {
  ...actionTypes,
  ...actionCreators,
};
