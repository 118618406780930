import { useActionDispatcher } from '../../common/hooks';

import actions from '../actions';

const SignOut = () => {
  const signOut = useActionDispatcher(actions.signOut);
  signOut();

  return null;
};

export default SignOut;
