import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { contractPropType } from 'common/propTypes';

import { isCrypto } from 'common/currencies';
import Text from 'common/components/Text';

import BankAccountDetails from '../BankAccountDetails';
import CryptoWalletDetails from '../CryptoWalletDetails';
import Card from '../Card';

import styles from './index.module.css';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const Account = ({
  countries,
  data,
  contractSettlementCurrency,
  nextSettlementCurrency,
}) => {
  const { settlementCurrency: accountSettlementCurrency } = data;

  const bankProps = _.pick(data, [
    'bankAccountNumber',
    'bankCountry',
    'iban',
    'routingNumber',
    'swiftCode',
    'sortCode',
  ]);

  const isActive = contractSettlementCurrency === accountSettlementCurrency;
  const isNext = nextSettlementCurrency === accountSettlementCurrency;

  return (
    <div className={styles.account} data-test={`payout-account`}>
      <div className={styles.accountName}>
        <Text weight="semiBold">{`${accountSettlementCurrency} account`}</Text>
        {isActive && (
          <div data-test={`payout-account-active-${accountSettlementCurrency}`}>
            <Text weight="semiBold" color="rebranding-md-color-green">
              Active destination
            </Text>
          </div>
        )}
        {isNext && (
          <Text weight="semiBold" color="rebranding-md-color-blue">
            Active on the next settlement period
          </Text>
        )}
        {!isNext && !isActive && (
          <Text weight="semiBold" color="rebranding-md-color-grey-50">
            Disabled
          </Text>
        )}
      </div>
      <br />
      {isCrypto(accountSettlementCurrency) ? (
        <CryptoWalletDetails
          address={data.cryptoWalletAddress}
          blockchain={data.settlementBlockchain}
        />
      ) : (
        <BankAccountDetails bankInfo={bankProps} countries={countries} />
      )}
    </div>
  );
};

Account.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  contractSettlementCurrency: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  nextSettlementCurrency: PropTypes.string,
  ongoingSettlementEndDate: PropTypes.string,
};

const PayoutDestination = ({
  countries,
  settlementCurrency,
  nextSettlementCurrency,
  ongoingSettlementEndDate,
  payoutAccounts,
}) => {
  if (!payoutAccounts) return null;

  const sortedPayoutAccounts = _.chain(payoutAccounts)
    .sortBy((o) => o.settlementCurrency !== settlementCurrency)
    .value();

  return (
    <motion.div initial="hidden" animate="visible" variants={variants}>
      <Card heading="Payout destination" id="payout-destination">
        {Object.keys(sortedPayoutAccounts).map((account) => (
          <Account
            countries={countries}
            data={sortedPayoutAccounts[account]}
            key={sortedPayoutAccounts[account].id}
            contractSettlementCurrency={settlementCurrency}
            nextSettlementCurrency={nextSettlementCurrency}
            ongoingSettlementEndDate={ongoingSettlementEndDate}
          />
        ))}
      </Card>
    </motion.div>
  );
};

PayoutDestination.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  nextSettlementCurrency: PropTypes.string,
  ongoingSettlementEndDate: PropTypes.string,
  payoutAccounts: PropTypes.objectOf(contractPropType).isRequired,
  settlementCurrency: PropTypes.string.isRequired,
};

export default PayoutDestination;
