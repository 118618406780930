import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'COUNTRIES_LIST_SUCCESS',
  'ORDER_ACTIVITIES_FAILURE',
  'ORDER_ACTIVITIES_REQUEST',
  'ORDER_ACTIVITIES_SUCCESS',
  'EXPORT_ORDERS_FAILURE',
  'EXPORT_ORDERS_REQUEST',
  'EXPORT_ORDERS_SUCCESS',
  'INVOICES_FAILURE',
  'INVOICES_REQUEST',
  'INVOICES_SUCCESS',
  'INVOICES_TABLE_NEXT_PAGE',
  'INVOICES_TABLE_PREVIOUS_PAGE',
  'ORDERS_FAILURE',
  'ORDERS_REQUEST',
  'ORDERS_SUCCESS',
  'ORDERS_TABLE_NEXT_PAGE',
  'ORDERS_TABLE_PREVIOUS_PAGE',
  'REFUND_FAILURE',
  'REFUND_FETCHING',
  'REFUND_LOAD_FAILURE',
  'REFUND_LOAD_REQUEST',
  'REFUND_LOAD_SUCCESS',
  'REFUND_REQUEST',
  'REFUND_SUCCESS',
  'RESET_INVOICES_TABLE',
  'RESET_INVOICES_TABLE_PAGE',
  'RESET_ORDERS_TABLE',
  'RESET_ORDERS_TABLE_PAGE',
  'SET_INVOICES_TABLE_FILTER',
  'SET_INVOICES_TABLE_PAGE',
  'SET_INVOICES_TABLE_PAGE_SIZE',
  'SET_INVOICES_TABLE_SORT',
  'SET_ORDERS_TABLE_FILTER',
  'SET_ORDERS_TABLE_PAGE',
  'SET_ORDERS_TABLE_PAGE_SIZE',
  'SET_ORDERS_TABLE_SORT',
  'SINGLE_ORDER_PAGE_FAILURE',
  'SINGLE_ORDER_PAGE_REQUEST',
  'SINGLE_ORDER_PAGE_SUCCESS',
  'SINGLE_INVOICE_PAGE_FAILURE',
  'SINGLE_INVOICE_PAGE_REQUEST',
  'SINGLE_INVOICE_PAGE_SUCCESS',
  'SINGLE_INVOICE_SET_CANCELLED',
]);

const actionCreators = createActions(
  {
    [actionTypes.COUNTRIES_LIST_SUCCESS]: (countries) => ({ countries }),
    [actionTypes.INVOICES_FAILURE]: Error,
    [actionTypes.ORDERS_FAILURE]: Error,
    [actionTypes.SINGLE_ORDER_PAGE_REQUEST]: (id) => ({ id }),
    [actionTypes.SINGLE_ORDER_PAGE_FAILURE]: Error,
    [actionTypes.SINGLE_INVOICE_PAGE_REQUEST]: (id) => ({ id }),
    [actionTypes.SINGLE_INVOICE_PAGE_FAILURE]: Error,
    [actionTypes.REFUND_REQUEST]: (values, formName) => ({ values, formName }),
    [actionTypes.REFUND_LOAD_REQUEST]: (paymentId, billId) => ({
      paymentId,
      billId,
    }),
    [actionTypes.REFUND_LOAD_FAILURE]: Error,
    [actionTypes.ORDER_ACTIVITIES_REQUEST]: (orderId) => ({ orderId }),
    [actionTypes.EXPORT_ORDERS_FAILURE]: Error,
  },
  actionTypes.ORDER_ACTIVITIES_FAILURE,
  actionTypes.ORDER_ACTIVITIES_SUCCESS,
  actionTypes.EXPORT_ORDERS_REQUEST,
  actionTypes.EXPORT_ORDERS_SUCCESS,
  actionTypes.INVOICES_REQUEST,
  actionTypes.INVOICES_SUCCESS,
  actionTypes.INVOICES_TABLE_NEXT_PAGE,
  actionTypes.INVOICES_TABLE_PREVIOUS_PAGE,
  actionTypes.ORDERS_REQUEST,
  actionTypes.ORDERS_SUCCESS,
  actionTypes.ORDERS_TABLE_NEXT_PAGE,
  actionTypes.ORDERS_TABLE_PREVIOUS_PAGE,
  actionTypes.REFUND_FAILURE,
  actionTypes.REFUND_FETCHING,
  actionTypes.REFUND_LOAD_SUCCESS,
  actionTypes.REFUND_SUCCESS,
  actionTypes.RESET_INVOICES_TABLE,
  actionTypes.RESET_INVOICES_TABLE_PAGE,
  actionTypes.RESET_ORDERS_TABLE,
  actionTypes.RESET_ORDERS_TABLE_PAGE,
  actionTypes.SET_INVOICES_TABLE_FILTER,
  actionTypes.SET_INVOICES_TABLE_PAGE,
  actionTypes.SET_INVOICES_TABLE_PAGE_SIZE,
  actionTypes.SET_INVOICES_TABLE_SORT,
  actionTypes.SET_INVOICES_TABLE_FILTER,
  actionTypes.SET_ORDERS_TABLE_FILTER,
  actionTypes.SET_ORDERS_TABLE_PAGE,
  actionTypes.SET_ORDERS_TABLE_PAGE_SIZE,
  actionTypes.SET_ORDERS_TABLE_SORT,
  actionTypes.SINGLE_INVOICE_SET_CANCELLED,
  actionTypes.SINGLE_INVOICE_PAGE_SUCCESS,
  actionTypes.SINGLE_ORDER_PAGE_SUCCESS
);

export default {
  ...actionTypes,
  ...actionCreators,
};
