//Temporary a auxiliar to the css file, so it should be exact copy

module.exports = {
  white: '#FFFFFF',
  'color-white': '#FFFFFF',
  'color-titan-white': '#F5F5FF',
  'color-soft-blue': '#F0F4FD',
  'color-gray-blue': '#E2E9F7',
  'color-mountain-meadow': '#18c768',
  'color-minsk': '#2F347C',
  'color-indigo': '#5573C2',
  'color-light-grey': '#F4F4F5',
  'color-red': '#E86868',
  'color-brand': '#7A42FF',
  'color-paid': '#18c768',
  'color-refunded': '#e956ae',
  'color-refunding': '#e956ae',
  'color-dispute': '#f19305',
  'color-resent': '#2a9ee2',
  'color-pending': '#7C81B0',
  'color-canceled': '#7C81B0',
  'color-expired': '#ff595e',
  'color-wild-blue-yonder': '#7C81B0',
  'color-claim': '#076D78',
  'color-dodger-blue': '#4275FA',
  'color-royal-blue': '#5840ec',
  'color-elrond-blue': '#133AAB',
  'rebranding-color-blue-aqua': '#34F6F2',
  'rebranding-color-blue-light': '#F1FAFF',
  'rebranding-color-green': '#35C668',
  'rebranding-color-green-light': '#94F0B4',
  'rebranding-color-red': '#FF4848',
  'rebranding-color-yellow': '#FFCD4C',
  'rebranding-color-black': '#252D3D',
  'rebranding-color-grey': '#A9ACB5',
  'rebranding-color-grey-light': '#E9EDF0',
  'rebranding-color-white': '#FFFFFF',
  'rebranding-md-color-brand-black': '#252D3D',
  'rebranding-md-color-brand-purple': '#7C4DFF',
  'rebranding-md-color-brand-purple-lightest': '#ECE5FF',
  'rebranding-md-color-brand-purple-light': '#CEBDFF',
  'rebranding-md-color-brand-purple-dark': '#412297',
  'rebranding-md-color-brand-purple-darkest': '#2D1861',
  'rebranding-md-color-blue': '#4794FF',
  'rebranding-md-color-blue-lightest': '#DAEAFF',
  'rebranding-md-color-blue-light': '#A3C9FF',
  'rebranding-md-color-blue-dark': '#3976CC',
  'rebranding-md-color-blue-darkest': '#2B5999',
  'rebranding-md-color-green': '#10AB46',
  'rebranding-md-color-green-lightest': '#CFEEDA',
  'rebranding-md-color-green-light': '#87D5A3',
  'rebranding-md-color-green-dark': '#0D8938',
  'rebranding-md-color-green-darkest': '#0A672A',
  'rebranding-md-color-orange': '#EB7100',
  'rebranding-md-color-orange-lightest': '#FBE3CC',
  'rebranding-md-color-orange-light': '#F5B880',
  'rebranding-md-color-orange-dark': '#BC5A00',
  'rebranding-md-color-orange-darkest': '#8D4400',
  'rebranding-md-color-red': '#CD2553',
  'rebranding-md-color-red-lightest': '#F5D3DD',
  'rebranding-md-color-red-light': '#E692A9',
  'rebranding-md-color-red-dark': '#A41E42',
  'rebranding-md-color-red-darkest': '#7B1632',
  'rebranding-md-color-pink': '#EC6798',
  'rebranding-md-color-pink-lightest': '#FBE1EA',
  'rebranding-md-color-pink-light': '#F6B3CC',
  'rebranding-md-color-pink-dark': '#BD527A',
  'rebranding-md-color-pink-darkest': '#8E3E5B',
  'rebranding-md-color-grey-10': '#E9EAEC',
  'rebranding-md-color-grey-20': '#D3D5D8',
  'rebranding-md-color-grey-30': '#BEC0C5',
  'rebranding-md-color-grey-40': '#A8ABB1',
  'rebranding-md-color-grey-50': '#92969E',
  'rebranding-md-color-grey-60': '#7C818B',
  'rebranding-md-color-grey-70': '#666C77',
  'rebranding-md-color-grey-80': '#515764',
  'rebranding-md-color-grey-90': '#3B4250',
};
