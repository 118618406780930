import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { paymentActivity as paymentActivityPropType } from 'payments/propTypes';
import DayActivities from './DayActivities';
import eventsByDate from './eventsByDate';
import styles from './index.module.css';

const Activities = ({ activities }) => (
    <div className={styles.root}>
      {_.map(eventsByDate(activities), (events, day) => (
        <DayActivities day={day} events={events} key={day} />
      ))}
    </div>
  );

Activities.propTypes = {
  activities: PropTypes.arrayOf(paymentActivityPropType).isRequired,
};

export default Activities;
