import { put, call, all } from 'redux-saga/effects';

import watch from 'common/sagas/watch';
import { actions as errorActions } from 'error';
import { api } from 'app/services';
import actions from '../actions';

function* fetchSettlements() {
  const { response, error } = yield call(api.fetchSettlements);

  if (response) yield put(actions.settlementsSuccess(response));
  else {
    yield all([
      put(actions.settlementsFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* fetchSingleSettlement({ id }) {
  const { response, error } = yield call(api.fetchSingleSettlement, id);

  if (response) yield put(actions.singleSettlementSuccess(response));
  else {
    yield all([
      put(actions.singleSettlementFailure(error)),
      put(errorActions.apiError(error)),
    ]);
  }
}

export default function* root() {
  yield watch(actions.settlementsRequest, fetchSettlements);
  yield watch(actions.singleSettlementRequest, fetchSingleSettlement);
}
