import React from 'react';
import PropTypes from 'prop-types';

const Store = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 23 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.06896 0H2.7587H3.44827H18.6205H19.3104H19.9998L22.0687 6.2066H22.069C22.069 7.49195 21.1899 8.57198 20.0001 8.87827V17.2448L17.2451 19.9999H4.82443L2.06911 17.2445V8.87835C0.87932 8.57219 0.000136534 7.49222 0 6.2069V6.2066H9.98179e-05L2.06896 0ZM3.44843 8.87827V15.1726H3.44805V18.6209H6.89633V18.6205H15.1724V18.6209H18.6207V17.9453L18.6208 17.9309V8.87835C18.0786 8.7388 17.6008 8.43852 17.2414 8.0313C16.7359 8.60401 15.9963 8.96522 15.1724 8.96522C14.3485 8.96522 13.6089 8.60401 13.1034 8.0313C12.598 8.60401 11.8584 8.96522 11.0345 8.96522C10.2106 8.96522 9.47099 8.60401 8.96552 8.0313C8.46004 8.60401 7.72048 8.96522 6.89655 8.96522C6.07262 8.96522 5.33307 8.60401 4.82759 8.0313C4.46824 8.43844 3.99059 8.73869 3.44843 8.87827ZM1.37931 6.2069L2.9885 1.37931H19.0803L20.6895 6.2069H20.6896C20.6895 6.96853 20.072 7.58591 19.3103 7.58591C18.5486 7.58591 17.931 6.96837 17.931 6.2066H16.5517C16.5517 6.96837 15.9342 7.58591 15.1724 7.58591C14.4106 7.58591 13.7931 6.96837 13.7931 6.2066H12.4138C12.4138 6.96837 11.7963 7.58591 11.0345 7.58591C10.2727 7.58591 9.65517 6.96837 9.65517 6.2066H8.27586C8.27586 6.96837 7.65832 7.58591 6.89655 7.58591C6.13478 7.58591 5.51724 6.96837 5.51724 6.2066H4.13793C4.13793 6.96837 3.52039 7.58591 2.75862 7.58591C1.99695 7.58591 1.37947 6.96853 1.37931 6.2069Z"
      fill={fill}
    />
  </svg>
);

Store.propTypes = {
  fill: PropTypes.string,
};

Store.defaultProps = {
  fill: 'none',
};

export default Store;
