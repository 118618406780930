import React from 'react';
import PropTypes from 'prop-types';

const QuestionCircle = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 21 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6562 10C19.6562 14.8325 15.7387 18.75 10.9062 18.75C6.07376 18.75 2.15625 14.8325 2.15625 10C2.15625 5.16751 6.07376 1.25 10.9062 1.25C15.7387 1.25 19.6562 5.16751 19.6562 10ZM20.9062 10C20.9062 15.5228 16.4291 20 10.9062 20C5.3834 20 0.90625 15.5228 0.90625 10C0.90625 4.47715 5.3834 0 10.9062 0C16.4291 0 20.9062 4.47715 20.9062 10ZM10.1708 9.67829V11.9743H11.2488V10.4623C11.5568 10.4063 11.8648 10.3083 12.1728 10.1683C12.4808 10.0283 12.7562 9.84162 12.9988 9.60829C13.2415 9.37496 13.4375 9.09496 13.5868 8.76829C13.7455 8.43229 13.8248 8.04029 13.8248 7.59229C13.8248 6.78029 13.5728 6.13629 13.0688 5.66029C12.5648 5.18429 11.8788 4.94629 11.0108 4.94629C10.5722 4.94629 10.1848 5.00696 9.84882 5.12829C9.51282 5.24962 9.21882 5.41762 8.96682 5.63229C8.72415 5.83762 8.51882 6.08029 8.35082 6.36029C8.19215 6.63096 8.07082 6.91562 7.98682 7.21429L9.02282 7.60629C9.13482 7.13029 9.34948 6.73829 9.66682 6.43029C9.99348 6.12229 10.4275 5.96829 10.9688 5.96829C11.4728 5.96829 11.8742 6.11296 12.1728 6.40229C12.4715 6.68229 12.6208 7.06029 12.6208 7.53629V7.73229C12.6208 8.32029 12.4015 8.78229 11.9628 9.11829C11.5335 9.45429 10.9361 9.64096 10.1708 9.67829ZM10.0588 14.8303C10.1988 14.9796 10.4181 15.0543 10.7168 15.0543C11.0155 15.0543 11.2302 14.9796 11.3608 14.8303C11.5008 14.681 11.5708 14.485 11.5708 14.2423V14.0603C11.5708 13.8176 11.5008 13.6216 11.3608 13.4723C11.2302 13.323 11.0155 13.2483 10.7168 13.2483C10.4181 13.2483 10.1988 13.323 10.0588 13.4723C9.92815 13.6216 9.86282 13.8176 9.86282 14.0603V14.2423C9.86282 14.485 9.92815 14.681 10.0588 14.8303Z"
      fill={fill}
    />
  </svg>
);

QuestionCircle.propTypes = {
  fill: PropTypes.string,
};

QuestionCircle.defaultProps = {
  fill: 'none',
};

export default QuestionCircle;
