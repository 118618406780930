import React, { Component } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Griddle, {
  selectors,
  utils,
  RowDefinition,
  ColumnDefinition,
} from 'griddle-react';

import { paginationHelperProptypes } from 'payments/propTypes';
import {
  order as orderPropType,
  ordersSortProp,
  paymentsTablePropTypes,
} from '../propTypes';
import Amount from '../components/PaymentsTable/Amount';
import AmountMobile from '../components/PaymentsTable/AmountMobile';
import ColumnHeader from '../components/PaymentsTable/ColumnHeader';
import Layout from '../components/PaymentsTable/Layout';
import NextButton from '../components/PaymentsTable/NextButton';
import NoPaymentsYet from '../components/PaymentsTable/NoPaymentsYet';
import PageNumbers from '../components/PaymentsTable/PageNumbers';
import Pagination from '../components/PaymentsTable/Pagination';
import PaginationHelper from '../components/PaymentsTable/PaginationHelper';
import PreviousButton from '../components/PaymentsTable/PreviousButton';
import Reference from '../components/PaymentsTable/Reference';
import ReferenceDate from '../components/PaymentsTable/ReferenceDate';
import Status from '../components/PaymentsTable/Status';
import Timestamp from '../components/PaymentsTable/Timestamp';

import styles from '../components/PaymentsTable/index.module.css';

import { noResultsMap, tableMap, actionsMap } from '../tableUtils';

const styleConfig = {
  classNames: {
    Table: styles.table,
    Row: styles.row,
    Cell: styles.cell,
    TableHeading: styles.tableHeading,
    TableHeadingCell: styles.tableHeadingCell,
  },
};

const handleRowClick = (e, props, onOrderIdClick) => {
  e.preventDefault();
  const { uuid } = props.rowData;

  return onOrderIdClick(uuid);
};

const EnhanceWithRowData = utils.connect((state, props) => ({
  rowData: selectors.rowDataSelector(state, props),
}));

const EnhanceWithOnRowClick = (onOrderIdClick) => (OriginalComponent) =>
  EnhanceWithRowData((props) => (
    <OriginalComponent
      {...props}
      onClick={(e) => handleRowClick(e, props, onOrderIdClick)}
    />
  ));

const PaymentsTableDesktop = ({
  components,
  data,
  page,
  pageSize,
  paginationEvents,
  paymentType,
  sort,
  totalElements,
  paginationHelperData,
}) => (
  <span className={styles.tableDesktop}>
    <Griddle
      useExternal
      data={data}
      pageProperties={{
        currentPage: page,
        pageSize,
        recordCount: totalElements,
      }}
      components={components}
      sortProperties={[sort]}
      styleConfig={styleConfig}
      events={paginationEvents()}
    >
      <RowDefinition>
        <ColumnDefinition
          id="createdAt"
          title="Date"
          customComponent={Timestamp}
          customHeadingComponent={ColumnHeader}
        />
        <ColumnDefinition
          id="details.reference"
          title="Reference"
          customComponent={Reference}
          customHeadingComponent={ColumnHeader}
        />
        <ColumnDefinition
          id="details.customer.email"
          title="Customer email"
          customHeadingComponent={ColumnHeader}
        />
        <ColumnDefinition
          id="totalAmount.currency"
          title="Currency"
          customHeadingComponent={ColumnHeader}
        />
        <ColumnDefinition
          id="totalAmount.value"
          title="Amount"
          customComponent={Amount}
          customHeadingComponent={ColumnHeader}
        />
        <ColumnDefinition
          id="status"
          title="Status"
          customComponent={Status}
          customComponentMetadata={{ paymentType }}
          customHeadingComponent={ColumnHeader}
        />
      </RowDefinition>
    </Griddle>
    {data.length > 0 && <PaginationHelper data={paginationHelperData} />}
  </span>
);

PaymentsTableDesktop.propTypes = {
  ...paymentsTablePropTypes,
};

const PaymentsTableMobile = ({
  components,
  data,
  page,
  pageSize,
  paginationEvents,
  paginationHelperData,
  sort,
  totalElements,
}) => {
  const processedData = data.map((order) => ({
    ...order,
    column1: {
      date: order.createdAt,
      reference: order.reference,
    },
    column2: {
      status: order.status,
      amount: order.totalAmount,
    },
  }));

  return (
    <span className={styles.tableMobile}>
      <Griddle
        useExternal
        data={processedData}
        pageProperties={{
          currentPage: page,
          pageSize,
          recordCount: totalElements,
        }}
        components={components}
        sortProperties={[sort]}
        styleConfig={styleConfig}
        events={paginationEvents()}
      >
        <RowDefinition>
          <ColumnDefinition id="column1" customComponent={ReferenceDate} />
          <ColumnDefinition id="column2" customComponent={AmountMobile} />
        </RowDefinition>
      </Griddle>
      {data.length > 0 && <PaginationHelper data={paginationHelperData} />}
    </span>
  );
};

PaymentsTableMobile.propTypes = paymentsTablePropTypes;

class PaymentsTable extends Component {
  get components() {
    const { onOrderIdClick, paymentType } = this.props;
    const NoResults = noResultsMap[paymentType];

    return {
      Layout,
      NextButton,
      PreviousButton,
      NoResults,
      PageDropdown: PageNumbers,
      SettingsToggle: () => null,
      Pagination,
      RowEnhancer: EnhanceWithOnRowClick(onOrderIdClick),
    };
  }

  paginationEvents = () => {
    const { setPage, setSort, nextPage, previousPage } = this.props;
    return {
      onNext: () => nextPage(),
      onPrevious: () => previousPage(),
      onGetPage: (pageNumber) => setPage(pageNumber),
      onSort: (sortProperties) => setSort(sortProperties),
    };
  };

  render() {
    const {
      data,
      isEmpty,
      noDataYet,
      page,
      pageSize,
      paginationHelperData,
      paymentType,
      sort,
      tableRef,
      totalElements,
      windowWidth,
    } = this.props;
    const classes = classNames(styles.griddleTableWrapper, {
      [styles.empty]: isEmpty,
    });

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={classes}
        ref={tableRef}
        data-test="payments-table"
      >
        {noDataYet ? (
          <NoPaymentsYet paymentType={paymentType} />
        ) : (
          <>
            {windowWidth > 1046 ? (
              <PaymentsTableDesktop
                components={this.components}
                data={data}
                page={page}
                pageSize={pageSize}
                paginationEvents={this.paginationEvents}
                paginationHelperData={paginationHelperData}
                paymentType={paymentType}
                sort={sort}
                totalElements={totalElements}
              />
            ) : (
              <PaymentsTableMobile
                components={this.components}
                data={data}
                page={page}
                pageSize={pageSize}
                paginationEvents={this.paginationEvents}
                paginationHelperData={paginationHelperData}
                paymentType={paymentType}
                sort={sort}
                totalElements={totalElements}
              />
            )}
          </>
        )}
      </motion.div>
    );
  }
}

PaymentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(orderPropType)).isRequired,
  isEmpty: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  noDataYet: PropTypes.bool,
  onOrderIdClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  paymentType: PropTypes.string.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sort: ordersSortProp.isRequired,
  tableRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  totalElements: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  paginationHelperData: paginationHelperProptypes,
};

const mapStateToProps = (state, props) => {
  const { paymentType } = props;
  return tableMap[paymentType](state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { paymentType } = ownProps;
  const paymentActions = actionsMap[paymentType];

  return {
    nextPage: () => dispatch(paymentActions.nextPage()),
    onOrderIdClick: (orderId) =>
      dispatch(push(paymentActions.onOrderIdClick(orderId))),
    previousPage: () => dispatch(paymentActions.previousPage()),
    setPage: (pageNumber) => dispatch(paymentActions.setPage(pageNumber)),
    setSort: (sortProperties) =>
      dispatch(paymentActions.setSort(sortProperties)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTable);
