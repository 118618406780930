import React from 'react';
import PropTypes from 'prop-types';

const directionToDegrees = {
  up: 0,
  down: 180,
};

const Caret = ({ direction, ...rest }) => {
  const degrees = directionToDegrees[direction];

  return (
    <svg
      width="100%"
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      style={{ transform: `rotate(${degrees}deg)` }}
      {...rest}
    >
      <path d="M8 5L4 9h8L8 5z" fill="#252D3D" />
    </svg>
  );
};

Caret.propTypes = {
  direction: PropTypes.oneOf(['up', 'down']),
};

Caret.defaultProps = {
  direction: 'up',
};

export default Caret;
