import React from 'react';
import PropTypes from 'prop-types';

const EyeCrossed = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 20 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fill}
      d="M11.2284 4.43593C10.8274 4.37776 10.4173 4.34765 10.0001 4.34765C6.30663 4.34765 3.1645 6.70814 2 10.0028C2.64277 11.8214 3.88805 13.3553 5.496 14.3648L6.83845 12.0396C6.46064 11.4522 6.24147 10.7531 6.24147 10.0028C6.24147 7.92065 7.92941 6.23271 10.0116 6.23271C10.071 6.23271 10.13 6.23408 10.1887 6.23679L11.2284 4.43593ZM6.4601 16.465L8.09258 17.4076L9.12825 15.6138C9.41488 15.643 9.70573 15.658 10.0001 15.658C13.6935 15.658 16.8357 13.2975 18.0002 10.0028C17.3948 8.29014 16.2551 6.8299 14.7813 5.82245L15.6328 4.3475L14.0003 3.40497L6.4601 16.465ZM13.3643 8.27676L10.1935 13.7686C12.1912 13.6737 13.7817 12.024 13.7817 10.0028C13.7817 9.38079 13.6311 8.79393 13.3643 8.27676Z"
    />
  </svg>
);

EyeCrossed.propTypes = {
  fill: PropTypes.string,
};

EyeCrossed.defaultProps = {
  fill: 'none',
};

export default EyeCrossed;
