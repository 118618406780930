import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({ fill, ...rest }) => (
  <svg
    width="100%"
    viewBox="0 0 20 20"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9.37822 16.6287V10.8774H3.62695L3.62695 9.37743H9.37822V3.62616L10.8782 3.62616V9.37743H16.6295V10.8774H10.8782V16.6287H9.37822Z"
      fill={fill}
    />
  </svg>
);

Plus.propTypes = {
  fill: PropTypes.string,
};

Plus.defaultProps = {
  fill: 'white',
};

export default Plus;
