import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import RecoveryCodeInput from '../RecoveryCodeInput';

const Reset = ({ onResetSubmit, onCancelClick }) => (
  <>
    <Text color="rebranding-md-color-grey-70">
      To reset your two-factor authentication credentials simply input the
      recovery code you saved previously in the field below.
    </Text>
    <br />
    <RecoveryCodeInput
      onResetSubmit={onResetSubmit}
      onCancelClick={onCancelClick}
    />
  </>
);

Reset.propTypes = {
  onResetSubmit: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default Reset;
