import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const Total = ({ currency, value }) => (
  <span className={styles.value}>
    {currency} <strong className={styles.bold}>{value}</strong>
  </span>
);

Total.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default Total;
