import React from 'react';

import Heading from 'common/components/Heading';
import Icon from 'common/components/Icon';
import Text from 'common/components/Text';
import colors from 'common/styles/colors';

import styles from './index.module.css';

const WebhooksEmptyTable = () => (
  <div className={styles.root}>
    <Heading size="1" level="1" color="blue">
      No webhook notifications yet
    </Heading>
    <div className={styles.iconWrapper}>
      <Icon.PlugCrossed
        description="No webhooks yet"
        fill={colors['rebranding-md-color-brand-purple-lightest']}
      />
    </div>
    <Text>
      You can pass a callback URL on the{' '}
      <a
        href="https://docs.api.crypto.xmoney.com/#tag/Store-Order"
        target="_blank"
        rel="noreferrer"
      >
        Create Order endpoint
      </a>{' '}
      using our API
    </Text>
  </div>
);

export default WebhooksEmptyTable;
