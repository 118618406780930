import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { pick, uniqueId } from 'lodash';

import Label from 'common/components/Label';
import Button from 'common/components/Button';
import ValidationError from 'common/components/ValidationError';

import styles from './index.module.css';

const getNumber = (value) => {
  const parsedValue = parseFloat(value);
  return Number.isNaN(parsedValue) ? '' : parsedValue;
};

const PartialWarning = () => (
  <span className={styles.partialWarning}>
    You can only do <strong className={styles.bold}>one refund</strong> per
    order.
  </span>
);

class AmountInput extends PureComponent {
  maxValue = getNumber(this.props.maxValue);

  id = this.props.id || uniqueId('amount-input-');

  get inputProps() {
    const props = pick(this.props, [
      'placeholder',
      'type',
      'disabled',
      'required',
      'onBlur',
      'onFocus',
      'onChange',
      'autoComplete',
      'name',
      'data-lpignore',
      'inputRef',
    ]);

    return {
      ...props,
      min: 0.0,
      step: 'any',
      type: 'number',
      placeholder: 0.0,
      max: this.maxValue,
      value: getNumber(this.props.value),
      id: this.id,
      'aria-invalid': this.props.invalid,
      'aria-describedby': `${this.id}-error-message`,
    };
  }

  handleMaxClick = (evt) => {
    evt.preventDefault();

    const { setMaxValue, disabled } = this.props;

    if (!disabled) setMaxValue();
  };

  render() {
    const {
      currency,
      disabled,
      error,
      errorText,
      invalid,
      label,
      maxLabel,
      partial,
      required,
      value,
    } = this.props;

    const wrapperClasses = classNames(styles.wrapper, {
      [styles.active]: value,
      [styles.invalid]: invalid || error,
      [styles.disabled]: disabled,
      [styles.required]: required,
    });

    return (
      <div className={styles.root}>
        {label && (
          <Label for={this.id} disabled={disabled}>
            {label}
          </Label>
        )}
        <div className={wrapperClasses}>
          <span className={styles.currency}>{currency}</span>
          <input {...this.inputProps} className={styles.input} />
          <Button
            kind="subtle"
            onClick={this.handleMaxClick}
            name="full-amount-button"
            disabled={disabled}
          >
            <button type="button">{maxLabel}</button>
          </Button>
        </div>
        {error && errorText && (
          <ValidationError id={this.id} errorString={errorText} />
        )}
        {partial && <PartialWarning />}
      </div>
    );
  }
}

AmountInput.propTypes = {
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorText: PropTypes.string,
  id: PropTypes.string,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  maxLabel: PropTypes.string,
  maxValue: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  partial: PropTypes.bool,
  required: PropTypes.bool,
  setMaxValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AmountInput.defaultProps = {
  disabled: false,
  error: false,
  errorText: '',
  id: undefined,
  invalid: false,
  label: null,
  maxLabel: 'Full Amount',
  name: 'amountInput',
  partial: false,
  required: false,
  value: null,
};
export default AmountInput;
