import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const AppContainer = ({ children }) => (
  <main className={styles.root}>{children}</main>
);

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContainer;
