import React from 'react';
import PropTypes from 'prop-types';

const SortIcon = ({ sortAscending }) => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${sortAscending ? '180deg' : ''})` }}
  >
    <path d="M4 4L0 0L8 6.9938e-07L4 4Z" fill="#252D3D" />
  </svg>
);

SortIcon.propTypes = {
  sortAscending: PropTypes.bool.isRequired,
};

export default SortIcon;
