import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import { groupBy } from 'lodash';
import build from 'redux-object';

import actions from '../actions';

const {
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  KYB_DOCUMENTS_SUCCESS,
  KYB_DELETE_DOCUMENT_SUCCESS,
  KYB_CREATE_DOCUMENT_SUCCESS,
} = actions;

const isSettingPasswordReducer = handleActions(
  {
    [SET_PASSWORD_REQUEST]: () => true,
    [combineActions(SET_PASSWORD_SUCCESS, SET_PASSWORD_FAILURE)]: () => false,
  },
  false
);

const documentsReducer = handleActions(
  {
    [KYB_DOCUMENTS_SUCCESS]: (state, { payload }) =>
      build(payload, 'kybDocument') || [],
    [KYB_CREATE_DOCUMENT_SUCCESS]: (state, { payload }) => {
      const newDocuments = build(payload, 'kybDocument');

      return [...state, ...newDocuments];
    },
    [KYB_DELETE_DOCUMENT_SUCCESS]: (state, { payload }) =>
      state.filter(doc => doc.id !== payload.id),
  },
  []
);

export const isSettingPassword = state => state.onboarding.isSettingPassword;
export const kybDocumentsByType = state =>
  groupBy(state.onboarding.kybDocuments, 'label');
export default combineReducers({
  isSettingPassword: isSettingPasswordReducer,
  kybDocuments: documentsReducer,
});
