import React from 'react';
import PropTypes from 'prop-types';

import Text from 'common/components/Text';
import { cryptoChains } from 'common/currencies';

import styles from './index.module.css';

const CryptoWalletDetails = ({ address, blockchain }) => (
  <>
    <div className={styles.bankField}>
      <Text size="caption" color="rebranding-md-color-grey-60">
        Crypto wallet address
      </Text>
      <Text>{address}</Text>
    </div>
    {blockchain && (
      <div className={styles.bankField}>
        <Text size="caption" color="rebranding-md-color-grey-60">
          Network
        </Text>
        <Text>{cryptoChains[blockchain]}</Text>
      </div>
    )}
  </>
);

CryptoWalletDetails.propTypes = {
  address: PropTypes.string.isRequired,
  blockchain: PropTypes.string.isRequired,
};

export default CryptoWalletDetails;
