import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const UserEmail = ({ email }) => (
  <span>
    {' '}
    to{' '}
    <strong className={styles.strong} data-test="user-email">
      {email}
    </strong>
  </span>
);

const RegistrationComplete = ({
  userEmail,
  resendConfirmationEmail,
  createAnotherAccount,
}) => {
  const [clickedResend, setClickedResend] = useState(false);
  const resendEmail = () => {
    resendConfirmationEmail();
    setClickedResend(true);
  };

  return (
    <div data-test="registration-complete" className={styles.root}>
      <div className={styles.logoWrapper}>
        <LogoxMoney />
      </div>
      <Heading level="3" size="3" weight="medium" id="page-title" color="black">
        Account created
      </Heading>
      <div className={styles.iconWrapper}>
        <Icon.Mail
          fill={colors['rebranding-md-color-brand-purple']}
          description="Mail Icon"
        />
      </div>
      <Heading level="3" size="3">
        <p>
          We’ve just sent you a confirmation link
          {userEmail && <UserEmail email={userEmail} />}.
        </p>
        <p>
          Please do check your inbox and make sure to confirm this account in
          the next 24 hours.
        </p>
      </Heading>
      <div className={styles.resendMessage}>
        {clickedResend ? (
          <Text color="rebranding-md-color-grey-60">
            Don’t forget to check your spam folder.
          </Text>
        ) : (
          <>
            <Text color="rebranding-md-color-grey-60">
              <p>
                Didn’t get the email? Make sure to check your spam folder,
                otherwise we can {''}
                <span
                  className={styles.link}
                  data-test="resend-email-link"
                  onClick={resendEmail}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') resendEmail();
                  }}
                  role="button"
                  tabIndex="0"
                >
                  resend it
                </span>
                .
              </p>
            </Text>
            <Text color="rebranding-md-color-grey-60">
              <p>
                If the email address is wrong, however, you’ll have to {''}
                <span
                  className={styles.link}
                  data-test="create-another-account-link"
                  onClick={createAnotherAccount}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') createAnotherAccount();
                  }}
                  role="button"
                  tabIndex="0"
                >
                  create another account
                </span>
                .
              </p>
            </Text>
          </>
        )}
      </div>
    </div>
  );
};

RegistrationComplete.propTypes = {
  userEmail: PropTypes.string,
  resendConfirmationEmail: PropTypes.func.isRequired,
  createAnotherAccount: PropTypes.func.isRequired,
};

export default RegistrationComplete;
