import { displayUtcDate } from 'common/services/date';
import CheckBubble from './CheckBubble';

const getSettledOnData = (settledOn) =>
  settledOn ? displayUtcDate(settledOn, 'dd MMM, yy') : null;

const getScenario = (settledOn) => {
  const settledOnDate = getSettledOnData(settledOn);

  if (settledOnDate)
    return {
      color: 'rebranding-md-color-brand-black',
      content: settledOnDate,
      mobileSpecific: `Transferred ${settledOnDate}`,
      icon: CheckBubble,
    };

  return {
    color: 'rebranding-md-color-grey-50',
    content: 'Processing',
    showTooltip: true,
  };
};

export { getSettledOnData, getScenario };
