import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const Card = ({ children }) => <div className={styles.root}>{children}</div>;

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
