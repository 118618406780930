import React from 'react';

import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import LogoxMoney from 'common/components/LogoxMoney';

import styles from './index.module.css';

const SystemDown = () => (
  <div className={styles.root} data-test="something-missing-page">
    <div>
      <LogoxMoney />
    </div>
    <h1 className={styles.errorCode}>500</h1>
    <Heading level="2" size="2" color="blue">
      Something went wrong
    </Heading>
    <div>
      <Text>We are experiencing an internal server error.</Text>
      <Text>Don’t worry, it’s not you.</Text>
    </div>
  </div>
);

export default SystemDown;
