import React, { useEffect, useState } from 'react';
import { first, values } from 'lodash';
import { motion } from 'framer-motion';

import { useActionDispatcher } from 'common/hooks';
import { actions as authActions } from 'auth';
import { api } from 'app/services';
import RegistrationComplete from '../components/RegistrationComplete';

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

function parseEmailResponse(response) {
  return first(values(response.emailConfirmationEmail))?.attributes?.email;
}

const RegistrationCompleteContainer = () => {
  const [userEmail, setUserEmail] = useState('loading');
  const signOut = useActionDispatcher(authActions.signOut);
  const createAnotherAccount = () => {
    signOut();
  };

  useEffect(() => {
    let isSubscribed = true;

    async function fetchEmail() {
      try {
        const { response, error } = await api.fetchUserEmail();

        if (error) throw error;
        if (isSubscribed) setUserEmail(parseEmailResponse(response));
      } catch (e) {
        if (isSubscribed) setUserEmail(null);
      }
    }

    fetchEmail();

    return function cleanup() {
      isSubscribed = false;
    };
  });

  if (userEmail === 'loading') return null;

  return (
    <motion.div initial="hidden" animate="visible" variants={variants}>
      <RegistrationComplete
        userEmail={userEmail}
        resendConfirmationEmail={api.resendConfirmationEmail}
        createAnotherAccount={createAnotherAccount}
      />
    </motion.div>
  );
};

export default RegistrationCompleteContainer;
