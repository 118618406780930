import React from 'react';
import propTypes from 'prop-types';

import Text from 'common/components/Text';
import instructions from './steps';
import styles from './index.module.css';

const Credentials = ({ codes, hideSecret, integrationType, loading }) => {
  const InstructionsComponent = instructions[integrationType];

  return (
    <>
      <br />
      <Text>Setup instructions:</Text>
      <ol className={styles.steps}>
        <InstructionsComponent
          codes={codes}
          hideSecret={hideSecret}
          loading={loading}
        />
      </ol>
      <br />
    </>
  );
};

Credentials.propTypes = {
  codes: propTypes.shape({
    apiKey: propTypes.string,
    secret: propTypes.string,
  }),
  hideSecret: propTypes.bool,
  integrationType: propTypes.string.isRequired,
  loading: propTypes.bool.isRequired,
};

export default Credentials;
