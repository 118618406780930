import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';

import settings from 'common/settings';
import { attachment as attachmentType } from 'common/propTypes';
import Attachment, { AttachmentLoading } from './Attachment';
import Uploader from './Uploader';

import styles from './index.module.css';

const attachmentExists = (file, list) => {
  const existAttachment = filter(
    list,
    (attachment) => attachment.name === file.name
  );

  return existAttachment.length > 0;
};

const supportedTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

/* eslint-disable no-alert*/
const confirmReplacement = (file) =>
  window.confirm(
    `Attachment with this name already exists: ${file.name}. Do you want replace it?`
  );
/* eslint-enable */

const Attachments = ({
  list,
  onUploadFinish,
  uploadFile,
  deleteAttachment,
  missing,
}) => {
  const [uploadState, setUploadState] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const onUploadError = (error) => {
    setUploadState({ ...uploadState, isLoading: false, error });
  };
  const handleOnUploadFinish = (uploadResult, file) => {
    if (!attachmentExists(file, list)) {
      onUploadFinish(uploadResult, file);
    }
    setUploadState(null);
  };
  const onUploadProgress = (progressEvent) =>
    setUploadPercentage(
      Math.round((progressEvent.loaded / progressEvent.total) * 100)
    );
  const handleUpload = (file, callback) => {
    if (file.size > settings.uploadSizeLimitInMB * 1000000) {
      setUploadState({
        error: {
          message: `File size exceeds ${settings.uploadSizeLimitInMB} MB`,
        },
      });
      return false;
    }

    if (!supportedTypes.includes(file.type)) {
      setUploadState({
        error: {
          message: `File type is not supported.`,
        },
      });
      return false;
    }

    if (!attachmentExists(file, list)) {
      setUploadState({ isLoading: true, uploadFile: file.name });
      uploadFile(file, callback);
      return true;
    }

    if (confirmReplacement(file)) {
      uploadFile(file, callback);

      return true;
    }

    return false;
  };

  return (
    <div className={styles.root}>
      {list.map(({ id, name, url }) => (
        <Attachment
          key={id}
          id={id}
          name={name}
          url={url}
          removeAction={deleteAttachment}
        />
      ))}
      {uploadState?.isLoading && (
        <AttachmentLoading
          name={uploadState?.uploadFile}
          percentage={uploadPercentage}
        />
      )}
      <Uploader
        getCredentials={handleUpload}
        onUploadProgress={onUploadProgress}
        onUploadFinish={handleOnUploadFinish}
        onUploadError={onUploadError}
        missing={missing}
      />
      {uploadState?.error && (
        <p className={styles.error}>{uploadState.error.message}</p>
      )}
      {missing && <p className={styles.error}>This file is required</p>}
    </div>
  );
};

Attachments.propTypes = {
  list: PropTypes.arrayOf(attachmentType).isRequired,
  uploadFile: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  missing: PropTypes.bool.isRequired,
};

export default Attachments;
