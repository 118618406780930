import PropTypes from 'prop-types';

export const icon = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['auto', 'small', 'messenger', 'xSmall']),
};

export const route = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export const match = PropTypes.shape({
  path: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
}).isRequired;

export const history = PropTypes.shape({}).isRequired;

export const attachment = PropTypes.shape({
  id: PropTypes.string,
  merchantId: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
}).isRequired;

export const amount = PropTypes.shape({
  currency: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const orderItems = PropTypes.arrayOf(
  PropTypes.shape({
    currency: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sku: PropTypes.string,
  })
);

export const customerPropType = {
  email: PropTypes.string.isRequired,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
};

export const contractPropType = PropTypes.shape({
  alwaysPayout: PropTypes.bool.isRequired,
  payoutMinLimit: PropTypes.string.isRequired,
  payoutExtraFee: PropTypes.string.isRequired,
  payoutPeriod: PropTypes.string.isRequired,
  settlementCurrency: PropTypes.string.isRequired,
});

export const invoicePrefillProptype = PropTypes.shape({
  amount: PropTypes.string,
  billingAddress: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  currency: PropTypes.string,
  customerEmail: PropTypes.string,
  customerName: PropTypes.string,
  description: PropTypes.string,
  postCode: PropTypes.string,
  state: PropTypes.string,
});

export const countryPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

export const countriesPropType = PropTypes.arrayOf(countryPropType);

export const orderPaidStatus = 'PAID';
export const orderPendingStatus = 'PENDING';
export const orderCancelledStatus = 'CANCELLED';
export const orderCanceledStatus = 'CANCELED';
export const orderExpiredStatus = 'EXPIRED';
export const orderRefundProcessingStatus = 'REFUND_PROCESSING';
export const orderRefundProposedStatus = 'REFUND_PROPOSED';
export const orderRefundCompletedStatus = 'REFUND_COMPLETED';
export const orderInClaimStatus = 'IN_CLAIM';
export const orderInDisputeStatus = 'IN_DISPUTE';

export const merchantPaid = 'MERCHANT_PAID';
export const buyerPaid = 'BUYER_PAID';
