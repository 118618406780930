import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IntegrationInfo from 'onboarding/components/IntegrationInfo';
import Credentials from 'onboarding/components/Credentials';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import FadeInTransition from 'common/components/FadeInTransition';
import { api } from 'app/services';
import CredentialsModal from '../CredentialsModal';

import styles from './index.module.css';

const APIIntegration = ({
  activeStoreId,
  apiError,
  codes,
  isFetchingCredentials,
  onGenerate,
  storeIntegrationType,
  setIntegrationType,
  clearSecret,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeIntegrationType = async (type) => {
    const { error } = await api.updateIntegrationType({
      uuid: activeStoreId,
      integrationType: type,
    });
    if (error) {
      setErrorMessage('Could not submit integration type.');
      apiError(error);
    } else {
      setIntegrationType({ integrationType: type });
    }

    if (!codes.apiKey) {
      onGenerate();
    }
  };

  const openCredentialsModal = () => {
    setShowModal(true);
  };

  const closeCredentialsModal = () => {
    setShowModal(false);
    clearSecret();
  };

  return (
    <FadeInTransition>
      <div className={styles.root}>
        <Heading level="3" size="3" weight="medium">
          API integration
        </Heading>
        <br />
        <IntegrationInfo
          storeIntegrationType={storeIntegrationType}
          setIntegrationType={changeIntegrationType}
        />
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
        {storeIntegrationType && (
          <>
            <Credentials
              codes={codes}
              integrationType={storeIntegrationType}
              loading={isFetchingCredentials && !showModal}
              hideSecret={!codes.secret}
            />
            <div className={styles.buttonWrapper}>
              <Button kind="secondary" onClick={openCredentialsModal} full>
                <button type="button" data-test="generate-credentials">
                  Generate credentials
                </button>
              </Button>
            </div>
            <CredentialsModal
              codes={codes}
              isOpen={showModal}
              onClose={closeCredentialsModal}
              onSubmit={onGenerate}
              loading={isFetchingCredentials}
            />
          </>
        )}
      </div>
    </FadeInTransition>
  );
};

APIIntegration.propTypes = {
  activeStoreId: PropTypes.string,
  apiError: PropTypes.func,
  codes: PropTypes.shape({
    apiKey: PropTypes.string,
    secret: PropTypes.string,
  }).isRequired,
  isFetchingCredentials: PropTypes.bool.isRequired,
  onGenerate: PropTypes.func.isRequired,
  storeIntegrationType: PropTypes.string,
  setIntegrationType: PropTypes.func.isRequired,
  clearSecret: PropTypes.func.isRequired,
};

export default APIIntegration;
