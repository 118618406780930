import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const ToolTip = ({ message, children }) => (
  <div className={styles.root}>
    {children}
    <div className={styles.text}>{message}</div>
  </div>
);

ToolTip.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};

export default ToolTip;
