import _ from 'lodash';
import { takeEvery, put, call, fork, select, all } from 'redux-saga/effects';

import { actions as errorActions } from 'error';
import { api } from 'app/services';
import actions from '../actions';
import { getProfile } from '../reducers';

function* fetchAccountProfile() {
  yield put(actions.accountProfileRequest());

  const { response, error } = yield call(api.fetchAccountProfile);
  if (response) yield put(actions.accountProfileSuccess(response));
  else
    yield all([
      put(actions.accountProfileFailure(error)),
      put(errorActions.apiError(error)),
    ]);
}

function* profile() {
  return yield select(getProfile);
}

function* updateProfile({ payload }) {
  const { response, error } = yield call(api.updateProfile, payload);
  if (response) {
    yield put(actions.updateProfileSuccess(response));
  } else {
    yield all([
      put(actions.updateProfileFailure()),
      put(errorActions.apiError(error)),
    ]);
  }
}

function* loadProfile() {
  if (_.isEmpty(yield profile())) {
    yield call(fetchAccountProfile);
  }
}

/** *************************************************************************** */
/** ***************************** WATCHERS ************************************ */
/** *************************************************************************** */

function* watchUpdateProfile() {
  yield takeEvery(actions.updateProfileRequest, updateProfile);
}

function* watchloadPersonalPage() {
  yield takeEvery(actions.loadPersonalPage, loadProfile);
}

export default function* root() {
  yield fork(watchUpdateProfile);
  yield fork(watchloadPersonalPage);
}
