import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';

import styles from './index.module.css';

const OnboardingHeaderWithButton = ({ children, dataTest }) => (
    <div data-test={dataTest} className={styles.root}>
      <Heading size="1" level="1" id="onboarding-header">
        <div className={styles.headerWithButton}>{children}</div>
      </Heading>
    </div>
  );

OnboardingHeaderWithButton.propTypes = {
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
};

const OnboardingHeading = ({ title, dataTest }) => (
    <div data-test={dataTest} className={styles.root}>
      <Heading size="1" level="1" id="onboarding-header">
        {title}
      </Heading>
    </div>
  );

OnboardingHeading.propTypes = {
  dataTest: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default memo(OnboardingHeading);

export { OnboardingHeaderWithButton };
