import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import ActiveLabel from '../../ActiveLabel';

import styles from './index.module.css';

const Active = ({ onDisableClick, onResetClick }) => (
  <>
    <ActiveLabel />
    <div className={styles.buttonWrapper}>
      <Button kind="subtle" onClick={onResetClick}>
        <button
          type="button"
          id="reset-twofactor"
          data-test="update-password-button"
        >
          Reset two-factor authentication
        </button>
      </Button>
      <br />
      <Button kind="destructiveSubtle" onClick={onDisableClick}>
        <button
          type="button"
          id="disable-authenticator"
          data-test="update-password-button"
        >
          Disable authenticator
        </button>
      </Button>
    </div>
  </>
);

Active.propTypes = {
  onDisableClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
};

export default Active;
