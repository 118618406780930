import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from 'common/components/Label';

import styles from './index.module.css';

const TextBoxLabel = ({
  disabled,
  id,
  labelText,
  hideLabel,
  optionalLabel,
  required,
}) => {
  const labelClasses = classNames(styles.label, {
    [styles.optionalLabel]: optionalLabel,
    [styles.required]: required,
    [styles.visuallyHidden]: hideLabel,
  });

  return (
    <div className={labelClasses} required={required}>
      {labelText && (
        <Label for={id} disabled={disabled} labelType="main">
          {labelText}
          {optionalLabel && (
            <span className={styles.optional}>{` (optional)`}</span>
          )}
        </Label>
      )}
    </div>
  );
};

TextBoxLabel.propTypes = {
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  labelText: PropTypes.string,
  optionalLabel: PropTypes.string,
  required: PropTypes.bool,
};

TextBoxLabel.defaultProps = {
  disabled: false,
  hideLabel: false,
  id: undefined,
  labelText: '',
  optionalLabel: '',
  required: false,
};

export default TextBoxLabel;
