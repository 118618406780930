import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import classNames from 'classnames';

import Button from 'common/components/Button';
import TextBox from 'common/components/TextBox';
import formUtils from 'common/formUtils.js';

import styles from '../index.module.css';

const initialValues = {
  authenticatorCode: '',
};

const CodeInput = ({
  onCodeSubmit,
  onCancelClick,
  labelText,
  buttonLabel,
  errorMessage,
  disableMode,
}) => {
  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const error = await onCodeSubmit(values);
    if (error) {
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
      {({ errors, touched, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.singleColumn}>
              <Field
                name="authenticatorCode"
                validate={formUtils.validateTwoFactorCode}
              >
                {({ field, meta }) => (
                  <TextBox
                    id="authenticatorCode"
                    labelText={labelText}
                    labelType="authenticatorCode"
                    placeholder="6-digit code"
                    error={
                      (meta.touched && meta.error) ||
                      (!meta.touched && errorMessage)
                    }
                    errorText={meta.error || errorMessage}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className={styles.buttons}>
              <div
                className={classNames(styles.buttonWrapper, styles.deactivate)}
              >
                <Button
                  full
                  kind={disableMode ? 'destructive' : 'primary'}
                  loading={isSubmitting}
                  disabled={formUtils.isSubmitDisabled(errors, touched)}
                >
                  <button
                    type="submit"
                    id="2fa-submit"
                    data-test="submit-2fa-button"
                  >
                    {buttonLabel}
                  </button>
                </Button>
              </div>
              {onCancelClick && (
                <div className={styles.buttonWrapper}>
                  <Button full kind="subtle" onClick={onCancelClick}>
                    <button
                      type="button"
                      id="2fa-cancel"
                      data-test="cancel-2fa-button"
                    >
                      Cancel
                    </button>
                  </Button>
                </div>
              )}
            </div>
          </form>
        )}
    </Formik>
  );
};

CodeInput.propTypes = {
  labelText: PropTypes.string,
  buttonLabel: PropTypes.string,
  onCodeSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  onCancelClick: PropTypes.func,
  disableMode: PropTypes.bool,
};

CodeInput.defaultProps = {
  labelText: 'Two factor authentication code',
  buttonLabel: 'Submit',
  disableMode: false,
};

export default CodeInput;
