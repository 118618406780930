import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';

import createRootReducer from '../reducers';
import { history } from '../services';

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  // Build the middleware for intercepting and dispatching navigation actions
  const routeMiddleware = routerMiddleware(history);

  const store = createStore(
    createRootReducer,
    initialState,
    compose(applyMiddleware(routeMiddleware, sagaMiddleware))
  );

  return {
    ...store,
    runSaga: sagaMiddleware.run,
    close: () => store.dispatch(END),
  };
}
