import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import Button from 'common/components/Button';
import Icon from 'common/components/Icon';

const ExportInvoiceButton = ({ full }) => {
  const { pathname } = useLocation();

  return (
    <Button
      full={full}
      kind="primary"
      icon={
        <Icon.Arrow
          description={`Arrow small down`}
          size="medium"
          direction="down"
        />
      }
    >
      <Link to={`${pathname}/export`}>Export PDF</Link>
    </Button>
  );
};

ExportInvoiceButton.defaultProps = {
  full: PropTypes.false,
};

ExportInvoiceButton.propTypes = {
  full: PropTypes.bool,
};

export default ExportInvoiceButton;
