import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useOnMountDataRequest } from 'common/hooks';

import BackButton from 'common/components/BackButton';
import FadeInTransition from 'common/components/FadeInTransition';
import Layout from 'common/containers/Layout';
import LoadingPage from 'common/components/LoadingPage';

import WebhookDetails from '../components/WebhookDetails';

import {
  webhook as singleWebhookSelector,
  isFetchingSingleWebhook as isFetchingSingleWebhookSelector,
} from '../reducers';
import actions from '../actions';

const useWebhookData = (id) => {
  const { isMounting } = useOnMountDataRequest(
    actions.singleWebhookRequest,
    id
  );

  const isFetchingWebhookData = useSelector(isFetchingSingleWebhookSelector);
  const webhookData = useSelector(singleWebhookSelector);

  return {
    data: webhookData,
    isFetching: isMounting || isFetchingWebhookData,
  };
};

const Webhook = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const goBack = () => history.push('/integrations/webhooks');

  const { data, isFetching } = useWebhookData(id);

  return (
    <Layout
      heading="Webhook details"
      sideAction={<BackButton onGoBack={goBack} />}
    >
      {isFetching && <LoadingPage />}
      {!isFetching && data && (
        <FadeInTransition>
          <WebhookDetails data={data} />
        </FadeInTransition>
      )}
    </Layout>
  );
};

export default Webhook;
