import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useOnMountDataRequest = (requestAction, params) => {
  const dispatch = useDispatch();
  const [isMounting, setMountingStatus] = useState(true);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (isMounting) {
      dispatch(requestAction(params));
      setMountingStatus(false);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  return { isMounting };
};

export default useOnMountDataRequest;
