import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';
import { Previous, Next } from './PaginationButton';
import PageNumbers from './PageNumbers';

const Pagination = (props) => (
  <nav className={props.className} aria-label="Pagination Navigation">
    <ul className={styles.root}>
      <li>
        <Previous {...props} />
      </li>
      <li className={styles.pageNumbers}>
        <PageNumbers {...props} />
      </li>
      <li>
        <Next {...props} />
      </li>
    </ul>
  </nav>
);

Pagination.propTypes = {
  className: PropTypes.string,
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;
