export const payoutLabels = {
  bankAccountNumber: 'Account number',
  cryptoWalletAddress: 'Crypto wallet address',
  iban: 'IBAN',
  routingNumber: 'Routing number',
  sortCode: 'Sort code',
  swiftCode: 'SWIFT/BIC codes',
  variableAccountTypeNumber: 'IBAN/Account number',
};

export const payoutPlaceholders = {
  bankAccountNumber: 'Company’s account number',
  cryptoWalletAddress: 'Company’s crypto wallet address',
  iban: 'Company’s IBAN number',
  routingNumber: 'Company’s routing number',
  sortCode: 'Company’s sort code',
  swiftCode: 'Company’s SWIFT code',
  variableAccountTypeNumber: 'Company’s IBAN or Account number',
};
