import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'common/components/Loading';

import styles from './index.module.css';

const AttachmentLoading = ({ percentage, name }) => (
  <div className={styles.root}>
    <Loading kind="secondary" size="large" />
    <span className={styles.percentage}>{`${percentage}% - ${name}`}</span>
  </div>
);

AttachmentLoading.propTypes = {
  percentage: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default AttachmentLoading;
