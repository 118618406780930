import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'common/components/Checkbox';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import { contractPropType } from 'common/propTypes';

import Card from '../Card';

import styles from '../index.module.css';
import localStyles from './index.module.css';

const periodCopy = {
  daily: 'day',
  weekly: 'Monday',
  monthly: '1st day of the month',
};

const childrenProptypes = {
  payoutMinLimit: PropTypes.string.isRequired,
  payoutExtraFee: PropTypes.string.isRequired,
  payoutPeriod: PropTypes.string.isRequired,
  settlementCurrency: PropTypes.string.isRequired,
};

const Header = ({
  payoutMinLimit,
  referenceFiatCurrency,
  payoutPeriod,
  payoutExtraFee,
}) => (
  <Text color="rebranding-md-color-grey-70">
    Currently you need to reach a minimum value of {payoutMinLimit}{' '}
    {referenceFiatCurrency} in your balance for us to initiate the payout. If
    you want to get paid every {periodCopy[payoutPeriod]} instead of waiting to
    reach the minimum you can use the setting below, but you will pay an{' '}
    <strong className={localStyles.bold}>
      extra fee of {payoutExtraFee} {referenceFiatCurrency}
    </strong>
    .
  </Text>
);
Header.propTypes = childrenProptypes;

const AlwaysPayCaption = ({
  payoutMinLimit,
  referenceFiatCurrency,
  payoutPeriod,
  payoutExtraFee,
}) => (
  <>
    Payouts will start on every {periodCopy[payoutPeriod]} regardless of your
    total for each period. If the total is below the {payoutMinLimit}{' '}
    {referenceFiatCurrency} minimum{' '}
    <strong className={localStyles.orange} data-test="alwaysPayout-enabled">
      {' '}
      we will deduct a {payoutExtraFee} {referenceFiatCurrency} fee{' '}
    </strong>
    from your settlement.
  </>
);
AlwaysPayCaption.propTypes = childrenProptypes;

const AlwaysPayDisabledCaption = ({
  payoutMinLimit,
  referenceFiatCurrency,
  payoutPeriod,
}) => (
  <>
    We will extend settlement periods until you reach a total of{' '}
    {payoutMinLimit} {referenceFiatCurrency}. Payouts will be started on the
    next {periodCopy[payoutPeriod]} after reaching that total.{' '}
    <strong className={localStyles.blue} data-test="alwaysPayout-disabled">
      You will not pay any extra fees.
    </strong>
  </>
);
AlwaysPayCaption.propTypes = childrenProptypes;

const SettlementsSettingsForm = ({
  contract: {
    alwaysPayout,
    payoutMinLimit,
    payoutExtraFee,
    payoutPeriod,
    referenceFiatCurrency,
    settlementCurrency,
  },
  error,
  handleContractSubmit,
}) => {
  const childrenProps = {
    payoutMinLimit,
    referenceFiatCurrency,
    settlementCurrency,
    payoutPeriod,
    payoutExtraFee,
  };

  const [alwaysPayoutChecked, setAlwaysPayoutChecked] = useState(alwaysPayout);

  const handleChangeAlwaysPayout = () =>
    setAlwaysPayoutChecked(!alwaysPayoutChecked);

  return (
    <Card
      heading="Payout transfer"
      id="payout-settings"
      className={localStyles.form}
    >
      <Header {...childrenProps} />
      <>
        <div className={localStyles.checkBox}>
          <Checkbox
            label={`Always get paid every ${periodCopy[payoutPeriod]}`}
            name="alwaysPayout"
            id="alwaysPayout"
            checked={alwaysPayoutChecked}
            value={alwaysPayoutChecked}
            onChange={handleChangeAlwaysPayout}
          />
        </div>
        <Text color="rebranding-md-color-grey-70" size="caption">
          {alwaysPayoutChecked ? (
            <AlwaysPayCaption {...childrenProps} />
          ) : (
            <AlwaysPayDisabledCaption {...childrenProps} />
          )}
        </Text>
      </>
      <br />
      <div className={localStyles.footer}>
        <div className={styles['buttonWraper-2-cols']}>
          <Button
            full
            kind="primary"
            onClick={() => handleContractSubmit(alwaysPayoutChecked)}
          >
            <button
              type="button"
              id="contract-submit"
              data-test="contract-submit-button"
            >
              Save payout settings
            </button>
          </Button>
        </div>
        {error && (
          <Text color="rebranding-md-color-red" size="caption">
            Something went wrong trying to update your settings. Please contact
            support.
          </Text>
        )}
      </div>
      <hr className={localStyles.cardDivider} />
      <Text color="rebranding-md-color-grey-60">
        To change settlement currency or payout details{' '}
        <a
          href="https://support.xmoney.com/en/articles/8486598-how-to-update-my-settlements-and-payout-settings"
          target="_blank"
          rel="noreferrer"
          className={localStyles.link}
        >
          click here
        </a>{' '}
        to check how to proceed.{' '}
      </Text>
    </Card>
  );
};

SettlementsSettingsForm.propTypes = {
  contract: contractPropType.isRequired,
  error: PropTypes.string,
  handleContractSubmit: PropTypes.func.isRequired,
};

export default SettlementsSettingsForm;
