import SignInPage from './SignIn';
import SignOutPage from './SignOut';
import ForgotPasswordPage from './ForgotPassword';
import ResetPasswordPage from './ResetPassword';

export default {
  SignInPage,
  SignOutPage,
  ForgotPasswordPage,
  ResetPasswordPage,
};
