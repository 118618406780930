import React from 'react';
import PropTypes from 'prop-types';
import {
  kybStarted,
  integrationStatuses,
  complianceStatuses,
  businessDetailsEnteredIntegration,
  kybBaseInfoSubmitted,
} from 'common/constants';

import ProgressStep, {
  stepCompleted,
  stepCurrent,
  stepIdle,
} from 'common/components/ProgressStep';

import styles from './index.module.css';

const currentSteps = [stepCurrent, stepIdle];
const completionSteps = [stepCompleted, stepCurrent];

const determineSteps = (complianceStatus, integrationStatus) => {
  if ([kybStarted, kybBaseInfoSubmitted].includes(complianceStatus)) {
    return integrationStatus === businessDetailsEnteredIntegration
      ? completionSteps
      : currentSteps;
  }
  return null;
};

const KybSteps = ({ complianceStatus, integrationStatus }) => {
  const steps = determineSteps(complianceStatus, integrationStatus);
  if (!steps) return null;
  return (
    <div className={styles.root}>
      <ProgressStep status={steps[0]} text="Business Details" />
      <ProgressStep status={steps[1]} text="Verification Documents" />
    </div>
  );
};

KybSteps.propTypes = {
  integrationStatus: PropTypes.oneOf(integrationStatuses).isRequired,
  complianceStatus: PropTypes.oneOf(complianceStatuses).isRequired,
};

export default KybSteps;
