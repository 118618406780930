import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { orderItems, amount, customerPropType } from 'common/propTypes';
import { orderStatus } from 'payments/propTypes';
import Heading from 'common/components/Heading';
import LineItems from 'common/components/LineItems';
import Separator from 'common/components/Separator';
import StatusTag from 'common/components/StatusTag';
import Customer from '../Customer';
import DetailsFooter from '../DetailsFooter';

import styles from './index.module.css';

const OrderDetails = ({
  createdAt,
  customer: { email, address1, address2, city, state, country },
  discount,
  items,
  reference,
  referenceAmount,
  settlementAmount,
  shippingFee,
  status,
  subtotal,
  tax,
  totalAmount,
}) => (
  <div className={styles.panel}>
    <div className={styles.root}>
      <Heading level="3" size="3">
        <span className={styles.reference}>
          <strong className={styles.strong}>Reference: </strong>
          {reference}
        </span>
      </Heading>
      <div data-test={`order-details-order-status-${status}`}>
        <StatusTag status={status} />
      </div>
      <Customer
        email={email}
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        country={country}
        createdAt={createdAt}
      />
      {!!items && items.length > 0 && (
        <>
          <Separator />
          <LineItems
            items={items}
            subtotal={subtotal}
            tax={tax}
            shippingFee={shippingFee}
            discount={discount}
          />
        </>
      )}
    </div>
    <DetailsFooter
      totalAmount={totalAmount}
      referenceAmount={referenceAmount}
      settlementAmount={settlementAmount}
    />
  </div>
);

OrderDetails.propTypes = {
  reference: PropTypes.string.isRequired,
  items: orderItems,
  customer: PropTypes.shape(customerPropType).isRequired,
  createdAt: PropTypes.string.isRequired,
  totalAmount: amount.isRequired,
  settlementAmount: amount,
  subtotal: amount,
  tax: amount,
  shippingFee: amount,
  discount: amount,
  status: orderStatus.isRequired,
};

OrderDetails.defaultProps = {
  items: [],
  subtotal: null,
  tax: null,
  shippingFee: null,
  discount: null,
};

export default memo(OrderDetails);
