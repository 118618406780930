import React from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pagesReducer from './reducers';

import styles from './index.module.css';

export const getPageArray = (currentPage, totalPages) => [1, ..._.range(currentPage - 1, currentPage + 2), totalPages];

export const reducePages = (pages, totalPages, reducer) => _(pages).uniq().reduce(reducer(totalPages), []);

const PageNumbers = ({ setPage, totalPages, currentPage }) => {
  const pages = getPageArray(currentPage, totalPages);
  const handleClick = (page) => () => {
    setPage(page);
  };

  const handleKeyDown = (page) => ({ key = '' }) => {
    if (key === 'Enter') {
      setPage(page);
    }
  };

  const renderButton = (page, index) => {
    const isNumber = _.isNumber(page);
    const isCurrentPage = currentPage === page;
    const ariaLabel = isNumber ? `Page ${page}` : '...';
    const className = classNames({
      [styles.pageNumber]: isNumber,
      [styles.currentPage]: isCurrentPage,
    });

    return (
      <motion.span
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        key={`${page}-${index}`}
        className={className}
        aria-current="page"
        aria-label={ariaLabel}
        role="button"
        tabIndex="0"
        onClick={isNumber ? handleClick(page) : null}
        onKeyDown={isNumber ? handleKeyDown(page) : null}
      >
        {page}
      </motion.span>
    );
  };

  const buttons = reducePages(pages, totalPages, pagesReducer).map(
    renderButton
  );

  return <>{buttons}</>;
};

PageNumbers.propTypes = {
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PageNumbers;
