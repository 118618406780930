import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import colors from 'common/styles/colors';
import Text from 'common/components/Text';
import Icon from 'common/components/Icon';

import styles from './index.module.css';

const AlertBase = ({ color, text, children }) => {
  const rootStyles = classNames(styles.root, styles[color]);

  return (
    <div className={rootStyles}>
      <div className={styles.row}>
        <div className={styles.iconWrapper}>
          <Icon.Info
            fill={colors['rebranding-md-color-blue']}
            circleFill={colors['rebranding-md-color-blue-dark']}
            description="Info Sign"
          />
        </div>
        <Text color="white">{text}</Text>
        {children && (
          <div className={styles.actionButton} data-test="alert-action-button">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

AlertBase.propTypes = {
  color: PropTypes.oneOf(['blue', 'red']),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    ),
  ]).isRequired,
  children: PropTypes.node,
};

AlertBase.defaultProps = {
  color: 'blue',
};

export default AlertBase;
