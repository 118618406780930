import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';

import styles from './index.module.css';

const Close = ({ onClick }) => (
  <button type="button" className={styles.icon} onClick={onClick}>
    <Icon.Cross fill={colors['rebranding-md-color-brand-black']} size="small" />
  </button>
);

Close.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default memo(Close);
