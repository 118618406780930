import React from 'react';

import Icon from 'common/components/Icon';
import colors from 'common/styles/colors';
import styles from './index.module.css';

const CheckBubble = () => (
  <div className={styles.wrapper}>
    <Icon.Checkmark
      size="small"
      description="complete"
      fill={colors['rebranding-color-white']}
    />
  </div>
);

export default CheckBubble;
