import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { keys, lowerCase, capitalize } from 'lodash';

import { api } from 'app/services';
import { track, page } from 'common/analytics';
import SignUp from '../components/SignUp';
import actions from '../../auth/actions';

const FIELDS_TO_PREPEND = ['first_name', 'last_name'];

const createFieldErrorMessage = (error) => {
  if (!FIELDS_TO_PREPEND.includes(error[0])) return error[1][0];

  const errorField = capitalize(error[0].split('_').join(' '));
  const errorMessage = error[1][0];

  return `${errorField} ${errorMessage}`;
};

const SignUpPage = ({ tokenSignIn, ...props }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState(null);
  const recaptchaRef = React.useRef();

  const handleError = (error) => {
    setSubmitting(false);
    if (typeof error === 'string') return setErrorMessage(error);
    if (Array.isArray(error) && error[1] && error[1][0])
      return setErrorMessage(createFieldErrorMessage(error));
    if (Array.isArray(error) && error[0])
      return setErrorMessage(capitalize(lowerCase(error[0])));

    return setErrorMessage('Something went wrong');
  };

  const handleSubmit = async (newValues) => {
    setSubmitting(true);
    const captchaToken = await recaptchaRef.current.executeAsync();
    if (captchaToken) return setValues({ captchaToken, ...newValues });
    handleError('Unable to verify user.');
  };

  useEffect(() => {
    page('Sign Up');
  }, []);

  useEffect(() => {
    const handleSuccess = (result, email) => {
      const merchant =
        result.response.merchants[keys(result.response.merchants)[0]];
      tokenSignIn({
        token: merchant.attributes.token,
        intercomUserHash: merchant.attributes.intercomUserHash,
        email,
      });
    };

    if (values && submitting) {
      track('Sign Up Form submitted');

      api.signUp(values).then((result) => {
        if (result.error) {
          track('Error returned during Sign Up');
          handleError(result.error);
        } else {
          handleSuccess(result, values.email);
        }
      }, handleError);
    }
  }, [submitting, values, tokenSignIn]);

  return (
    <SignUp
      {...props}
      submitting={submitting}
      onSubmit={handleSubmit}
      errorMessage={errorMessage}
      recaptchaRef={recaptchaRef}
    />
  );
};

SignUpPage.propTypes = {
  tokenSignIn: PropTypes.func.isRequired,
};

const ConnectedSignUpPage = connect(null, {
  tokenSignIn: actions.tokenSignIn,
})(SignUpPage);

export default ConnectedSignUpPage;
