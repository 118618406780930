import React from 'react';
import PropTypes from 'prop-types';

import Tabs, { Tab } from 'common/components/Tabs';
import { ordersRoute, invoicesRoute } from 'app/containers/routes.js';

const PaymentsTabs = ({ isInvoicingEnabled }) => (
    <Tabs dataTest="paymentsTabs">
      <Tab to={ordersRoute}>Orders</Tab>
      {isInvoicingEnabled && <Tab to={invoicesRoute}>Invoices</Tab>}
    </Tabs>
  );

PaymentsTabs.propTypes = {
  isInvoicingEnabled: PropTypes.bool,
};

export default PaymentsTabs;
