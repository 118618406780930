import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';

import TextArea from 'common/components/TextArea';
import Button from 'common/components/Button';
import formUtils from 'common/formUtils.js';

import styles from './index.module.css';

const Form = ({ onSubmit }) => {
  const commentsField = useRef(null);

  return (
    <Formik
      initialValues={{ comments: '' }}
      onSubmit={(values) => onSubmit(values.comments)}
    >
      {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <Field name="comments" validate={formUtils.validateRequired}>
            {({ field, meta }) => (
              <div className={styles.row}>
                <TextArea
                  id="comments"
                  error={meta.touched && meta.error}
                  errorText={meta.error}
                  inputRef={commentsField}
                  {...field}
                />
              </div>
            )}
          </Field>
          <div className={styles.formFooter}>
            <div className={styles.buttonWrapper}>
              <Button kind="secondary" name="back-to-app" full>
                <a href="/">Close</a>
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                kind="primary"
                disabled={formUtils.isSubmitDisabled(errors, touched)}
                full
              >
                <button
                  type="submit"
                  id="progress-status"
                  data-test="reset-comments-button"
                >
                  Submit
                </button>
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
};

Form.defaultProps = {
  submitted: false,
};

export default Form;
