import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from './index.module.css';
import HeaderCell from './HeaderCell';

const TableHeader = ({ headers }) => (
  <tr className={styles.tr}>
    {_.map(headers, ({ label, justify }) => (
      <HeaderCell content={label} justify={justify} key={label} />
    ))}
  </tr>
);

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      justify: PropTypes.string,
    })
  ).isRequired,
};

export default TableHeader;
