import mirrorCreator from 'mirror-creator';
import { createActions } from 'redux-actions';

const actionTypes = mirrorCreator([
  'CREDENTIALS_REQUEST',
  'CREDENTIALS_SUCCESS',
  'CREDENTIALS_FAILURE',
  'NEW_CREDENTIALS_REQUEST',
  'NEW_CREDENTIALS_SUCCESS',
  'NEW_CREDENTIALS_FAILURE',
  'CLEAR_CREDENTIALS_SECRET',
  'SET_INTEGRATION_TYPE',
  'WEBHOOKS_REQUEST',
  'WEBHOOKS_SUCCESS',
  'WEBHOOKS_FAILURE',
  'SINGLE_WEBHOOK_REQUEST',
  'SINGLE_WEBHOOK_SUCCESS',
  'SINGLE_WEBHOOK_FAILURE',
]);

const actionCreators = createActions(
  {
    [actionTypes.NEW_CREDENTIALS_FAILURE]: Error,
    [actionTypes.CREDENTIALS_FAILURE]: Error,
    [actionTypes.WEBHOOKS_FAILURE]: Error,
    [actionTypes.SINGLE_WEBHOOK_FAILURE]: Error,
    [actionTypes.SINGLE_WEBHOOK_REQUEST]: (id) => ({ id }),
  },
  actionTypes.CREDENTIALS_REQUEST,
  actionTypes.CREDENTIALS_SUCCESS,
  actionTypes.NEW_CREDENTIALS_REQUEST,
  actionTypes.NEW_CREDENTIALS_SUCCESS,
  actionTypes.CLEAR_CREDENTIALS_SECRET,
  actionTypes.SET_INTEGRATION_TYPE,
  actionTypes.WEBHOOKS_REQUEST,
  actionTypes.WEBHOOKS_SUCCESS,
  actionTypes.SINGLE_WEBHOOK_SUCCESS
);

export default {
  ...actionTypes,
  ...actionCreators,
};
