import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'common/components/Heading';
import Icon from 'common/components/Icon';
import Button from 'common/components/Button';
import Text from 'common/components/Text';

import styles from './index.module.css';

const defaultCopy = {
  title: 'Settlements not enabled yet',
  firstDescription: 'Crypto payments are still coming through!',
  secondDescription: 'To enable settlements, we need to verify your business.',
};
const sandboxCopy = {
  title: 'Settlements not available in sandbox',
  firstDescription:
    "You'll be able to verify your business and enable settlements once you're in production.",
  secondDescription: "For now, in sandbox, you don't need to configure this.",
};

const KybMissingSettlements = ({ handleStartKyb, isSandboxMode }) => {
  const { title, firstDescription, secondDescription } = isSandboxMode
    ? sandboxCopy
    : defaultCopy;

  return (
    <div className={styles.root}>
      <Heading size="1" level="1" color="blue" id="page-title-settlementws">
        {title}
      </Heading>
      <Icon.SuspendedSettlements description="Settlements are currently suspended" />
      <Text>
        {firstDescription}
        <br />
        {secondDescription}
      </Text>
      <Button
        kind="primary"
        onClick={handleStartKyb}
        disabled={isSandboxMode}
        name="initiate-kyb"
      >
        <button type="button">Verify my business</button>
      </Button>
    </div>
  );
};

KybMissingSettlements.propTypes = {
  handleStartKyb: PropTypes.func.isRequired,
  isSandboxMode: PropTypes.bool.isRequired,
};

export default KybMissingSettlements;
