import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import 'app';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_RELEASE_NAME,
});

/* eslint-disable no-console*/
console.log(
  `%c==> Release: ${process.env.REACT_APP_RELEASE_NAME} <==`,
  'color: green; font-weight: bold'
);
/* eslint-enable no-console*/
